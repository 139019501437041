import React from "react";

function PaymentNokImage(props) {

  return (
    <svg width="97" height="82" viewBox="0 0 97 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.16906" y="41.2775" width="44.1369" height="40.4469" rx="6.35902" stroke="#816CFF" strokeWidth="0.552959" strokeDasharray="1.11 1.11"/>
      <path d="M66.0584 8.51539C68.4098 8.51539 70.3161 6.60915 70.3161 4.25769C70.3161 1.90623 68.4098 0 66.0584 0C63.7069 0 61.8008 1.90623 61.8008 4.25769C61.8008 6.60915 63.7069 8.51539 66.0584 8.51539Z" fill="#CEC8F3"/>
      <path d="M4.49463 12.165V19.3535" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M8.09715 15.7539H0.908691" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <rect x="27.5723" y="15.5781" width="50.0198" height="47.9698" rx="6.14998" fill="#E0DBFF"/>
      <path d="M52.8791 20.4014H22.2915C16.174 20.4014 13.1152 23.4601 13.1152 29.5776V60.1652C13.1152 66.2827 16.174 69.3415 22.2915 69.3415H52.8791C58.9966 69.3415 62.0554 66.2827 62.0554 60.1652V29.5776C62.0554 23.4601 58.9966 20.4014 52.8791 20.4014ZM35.5461 32.6364C35.5461 31.5108 36.4597 30.5972 37.5853 30.5972C38.7109 30.5972 39.6245 31.5108 39.6245 32.6364V45.0646C39.6245 46.1902 38.7109 47.1038 37.5853 47.1038C36.4597 47.1038 35.5461 46.1902 35.5461 45.0646V32.6364ZM37.6397 57.1065C36.1389 57.1065 34.9069 55.8884 34.9069 54.3876C34.9069 52.8867 36.1117 51.6687 37.6125 51.6687H37.6397C39.1433 51.6687 40.3586 52.8867 40.3586 54.3876C40.3586 55.8884 39.1406 57.1065 37.6397 57.1065Z" fill="#442ECB"/>
      <path d="M92.3579 47.8664L85.4757 34.9953C84.1424 32.5016 80.5601 32.5016 79.2257 34.9953L72.3435 47.8664C71.1206 50.1539 72.781 52.9163 75.38 52.9163H89.3214C91.9193 52.9163 93.5808 50.1528 92.3579 47.8664ZM81.5695 40.4163C81.5695 39.985 81.9195 39.635 82.3507 39.635C82.782 39.635 83.132 39.985 83.132 40.4163V44.5829C83.132 45.0142 82.782 45.3642 82.3507 45.3642C81.9195 45.3642 81.5695 45.0142 81.5695 44.5829V40.4163ZM82.3716 48.7496C81.7966 48.7496 81.3245 48.2829 81.3245 47.7079C81.3245 47.1329 81.7861 46.6663 82.3611 46.6663H82.3716C82.9476 46.6663 83.4132 47.1329 83.4132 47.7079C83.4132 48.2829 82.9466 48.7496 82.3716 48.7496Z" fill="#FF8C5A"/>
    </svg>
  );
}

export default PaymentNokImage;