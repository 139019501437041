import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate, useParams } from 'react-router-dom';
import { getSubdomain } from "../shared/functions.js";
import routes from "../../routes/routes.js";

export default function Checkout(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState } = React.useContext(AppContext);

  let { ticketToSaleId, eventElvisId } = useParams();
  var navigate = useNavigate();
  const subdomain = useMemo(() => getSubdomain(), []);

  return (
    <div onClick={ authState.isAuthenticated && authState.roles[0] === "ROLE_USER" ? 
      () => navigate(`${routes.landing}/${eventElvisId}/checkout`)
      :
      () => navigate(routes.auth)
      } 
      className={`sale-resume d-flex justify-content-center align-items-center`}
    >
      {t('landingElvis.resume')}
      <div className={`d-flex justify-content-center align-items-center`}>
        { props.ticketSaleQuantity.map(item => item.quantity).reduce((prev, curr) => prev + curr, 0) }
      </div>
    </div>
  )

}
