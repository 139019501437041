import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getEventById } from '../../services/axiosService.js';
import routes from '../../routes/routes.js';
import EventsCreateComponent from './EventsCreateComponent.jsx';
import EventDetailComponentNotADraft from './EventDetailComponentNotADraft.jsx';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';

function EventDetailComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState, dispatch } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const [draft, setDraft] = useState(1);
  /*   const [select, setSelect] = useState([0, 0, 0]);
  const [selectOpen, setSelectOpen] = useState([false, false, false]); */

  /* const updateSelectStatus = index => {
    let newArrOpen = selectOpen;
    newArrOpen[index] = !newArrOpen[index];
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
  }

  const updateSelect = (index, value) => {
    let newArr = select;
    newArr[index] = value;
    let newArrOpen = selectOpen;
    newArrOpen[index] = false;
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
    setSelect([newArr[0], newArr[1], newArr[2]]);
  }
 */
  const navigateToTicketerSections = (id) => {
    var route = id;
    if (id === -1) {
      route = -1;
    }
    if (id === 1) {
      route = `${routes.events}/${eventId}${routes.createData}`;
    }
    if (id === 2) {
      route = `${routes.events}/${eventId}${routes.createNewTickets}`;
    }
    if (id === 3) {
      route = `${routes.events}/${eventId}${routes.createPicture}`;
    }
    if (id === 7) {
      route = `${routes.events}/${eventId}${routes.createSocial}`;
    }
    if (id === 8) {
      route = `${routes.events}/${eventId}${routes.createLocation}`;
    }
    if (id === 9) {
      route = `${routes.events}/${eventId}${routes.createDateTime}`;
    }
    navigate(route);
  };

  const {
    isLoading,
    isError,
    error,
    data: event,
    isFetching
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      if (res.data.status === 3) {
        setDraft(2);
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    setDraft(event?.data?.status === 3 ? 2 : 0);
  }, []);

  return (
    <section
      id="detail-create-event-section"
      className={`ticketer-section ${
        !draft ? 'detail-event-section' : ''
      } d-flex flex-column justify-content-start align-items-center`}
    >
      {isLoading ? (
        <SpinnerPurple />
      ) : isError ? (
        <h3 className="fetching-error">{t('home.errorRetrievingEvents')}</h3>
      ) : draft === 2 ? (
        <EventsCreateComponent
          event={event?.data}
          navigateToTicketerSections={navigateToTicketerSections}
        />
      ) : draft === 0 ? (
        <EventDetailComponentNotADraft
          event={event?.data}
          navigateToTicketerSections={navigateToTicketerSections}
        />
      ) : null}
    </section>
  );
}

export default EventDetailComponent;
