import React from "react";

function TermsPolicyButton(props) {
  return (
    <button
      className={`terms-policy-button d-flex justify-content-center align-items-center ${
        props.disabled && "disabled"
      }`}
      onClick={props.function}
      disabled={props.disabled ? true : false}
    >
      {props.icon}
      {props.text}
    </button>
  );
}

export default TermsPolicyButton;
