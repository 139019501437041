import React from "react";

function StarshipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="79"
      fill="none"
      viewBox="0 0 81 79"
    >
      <circle
        cx="40.179"
        cy="45.68"
        r="28.313"
        stroke="#816CFF"
        strokeDasharray="1.06 1.06"
        strokeWidth="0.531"
      ></circle>
      <path
        fill="#F0F0F0"
        d="M31.853 67.058c13.173 0 23.852-10.68 23.852-23.853 0-13.173-10.679-23.853-23.852-23.853C18.679 19.352 8 30.032 8 43.205c0 13.173 10.68 23.853 23.853 23.853z"
      ></path>
      <path
        fill="#9A9FA5"
        d="M38.79 60.255c-1.843 1.842-8.258 3.48-9.525 3.79a1.163 1.163 0 01-1.402-1.433c.351-1.32 2.194-8.009 4.027-9.842 1.032-1.032 2.371-1.563 3.718-1.55 1.302.031 2.553.583 3.52 1.55 1.97 1.973 1.819 5.33-.338 7.485zM62.085 48.31l-.601 3.66c-.699 4.403-3.727 8.153-7.874 9.807l-5.544 2.213a1.01 1.01 0 01-.443.093c-.256 0-.535-.093-.745-.28a1.127 1.127 0 01-.396-1.117l1.544-7.771c-.226.044-.44.104-.67.144-.285.049-.571.072-.855.072a5.257 5.257 0 01-3.7-1.551l-4.477-4.48c-1.211-1.211-1.764-2.912-1.48-4.554.04-.226.1-.438.143-.662l-7.722 1.535c-.07.024-.163.024-.233.024-.35 0-.676-.14-.886-.42a1.16 1.16 0 01-.186-1.188l2.236-5.567c1.631-4.123 5.381-7.152 9.784-7.85l3.613-.604c5.966-6.842 15.335-10.58 27.32-10.58.965 0 1.748.782 1.748 1.747.004 11.994-3.732 21.364-10.576 27.33zm4.102-11.726A11.6 11.6 0 0055.32 25.718c-6.753 2.91-13.145 8.551-15.032 19.427-.089.515.105 1.084.508 1.484l4.477 4.48c.4.4.971.592 1.486.506 10.879-1.885 16.52-8.275 19.428-15.03zm-12.74-3.367a5.24 5.24 0 00-5.241 5.242 5.24 5.24 0 005.241 5.241 5.24 5.24 0 005.241-5.241 5.24 5.24 0 00-5.24-5.242z"
      ></path>
      <path
        stroke="#F27327"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.223"
        d="M6.39 56v10.801M11.801 61.39H1"
      ></path>
      <path
        stroke="#FF8C5A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.866"
        d="M76.367 57.458v7.65M80.2 61.276h-7.651"
      ></path>
      <path
        fill="#816CFF"
        d="M49.63 4.95a2.475 2.475 0 100-4.95 2.475 2.475 0 000 4.95z"
      ></path>
      <path
        fill="#C8BFFF"
        d="M60.206 78.759a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6z"
      ></path>
      <path
        fill="#FF8C5A"
        d="M49.63 75.158a2.475 2.475 0 100-4.95 2.475 2.475 0 000 4.95z"
      ></path>
      <path
        fill="#9A9FA5"
        d="M16.646 18.337h.007c.227 0 .325.28.154.428a4.603 4.603 0 00-1.52 4.399c.372 1.939 1.968 3.415 3.952 3.68a4.735 4.735 0 003.337-.783c.188-.13.437.04.38.259-.786 3.075-3.821 5.267-7.284 4.773-2.876-.41-5.147-2.718-5.487-5.563a6.33 6.33 0 01.858-4.044 6.523 6.523 0 015.603-3.15z"
      ></path>
    </svg>
  );
}

export default StarshipIcon;
