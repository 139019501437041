import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getTicketingCompanyById,
  getEventById,
  patchTicketingCompany,
  patchEvent
} from '../../services/axiosService.js';
import { Formik } from 'formik';
import AngleLeft from '../icons/AngleLeft.jsx';
import ProfileButton from '../ui/ProfileButton.jsx';
import AdviceFooter from '../ui/AdviceFooter.jsx';
import SuccessFailPopupLarge from '../ui/SuccessFailPopupLarge.jsx';

function TicketingSectionComponent({
  initialValues,
  validationSchema,
  ticketer,
  title,
  pagination,
  description,
  paragraph,
  footer,
  children,
  titleFunction,
  breadcrum,
  button,
  buttonIcon,
  buttonText,
  buttonFunction,
  buttonDisabled,
  noFormikValidation,
  nextPage,
  eventTitle,
  advicePayment
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setTicketerModal,
    setTicketerSectionModal
  } = React.useContext(AppContext);

  var location = useLocation().pathname;
  var navigate = useNavigate();
  let { eventId } = useParams();

  const [popup, setPopup] = useState(false);

  const { mutate } = useMutation(
    location.includes('ticketer')
      ? (values) =>
          patchTicketingCompany(
            authState.token,
            data.data[0].id,
            values,
            null,
            null
          )
      : (values) =>
          patchEvent(authState.token, data.data.id, values, null, null),
    {
      onSuccess: (res) => {
        /*if (location.includes('ticketer/company-info') || location.includes('ticketer/contact-info')) {
        queryClient.setQueryData(
          ['ticketingCompany'],
          (oldData) => oldData ? {
            ...oldData,
            data: res.data
          } : oldData
        )
      }*/
        if (!nextPage) {
          if (res) {
            navigate(
              location.includes('ticketer')
                ? routes.ticketer
                : `${routes.events}/${data.data.id}`
            );
          }
        }
      },
      onError: (err) => {
        if (
          err.response.status === 409 &&
          err.response.data.fields[0] === 'ourSubdomain'
        ) {
          setPopup({
            state: 0,
            text:
              t('ticketer.failSubdomain') +
              '. (' +
              err.response.data.message +
              ')'
          });
        }
        console.error(err);
      }
    }
  );

  const { data } = useQuery({
    queryKey: location.includes('ticketer')
      ? ['ticketingCompany']
      : ['events', eventId],
    queryFn: location.includes('ticketer')
      ? () => getTicketingCompanyById(authState.token)
      : () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    setTicketerModal(false);
    setTicketerSectionModal(false);
  }, []);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <section
      id="ticketer-sections-section"
      className="ticketer-sections-section d-flex justify-content-center"
    >
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values, actions) => {
          mutate(values);
        }}
      >
        {(props) => {
          const { errors, setFieldTouched } = props;
          i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });
          return (
            <div className="ticketer-sections-container d-flex flex-column">
              <div className="ticketer-sections-title d-flex justify-content-between">
                <div
                  onClick={titleFunction}
                  className="ticketer-sections-title-left d-flex align-items-center"
                >
                  {breadcrum ? <AngleLeft /> : null}
                  <h2>
                    {eventTitle
                      ? eventTitle.length > 14
                        ? eventTitle.slice(0, 14) + '... / Tickets / '
                        : eventTitle + ' / Tickets / '
                      : ticketer
                      ? t('ticketer.title') + ' / '
                      : t('events.title') + ' / '}
                    <span>{title}</span>
                  </h2>
                </div>
                <div className="ticketer-sections-title-right d-flex align-items-center">
                  {button ? (
                    <ProfileButton
                      icon={buttonIcon}
                      text={buttonText}
                      function={buttonFunction}
                      disabled={buttonDisabled}
                    />
                  ) : null}
                </div>
              </div>
              {pagination ? (
                <div className="ticketer-sections-counter d-flex justify-content-center align-items-center">
                  {pagination}
                </div>
              ) : null}
              <div className="ticketer-sections-title d-flex flex-column">
                <h3>{description}</h3>
                {paragraph && <p>{paragraph}</p>}
              </div>
              <div className="terms-and-policy-actions d-flex flex-column-reverse flex-lg-column">
                <div className="terms-and-policy-text d-flex flex-column">
                  {children}
                </div>
                {footer ? <AdviceFooter footer={footer} /> : null}
              </div>
            </div>
          );
        }}
      </Formik>
    </section>
  );
}

export default TicketingSectionComponent;
