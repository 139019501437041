import React from "react";

function PlanPro() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      fill="none"
      viewBox="0 0 20 21"
    >
      <g>
        <path
          fill="#A5A9B8"
          d="M8.334 18.625A.625.625 0 017.709 18v-6.042H4.167a.625.625 0 01-.465-1.043l7.5-8.333a.623.623 0 011.09.418v6.042h3.542a.625.625 0 01.465 1.043l-7.5 8.333a.627.627 0 01-.465.207zM5.57 10.708h2.764c.345 0 .625.28.625.625v5.039l5.471-6.08h-2.763a.625.625 0 01-.625-.625V4.628l-5.472 6.08z"
        ></path>
      </g>
    </svg>
  );
}

export default PlanPro;
