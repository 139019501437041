import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../App.js";
import TibetLandingComponentTitle from '../shared/TibetLandingComponentTitle';
import ProfileButton from '../ui/ProfileButton';
import TermsPolicyButton from '../ui/TermsPolicyButton';
import Check from "../icons/Check";
import routes from '../../routes/routes';

export default function TibetLandingComponent5() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setLandingModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  const cards = [
    { id: 0, title: t('landing.priceCardsQuantity1'), buttonText: t('landing.priceCardsTitle1'), bullets: [t('landing.priceCardsBullet1_1'), t('landing.priceCardsBullet1_2'), t('landing.priceCardsBullet1_3'), t('landing.priceCardsBullet1_4'), t('landing.priceCardsBullet1_5')] },
    { id: 1, title: t('landing.priceCardsQuantity2'), buttonText: t('landing.priceCardsTitle2'), bullets: [t('landing.priceCardsBullet2_1'), t('landing.priceCardsBullet2_2'), t('landing.priceCardsBullet2_3'), t('landing.priceCardsBullet2_4'), t('landing.priceCardsBullet2_5'), t('landing.priceCardsBullet2_6'), t('landing.priceCardsBullet2_7')] },
    { id: 2, title: t('landing.priceCardsQuantity3'), buttonText: t('landing.priceCardsTitle3'), bullets: [t('landing.priceCardsBullet3_1'), t('landing.priceCardsBullet3_2'), t('landing.priceCardsBullet3_3'), t('landing.priceCardsBullet3_4'), t('landing.priceCardsBullet3_5'), t('landing.priceCardsBullet3_6')] },
  ];

  document.addEventListener('scroll', function (e) {
    const el = document.getElementById("pricing-landing-section");
    const observer = new IntersectionObserver((entries) => {
        if(entries[0].isIntersecting){
          document.getElementById('pricing-landing-section').classList.add('animate');
        } else {
          document.getElementById('pricing-landing-section').classList.remove('animate');
        }
    });
    if (el) {
      observer.observe(el);
    }
  });

  return (
    <section id="pricing-landing-section" className="pricing-landing-container d-flex flex-column justify-content-center align-items-center">
      <TibetLandingComponentTitle
        text1 = { t('landing.pricingTitle1') }
        text2 = { t('landing.pricingTitle2') }
      />
      <div className="pricing-landing-cards-container-scroll"> 
        <div className="pricing-landing-cards-container d-flex flex-sm-wrap justify-content-start justify-content-sm-center align-items-center">
          { cards.map(item => {
            return (
              <div key={ item.id } className="pricing-landing-card d-flex flex-column align-items-center">
                  <div className="pricing-landing-card-top d-flex flex-column justify-content-center align-items-center">
                    <ProfileButton
                      icon = { null } 
                      text = { item.buttonText } 
                      function = { null }
                      disabled = { true }
                    />
                    <h2>{ item.title }</h2>
                  </div>
                <div className="ticket-cut"></div>
                <div className="pricing-landing-card-bottom d-flex flex-column justify-content-between align-items-center">
                  <div className="pricing-landing-card-text d-flex flex-column justify-content-center">
                    { item.bullets.map((bullet, index) => {
                      return (
                        <div key={ index } className="pricing-landing-card-text-line d-flex align-items-center">
                          <Check/>
                          { bullet }
                        </div>
                      )
                    })}
                  </div>
                  <TermsPolicyButton text = { t('landing.priceCardsButton') } disabled = { false } function = { item.id === 2 ? () => window.scrollTo(({ top: document.getElementById("contact-landing-section").offsetTop - 80, behavior: 'smooth'})) : () => window.scrollTo(({ top: document.getElementById("contact-landing-section").offsetTop - 80, behavior: 'smooth'})) }/>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )

}
