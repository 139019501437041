import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";

export default function NotFoundPage() {
  const navigation = useNavigate();
  const { state: authState } = useContext(AppContext);

  useEffect(() => {
    const isToken = localStorage.getItem("token");
    if (!authState?.isAuthenticated || !isToken) {
      navigation("/auth");
    }
  }, []);

  return (
    <main id="notfound-page">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <h2>Página no encontrada</h2>
      </div>
    </main>
  );
}
