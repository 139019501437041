import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { createDiscountByEventId } from '../../services/axiosService.js';
import { AppContext } from '../../App.js';
import { queryClient } from '../../index.js';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

function ModalDiscount() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    discountModal,
    setDiscountModal
  } = React.useContext(AppContext);

  const {
    isLoading: mutateIsLoading,
    isSuccess: mutateIsSuccess,
    isError: mutateIsError,
    error: mutateError,
    mutate
  } = useMutation(
    (values) =>
      createDiscountByEventId(authState.token, discountModal.eventId, values),
    {
      onSuccess: (res) => {
        if (res.data) {
          queryClient.setQueryData(
            ['codes', discountModal.eventId],
            (oldData) =>
              oldData
                ? {
                    ...oldData,
                    data: {
                      ...oldData.data,
                      promoCodes: [...oldData.data.promoCodes, res.data]
                    }
                  }
                : oldData
          );
          discountModal.popup({ state: 1, text: t('events.discountSuccess') });
          setDiscountModal(false);
        }
      },
      onError: (err) => {
        console.error(err);
        discountModal.popup({ state: 2, text: t('events.discountFail') });
      }
    }
  );

  var initialData = {
    categoryName: 0,
    quantity: 0,
    code: '',
    percentage: 0
  };

  const dataSchema = Yup.object().shape({
    categoryName: Yup.string().required(t('register.error2')),
    quantity: Yup.number()
      .required(t('register.error2'))
      .positive(t('ticketerSection.currencyError'))
      .integer(),
    code: Yup.string().required(t('register.error2')),
    percentage: Yup.number()
      .required(t('register.error2'))
      .positive(t('ticketerSection.currencyError'))
      .integer()
  });

  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape' && document.querySelector('.modal-background')) {
      setDiscountModal(false);
    }
  });

  document.addEventListener('click', (e) => {
    if (e.target === document.querySelector('.modal-container')) {
      setDiscountModal(false);
    }
  });

  return (
    <div
      id="modal-delete-section"
      className={discountModal ? 'modal-background' : 'd-none'}
    >
      <div className="modal-container modal-relative justify-content-center align-items-center">
        <div
          className={`modal-terms-container modal-cancel-container modal-discount sign-mail-box d-flex flex-column justify-content-center`}
        >
          <div className="modal-cancel-button-text d-flex flex-column justify-content-center">
            <h2>{t('ticket.discountTitle')}</h2>
            <p>{t('ticket.discountParagraph')}</p>
          </div>
          <Formik
            // *** Initial values that the form will take
            enableReinitialize={true}
            initialValues={initialData}
            // *** Yup Validation Schema ***
            validationSchema={dataSchema}
            validateOnChange={true}
            validateOnBlur={true}
            // ** onSubmit Event
            onSubmit={(values, actions) => {
              mutate(values);
            }}
          >
            {/* We obtain props from Formik */}
            {(props) => {
              const { touched, errors, values, isValid, setFieldTouched } =
                props;
              i18n.on('languageChanged', () => {
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                });
              });
              return (
                <Form className="w-100 discount-form d-flex flex-wrap">
                  <div className="discount-field-container d-flex flex-column">
                    <h5>{t('ticket.discountField1')}</h5>
                    <Field
                      className={`ticketer-field ticketer-select ${
                        errors.categoryName && touched.categoryName
                          ? 'red-border'
                          : null
                      } ${touched.categoryName ? 'purple-border' : null} ${
                        values.categoryName !== 0 ? 'select-black' : null
                      }`}
                      id="discount-categoryName"
                      name="categoryName"
                      as="select"
                    >
                      <option value="0" defaultValue disabled hidden>
                        {t('ticket.chooseTicket')}
                      </option>
                      {discountModal?.data
                        ?.filter((obj, pos, arr) => {
                          return (
                            arr
                              .map((mapObj) => mapObj?.category?.id)
                              .indexOf(obj.category.id) === pos
                          );
                        })
                        ?.map((item) => {
                          return (
                            <option key={item.id} value={item.category?.id}>
                              {item.category?.name}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <div className="discount-field-container d-flex flex-column">
                    <h5>{t('ticket.discountField2')}</h5>
                    <Field
                      className={
                        errors.quantity && touched.quantity
                          ? 'ticketer-field red-border'
                          : touched.quantity
                          ? 'ticketer-field purple-border'
                          : 'ticketer-field'
                      }
                      id="discount-quantity"
                      type="number"
                      name="quantity"
                      placeholder=""
                      min="0"
                      step="1"
                    />
                    {errors.quantity && touched.quantity ? (
                      <ErrorMessage name="quantity">
                        {(msg) => (
                          <div className="ticketer-field-error">{msg}</div>
                        )}
                      </ErrorMessage>
                    ) : null}
                  </div>
                  <div className="discount-field-container d-flex flex-column">
                    <h5>{t('ticket.discountField3')}</h5>
                    <Field
                      className={
                        errors.code && touched.code
                          ? 'ticketer-field red-border'
                          : touched.code
                          ? 'ticketer-field purple-border'
                          : 'ticketer-field'
                      }
                      id="discount-code"
                      type="text"
                      name="code"
                      placeholder="Ej: Tibet10"
                    />
                    {errors.code && touched.code ? (
                      <ErrorMessage name="code">
                        {(msg) => (
                          <div className="ticketer-field-error">{msg}</div>
                        )}
                      </ErrorMessage>
                    ) : null}
                  </div>
                  <div className="discount-field-container d-flex flex-column">
                    <h5>{t('ticket.discountField4')}</h5>
                    <Field
                      className={
                        errors.percentage && touched.percentage
                          ? 'ticketer-field red-border'
                          : touched.percentage
                          ? 'ticketer-field purple-border'
                          : 'ticketer-field'
                      }
                      id="discount-percentage"
                      type="number"
                      name="percentage"
                      placeholder=""
                      min="0"
                      step="1"
                    />
                    {errors.percentage && touched.percentage ? (
                      <ErrorMessage name="name">
                        {(msg) => (
                          <div className="ticketer-field-error">{msg}</div>
                        )}
                      </ErrorMessage>
                    ) : null}
                  </div>
                  {mutateIsLoading ? (
                    <div className="modal-cancel-button-container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center">
                      <button
                        className="register-email-form-button"
                        type="button"
                        disabled={true}
                      >
                        {t('profile.sending')}
                      </button>
                    </div>
                  ) : (
                    <div className="modal-cancel-button-container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center">
                      <button
                        className="register-email-form-button"
                        onClick={() => setDiscountModal(false)}
                      >
                        {t('events.cancelButton1')}
                      </button>
                      <button
                        className="register-email-form-button"
                        type="submit"
                        disabled={
                          !(
                            isValid &&
                            values.code &&
                            values.percentage &&
                            values.quantity &&
                            values.categoryName
                          )
                        }
                      >
                        {t('ticket.discountButton1')}
                      </button>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ModalDiscount;
