import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import TermsPolicyButton from "../ui/TermsPolicyButton";
import PaymentOkImage from "../icons/PaymentOkImage.jsx";
import PaymentNokImage from "../icons/PaymentNokImage.jsx";
import routes from "../../routes/routes.js";
import PendingTicketerImage from "../icons/PendingTicketerImage.jsx";

function ModalTicketerSection() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { ticketerSectionModal, setTicketerSectionModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  document.addEventListener("keyup", e => {
    if (e.key === "Escape" && document.querySelector(".modal-background") && ticketerSectionModal !== 5) {
      setTicketerSectionModal(false);
    }
  });

  document.addEventListener("click", e => {
    if (e.target === document.querySelector(".modal-container") && ticketerSectionModal !== 5) {
      setTicketerSectionModal(false);
    }   
  });

  const closePendingTicketer = () => {
    setTicketerSectionModal(false);
    navigate(routes.ticketer)
  }

  const closeCreatingTickets = () => {
    setTicketerSectionModal(false);
    navigate(routes.events)
  }

  return (
    <div id="modal-ticketeritems-section" className={ ticketerSectionModal ? "modal-background" : "d-none" }>
      <div className="modal-container justify-content-center align-items-center">
        <div className="modal-ticketeritems-container d-flex flex-column justify-content-center align-items-center">
          <div className="profile-upload-image-mobile-box-line d-lg-none"></div>
          <div className="modal-ticketeritems-text d-flex flex-column align-items-center"> 
            { ticketerSectionModal === 2 ? <PaymentOkImage/> : ticketerSectionModal === 3 ? <PaymentNokImage/> : ticketerSectionModal === 4 || ticketerSectionModal === 5 || ticketerSectionModal === 6 ? <PendingTicketerImage/> : null }
            <h3>{ ticketerSectionModal === 1 ? t('ticketerSection.ownDomain1') : ticketerSectionModal === 2 ? t('ticketerSection.paymentModal1') : ticketerSectionModal === 3 ? t('ticketerSection.paymentModal3') : ticketerSectionModal === 4 ? t('ticketerSection.pendingTicketer1') : ticketerSectionModal === 5 ? t('ticketerSection.pendingTicketModel1') : ticketerSectionModal === 6 ? t('ticketerSection.publishedTicketModel1') : null }</h3>
            <p>{ ticketerSectionModal === 1 ? t('ticketerSection.ownDomain2') : ticketerSectionModal === 2 ? t('ticketerSection.paymentModal2') : ticketerSectionModal === 3 ? t('ticketerSection.paymentModal4') : ticketerSectionModal === 4 ? t('ticketerSection.pendingTicketer2') : ticketerSectionModal === 5 ? t('ticketerSection.pendingTicketModel2') : ticketerSectionModal === 6 ? t('ticketerSection.publishedTicketModel2') : null }</p>
          </div>
          <div className={`modal-ticketeritems-buttons d-flex flex-column flex-sm-row ${ ticketerSectionModal === 1 ? "justify-content-end" : "justify-content-center" } align-items-center`}>
            { ticketerSectionModal === 1 ? <TermsPolicyButton text = { t('ticketer.back') } disabled = { false } function = { () => setTicketerSectionModal(false) }/> : null }
            <TermsPolicyButton text = { ticketerSectionModal === 1 ? t('ticketerSection.ownDomain3') : ticketerSectionModal === 2 ? t('ticketerSection.paymentModalButton1') : ticketerSectionModal === 3 ? t('ticketerSection.paymentModalButton2') : ticketerSectionModal === 4 ? t('ticketerSection.pendingTicketerButton') : ticketerSectionModal === 5 ? t('ticketerSection.pendingTicketModelButton') : ticketerSectionModal === 6 ? t('ticketerSection.publishedTicketModelButton') : null } 
              disabled = { false } function = { ticketerSectionModal === 6 || ticketerSectionModal === 5 ? () => closeCreatingTickets() : ticketerSectionModal === 4 ? () => closePendingTicketer() : () => setTicketerSectionModal(false) }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTicketerSection;