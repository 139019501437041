import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from "@tanstack/react-query";
import routes from "../../routes/routes.js";
import { getTicketingCompanyById, patchTicketingCompany } from "../../services/axiosService.js";
import { queryClient } from '../../index.js';
import TicketerItem from "../ui/TicketerItem.jsx";
import DashboardBar from "../shared/DashboardBar";
import CalendarEdit from "../icons/CalendarEdit";
import Eye from "../icons/Eye.jsx";
import { ticketerData } from "./ticketerData.js";
import SpinnerPurple from "../icons/SpinnerPurple.jsx";
import SuccessFailPopupLarge from "../ui/SuccessFailPopupLarge.jsx";

function TicketingComponent(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, elvis, setTicketerSectionModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  const navigateToTicketerSections = (id) => {
    var route;
    if (id === 1) {
      route = routes.info;
    }
    if (id === 2) {
      route = routes.payment;
    }
    if (id === 3) {
      route = routes.customize;
    }
    if (id === 4) {
      route = routes.domain;
    }
    if (id === 5) {
      route = routes.terms;
    }
    if (id === 6) {
      route = routes.privacy;
    }
    if (id === 7) {
      route = routes.social;
    }
    if (id === 8) {
      route = routes.infoContact;
    }
    if (id === 9) {
      route = routes.infoBilling;
    }
    navigate(route);
  }

  const sections = [{ id: 0, title: t('ticketer.required') }, { id: 1, title: t('ticketer.optional') }];

  const [fieldsStatus, setFieldsStatus] = useState([false, false, false, false, false, false]);
  const [popup, setPopup] = useState(null);

  const { isLoading: mutateIsLoading, isSuccess: mutateIsSuccess, isError: mutateIsError, error: mutateError, mutate } =
  useMutation((values) => patchTicketingCompany(authState.token, ticketingCompany.data[0].id, values, null, null), {
    onSuccess: (res) => {
      if (res.data) {
        queryClient.setQueryData(
          ['ticketingCompany'],
          (oldData) => oldData ? {
            ...oldData,
            data: {
              ...oldData.data,
              0: {
                ...oldData.data[0],
                isPublished: res.data.isPublished 
              }
            }
          } : oldData
        )
        //setPopup({ state: 1, text: t('ticketer.success') });
        setTicketerSectionModal(6);
      }
    },
    onError: (err) => {
      console.error(err);
      setPopup({ state: 0, text: t('ticketer.fail') + ". (" + err.response.data.message + ")" });
    }
  });

  const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (ticketingCompany) {
      var info = ticketingCompany?.data[0]?.comercialName && ticketingCompany?.data[0]?.description 
      && ticketingCompany?.data[0]?.email && ticketingCompany?.data[0]?.phone 
      && ticketingCompany?.data[0]?.address && ticketingCompany?.data[0]?.companyName && ticketingCompany?.data[0]?.cif ? true : false;
      var paymentMethod = ticketingCompany?.data[0]?.paymentMethod ? true : false;
      var customize = ticketingCompany?.data[0]?.logo && ticketingCompany?.data[0]?.background ? true : false;
      var domain = ticketingCompany?.data[0]?.ourSubdomain ? true : false;
      var terms = ticketingCompany?.data[0]?.terms ? true : false;
      var policy = ticketingCompany?.data[0]?.privacyPolicy ? true : false;
      setFieldsStatus([info, paymentMethod, customize, domain, terms, policy]);
    }
  }, [ticketingCompany])

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup])

  //() => window.open("https://www.google.com?q=ABC", '_blank', 'noreferrer')
  
  return (
    <section id="myticketer-section" className="ticketer-section d-flex flex-column justify-content-start align-items-center">
      { popup ? 
        <SuccessFailPopupLarge text = { popup.text } state = { popup.state } function = { () => setPopup(null) }/> 
        : 
        null 
      }
      <DashboardBar
        text = { t('ticketer.title') }
        session = { false }
        emoji = "ticketer"
        icon = { <CalendarEdit/> } 
        buttonText = { t('ticketer.publish') } 
        function = { fieldsStatus[0] ? () => mutate({ isPublished: true }) : null }
        disabled = { !(fieldsStatus[0] && fieldsStatus[1]) }
        button = { !ticketingCompany?.data[0].isPublished }
        ticketer = { elvis }
        icon2 = { <Eye/> } 
        buttonText2 = { ticketingCompany?.data[0].isPublished ? t('ticketer.seeTicketer') : t('ticketer.preview') } 
        function2 = { ticketingCompany?.data[0].isPublished ? () => navigate(routes.ticketerPreview) : () => navigate(routes.ticketerPreview) }
        disabled2 = { false }
        altColor = { fieldsStatus.every(v => v === true) && ticketingCompany?.data[0].isPublished }
      />
      <div className={`ticketer-section-content d-flex flex-column justify-content-center ${isLoading || isError ? "align-items-center" : ""}`}>
        <p>{t('ticketer.content')}</p>
        { isLoading ?
          <SpinnerPurple/>
          :
          isError ?
          <h3 className="fetching-error">{t('ticketer.errorRetrievingTicketer')}</h3>
          :
          sections.map(section => {
          return (
            <div key={ section.id } className="ticketer-section-top d-flex flex-column justify-content-center">
              <h3>{ section.title }</h3>
              <div className="ticketer-section-container d-flex flex-wrap justify-content-center align-items-center">
                { ticketerData.map((card, index) => {
                  if (section.id ? card.id >= 3 : card.id < 3) {
                    return (
                      <TicketerItem
                        key = { card.id }
                        id = { card.id }
                        icon = { card.icon }
                        text = { t(card.text) }
                        altText = { t(card.altText) }
                        button = { t(card.button) }
                        required = { section.id ? false : true }
                        success = { fieldsStatus[index] }
                        function = { navigateToTicketerSections }
                        data = { ticketingCompany?.data[0] }
                      />
                    )
                  } else return null;
                })}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  );
}

export default TicketingComponent;