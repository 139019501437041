import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import routes from '../../routes/routes.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import SettingsSmall from '../../components/icons/SettingsSmall.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import { paymentItems } from '../../components/dashboard/paymentMethods.js';
import {
  createAccountStripeCallback,
  stripeAccountVerify,
  stripeConnectAccount
} from '../../services/axiosService';
import { AppContext } from '../../App';
import { useMutation, useQuery } from '@tanstack/react-query';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';
import SuccessFailPopupLarge from '../../components/ui/SuccessFailPopupLarge.jsx';
import { Field, Form, Formik } from 'formik';
import AdviceFooter from '../../components/ui/AdviceFooter.jsx';
import TriangleExclamationSmall from '../../components/icons/TriangleExclamationSmall.jsx';

const serviceChargeSchema = Yup.object().shape({
  'service-charge': Yup.number()
    .min(1, 'El porcentaje debe ser mayor a 0%')
    .max(
      10,
      'Porcentaje superior al permitido, por favor agrega una cantidad hasta 10%'
    )
    .required('Debes agregar una cantidad')
});

function PaymentPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const [step, setStep] = useState(1);

  const initialValues = {
    'service-charge': ''
  };

  var navigate = useNavigate();

  const {
    state: authState,
    setTicketerSectionModal,
    ticketingCompany
  } = React.useContext(AppContext);

  const navigateRoute = (route) => {
    navigate(route === 'back' ? -1 : route);
  };

  // callback

  const [paymentMethod, setPaymentMethod] = useState(
    ticketingCompany?.data[0]?.paymentMethod === 'stripe' ? 2 : 0
  );
  const [popup, setPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const stripeScope = searchParams.get('scope');
  const stripeCode = searchParams.get('code');

  const {
    isLoading: stripeCallbackIsLoading,
    isFetching: stripeCallbackIsFetching
  } = useQuery({
    queryKey: ['createAccountStripeCallback'],
    queryFn: () =>
      createAccountStripeCallback(authState.token, stripeScope, stripeCode),
    enabled: !!authState.isAuthenticated && !!stripeScope && !!stripeCode,
    onSuccess: (response) => {
      setTicketerSectionModal(2);
      searchParams.delete('scope');
      searchParams.delete('code');
      setSearchParams(searchParams);
      navigate(routes.ticketer);
    },
    onError: (err) => {
      setTicketerSectionModal(3);
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  });

  const handlePaymentMethodChange = async (id) => {
    if (paymentMethod === id) {
      setPaymentMethod(0);
    } else {
      setPaymentMethod(id);
    }
  };

  /* GET LINK PAYMENT METHOD */
  const { mutate: getPaymentMethod } = useMutation(
    () => stripeConnectAccount(authState.token),
    {
      onSuccess: (res) => {
        if (res?.data?.message) {
          window.location.href = res?.data?.message;
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const { data } = useQuery({
    queryKey: ['stripeAccountVerify'],
    queryFn: () => stripeAccountVerify(authState.token),
    onSuccess: (res) => {
      if (res) {
        navigate(routes.paymentSuccess);
      }
    },
    onError: (err) => {
      navigate(routes.paymentFailed);
    },
    refetchOnMount: true
  });

  const sendPaymentMethod = () => {
    if (ticketingCompany?.data[0]?.paymentMethod) {
      setPopup({ state: 0, text: t('ticketerSection.paymentMethodSetted') });
    } else {
      if (data?.data?.verified === true) {
        setPopup(true);
      } else {
        getPaymentMethod();
      }
    }
  };

  useEffect(() => {
    const errorParam = searchParams.get('error');
    if (errorParam) {
      setTicketerSectionModal(3);
      searchParams.delete('error');
      searchParams.delete('error_description');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    setPaymentMethod(
      ticketingCompany?.data[0]?.paymentMethod === 'stripe' ? 2 : 0
    );
  }, [ticketingCompany?.data[0]?.paymentMethod]);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <TicketingSectionComponent
      initialValues={null}
      validationSchema={null}
      ticketer={true}
      title={step === 0 ? 'Service charge' : t('ticketer.payment1')}
      pagination={null}
      description={
        step === 0
          ? 'Asigna el service charge que impactará en tus eventos'
          : t('ticketer.infoPayment3')
      }
      advicePayment={true}
      footer={
        step === 0
          ? null
          : { text: t('ticketer.advicePayment'), icon: <SettingsSmall /> }
      }
    >
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      {step === 0 ? (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={serviceChargeSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values) => {
              setStep(1);
            }}
          >
            {(props) => {
              return (
                <Form
                  className={`contact-landing-form-wrap d-flex flex-column align-items-center`}
                >
                  <div className="w-100 ticketer-sections-payment-container d-flex flex-column justify-content-center">
                    <p>
                      Con tu plan puedes asignarle hasta 10% de Service charge.{' '}
                    </p>
                    <div className="d-flex flex-column gap-2">
                      <div className="w-100 service-change-input">
                        <h3>%</h3>
                        <Field
                          className="ticketer-field"
                          id="service-charge"
                          type="number"
                          max="10"
                          name="service-charge"
                        />
                      </div>
                      {props.errors['service-charge'] &&
                      props.touched['service-charge'] ? (
                        <div className="contact-landing-form-item-error">
                          {props.errors['service-charge']}
                        </div>
                      ) : null}
                    </div>
                    <ButtonGroupTibet
                      button={{
                        text: t('ticketerSection.paymentButton'),
                        disabled:
                          props.isValid &&
                          props.dirty &&
                          !(
                            props.errors['service-charge'] &&
                            props.touched['service-charge']
                          )
                            ? false
                            : true,
                        function: () => props.submitForm()
                      }}
                      backButton={{ function: () => navigate(routes.ticketer) }}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      ) : (stripeCallbackIsLoading || stripeCallbackIsFetching) &&
        !!stripeScope &&
        !!stripeCode ? (
        <div className="ticketer-sections-payment-container d-flex flex-column justify-content-center align-items-center">
          <SpinnerPurple />
          <h4>{t('ticketerSection.paymentLoading')}</h4>
        </div>
      ) : (
        <>
          <div className="ticketer-sections-payment-container d-flex flex-column justify-content-center">
            {paymentItems?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="ticketer-sections-payment-item d-flex justify-content-between align-items-center"
                >
                  <div className="ticketer-sections-payment-item-left d-flex align-items-center">
                    {item.icon}
                    <p>{item.name}</p>
                  </div>
                  <input
                    type="radio"
                    checked={paymentMethod === item.id}
                    onChange={() => handlePaymentMethodChange(item.id)}
                  />
                </div>
              );
            })}
          </div>
          <ButtonGroupTibet
            button={{
              text: t('ticketerSection.paymentButton'),
              disabled: !paymentMethod,
              function: sendPaymentMethod
            }}
            backButton={{ function: () => navigate(routes.ticketer) }}
          />
        </>
      )}
      {step === 0 ? (
        <AdviceFooter
          footer={{
            icon: <TriangleExclamationSmall />,
            text: 'El service charge un monto que se suma al costo de la entrada que el público debe pagar para un espectáculo.'
          }}
        />
      ) : (
        <AdviceFooter
          footer={{
            icon: <TriangleExclamationSmall />,
            text: 'Recuerda que tenés que completar la asociación de cuenta de Stripe con la de Tibetpass para poder pasarte los pagos.'
          }}
        />
      )}
    </TicketingSectionComponent>
  );
}

export default PaymentPage;
