import React from "react";

function VideoPlay(props) {

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.15819 5.42372V2.4644C7.15819 2.35875 7.2435 2.27344 7.34914 2.27344H9.83115C9.93679 2.27344 10.0221 2.35875 10.0221 2.4644V5.42372C10.0221 5.52937 9.93679 5.61468 9.83115 5.61468H7.34914C7.2435 5.61468 7.15819 5.52937 7.15819 5.42372ZM14.318 6.76028V11.5812C14.318 13.0131 13.602 13.7291 12.17 13.7291H5.01024C3.57828 13.7291 2.8623 13.0131 2.8623 11.5812V6.76028C2.8623 6.65463 2.94762 6.56932 3.05326 6.56932H14.127C14.2327 6.56932 14.318 6.65463 14.318 6.76028ZM10.2328 9.11751L8.22866 7.89112C7.82962 7.64736 7.31667 7.93317 7.31667 8.39967V10.7844C7.31667 11.2509 7.82962 11.5373 8.22866 11.293L10.2328 10.0666C10.5886 9.84954 10.5886 9.33517 10.2328 9.11751ZM3.05326 5.61468H6.01259C6.11823 5.61468 6.20354 5.52937 6.20354 5.42372V2.4644C6.20354 2.35875 6.11823 2.27344 6.01259 2.27344H5.01024C3.57828 2.27344 2.8623 2.98942 2.8623 4.42138V5.42372C2.8623 5.52937 2.94762 5.61468 3.05326 5.61468ZM12.17 2.27344H11.1677C11.0621 2.27344 10.9767 2.35875 10.9767 2.4644V5.42372C10.9767 5.52937 11.0621 5.61468 11.1677 5.61468H14.127C14.2327 5.61468 14.318 5.52937 14.318 5.42372V4.42138C14.318 2.98942 13.602 2.27344 12.17 2.27344Z" fill="#FF5F00"/>
    </svg>
  );
}

export default VideoPlay;