import Coupon from "../icons/Coupon";
import ActivityStarCircle from "../icons/ActivityStarCircle";
import CouponStar from "../icons/CouponStar";
import CouponHeart from "../icons/CouponHeart";

export const dashboardData = [
  {
    id: 0,
    icon: <Coupon />,
    text: "home.box1",
    altText: "home.box1alt",
    dropdown: false,
    span: true,
  },
  {
    id: 1,
    icon: <CouponStar />,
    text: "home.box3",
    altText: null,
    dropdown: true,
    span: false,
  },
  {
    id: 2,
    icon: <ActivityStarCircle />,
    text: "home.box2",
    altText: null,
    dropdown: true,
    span: false,
  },
];

export const dashboardEventData = [
  {
    id: 0,
    icon: <CouponStar />,
    text: "home.box3",
    altText: null,
    dropdown: true,
    span: false,
  },
  {
    id: 1,
    icon: <ActivityStarCircle />,
    text: "home.box2",
    altText: null,
    dropdown: true,
    span: false,
  },
  {
    id: 2,
    icon: <CouponHeart />,
    text: "home.box4",
    altText: null,
    dropdown: false,
    span: false,
  },
];

export const dashboardSubscriptionData = [
  {
    id: 0,
    icon: <CouponStar />,
    text: "home.box3",
    altText: null,
    dropdown: true,
    span: true,
  },
  {
    id: 1,
    icon: <CouponHeart />,
    text: "home.box4",
    altText: null,
    dropdown: true,
    span: true,
  },
  {
    id: 2,
    icon: <ActivityStarCircle />,
    text: "home.box2",
    altText: null,
    dropdown: true,
    span: false,
  },
];
