import React from "react";

function PenLine(props) {

  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3125 5.42395C16.3132 4.87195 16.0988 4.35295 15.7081 3.96295L14.037 2.29202C13.6463 1.90202 13.1288 1.68675 12.576 1.6875C12.024 1.68825 11.5057 1.9042 11.1172 2.29495L1.85175 11.6032C1.746 11.709 1.6875 11.8515 1.6875 12V15.75C1.6875 16.0605 1.9395 16.3125 2.25 16.3125H6C6.1485 16.3125 6.29179 16.2532 6.39679 16.149L15.705 6.88275C16.0965 6.49425 16.3118 5.97595 16.3125 5.42395ZM5.76746 15.1882H2.8125V12.2333L9.55719 5.45774L12.5438 8.44345L5.76746 15.1882ZM14.9116 6.08624L13.3411 7.64997L10.3508 4.66049L11.9145 3.08927C12.0915 2.91152 12.327 2.81398 12.5782 2.81323H12.579C12.8295 2.81323 13.065 2.91071 13.2427 3.08771L14.9138 4.75873C15.0908 4.93648 15.1882 5.17197 15.1882 5.42322C15.1875 5.67372 15.0893 5.90924 14.9116 6.08624ZM16.3125 15.7507C16.3125 16.0612 16.0605 16.3132 15.75 16.3132H10.5C10.1895 16.3132 9.9375 16.0612 9.9375 15.7507C9.9375 15.4402 10.1895 15.1882 10.5 15.1882H15.75C16.0605 15.1882 16.3125 15.4402 16.3125 15.7507Z" fill="#9A9FA5"/>
    </svg>
  );
}

export default PenLine;