import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { getPublicElvisKey } from '../../services/axiosService';
import { getSubdomain } from '../../components/shared/functions.js';
import RegisterImg from '../../assets/register_image.png';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';
import jwtDecode from 'jwt-decode';

function AuthLayout(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  const {
    state: authState,
    setTicketingCompany,
    setPublicElvisId,
    firebaseLoading
  } = React.useContext(AppContext);
  const subdomain = useMemo(() => getSubdomain(), []);
  const [notFound, setNotFound] = useState(false);

  const { isLoading: ticketingCompanyIsLoading } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getPublicElvisKey(subdomain),
    enabled: !!subdomain,
    onSuccess: (res) => {
      setTicketingCompany({ data: [res.data] });
      setPublicElvisId(res.data.apiKey);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
      }
      if (err.response.status === 404) {
        setNotFound(true);
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });
  // NO ESTOY CMPROBANDO SI ES VALIDO EL TOKEN, SOLO COMPRUEBO SI HAY TOKEN, Y COMO SI HAY TOKEN NO ME SACA DE LA SESIÓN
  useEffect(() => {
    const isToken = localStorage.getItem('token');
    if (isToken) {
      const decoded = jwtDecode(isToken);
      const currentTimestamp = Math.floor(Date.now() / 1000); // Fecha actual en segundos
      const expirationTimestamp = decoded.exp;

      if (expirationTimestamp > currentTimestamp) {
        navigate('/dashboard/home');
      } else {
        localStorage.removeItem('token');
        navigate('/auth');
      }
    }
  }, []);

  return (
    <main className="main-register d-flex flex-row justify-content-center align-items-start align-items-lg-center">
      <div className="inner-container main-register-container d-flex flex-row justify-content-between align-items-start align-items-lg-center">
        <div className="main-register-left d-none d-lg-flex flex-row justify-content-center align-items-center">
          <img src={RegisterImg} alt="register-pic" className="" />
        </div>
        <div className="main-register-right d-flex flex-row justify-content-end align-items-center">
          {notFound ? (
            <div className="register-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center">
              <p>No se ha encontrado la ticketera</p>
            </div>
          ) : (!notFound && !!firebaseLoading) ||
            !!authState.isAuthenticated ||
            (!!subdomain ? !!ticketingCompanyIsLoading : null) ? (
            <section
              id="register-buttons-section"
              className="register-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
            >
              <SpinnerPurple />
            </section>
          ) : (
            props.main
          )}
        </div>
      </div>
    </main>
  );
}

export default AuthLayout;
