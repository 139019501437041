import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { qrLogin } from '../../services/axiosService';
import { AppContext } from '../../App';
import QRCode from 'react-qr-code';
import DashboardBar from '../shared/DashboardBar';

const ReaderComponent = () => {
  const { state: authState } = React.useContext(AppContext);
  const { data } = useQuery({
    queryKey: ['reader'],
    queryFn: () => qrLogin(authState.token),
    onError: (err) => {
      console.error(err.response.status);
    }
  });

  return (
    <div className="ticketer-section d-flex flex-column justify-content-start align-items-center">
      <DashboardBar
        text={'Reader'}
        session={false}
        disabled={false}
        button={false}
      />
      <div className="w-100">
        <div className="faq-card d-flex align-items-center justify-content-center p-5">
          {data ? (
            <QRCode
              size={'150px'}
              value={JSON.stringify(data.data)}
              viewBox={`0 0 256 256`}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ReaderComponent;
