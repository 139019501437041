import React from "react";
import { useTranslation } from "react-i18next";
import VideoPlay from "../../components/icons/VideoPlay.jsx";
import Image from "../../components/icons/Image.jsx";
import FileList from "../../components/icons/FileList.jsx";
import Music from "../../components/icons/Music.jsx";
import Envelope from "../icons/Envelope.jsx";
import TermsPolicyButton from "./TermsPolicyButton.jsx";
import TrashPlus from "../icons/TrashPlus.jsx";

function ContentField({ type, functionClick, text, radiusCircle, width, deleteEnabled, deleteFunction }) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className={`${width === 100 ? "w-100" : ""} modal-ticketer-item-content-picture d-flex justify-content-between align-items-center`}
      onClick={ functionClick }
    >
      <div className={`modal-ticketer-item-content-picture-left d-flex align-items-center`}>
        <div className={`modal-ticketer-item-content-picture-item ${radiusCircle ? "radius-circle" : ""} orange-background d-flex justify-content-center align-items-center`}>
          { type === "video" ? <VideoPlay/> : type === "image" || type === "photo" ? <Image/> : type === "application" ? <FileList/> : type === "audio" ? <Music/> : type === 4 ? <Envelope/> : null }
        </div>
        <div className="modal-ticketer-item-content-data d-flex flex-column">
          <h6>{ text ? text : `${""}` }</h6> 
        </div>
      </div>
      <div className={`modal-ticketer-item-content-picture-right d-flex align-items-center`}>
        { deleteEnabled ? 
          <TermsPolicyButton
            icon = { <TrashPlus/> }
            text = { null }
            function = { deleteEnabled ? deleteFunction : null }
            disabled = { false }
          />  
          : null      
        }
      </div>
    </div>
  );
}

export default ContentField;