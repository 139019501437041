import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import routes from '../../routes/routes';
import { AppContext } from "../../App.js";
import { navDataElvis, navDataFlora } from "./sidebarData.js";
import UserPicture from '../ui/UserPicture';

export default function DashboardFooter() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { elvis } = React.useContext(AppContext);

  const [sidebar, setSidebar] = useState(elvis ? navDataElvis : navDataFlora)

  return (
    <footer id="footer-section" className="footer-section d-flex d-lg-none flex-row justify-content-between">
      { sidebar.map(item => {
        return <NavLink key={ item.id } className={({ isActive }) =>
            isActive ? "footer-item footer-item-active d-flex" : "footer-item d-flex"
          } to={ item.link }>
          { item.icon }
          { item.id === 2 ? t('sidebar.elvis4') : item.id === 3 ? t('sidebar.elvis5') : t(item.text) }
        </NavLink>
      })}
      <NavLink className={({ isActive }) =>
          isActive ? "footer-item footer-item-active d-flex" : "footer-item d-flex"
        } to={ routes.profile }>
        <UserPicture/>
        {t('sidebar.profile')}
      </NavLink>
    </footer>
  )

}
