import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes';
import LogoNew from '../icons/LogoNew';
import NavbarButton from '../ui/NavbarButton.jsx';
import LandingFacebook from '../icons/LandingFacebook';
import LandingInstagram from '../icons/LandingInstagram';
import LandingTwitter from '../icons/LandingTwitter';
import LandingLinkedin from '../icons/LandingLinkedin';

export default function Footer({ landing }) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  const buttonToRegister = () => {
    navigate(landing ? routes.faq : routes.landing);
  }

  return (
    <footer id="footer-landing-section" className="footer-landing-section d-flex flex-column justify-content-center align-items-center">
      <div className="footer-landing-section-top d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div className="footer-landing-section-left d-flex d-flex flex-column flex-sm-row justify-content-between align-items-center align-items-sm-end">
          <LogoNew/>
          <div className="footer-landing-section-left-icons d-flex justify-content-between align-items-center">
            {/*<LandingFacebook/>*/}
            {/*<LandingInstagram/>*/}
            {/*<LandingTwitter/>*/}
            {/*<LandingLinkedin/>*/}
          </div>
        </div>
        <div className="footer-landing-section-right d-flex flex-column flex-sm-row justify-content-between align-items-center">
          {/*<NavbarButton text = { landing ? t('footer.faq') : t('footer.landing') } function = { buttonToRegister }/>*/}
        </div>
      </div>
      <div className="footer-landing-section-bottom d-flex justify-content-center align-items-center">
        <h6>{t('footer.rights')}</h6>
      </div>
    </footer>
  )

}
