import React from "react";

function GridWeb(props) {

  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.46224 4.63147L2.46224 3.0907C2.46224 2.57711 2.71903 2.32031 3.23263 2.32031L9.9093 2.32031C10.4229 2.32031 10.6797 2.57711 10.6797 3.0907L10.6797 4.63147C10.6797 5.14506 10.4229 5.40186 9.9093 5.40186L3.23262 5.40186C2.71903 5.40186 2.46224 5.14506 2.46224 4.63147ZM3.23262 10.5378L9.9093 10.5378C10.4229 10.5378 10.6797 10.281 10.6797 9.76737L10.6797 8.2266C10.6797 7.71301 10.4229 7.45622 9.9093 7.45622L3.23262 7.45622C2.71903 7.45622 2.46224 7.71301 2.46224 8.2266L2.46224 9.76737C2.46224 10.281 2.71903 10.5378 3.23262 10.5378Z" fill="#DADADA"/>
    </svg>
  );
}

export default GridWeb;