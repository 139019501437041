import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { esES } from '@mui/x-date-pickers/locales';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import { getEventById, getRegions } from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import StopwatchSmall from '../../components/icons/StopwatchSmall.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';
const { DateTime } = require('luxon');

function InfoEventThirdPageChildren(props) {
  const { state: authState } = React.useContext(AppContext);

  const { data: regions } = useQuery({
    queryKey: ['companyApiKey'],
    queryFn: () => getRegions(authState.token),
    onError: (err) => {
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    errors,
    dirty,
    touched,
    values,
    setFieldError,
    submitForm,
    setFieldValue,
    validateForm,
    resetForm,
    setFieldTouched
  } = useFormikContext();
  /* 
  useEffect(() => {
    if (values.length > 0) {
      if (values.startDate < values.openingDate) {
        setFieldValue("openingDate", new DateTime(values.startDate));
      }
      if (values.startDate > values?.finishDate) {
        setFieldValue("finishDate", new DateTime(values.startDate));
      }
      if (values.openingDate && values.openingDate < DateTime.now()) {
        setFieldValue("openingDate", DateTime.now());
      }
      if (values.startDate && values.startDate < DateTime.now()) {
        setFieldValue("startDate", DateTime.now());
      }
      if (values.finishDate && values.finishDate < DateTime.now()) {
        setFieldValue("finishDate", DateTime.now());
      }
    }
  }, []); */

  useEffect(() => {
    if (values?.startDate) {
      if (values.finishDate <= values.startDate) {
        setFieldError(
          'finishDate',
          'La fecha no puede ser anterior a la fecha de comienzo'
        );
      } else {
        setFieldError('finishDate', '');
      }
    }

    if (values?.finishDate) {
      if (values.finishDate < values.openingDate) {
        setFieldError(
          'openingDate',
          'La fecha no puede ser superior a la fecha de finalización'
        );
      } else {
        setFieldError('openingDate', '');
      }
    }

    if (values?.openingDate) {
      if (values.finishDate < values.openingDate) {
        setFieldError(
          'openingDate',
          'La fecha no puede ser superior a la fecha de finalización'
        );
      } else if (
        values.finishDate > values.openingDate &&
        values.openingDate > values.startDate
      ) {
        setFieldError(
          'openingDate',
          'La fecha no puede ser superior a la fecha de inicio'
        );
      } else {
        setFieldError('openingDate', '');
      }
    }
  }, [values?.startDate, values?.finishDate, values?.openingDate]);

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale="es"
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
            <div
              className={`${
                errors.startDate && touched.startDate
                  ? 'datepicker-error'
                  : touched.startDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField9') + '*'}</h5>
              <DatePicker
                value={values.startDate}
                disablePast
                onChange={(newValue) => {
                  setFieldTouched('startDate', newValue ? true : false);
                  setFieldValue('startDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (values.startDate < DateTime.now()) {
                    setFieldError('startDate', t('ticket.errorDate'));
                  } else {
                    setFieldError('startDate', '');
                  }
                }}
              />
              {(errors.length > 0 && errors.startDate && touched.startDate) ||
              values.startDate < DateTime.now() ? (
                <ErrorMessage name="startDate">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
            <div
              className={`${
                errors.startDate && touched.startDate
                  ? 'datepicker-error'
                  : touched.startDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField10') + '*'}</h5>
              <TimePicker
                value={values.startDate}
                views={['hours', 'minutes']}
                onChange={(newValue) => {
                  setFieldTouched('startDate', newValue ? true : false);
                  setFieldValue('startDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (value?.invalid || !value) {
                    setFieldError('startDate', t('register.error2'));
                  } else {
                    setFieldError('startDate', '');
                  }
                }}
              />
            </div>
          </div>
          <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
            <div
              className={`${
                errors.finishDate && touched.finishDate
                  ? 'datepicker-error'
                  : touched.finishDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField11') + '*'}</h5>
              <DatePicker
                value={values.finishDate}
                disablePast
                minDate={values?.startDate}
                onChange={(newValue) => {
                  setFieldTouched('finishDate', newValue ? true : false);
                  setFieldValue('finishDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (values.finishDate < values.startDate) {
                    setFieldError('finishDate', t('ticket.errorStartDate'));
                  } else {
                    setFieldError('finishDate', '');
                  }
                }}
              />
              {errors?.finishDate && (
                <div className="ticketer-field-error">
                  {t('ticket.errorStartDate')}
                </div>
              )}
            </div>
            <div
              className={`${
                errors.finishDate && touched.finishDate
                  ? 'datepicker-error'
                  : touched.finishDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField12') + '*'}</h5>
              <TimePicker
                value={values.finishDate}
                minTime={
                  values?.startDate?.c?.day === values?.finishDate?.c?.day &&
                  values?.startDate?.c?.month ===
                    values?.finishDate?.c?.month &&
                  values?.startDate?.c?.year === values?.finishDate?.c?.year
                    ? values?.startDate
                    : null
                }
                views={['hours', 'minutes']}
                onChange={(newValue) => {
                  setFieldTouched('finishDate', newValue ? true : false);
                  setFieldValue('finishDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (value?.invalid) {
                    setFieldError('finishDate', t('register.error2'));
                  } else if (reason === 'minTime') {
                    setFieldError('finishDate', t('ticket.errorStartDate'));
                  } else {
                    setFieldError('finishDate', '');
                  }
                }}
              />
            </div>
          </div>
          <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
            <div
              className={`${
                errors.openingDate && touched.openingDate
                  ? 'datepicker-error'
                  : touched.openingDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField15') + '*'}</h5>
              <DatePicker
                value={values.openingDate}
                disablePast
                maxDate={values?.startDate}
                onChange={(newValue) => {
                  setFieldTouched('openingDate', newValue ? true : false);
                  setFieldValue('openingDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (values.openingDate > values.finishDate) {
                    setFieldError(
                      'openingDate',
                      t('ticket.errorOpeningFinishDate')
                    );
                  } else {
                    setFieldError('openingDate', '');
                  }
                }}
              />
              {errors?.openingDate &&
                values?.startDate < values?.openingDate && (
                  <div className="ticketer-field-error">
                    {t('ticket.errorOpeningStartDate')}
                  </div>
                )}

              {errors?.openingDate &&
                values?.finishDate < values?.openingDate && (
                  <div className="ticketer-field-error">
                    {t('ticket.errorOpeningFinishDate')}
                  </div>
                )}
            </div>
            <div
              className={`${
                errors.openingDate && touched.openingDate
                  ? 'datepicker-error'
                  : touched.openingDate
                  ? 'datepicker-touched'
                  : ''
              }`}
            >
              <h5>{t('events.infoField13') + '*'}</h5>
              <TimePicker
                value={values.openingDate}
                maxTime={
                  values?.startDate?.c?.day === values?.openingDate?.c?.day &&
                  values?.startDate?.c?.month ===
                    values?.openingDate?.c?.month &&
                  values?.startDate?.c?.year === values?.openingDate?.c?.year
                    ? values?.startDate
                    : null
                }
                views={['hours', 'minutes']}
                onChange={(newValue) => {
                  setFieldTouched('openingDate', newValue ? true : false);
                  setFieldValue('openingDate', new DateTime(newValue));
                }}
                onError={(reason, value) => {
                  if (value?.invalid || !value) {
                    setFieldError('openingDate', t('register.error2'));
                  } else {
                    setFieldError('openingDate', '');
                  }
                }}
              />
            </div>
          </div>
          <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
            <div>
              <h5>{t('events.infoField14') + '*'}</h5>
              <Field
                className={`ticketer-field ticketer-select ${
                  errors.timezone && touched.timezone ? 'red-border' : null
                } ${touched.timezone ? 'purple-border' : null} ${
                  values.timezone !== 0 ? 'select-black' : null
                }`}
                id="event-timezone"
                type="text"
                name="timezone"
                placeholder=""
                onChange={(e) => {
                  setFieldValue('timezone', e.target.value);
                }}
                as="select"
              >
                <option value="" disabled hidden>
                  {t('ticketerSection.timezoneRegions')}
                </option>
                {regions?.data?.map((item, index) => (
                  <option key={index} value={item.timezone}>
                    {item.gmt.replace('UTC', 'GMT')} hora de {item.timezone}
                  </option>
                ))}
              </Field>
              {errors.length > 0 && errors.timezone && touched.timezone ? (
                <ErrorMessage name="timezone">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
          </div>
        </LocalizationProvider>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.save'),
          disabled: !(
            dirty &&
            !errors.startDate &&
            values.startDate &&
            !errors.finishDate &&
            values.finishDate &&
            !errors.openingDate &&
            values.openingDate &&
            !errors.timezone &&
            values.timezone
          ),
          function: submitForm
        }}
      />
    </>
  );
}

function InfoEventThirdPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState, dispatch } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const {
    isLoading,
    isError,
    data: events
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialThirdInfoData = {
    startDate: events?.data?.dateEvent
      ? DateTime.fromFormat(
          String(events?.data?.dateEvent),
          'yyyy-MM-dd HH:mm:ss'
        )
      : '',
    finishDate: events?.data?.dateEnd
      ? DateTime.fromFormat(
          String(events?.data?.dateEnd),
          'yyyy-MM-dd HH:mm:ss'
        )
      : '',
    openingDate: events?.data?.dateDoors
      ? DateTime.fromFormat(
          String(events?.data?.dateDoors),
          'yyyy-MM-dd HH:mm:ss'
        )
      : '',
    timezone: events?.data?.timezone ?? ''
  };

  const dataThirdInfoSchema = Yup.object().shape({
    startDate: Yup.string(),
    finishDate: Yup.string(),
    openingDate: Yup.string(),
    timezone: Yup.string()
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <TicketingSectionComponent
          initialValues={initialThirdInfoData}
          validationSchema={dataThirdInfoSchema}
          ticketer={false}
          title={t('events.infoData')}
          pagination={'3/3'}
          description={t('events.info5')}
          footer={{ text: t('events.adviceInfo3'), icon: <StopwatchSmall /> }}
          noFormikValidation={true}
        >
          <InfoEventThirdPageChildren
            initialValues={initialThirdInfoData}
            eventId={eventId}
            events={events}
          />
        </TicketingSectionComponent>
      )}
    </>
  );
}

export default InfoEventThirdPage;
