import React from 'react';
import Times from '../icons/Times';

export default function SuccessFailPopupLarge(props) {

  return (
    <div className={ props.state ? "success-fail-popup-large success-fail-popup-green d-flex flex-row justify-content-between align-items-center"
      : "success-fail-popup-red success-fail-popup-large d-flex flex-row justify-content-between align-items-center" }
      id={ props.id } ref={ props.reference }
    >
      <div className="success-fail-popup-left">
        { props.icon }{ props.text }
      </div>
      <div className="success-fail-popup-right" onClick={ props.function }>
        <Times/>
      </div>
    </div>
  )

}