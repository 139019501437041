import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routes from '../../routes/routes';
import { AppContext } from '../../App.js';
import { navDataElvis, navDataFlora } from './sidebarData.js';
import UserPicture from '../ui/UserPicture';
import LogoSvg from '../icons/LogoSvg.jsx';

export default function Sidebar(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { elvis, ticketingCompany } = React.useContext(AppContext);

  const [sidebar, setSidebar] = useState(elvis ? navDataElvis : navDataFlora);

  return (
    <aside
      id="sidebar-section"
      className="sidebar-section d-none d-lg-flex flex-column justify-content-between"
    >
      <div className="sidebar-section-top d-flex flex-column">
        {elvis ? (
          <LogoSvg />
        ) : ticketingCompany?.data[0]?.logo ? (
          <img
            src={ticketingCompany?.data[0]?.logo}
            alt={`logo-${ticketingCompany?.data[0]?.logo}`}
            className=""
          />
        ) : (
          <h2>
            {ticketingCompany?.data[0]?.comercialName
              .split(' ')
              .map(function (item, index) {
                if (index < 2) return item.toUpperCase()[0] + '.';
                else return null;
              })}
          </h2>
        )}
        <div className="sidebar-section-links d-flex flex-column">
          {sidebar.map((item) => {
            return (
              <NavLink
                key={item.id}
                className={({ isActive }) =>
                  isActive
                    ? 'sidebar-item sidebar-item-active d-flex'
                    : 'sidebar-item d-flex'
                }
                to={item.link}
              >
                {item.icon}
                {t(item.text)}
              </NavLink>
            );
          })}
        </div>
      </div>
      <div className="sidebar-section-bottom d-flex flex-column">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? 'sidebar-item sidebar-item-active d-flex'
              : 'sidebar-item d-flex'
          }
          to={routes.profile}
        >
          <UserPicture />
          {t('sidebar.profile')}
        </NavLink>
      </div>
    </aside>
  );
}
