import React from "react";

function Google(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6295 12.2841C23.6295 11.4829 23.552 10.6559 23.4228 9.88062H12.2326V14.4549H18.6417C18.3833 15.928 17.5305 17.2201 16.2641 18.0471L20.089 21.0191C22.3373 18.9258 23.6295 15.8763 23.6295 12.2841Z" fill="#4280EF"/>
      <path d="M12.2326 23.8621C15.4372 23.8621 18.1249 22.8025 20.089 20.9935L16.2641 18.0473C15.2046 18.771 13.8349 19.1845 12.2326 19.1845C9.13136 19.1845 6.52117 17.0911 5.56497 14.3L1.63676 17.3237C3.65255 21.3295 7.73582 23.8621 12.2326 23.8621Z" fill="#34A353"/>
      <path d="M5.56493 14.2742C5.07391 12.8012 5.07391 11.1989 5.56493 9.72579L1.63673 6.67627C-0.0430943 10.0359 -0.0430943 13.99 1.63673 17.3238L5.56493 14.2742Z" fill="#F6B704"/>
      <path d="M12.2326 4.84116C13.9124 4.81532 15.5664 5.46141 16.781 6.62436L20.1665 3.21303C18.0215 1.19724 15.1787 0.111815 12.2326 0.137658C7.73582 0.137658 3.65255 2.67031 1.63676 6.67605L5.56497 9.72557C6.52117 6.90864 9.13136 4.84116 12.2326 4.84116Z" fill="#E54335"/>
    </svg>
  );
}

export default Google;