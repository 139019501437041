import React from "react";

function ProfileStar(props) {

  return (
    <svg width="115" height="66" viewBox="0 0 115 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.9297 -43.5995C54.4213 -47.9532 60.5787 -47.9532 62.0703 -43.5995L67.028 -29.1296C68.4839 -24.8801 73.3365 -22.8701 77.3709 -24.8455L91.1082 -31.5716C95.2415 -33.5954 99.5954 -29.2415 97.5716 -25.1082L90.8455 -11.3709C88.8701 -7.33649 90.8801 -2.48394 95.1296 -1.02798L109.6 3.92968C113.953 5.42133 113.953 11.5787 109.6 13.0703L95.1296 18.028C90.8801 19.4839 88.8701 24.3365 90.8455 28.3709L97.5716 42.1082C99.5954 46.2415 95.2415 50.5954 91.1082 48.5716L77.3709 41.8455C73.3365 39.8701 68.4839 41.8801 67.028 46.1296L62.0703 60.5995C60.5787 64.9532 54.4213 64.9532 52.9297 60.5995L47.972 46.1296C46.5161 41.8801 41.6635 39.8701 37.6291 41.8455L23.8918 48.5716C19.7585 50.5954 15.4046 46.2415 17.4284 42.1082L24.1545 28.3709C26.1299 24.3365 24.1199 19.4839 19.8704 18.028L5.40047 13.0703C1.04679 11.5787 1.0468 5.42133 5.40046 3.92968L19.8704 -1.02798C24.1199 -2.48394 26.1299 -7.33649 24.1545 -11.3709L17.4284 -25.1082C15.4046 -29.2415 19.7585 -33.5954 23.8918 -31.5716L37.6291 -24.8455C41.6635 -22.8701 46.5161 -24.8801 47.972 -29.1296L52.9297 -43.5995Z" stroke="#F27327" strokeWidth="2.63258"/>
    </svg>
  );
}

export default ProfileStar;