import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Iphone11 from "../../components/icons/Iphone11.jsx";
import EmptyEventsInfo from "../../components/shared/EmptyEventsInfo.jsx";
import PreviewElvisImage from "../../components/icons/PreviewElvisImage.jsx";
import Ipad from "../../components/icons/Ipad.jsx";
import HomeEventVertical from "./HomeEventVertical.jsx";

function PreviewPicturesDashboard(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const [desktop, setDesktop] = useState(true);
  const [resetIframe, setResetIframe] = useState(0);

  useEffect(() => {
    setResetIframe(resetIframe + 1);
  }, [
    props.ticketingCompany?.data[0].logo,
    props.ticketingCompany?.data[0].background,
    props.event?.data?.images?.cover,
    props.event?.data?.images?.thumb,
  ]);

  return (
    <div
      className={`ticketer-customizate-preview-box ${
        desktop ? "desktop-preview" : "mobile-preview"
      } d-flex flex-column align-items-center`}
    >
      {props.thumb ? null : (
        <div
          className="ticketer-customizate-preview-box-bar d-flex justify-content-center align-items-center"
          onClick={() => setDesktop(!desktop)}
        >
          {desktop
            ? t("ticketer.desktopVisualization")
            : t("ticketer.mobileVisualization")}
        </div>
      )}
      {(props.ticketingCompany?.data[0].logo &&
        props.ticketingCompany?.data[0].background) ||
      (!props.thumb && props.event?.data?.images?.cover) ||
      props.thumb ? (
        props.thumb ? (
          <div
            className={`w-100 h-100 d-flex justify-content-center align-items-center`}
          >
            <HomeEventVertical
              item={{ images: { thumb: props.event?.data?.images?.thumb } }}
              preview={true}
            />
          </div>
        ) : desktop ? (
          <>
            <Ipad>
              <div
                className={`ticketer-customizate-preview-box-desktop d-flex justify-content-center align-items-center`}
              >
                <iframe
                  key={resetIframe}
                  src={props.url}
                  title="Ticketer Preview"
                ></iframe>
              </div>
            </Ipad>
          </>
        ) : (
          <>
            <Iphone11>
              <iframe
                key={resetIframe}
                src={props.url}
                title="Ticketer Preview"
              ></iframe>
            </Iphone11>
          </>
        )
      ) : (
        <EmptyEventsInfo
          icon={<PreviewElvisImage />}
          text1={t("ticketerSection.previewText1")}
          text2={t("ticketerSection.previewText2")}
          text3={t("ticketerSection.previewText3")}
          span={null}
        />
      )}
    </div>
  );
}

export default PreviewPicturesDashboard;
