import React from "react";

function BellExclamation(props) {

  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.3913 15.188C17.3723 15.165 15.4953 12.782 15.4953 10V6.495C15.4953 2.914 12.5813 0 9.00029 0C5.41929 0 2.50529 2.914 2.50529 6.495V10C2.50529 12.781 0.629295 15.164 0.609295 15.188C0.489295 15.338 0.466298 15.544 0.549298 15.717C0.632298 15.89 0.807279 16 0.999279 16H16.9993C17.1913 16 17.3663 15.89 17.4493 15.717C17.5343 15.543 17.5103 15.338 17.3913 15.188ZM8.25029 5.5C8.25029 5.086 8.58629 4.75 9.00029 4.75C9.41429 4.75 9.75029 5.086 9.75029 5.5V8.5C9.75029 8.914 9.41429 9.25 9.00029 9.25C8.58629 9.25 8.25029 8.914 8.25029 8.5V5.5ZM9.02028 12.5C8.46828 12.5 8.0153 12.052 8.0153 11.5C8.0153 10.948 8.4573 10.5 9.0103 10.5H9.02028C9.57228 10.5 10.0203 10.948 10.0203 11.5C10.0203 12.052 9.57228 12.5 9.02028 12.5ZM11.1623 17.752C10.7063 18.533 9.89829 19 9.00029 19C8.10229 19 7.2943 18.533 6.8383 17.752C6.7483 17.598 6.74728 17.406 6.83629 17.251C6.92529 17.096 7.09028 17 7.27028 17H10.7303C10.9093 17 11.0743 17.096 11.1643 17.251C11.2543 17.406 11.2523 17.598 11.1623 17.752Z" fill="#816CFF"/>
    </svg>
  );
}

export default BellExclamation;