import React from "react";

function EventCard4Svg(props) {

  return (
    <svg width="145" height="67" viewBox="0 0 145 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M133.207 -27.5991L133.212 -27.597C139.377 -25.1184 142.557 -21.5031 143.218 -17.8398C143.873 -14.2078 142.159 -9.90866 137.143 -5.78796C128.739 0.734602 119.605 6.27759 109.917 10.7343C107.387 11.4467 105.183 13.0022 103.676 15.1475C102.16 17.3042 101.444 19.92 101.642 22.5544C101.365 31.3645 100.504 40.1396 99.0671 48.8227L99.0669 48.8235C98.6173 51.5495 97.7546 54.1863 96.5083 56.6445L96.4987 56.6636L96.4896 56.6829C94.1318 61.6815 90.6398 64.5228 87.0698 65.2239C83.5486 65.9154 79.5592 64.6006 75.971 60.4591C68.8796 52.1212 62.9176 42.9003 58.2404 33.037C57.2141 30.0188 55.1943 27.4256 52.512 25.6794C49.8307 23.9338 46.6435 23.1363 43.4699 23.4187C33.325 23.1092 23.2489 21.4621 13.5054 18.5205C6.85319 16.4233 3.28207 12.7066 2.42949 8.81769C1.58331 4.958 3.28346 0.309624 8.53621 -3.97575C17.3037 -10.7039 26.8393 -16.3882 36.9517 -20.9146C39.2166 -21.774 41.1348 -23.3453 42.4155 -25.3953C43.6837 -27.4254 44.2561 -29.812 44.0536 -32.202C44.7286 -43.8078 46.5224 -55.3084 49.4103 -66.5455C50.7304 -71.1349 53.3758 -73.5905 56.6244 -74.2868C59.9632 -75.0024 64.3303 -73.9478 69.1761 -70.6189C75.9528 -64.7341 81.2818 -57.3815 84.7432 -49.1414C86.3603 -45.2919 87.61 -42.3389 88.8282 -40.1216C90.0575 -37.884 91.3335 -36.2431 93.0815 -35.1234C94.8194 -34.0102 96.8624 -33.5169 99.4095 -33.2479C101.569 -33.0198 104.236 -32.942 107.556 -32.8452C108.123 -32.8286 108.71 -32.8115 109.316 -32.793C117.504 -32.4533 125.589 -30.6958 133.207 -27.5991Z" stroke="#FFBC94" strokeWidth="3"/>
    </svg>
  );
}

export default EventCard4Svg;