import React from 'react';
import { useTranslation } from "react-i18next";

export default function TibetLandingComponentTitle(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className="section-title-landing-container d-flex flex-column justify-content-center align-items-center">
      <p>{t('landing.clients')}</p>
      <h2>{ props.text1 }</h2>
      <h6>{ props.text2 }</h6>
    </div>
  )

}
