import React from "react";

function CouponStar(props) {

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.25 15.832V11.082C33.25 7.91536 31.6667 6.33203 28.5 6.33203H9.5C6.33333 6.33203 4.75 7.91536 4.75 11.082V15.832C6.49167 15.832 7.91667 17.257 7.91667 18.9987C7.91667 20.7404 6.49167 22.1654 4.75 22.1654V26.9154C4.75 30.082 6.33333 31.6654 9.5 31.6654H28.5C31.6667 31.6654 33.25 30.082 33.25 26.9154V22.1654C31.5083 22.1654 30.0833 20.7404 30.0833 18.9987C30.0833 17.257 31.5083 15.832 33.25 15.832ZM25.5011 18.188L23.0787 20.5313C22.9014 20.7023 22.8205 20.9494 22.8632 21.1916L23.4176 24.3979C23.53 25.0486 22.8444 25.5442 22.2554 25.2386L19.3483 23.7218C19.1298 23.6078 18.8702 23.6078 18.6517 23.7218L15.7462 25.237C15.1572 25.5442 14.47 25.047 14.5824 24.3963L15.1368 21.1916C15.1779 20.9494 15.097 20.7023 14.9213 20.5313L12.4989 18.188C12.054 17.7574 12.2993 17.0053 12.9137 16.9182L16.2655 16.4353C16.5093 16.4005 16.72 16.2484 16.8293 16.0284L18.2812 13.1103C18.5757 12.5181 19.4258 12.5181 19.7203 13.1103L21.1723 16.0284C21.2815 16.2484 21.4922 16.4005 21.736 16.4353L25.0879 16.9182C25.7022 17.0069 25.946 17.7574 25.5011 18.188Z" fill="url(#paint0_linear_3251_11664)" fillOpacity="0.8"/>
      <defs>
        <linearGradient id="paint0_linear_3251_11664" x1="3.52299" y1="8.01755" x2="35.807" y2="48.7984" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7D68FF"/>
          <stop offset="0.229167" stopColor="#8976FF" stopOpacity="0.770833"/>
          <stop offset="1" stopColor="#B2A6FF" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CouponStar;