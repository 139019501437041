import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import routes from "../../routes/routes.js";
import { AppContext } from "../../App.js";
import { paymentItems } from "../dashboard/paymentMethods.js";
import TicketerButton from "../ui/TicketerButton";
import AngleRightSmall from "../icons/AngleRightSmall";
import Times from "../icons/Times";
import { socialMediaData } from "../dashboard/socialMediaData.js";
import {
  pictureFields,
  pictureEventFields,
  ticketerFields,
  createEventsFields,
  infoCreateEventsFields,
  infoFields,
} from "../dashboard//ticketerData.js";

function ModalTicketer() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const {
    state: authState,
    dispatch,
    ticketerModal,
    setTicketerModal,
    role,
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  const location = useLocation().pathname;
  let { eventId } = useParams();

  const [payment, setPayment] = useState(null);
  const picture = useRef(
    location === routes.ticketer ? pictureFields : pictureEventFields
  );
  const fields = useRef(
    location === routes.ticketer ? ticketerFields : createEventsFields
  );
  const informationFields = useRef(
    location === routes.ticketer ? infoFields : infoCreateEventsFields
  );

  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setTicketerModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".modal-container")) {
      setTicketerModal(false);
    }
  });

  var stringToHTML = function (id, str) {
    var dom = document.createElement("div");
    dom.innerHTML = str;
    if (document.getElementById(id).childNodes.length === 1) {
      document.getElementById(id).appendChild(dom);
    }
  };

  useEffect(() => {
    if (ticketerModal.data) {
      if (ticketerModal.id === 5) {
        stringToHTML("terms", ticketerModal.data?.terms);
      } else if (ticketerModal.id === 6) {
        stringToHTML("policy", ticketerModal.data?.privacyPolicy);
      }
    }
  }, [ticketerModal.data, ticketerModal.id]);

  return (
    <div
      id="modal-ticketer-section"
      className={
        ticketerModal.id
          ? "modal-background modal-background-footer modal-background-ticketer"
          : "d-none"
      }
    >
      <div className="modal-container justify-content-start justify-content-sm-center align-items-center">
        <div className="modal-ticketer-container d-flex flex-column align-items-center">
          <div className="modal-ticketer-container-top-container d-flex flex-column">
            <div className="modal-ticketer-container-top d-flex justify-content-between align-items-center">
              <div
                className="modal-ticketer-container-top-text d-flex align-items-center"
                onClick={() => setTicketerModal(false)}
              >
                <AngleRightSmall />
                <h3>
                  {ticketerModal.id === 1
                    ? location === routes.ticketer
                      ? t("ticketer.info1")
                      : t("events.info1")
                    : ticketerModal.id === 2
                    ? location === routes.ticketer
                      ? t("ticketer.payment1")
                      : t("ticketer.ticketList")
                    : ticketerModal.id === 3
                    ? location === routes.ticketer
                      ? t("ticketer.customize1")
                      : t("events.customize1")
                    : ticketerModal.id === 4
                    ? t("ticketer.domain1")
                    : ticketerModal.id === 5
                    ? t("ticketer.terms1")
                    : ticketerModal.id === 6
                    ? t("ticketer.policy1")
                    : null}
                </h3>
              </div>
              <Times function={() => setTicketerModal(false)} />
            </div>
            {location.includes(routes.events) ? (
              ticketerModal.id === 3 ? (
                <p>{t("events.customize2")}</p>
              ) : ticketerModal.id === 1 ? (
                <p>{t("events.info2")}</p>
              ) : null
            ) : null}
          </div>
          <div className="modal-ticketer-container-bottom d-flex flex-column justify-content-between align-items-center">
            {fields?.current?.map((section) => {
              if (section.id === ticketerModal.id) {
                return section?.fields?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="modal-ticketer-item d-flex flex-column justify-content-between"
                    >
                      <div className="modal-ticketer-item-header d-flex justify-content-between">
                        <h4>{t(item)}</h4>
                        {/* ticketerModal.id === 2 && location.includes(routes.ticketer) ? null : <TicketerButton text = { t('ticketer.editInfo') } function = { () => ticketerModal.function(index ? section.id === 3 ? 7 : section.id === 1 && index === 1 ? 8 : 9 : section.id) }/> */}
                        <TicketerButton
                          text={t("ticketer.editInfo")}
                          function={() =>
                            ticketerModal.function(
                              index
                                ? section.id === 3
                                  ? 7
                                  : section.id === 1 && index === 1
                                  ? 8
                                  : 9
                                : section.id
                            )
                          }
                        />
                      </div>
                      <div className="modal-ticketer-item-content d-flex flex-column">
                        {ticketerModal.id === 1 ? (
                          <div
                            className={`modal-ticketer-item-content-info-container ${
                              location === routes.ticketer
                                ? null
                                : "events-width"
                            } d-flex flex-wrap`}
                          >
                            {informationFields?.current[index]?.map(
                              (item, index) => {
                                var fieldInfo = null;
                                for (var key in ticketerModal.data) {
                                  if (key.indexOf(item.field) !== -1) {
                                    if (item.field === "currency") {
                                      fieldInfo =
                                        ticketerModal?.data[key]?.abbreviation;
                                    } else if (item.field === "dateEvent") {
                                      item.id === 9
                                        ? (fieldInfo =
                                            ticketerModal?.data[key]?.split(
                                              " "
                                            )[0])
                                        : (fieldInfo = ticketerModal?.data[key]
                                            ?.split(" ")[1]
                                            ?.slice(0, 5));
                                    } else if (item.field === "dateEnd") {
                                      item.id === 11
                                        ? (fieldInfo =
                                            ticketerModal?.data[key]?.split(
                                              " "
                                            )[0])
                                        : (fieldInfo = ticketerModal?.data[key]
                                            ?.split(" ")[1]
                                            ?.slice(0, 5));
                                    } else if (item.field === "dateDoors") {
                                      item.id === 13
                                        ? (fieldInfo =
                                            ticketerModal?.data[key]?.split(
                                              " "
                                            )[0])
                                        : (fieldInfo = ticketerModal?.data[key]
                                            ?.split(" ")[1]
                                            ?.slice(0, 5));
                                    } else {
                                      fieldInfo = ticketerModal.data[key];
                                    }
                                    break;
                                  }
                                }
                                return (
                                  <div
                                    key={item.id}
                                    className={`modal-ticketer-item-info ${
                                      item.id === 1
                                        ? location === routes.ticketer
                                          ? "modal-ticketer-item-info-long"
                                          : "modal-ticketer-item-info-long-2"
                                        : null
                                    } d-flex flex-column`}
                                  >
                                    <h5>{t(item.text)}</h5>
                                    <div className="modal-ticketer-item-content-social-text d-flex align-items-center">
                                      <h4>{fieldInfo}</h4>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : ticketerModal.id === 2 ? (
                          location.includes(routes.ticketer) ? (
                            <>
                              <h5>{t("ticketer.infoPayment2")}</h5>
                              <div className="modal-ticketer-item-content-payment d-flex align-items-center">
                                {
                                  paymentItems?.filter(
                                    (item) =>
                                      item.key ===
                                      ticketerModal.data.paymentMethod
                                  )[0]?.icon
                                }
                                <h4>
                                  {
                                    paymentItems?.filter(
                                      (item) =>
                                        item.key ===
                                        ticketerModal.data.paymentMethod
                                    )[0]?.name
                                  }
                                </h4>
                              </div>
                            </>
                          ) : (
                            <div
                              className={`modal-ticketer-item-content-tickets-container d-flex flex-column align-items-center`}
                            >
                              <div
                                className={`modal-ticketer-item-content-ticket d-flex justify-content-between align-items-center`}
                              >
                                <div
                                  className={`modal-ticketer-item-content-ticket-left d-flex align-items-center`}
                                >
                                  <h6>{t("events.ticketName")}</h6>
                                </div>
                                <div
                                  className={`modal-ticketer-item-content-ticket-right d-flex justify-content-end align-items-center`}
                                >
                                  <h6>{t("events.ticketCategory")}</h6>
                                  <h6>{t("events.ticketPrice")}</h6>
                                  <h6>{t("events.ticketQuantity")}</h6>
                                </div>
                              </div>
                              {ticketerModal?.data?.map((item, index) => {
                                return (
                                  <div
                                    key={item.id}
                                    className={`modal-ticketer-item-content-ticket d-flex justify-content-between align-items-center`}
                                  >
                                    <div
                                      className={`modal-ticketer-item-content-ticket-left d-flex align-items-center`}
                                    >
                                      <h6>{item.name}</h6>
                                    </div>
                                    <div
                                      className={`modal-ticketer-item-content-ticket-right d-flex justify-content-end align-items-center`}
                                    >
                                      <h6>{item.category?.name}</h6>
                                      <h6>{item.price}</h6>
                                      <h6>{item.amount}</h6>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : ticketerModal.id === 3 ? (
                          index ? (
                            <div className="modal-ticketer-item-content-social-container d-flex flex-wrap align-items-center">
                              {socialMediaData?.map((item) => {
                                return (
                                  <div
                                    key={item.id}
                                    className="modal-ticketer-item-content-social d-flex flex-column"
                                  >
                                    <div className="modal-ticketer-item-content-social-header d-flex align-items-center">
                                      {item.icon}
                                      <h5>{item.name}</h5>
                                    </div>
                                    <div className="modal-ticketer-item-content-social-text d-flex align-items-center">
                                      <h4>
                                        {item.id === 1 &&
                                          (ticketerModal.data?.facebook
                                            ? ticketerModal.data?.facebook
                                            : "-")}
                                        {item.id === 2 &&
                                          (ticketerModal.data?.instagram
                                            ? ticketerModal.data?.instagram
                                            : "-")}
                                        {item.id === 3 &&
                                          (ticketerModal.data?.twitter
                                            ? ticketerModal.data?.twitter
                                            : "-")}
                                        {item.id === 4 &&
                                          (ticketerModal.data?.tiktok
                                            ? ticketerModal?.data?.tiktok
                                            : "-")}
                                      </h4>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="modal-ticketer-item-content-picture-container d-flex flex-wrap flex-column flex-sm-row">
                              {picture?.current?.map((item) => {
                                return (
                                  <div
                                    key={item.id}
                                    className="modal-ticketer-item-content-picture d-flex align-items-center"
                                  >
                                    <div className="modal-ticketer-item-content-picture-item d-flex justify-content-center align-items-center">
                                      {(item.id && ticketerModal.data?.logo) ||
                                      (!item.id &&
                                        ticketerModal.data?.background) ||
                                      (item.id &&
                                        ticketerModal.data?.images?.cover) ||
                                      (!item.id &&
                                        ticketerModal.data?.images?.thumb) ? (
                                        <img
                                          src={
                                            location === routes.ticketer
                                              ? item.id
                                                ? ticketerModal.data?.logo
                                                : ticketerModal.data?.background
                                              : item.id
                                              ? ticketerModal.data?.images
                                                  ?.thumb
                                              : ticketerModal.data?.images
                                                  ?.cover
                                          }
                                          alt={item.id}
                                          className=""
                                        />
                                      ) : null}
                                    </div>
                                    <div className="modal-ticketer-item-content-data d-flex flex-column">
                                      <h5>{item.text1}</h5>
                                      <h6>{`${t("ticketer.size")} ${
                                        item.text2
                                      }`}</h6>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )
                        ) : ticketerModal.id === 4 ? (
                          <>
                            <h5>{t("ticketer.infoDomain2")}</h5>
                            <h6>{ticketerModal.data?.ourSubdomain}</h6>
                          </>
                        ) : ticketerModal.id === 5 ? (
                          <div id="terms">
                            <h5>{t(item)}</h5>
                          </div>
                        ) : (
                          <div id="policy">
                            <h5>{t(item)}</h5>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                });
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTicketer;
