import React from "react";

function StarCircleSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.5C6.477 2.5 2 6.977 2 12.5C2 18.023 6.477 22.5 12 22.5C17.523 22.5 22 18.023 22 12.5C22 6.977 17.523 2.5 12 2.5ZM16.8311 11.893L15.031 13.65C14.899 13.778 14.839 13.964 14.87 14.145L15.282 16.55C15.365 17.038 14.8549 17.41 14.4189 17.18L12.259 16.043C12.097 15.958 11.9039 15.958 11.7419 16.043L9.58398 17.179C9.14598 17.409 8.63599 17.036 8.71899 16.548L9.13098 14.145C9.16198 13.963 9.10197 13.778 8.96997 13.65L7.17004 11.893C6.83904 11.57 7.02103 11.007 7.47803 10.94L9.96802 10.578C10.149 10.552 10.306 10.437 10.387 10.273L11.4659 8.08398C11.6849 7.63998 12.316 7.63998 12.535 8.08398L13.614 10.273C13.695 10.438 13.852 10.552 14.033 10.578L16.5229 10.94C16.9799 11.006 17.1621 11.57 16.8311 11.893Z" fill="#816CFF"/>
    </svg>
  );
}

export default StarCircleSmall;