import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../App.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';

function StripeOk(props) {
  const { setStripeSubscriptionOk, stripeSubscriptionOk } =
    useContext(AppContext);
  useEffect(() => {
    setStripeSubscriptionOk(true);
  }, [stripeSubscriptionOk]);

  return (
    <TicketingSectionComponent>
      <div className="ticketer-sections-domain-container d-flex flex-wrap justify-content-center align-items-center">
        <h1>Pago satisfactorio!</h1>
      </div>
      <ButtonGroupTibet button={null} />
    </TicketingSectionComponent>
  );
}

export default StripeOk;
