import React from "react";

function ModalTermsEvents(props) {

  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="40.0791" cy="46.3003" r="28.3128" stroke="#816CFF" strokeWidth="0.530584" strokeDasharray="1.06 1.06"/>
      <path d="M31.753 67.6784C44.9264 67.6784 55.6056 56.9992 55.6056 43.8258C55.6056 30.6523 44.9264 19.9731 31.753 19.9731C18.5795 19.9731 7.90039 30.6523 7.90039 43.8258C7.90039 56.9992 18.5795 67.6784 31.753 67.6784Z" fill="#E0DBFF"/>
      <path d="M38.6908 60.8751C36.8482 62.7177 30.4327 64.3554 29.1654 64.6652C29.0723 64.6885 28.9814 64.6978 28.8883 64.6978C28.5784 64.6978 28.2779 64.5743 28.0566 64.3484C27.7678 64.0548 27.6583 63.6309 27.7631 63.2326C28.1149 61.9118 29.9575 55.2238 31.7908 53.3904C32.8228 52.3585 34.1623 51.8273 35.5087 51.8413C36.8109 51.8716 38.0618 52.4237 39.0286 53.3904C40.9993 55.3635 40.8479 58.7203 38.6908 60.8751ZM61.9858 48.9318L61.3848 52.5914C60.6859 56.9941 57.6576 60.7447 53.5111 62.3986L47.9669 64.6116C47.8271 64.6815 47.664 64.7048 47.5242 64.7048C47.268 64.7048 46.9884 64.6116 46.7788 64.4253C46.4527 64.169 46.2896 63.7264 46.3828 63.3071L47.9273 55.5359C47.7013 55.5801 47.487 55.6407 47.2564 55.6803C46.9722 55.7293 46.6856 55.7526 46.4014 55.7526C45.041 55.7526 43.7038 55.2004 42.7022 54.2011L38.2249 49.7214C37.0135 48.5101 36.4614 46.8096 36.7456 45.1673C36.7852 44.9413 36.8458 44.7294 36.8877 44.5057L29.1654 46.0409C29.0956 46.0642 29.0024 46.0641 28.9325 46.0641C28.5831 46.0641 28.257 45.9244 28.0473 45.6448C27.7678 45.3187 27.6979 44.8528 27.8609 44.4568L30.0973 38.8893C31.7279 34.7661 35.4784 31.7377 39.8811 31.0389L43.4942 30.4355C49.46 23.5938 58.8293 19.855 70.8145 19.855C71.7789 19.855 72.5617 20.6377 72.5617 21.6021C72.5663 33.5967 68.8299 42.9659 61.9858 48.9318ZM66.088 37.2051C65.7293 31.358 61.0679 26.699 55.2209 26.338C48.4677 29.2475 42.0756 34.8895 40.1887 45.766C40.1002 46.2808 40.2935 46.8492 40.6965 47.2498L45.1738 51.7295C45.5744 52.1301 46.1452 52.3212 46.66 52.235C57.5388 50.3504 63.1808 43.9606 66.088 37.2051ZM53.348 33.8389C50.4524 33.8389 48.1066 36.1847 48.1066 39.0803C48.1066 41.9759 50.4524 44.3217 53.348 44.3217C56.2436 44.3217 58.5894 41.9759 58.5894 39.0803C58.5894 36.1847 56.2436 33.8389 53.348 33.8389Z" fill="#442ECB"/>
      <path d="M6.29053 56.6206V67.4218" stroke="#F27327" strokeWidth="1.22285" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M11.7016 62.0103H0.900391" stroke="#F27327" strokeWidth="1.22285" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M76.2681 58.0781V65.729" stroke="#FF8C5A" strokeWidth="0.866185" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M80.1001 61.896H72.4492" stroke="#FF8C5A" strokeWidth="0.866185" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M49.5299 5.57115C50.897 5.57115 52.0052 4.46293 52.0052 3.09588C52.0052 1.72882 50.897 0.620605 49.5299 0.620605C48.1629 0.620605 47.0547 1.72882 47.0547 3.09588C47.0547 4.46293 48.1629 5.57115 49.5299 5.57115Z" fill="#816CFF"/>
      <path d="M60.1068 79.3792C61.101 79.3792 61.907 78.5732 61.907 77.579C61.907 76.5848 61.101 75.7788 60.1068 75.7788C59.1126 75.7788 58.3066 76.5848 58.3066 77.579C58.3066 78.5732 59.1126 79.3792 60.1068 79.3792Z" fill="#C8BFFF"/>
      <path d="M49.5299 75.7787C50.897 75.7787 52.0052 74.6704 52.0052 73.3034C52.0052 71.9363 50.897 70.8281 49.5299 70.8281C48.1629 70.8281 47.0547 71.9363 47.0547 73.3034C47.0547 74.6704 48.1629 75.7787 49.5299 75.7787Z" fill="#FF8C5A"/>
      <path d="M16.546 18.9595C16.5482 18.9595 16.551 18.9595 16.5532 18.9595C16.7805 18.9595 16.8788 19.2395 16.7078 19.387C15.51 20.4181 14.8515 22.0392 15.1872 23.7865C15.5599 25.7254 17.1553 27.2011 19.1398 27.4662C20.3933 27.6337 21.554 27.3166 22.4768 26.6831C22.6642 26.5541 22.9129 26.7244 22.8567 26.9425C22.07 30.018 19.0351 32.2099 15.5727 31.7161C12.6967 31.3056 10.4257 28.9976 10.0851 26.1529C9.90697 24.6694 10.244 23.2713 10.943 22.1091C12.076 20.2236 14.161 18.9595 16.546 18.9595Z" fill="#442ECB"/>
    </svg>
  );
}

export default ModalTermsEvents;