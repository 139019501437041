import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import {
  createEvent,
  getGeneralStats,
  getSubscription,
  subscriptionsStates,
} from "../../services/axiosService";
import { AppContext } from "../../App";
import DashboardBar from "../shared/DashboardBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import CircleSubscriptionIcon from "../icons/CircleSubscriptionIcon";
import ButtonSwitch from "../shared/ButtonSwitch";
import SpinnerPurple from "../icons/SpinnerPurple";
import PlanPro from "../icons/PlanProIcon";
import SubscriptionCard from "./SubscriptionCard";
import SubscriptionCardMobile from "./SubscriptionCardMobile";
import HomeBoxes from "../shared/HomeBoxes";
import { getSubdomain } from "../shared/functions";
import { dashboardData } from "./dashboardData";
import ModalCancelSubscription from "../modals/ModalCancelSubscription";

const ManageSubscriptions = () => {
  const [t, i18n] = useTranslation("global");
  var navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [dataToModal, setDataTomodal] = React.useState({});
  const [typesAnual, setTypesAnual] = React.useState();
  const [typesMensual, setTypesMensual] = React.useState();
  const [selectedType, setSelectedType] = React.useState();
  const [isMensual, setIsMensual] = React.useState(true);
  const [subsStatistics, setStatistics] = React.useState({});
  const [perentage, setPercentage] = React.useState(10);
  const { state: authState, dispatch } = React.useContext(AppContext);
  const [select, setSelect] = useState([0, 0, 0]);
  const [selectOpen, setSelectOpen] = useState([false, false, false]);
  const subdomain = useMemo(() => getSubdomain(), []);
  const [openModalCancelPlan, setModalCancelPlan] = React.useState(false);
  const [dataToCancelModal, setDataToCancelmodal] = React.useState({});

  const { data: metrics, isLoading: isLoadingMetrics } = useQuery({
    queryKey: ["metrics"],
    queryFn: () => getGeneralStats(authState.token),
    enabled: !!authState.isAuthenticated && !subdomain,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        //navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const updateSelectStatus = (index) => {
    let newArrOpen = selectOpen;
    newArrOpen[index] = !newArrOpen[index];
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
  };

  const updateSelect = (index, value) => {
    let newArr = select;
    newArr[index] = value;
    let newArrOpen = selectOpen;
    newArrOpen[index] = false;
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
    setSelect([newArr[0], newArr[1], newArr[2]]);
  };

  /* CREATE NEW EVENT */
  const { mutate: createNewEvent } = useMutation(
    () => createEvent(authState.token),
    {
      onSuccess: (res) => {
        if (res) {
          navigate(routes.events + "/" + res.data.id);
        }
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  /* GET SUBSCRIPTIONS */
  const { isLoading, data, isSuccess } = useQuery({
    queryKey: ["getSubscription"],
    queryFn: () => getSubscription(authState.token),
    enabled: true,
    onSuccess: (res) => {
      const firstEl = res.data.filter((f) => f.id === 2);
      const secondEl = res.data.filter((f) => f.id === 1);
      const thirdEl = res.data.filter((f) => f.id === 3);
      const sortedData = [firstEl[0], secondEl[0], thirdEl[0]];
      const mensual = sortedData?.filter((f) => f.period === "mensual");
      setTypesMensual(mensual);
      setSelectedType(mensual);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /* SAVE IN MEMORIA ANUAL DATA TO CHANGE FROM MENSUAL TO ANUAL */
  const sortedData = useMemo(() => {
    if (data) {
      const filter = data.data.filter((f) => f.period === "anual");
      const firstEl = filter.filter((f) => f.name === "Free");
      const secondEl = filter.filter((f) => f.name === "Pro");
      const thirdEl = filter.filter((f) => f.name === "Empresa");
      const order = [firstEl[0], secondEl[0], thirdEl[0]];
      setSelectedType(order);
      setTypesAnual(order);
      return order;
    }
  }, [isLoading, isSuccess]);

  const { isLoading: isLoadStatistics, data: boxesData } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => subscriptionsStates(authState.token),
    /* enabled: true, */
    onSuccess: (res) => {
      setStatistics(res.data);
    },
    onError: (err) => {
      console.log(err);
      console.error(err.response.status);
    },
  });

  document.addEventListener("keyup", (e) => {
    // if we press the ESC
    if (openModal) {
      if (e.key === "Escape" && document.querySelector(".modal-background")) {
        setOpenModal(false);
      }
    }
  });

  document.addEventListener("click", (e) => {
    if (openModal) {
      if (e.target === document.querySelector(".modal-container")) {
        setOpenModal(false);
      }
    }
  });

  const getFilterSwitch = (side) => {
    if (side === "left") {
      setSelectedType(typesMensual);
      setIsMensual(true);
    } else {
      setSelectedType(sortedData);
      setIsMensual(false);
    }
  };

  /*   const openModalCancelSubscription = (item) => {
    switch (item) {
      case "Pro":
        setModalCancelPlan(true);
        setDataToCancelmodal(1);
        break;
      case "Free":
        setModalCancelPlan(true);
        setDataToCancelmodal(2);
        break;
      case "Empresa":
        setModalCancelPlan(true);
        setDataToCancelmodal(3);
        break;
      default:
        break;
    }
  }; */

  const calcPercent =
    (subsStatistics.totalTickets / subsStatistics?.freeTickets) * 100;
  const porcentajeRedondeado = Math.round(calcPercent);

  return (
    <section
      id="myticketer-section"
      className="ticketer-section sub-section-wrapper"
    >
      <section className="d-xs-block d-sm-block d-md-block d-lg-none">
        <DashboardBar
          text={<TitleSectionIcon />}
          session={false}
          buttonText={t("home.create")}
          function={() => createNewEvent()}
          disabled={false}
          button={true}
        />
      </section>
      <section className="d-lg-block">
        <div className="sub-dashboard-bar d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <div className="bar-section-buttons-left d-flex flex-row align-items-center">
              Suscripción
            </div>
            <div>
              <LabelTypeSuscription
                label={`Plan ${subsStatistics?.subscriptionName}`}
                icon={subsStatistics?.subscriptionName === "Pro" && <PlanPro />}
              />
            </div>
          </div>
          {/* <span
            className="sub-dashboard-bar-cancelPlan"
            onClick={() =>
              openModalCancelSubscription(subsStatistics?.subscriptionName)
            }
          >
            Cancelar plan
          </span> */}
        </div>
      </section>
      <section className="sub-cards">
        {!isLoadingMetrics ? (
          <>
            {dashboardData?.map((item) => (
              <HomeBoxes
                key={item.id}
                item={item}
                select={select}
                selectOpen={selectOpen}
                updateSelectStatus={updateSelectStatus}
                updateSelect={updateSelect}
                metrics={metrics?.data}
                home
              />
            ))}
          </>
        ) : (
          <div style={{ width: "100%", height: "100px" }}>
            <div className="spinner-container d-flex justify-content-center align-items-center">
              <SpinnerPurple />
            </div>
          </div>
        )}
      </section>
      <section className="mt-5">
        <div className="sub-upgrade-activeplan">
          <h2 className="fs-3 fw-semibold text">¿Necesitas mejorar tu plan?</h2>
          <ButtonSwitch
            textLeft={"Mensual"}
            textRight={"Anual"}
            handleButtonClick={getFilterSwitch}
          />
        </div>
        <p className="sub-save-percent">Ahorra 40%</p>
        {isLoading ? (
          <div className="spinner-container d-flex justify-content-center align-items-center">
            <SpinnerPurple />
          </div>
        ) : (
          <div className="sub-cards-types mt-5">
            <SubscriptionCardMobile
              data={selectedType ? selectedType : null}
              subsStatistics={subsStatistics}
              isMensual={isMensual}
            />
            {selectedType?.map((item, index) => (
              <SubscriptionCard
                key={index}
                item={item}
                subsStatistics={subsStatistics}
                isMensual={isMensual}
              />
            ))}
          </div>
        )}
      </section>
      {openModalCancelPlan && (
        <ModalCancelSubscription
          data={dataToCancelModal}
          isClosable={() => setModalCancelPlan(false)}
        />
      )}
    </section>
  );
};

export default ManageSubscriptions;

const LabelTypeSuscription = ({ label, icon }) => {
  return (
    <div className="home-section-dashboard-quantity div">
      <div>
        {icon} {label}
      </div>
    </div>
  );
};

const TitleSectionIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#C8BFFF",
        width: "auto",
        padding: "8px",
        borderRadius: "100px",
      }}
    >
      <CircleSubscriptionIcon />
    </div>
  );
};
