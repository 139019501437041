import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useQuery, useMutation } from "@tanstack/react-query";
import { queryClient } from "../../index.js";
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../routes/routes.js";
import {
  getEventById,
  patchEvent,
  deletePicturesEvent,
} from "../../services/axiosService.js";
import { PictureUploadComponent } from "../../components/shared/PictureUploadComponent.jsx";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import StarCircleSmall from "../../components/icons/StarCircleSmall";
import EmptyEventsInfo from "../../components/shared/EmptyEventsInfo.jsx";
import PreviewElvisImage from "../../components/icons/PreviewElvisImage.jsx";
import { pictureEventFields } from "../../components/dashboard/ticketerData.js";
import SpinnerPurple from "../../components/icons/SpinnerPurple.jsx";
import PreviewPicturesDashboard from "../../components/shared/PreviewPicturesDashboard.jsx";
import SuccessFailPopupLarge from "../../components/ui/SuccessFailPopupLarge.jsx";

function CustomizeEventFirstPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, setImageModal } = React.useContext(AppContext);
  const [popup, setPopup] = useState(null);
  const [isLoadingArray, setIsLoadingArray] = useState([false]);

  var navigate = useNavigate();

  const navigateRoute = (route) => {
    navigate(route === "back" ? -1 : route);
  };

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  const { mutate } = useMutation(
    (pictures) =>
      patchEvent(authState.token, props.eventId, null, pictures.cover, null),
    {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueryData(["events", props.eventId], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    images: {
                      ...oldData.data.images,
                      cover: res.data.images.cover,
                    },
                  },
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        console.log(err);
        setPopup({
          state: 0,
          text: `${err.response.data.message} 😖`,
        });
      },
      onSettled: () => {
        setIsLoadingArray([false]);
      },
    }
  );

  const { mutate: deletePicture } = useMutation(
    (values) => deletePicturesEvent(authState.token, props.eventId, true, null),
    {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueryData(["events", props.eventId], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    images: {
                      ...oldData.data.images,
                      cover: res.data.images.cover,
                    },
                  },
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        console.error(err);
      },
      onSettled: () => {
        setIsLoadingArray([false]);
      },
    }
  );

  function onSelectFile(e, id) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 1572864) {
        setImageModal({
          sizeExceeded: true,
          message: t("profile.sizeTextUploadImage"),
          messageTitle: t("profile.sizeTitleUploadImage"),
        });
      } else {
        setIsLoadingArray([false]);
        mutate({ cover: e.target.files[0], thumb: null });
      }
    }
    e.target.value = "";
  }

  return (
    <>
      <div className="ticketer-customize-picture-container d-flex flex-column">
        {pictureEventFields.map((item) => {
          if (item.id === 0) {
            return (
              <PictureUploadComponent
                key={item.id}
                id={item.id}
                icon={item.icon}
                text1={item.text1}
                text2={item.text2}
                buttonIcon={item.buttonIcon}
                buttonText={item.buttonText}
                buttonTextAlt={item.buttonTextAlt}
                picture={props.events?.images?.cover}
                uploadFunction={onSelectFile}
                deleteFunction={deletePicture}
                isLoading={isLoadingArray}
              />
            );
          } else return null;
        })}
        <div className="disclaimer-message">
          {t("ticketer.disclaimerPersonalizeTicket")}
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t("ticketer.next"),
          disabled: false,
          function: () =>
            navigateRoute(
              `${routes.events}/${props.eventId}${routes.createMiniature}`
            ),
        }}
      />
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
    </>
  );
}

function CustomizeEventFirstPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const {
    state: authState,
    dispatch,
    ticketerModal,
    setTicketerModal,
    role,
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const {
    isLoading,
    isError,
    error,
    data: events,
    isFetching,
  } = useQuery({
    queryKey: ["events", eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t("home.errorRetrievingEvent")}</h3>
        </div>
      ) : (
        <div className="w-100 ticketer-customizate d-flex justify-content-between align-items-streech">
          <TicketingSectionComponent
            initialValues={null}
            validationSchema={null}
            ticketer={false}
            title={t("ticketer.customize1")}
            pagination={"1/3"}
            description={t("events.sentenceCustomize1")}
            paragraph={t("events.sentenceCustomize2")}
            footer={{
              text: t("events.adviceCustomize1"),
              icon: <StarCircleSmall />,
            }}
          >
            <CustomizeEventFirstPageChildren
              eventId={eventId}
              events={events?.data}
            />
          </TicketingSectionComponent>
          <div className="ticketer-customizate-preview d-none d-xl-flex justify-content-center align-items-center">
            <PreviewPicturesDashboard
              ticketingCompany={null}
              event={events}
              url={`${routes.eventPreviewCustomizeNoId}/${events?.data?.id}`}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CustomizeEventFirstPage;
