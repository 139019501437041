import React from "react";

export default function ProfileButton(props) {
  return (
    <button
      className="profile-button d-flex flex-row justify-content-center align-items-center"
      onClick={props.function}
      id={props.id}
      ref={props.reference}
      disabled={props.disabled}
      data-direction={props.parallaxDirection}
      data-speed={props.parallaxSpeed}
    >
      <span className="profile-button-ellipsis">
        {props.icon}
        {props.text}
      </span>
    </button>
  );
}
