import React from "react";

function LocationPinSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 2.5C7.313 2.5 3.5 6.313 3.5 11C3.5 15.983 8.129 19.041 11.192 21.064L11.722 21.416C11.806 21.472 11.903 21.5 11.999 21.5C12.095 21.5 12.192 21.472 12.276 21.416L12.806 21.064C15.869 19.041 20.498 15.983 20.498 11C20.5 6.313 16.687 2.5 12 2.5ZM12 13.5C10.619 13.5 9.5 12.381 9.5 11C9.5 9.619 10.619 8.5 12 8.5C13.381 8.5 14.5 9.619 14.5 11C14.5 12.381 13.381 13.5 12 13.5Z" fill="#816CFF"/>
    </svg>
  );
}

export default LocationPinSmall;