import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TibetLandingComponentTitle from '../shared/TibetLandingComponentTitle';
import AngleRightSmall from '../icons/AngleRightSmall';

export default function TibetLandingTermsComponent() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const questions = [
    { id: 0, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') },
    { id: 1, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') },
    { id: 2, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') },
    { id: 3, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') },
    { id: 4, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') },
    { id: 5, question: t('landing.faqDummy1'), answer: t('landing.faqDummy2') }
  ];

  const [itemStatus, setItemStatus] = useState([...Array(questions.length)].map(function callback(currentValue, index, array) {
    return false;
  }));

  const setStatus = (id) => {
    setItemStatus(itemStatus.map(function callback(currentValue, index, array) {
      if (index === id) {
        return !currentValue;
      } else {
        return currentValue;
      }
    }));
  }

  return (
    <section id="faq-landing-section" className="faq-landing-container d-flex flex-column justify-content-center align-items-center">
      <TibetLandingComponentTitle
        text1 = { t('landing.termsTitle1') }
        text2 = { t('landing.termsTitle2') }
      />
      <div className="faq-landing-items d-flex flex-column"> 

      </div>
    </section>
  )

}
