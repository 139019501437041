import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import TibetLandingComponentTitle from '../shared/TibetLandingComponentTitle';
import ProfileButton from '../ui/ProfileButton';
import LandingCardSparkle from '../icons/LandingCardSparkle';
import LandingCardCircle from '../icons/LandingCardCircle';
import LandingCardStar from '../icons/LandingCardStar';
import routes from '../../routes/routes';
import Image1 from '../../assets/landing/landingcards1.png';
import Image2 from '../../assets/landing/landingcards2.png';
import Image3 from '../../assets/landing/landingcards3.png';
import Image4 from '../../assets/landing/landingcards4.png';
import Image5 from '../../assets/landing/landingcards5.png';
import Image6 from '../../assets/landing/landingcards6.png';
import Image7 from '../../assets/landing/landingcards7.png';
import Image8 from '../../assets/landing/landingcards8.png';

export default function TibetLandingComponent3() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  const cards = [
    { id: 0, text1: t('landing.benefitsCards1'), text2: t('landing.benefitsCards2'), buttonText: t('landing.benefitsButton'), svg: <LandingCardSparkle/>, img1: Image1, img2: Image2, img3: null },
    { id: 1, text1: t('landing.benefitsCards3'), text2: t('landing.benefitsCards4'), buttonText: null, svg: <LandingCardCircle/>, img1: Image3, img2: Image4, img3: Image5 },
    { id: 2, text1: t('landing.benefitsCards5'), text2: t('landing.benefitsCards6'), buttonText: null, svg: <LandingCardStar/>, img1: Image6, img2: Image7, img3: Image8 },
  ]

  return (
    <section id="benefits-landing-section" className="benefits-landing-container d-flex flex-column justify-content-center align-items-center">
      <TibetLandingComponentTitle
        text1 = { t('landing.benefitsTitle1') }
        text2 = { t('landing.benefitsTitle2') }
      />
      <div className="benefits-landing-cards-container d-flex flex-wrap justify-content-center align-items-center">
        { cards.map(item => {
          return (
            <div key={ item.id } className="benefits-landing-card d-flex flex-column justify-content-sm-between">
              { item.svg }
              <div className="benefits-image-container">
                { item.img1 && <img src={ item.img1 } alt={`benefits-${item.id}-1`} className=""/> }
                { item.img2 && <img src={ item.img2 } alt={`benefits-${item.id}-2`} className=""/> }
                { item.img3 && <img src={ item.img3 } alt={`benefits-${item.id}-3`} className=""/> }
              </div>
              <div className="benefits-landing-card-top d-flex flex-column">
                <h3>{ item.text1 }</h3>
                <p>{ item.text2 }</p>
              </div>
              { !item.id ?
                // FAQ QUITADO
                <ProfileButton
                  icon = { null } 
                  text = { item.buttonText } 
                  function = { null }
                  disabled = { false }
                />
                : null
              }
            </div>
          )
        })}
      </div>
    </section>
  )

}
