import React, { useState, useRef } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import ReactCrop from 'react-image-crop';
import ProfileButton from '../ui/ProfileButton.jsx';
import { canvasPreview } from './canvasPreview.tsx';
import { useDebounceEffect } from './useDebounceEffect.tsx';
import SizeExceeded from '../shared/SizeExceeded.jsx';

function ModalImage() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { imageModal, setImageModal, userData } = React.useContext(AppContext);

  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 100,
    height: 100,
    aspect: 1,
    minWidth: 100,
    minHeight: 100,
    locked: true
  });
  const [completedCrop, setCompletedCrop] = useState();
  const [sizeExceeded, setSizeExceeded] = useState(
    imageModal.sizeExceeded ? true : false,
    imageModal.messageTitle ? true : false,
    imageModal.message ? true : false
  );
  const previewCanvasRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef('');
  const imgRef = useRef(null);

  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist');
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob');
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      if (blob.size < 1572864) {
        let file = new File(
          [blob],
          `${userData?.id}_${blob.size}.${blob.type.split('/')[1]}`,
          {
            type: blob.type
          }
        );
        imageModal.functionForLoad({ picture: file });
        setTimeout(() => {
          URL.revokeObjectURL(blobUrlRef.current);
          setImageModal(false);
          if (imageModal.function) {
            imageModal.function(false);
          }
        }, 1000);
      } else {
        setSizeExceeded(true);
      }
    }, imageModal.src.split(';')[0].split(':')[1]);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const leaveModal = () => {
    setSizeExceeded(false);
    setImageModal(false);
  };

  document.addEventListener('keyup', (e) => {
    // if we press the ESC
    if (e.key === 'Escape' && document.querySelector('.modal-background')) {
      setImageModal(false);
    }
  });

  document.addEventListener('click', (e) => {
    if (e.target === document.querySelector('.modal-container')) {
      setImageModal(false);
    }
  });

  return (
    <div
      id="modal-picture-section"
      className={imageModal ? 'modal-background' : 'd-none'}
    >
      <div className="modal-container justify-content-center align-items-center">
        <div className="modal-terms-container sign-mail-box crop-modal d-flex flex-column justify-content-center align-items-center">
          {sizeExceeded ? (
            <SizeExceeded
              function={
                imageModal.sizeExceeded
                  ? () => leaveModal()
                  : () => setSizeExceeded(false)
              }
              messageText={imageModal.message}
              messageTitle={imageModal.messageTitle}
            />
          ) : (
            <>
              <div className="crop-modal-title d-flex justify-content-center justify-content-sm-start align-items-center">
                {t('profile.adjust')}
              </div>
              <ReactCrop
                crop={crop}
                aspect={1}
                onChange={(c) => setCrop(c)}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img ref={imgRef} src={imageModal.src} alt="" />
              </ReactCrop>
              <div className="crop-modal-buttons d-flex justify-content-between justify-content-sm-end align-items-center">
                <h3 onClick={() => setImageModal(false)}>
                  {t('profile.cancel')}
                </h3>
                <ProfileButton
                  icon={null}
                  text={t('profile.crop')}
                  function={() => onDownloadCropClick()}
                  disabled={false}
                />
                {!!completedCrop && (
                  <div
                    className="hidden-canvas"
                    style={{
                      visibility: 'hidden',
                      display: 'hidden',
                      opacity: 0
                    }}
                  >
                    <div>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: '1px solid black',
                          objectFit: 'contain',
                          width: completedCrop.width,
                          height: completedCrop.height
                        }}
                      />
                    </div>
                    <div>
                      <div
                        ref={hiddenAnchorRef}
                        style={{
                          position: 'absolute',
                          top: '-200vh',
                          visibility: 'hidden',
                          display: 'hidden',
                          opacity: 0
                        }}
                      >
                        Hidden download
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalImage;
