import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, ErrorMessage } from 'formik';

export default function LandingCheckoutFormField({
  title,
  error,
  touched,
  name
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  return (
    <div
      className={`checkout-main-form-field d-flex flex-column justify-content-between`}
    >
      <p>{t(title)}</p>
      <Field
        className={`${name === 'email' && 'FieldreadOnly'} ${
          error && touched
            ? 'ticketer-field red-border'
            : touched
            ? 'ticketer-field purple-border'
            : 'ticketer-field'
        }`}
        id="landing-sale-name"
        type="text"
        name={name}
        placeholder=""
        readOnly={name === 'email' ?? false}
        disabled={name === 'email' ?? false}
      />
      {error ? (
        <ErrorMessage name={name}>
          {(msg) => <div className="ticketer-field-error">{msg}</div>}
        </ErrorMessage>
      ) : null}
    </div>
  );
}
