import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import routes from '../../routes/routes.js';
import { useNavigate } from 'react-router-dom';
import { dashboardData } from './dashboardData';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getEvents,
  getGeneralStats,
  createEvent,
  getNextEventsByUser
} from '../../services/axiosService.js';
import { getSubdomain } from '../shared/functions.js';
import EmptyItemsBox from '../shared/EmptyItemsBox.jsx';
import DateButton from '../ui/DateButton.jsx';
import DashboardBar from '../shared/DashboardBar';
import CalendarEdit from '../icons/CalendarEdit';
import GridWeb from '../icons/GridWeb.jsx';
import AngleRightSmall from '../icons/AngleRightSmall.jsx';
import TermsPolicyButton from '../ui/TermsPolicyButton.jsx';
import HomeBoxes from '../shared/HomeBoxes.jsx';
import HomeEventHorizontal from '../shared/HomeEventHorizontal.jsx';
import HomeEventVertical from '../shared/HomeEventVertical.jsx';
import EmptyEvents from '../icons/EmptyEvents.jsx';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';
import TicketBanner from '../icons/TicketBanner.jsx';
const { DateTime } = require('luxon');

function HomeComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    elvis,
    ticketingCompany,
    publicElvisId
  } = React.useContext(AppContext);

  const subdomain = useMemo(() => getSubdomain(), []);
  var navigate = useNavigate();
  const [vertical, setVertical] = useState(false);
  const [floraSeeMore, setFloraSeeMore] = useState(false);
  const [select, setSelect] = useState([0, 0, 0]);
  const [selectOpen, setSelectOpen] = useState([false, false, false]);

  const {
    isLoading: eventsIsLoading,
    isError: eventsIsError,
    error: eventsError,
    data: events,
    isFetching: eventsIsFetching
  } = useQuery({
    queryKey: ['events', { status: 1 }],
    queryFn: () =>
      getEvents({ userToken: authState.token, status: 1, dateNext: true }),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log("res", res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        //navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: nextEventsIsLoading,
    isError: nextEventsIsError,
    error: nextEventsError,
    data: nextEvents,
    isFetching: nextEventsIsFetching
  } = useQuery({
    queryKey: ['next-events'],
    queryFn: () => getNextEventsByUser(authState.token, publicElvisId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER',
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      /*if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }*/
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: metricsIsLoading,
    isError: metricsIsError,
    error: metricsError,
    data: metrics,
    isFetching: metricsIsFetching
  } = useQuery({
    queryKey: ['metrics'],
    queryFn: () => getGeneralStats(authState.token),
    enabled: !!authState.isAuthenticated && !subdomain,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        //navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { mutate: createNewEvent } = useMutation(
    () => createEvent(authState.token),
    {
      onSuccess: (res) => {
        if (res) {
          navigate(routes.events + '/' + res.data.id);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const updateSelectStatus = (index) => {
    let newArrOpen = selectOpen;
    newArrOpen[index] = !newArrOpen[index];
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
  };

  const updateSelect = (index, value) => {
    let newArr = select;
    newArr[index] = value;
    let newArrOpen = selectOpen;
    newArrOpen[index] = false;
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
    setSelect([newArr[0], newArr[1], newArr[2]]);
  };

  useEffect(() => {
    if (!subdomain && authState.roles[0] === 'ROLE_USER') {
      dispatch({
        type: 'LOGOUT'
      });
      //navigate(routes.auth);
    }
    if (subdomain && authState.roles[0] === 'ROLE_ADMIN') {
      dispatch({
        type: 'LOGOUT'
      });
    }
  }, [authState]);

  return (
    <section
      id="home-section"
      className="home-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <DashboardBar
        text={t('home.title')}
        session={false}
        emoji="greeting"
        icon={elvis ? <CalendarEdit /> : null}
        buttonText={
          elvis
            ? t('home.create')
            : t('home.backToShop') + ticketingCompany?.data[0]?.comercialName
        }
        function={
          elvis ? () => createNewEvent() : () => navigate(routes.landing)
        }
        disabled={false}
        button={true}
      />
      {elvis ? (
        metricsIsLoading ? (
          <SpinnerPurple />
        ) : metricsIsError ? (
          <h3 className="fetching-error">{t('home.errorRetrievingMetrics')}</h3>
        ) : metrics?.data ? (
          <div className="home-section-dashboard d-flex flex-wrap justify-content-center align-items-center">
            {dashboardData.map((item) => {
              return (
                <HomeBoxes
                  key={item.id}
                  item={item}
                  select={select}
                  selectOpen={selectOpen}
                  updateSelectStatus={updateSelectStatus}
                  updateSelect={updateSelect}
                  metrics={metrics?.data}
                  home={true}
                />
              );
            })}
          </div>
        ) : null
      ) : null}
      <div className="home-section-events d-flex flex-column justify-content-center align-items-center">
        <div className="home-section-events-title d-flex justify-content-between align-items-center">
          <h3>{t('home.events')}</h3>
          <div className="home-section-events-title-right d-flex justify-content-between align-items-center">
            <div
              className={
                !vertical
                  ? 'd-flex justify-content-center vertical-active align-items-center'
                  : 'd-flex justify-content-center align-items-center'
              }
              onClick={() => setVertical(false)}
            >
              <GridWeb />
            </div>
            <div
              className={
                vertical
                  ? 'd-flex justify-content-center vertical-active align-items-center'
                  : 'd-flex justify-content-center align-items-center'
              }
              onClick={() => setVertical(true)}
            >
              <GridWeb />
            </div>
          </div>
        </div>
        {elvis ? (
          eventsIsFetching ? (
            <SpinnerPurple />
          ) : eventsIsError ? (
            <h3 className="fetching-error">
              {t('home.errorRetrievingEvents')}
            </h3>
          ) : events?.data?.events?.filter((item) => item.status === 1)
              .length ? (
            !vertical ? (
              <div className="home-section-events-horizontal-container d-flex flex-wrap justify-content-start align-items-center">
                {events?.data?.events
                  ?.filter((item) => item.status === 1)
                  .map((item) => {
                    return (
                      <HomeEventHorizontal
                        key={item.id}
                        item={item}
                        function={() => navigate(`${routes.events}/${item.id}`)}
                        elvis={elvis}
                        landing={false}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="home-section-events-vertical-container d-flex flex-column justify-content-center align-items-center">
                {events?.data?.events
                  ?.filter((item) => item.status === 1)
                  .map((item) => {
                    return (
                      <HomeEventVertical
                        key={item.id}
                        item={item}
                        function={() => navigate(`${routes.events}/${item.id}`)}
                        elvis={elvis}
                      />
                    );
                  })}
              </div>
            )
          ) : (
            <EmptyItemsBox
              icon={<EmptyEvents />}
              text1={t('home.empty1')}
              text2={t('home.empty2')}
              text3={t('home.empty3')}
              span={null}
              buttonIcon={<CalendarEdit />}
              buttonText={t('home.create')}
              buttonFunction={() => createNewEvent()}
              buttonDisabled={false}
            />
          )
        ) : nextEventsIsFetching ? (
          <SpinnerPurple />
        ) : nextEventsIsError ? (
          <h3 className="fetching-error">{t('home.errorRetrievingEvents')}</h3>
        ) : nextEvents?.data?.events?.filter((item) => item.status === 1)
            .length ? (
          !vertical ? (
            <div className="home-section-events-horizontal-container d-flex flex-wrap justify-content-start align-items-center">
              {nextEvents?.data?.events
                ?.filter((item) => item.status === 1)
                .map((item) => {
                  return (
                    <HomeEventHorizontal
                      key={item.id}
                      item={item}
                      function={() => navigate(`${routes.landing}/${item.id}`)}
                      elvis={elvis}
                      landing={false}
                    />
                  );
                })}
            </div>
          ) : (
            <div className="home-section-events-vertical-container d-flex flex-column justify-content-center align-items-center">
              {nextEvents?.data?.events
                ?.filter((item) => item.status === 1)
                .map((item) => {
                  return (
                    <HomeEventVertical
                      key={item.id}
                      item={item}
                      function={() => navigate(`${routes.landing}/${item.id}`)}
                      elvis={elvis}
                    />
                  );
                })}
            </div>
          )
        ) : (
          <EmptyItemsBox
            icon={null}
            text1={t('home.empty5')}
            text2={t('home.empty6')}
            text3={null}
            span={null}
            buttonIcon={null}
            buttonText={null}
            landing={true}
            buttonFunction={null}
            buttonDisabled={true}
          />
        )}
      </div>
      {elvis ? null : (
        <div className="home-section-more d-flex flex-column justify-content-center align-items-center">
          <div className="home-section-more-title d-flex justify-content-between align-items-center">
            <h3>
              {t('home.explore')}
              {ticketingCompany?.data[0]?.comercialName}
            </h3>
            {events?.data?.events?.length > 3 ? (
              <div
                className="home-section-more-title-right d-flex justify-content-between align-items-center"
                onClick={() => setFloraSeeMore(!floraSeeMore)}
              >
                <h4>{t('home.more')}</h4>
                <AngleRightSmall />
              </div>
            ) : null}
          </div>
          <div
            className={`${
              floraSeeMore ? 'flex-wrap' : null
            } home-section-more-horizontal-container d-flex align-items-center`}
          >
            {events?.data?.events?.map((item, index) => {
              if (index < (floraSeeMore ? events?.data?.events?.length : 3)) {
                const eventDate = DateTime.fromFormat(
                  String(item.dateEvent),
                  'yyyy-MM-dd HH:mm:ss'
                );
                return (
                  <div
                    key={item.id}
                    className="home-section-more-item d-flex flex-column justify-content-between"
                  >
                    <div className="home-section-more-item-image home-section-events-horizontal d-flex flex-column justify-content-between">
                      {item?.images?.cover ? (
                        <img
                          src={item?.images?.cover}
                          alt={`ticket-banner-`}
                          className=""
                        />
                      ) : (
                        <TicketBanner />
                      )}
                      <DateButton
                        day={eventDate?.c?.day}
                        month={eventDate?.monthShort}
                        black={true}
                      />
                    </div>
                    <div className="home-section-more-item-title">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3>{t('home.event')}</h3>
                        {/*<button className="home-section-more-item-info orange-number orange-background"
                            onClick={ () => navigate(`/${elvisId}${routes.landing}/${item.id}`) }
                          >{t('home.moreInfo')}</button>*/}
                      </div>
                      <h2>{item.name}</h2>
                    </div>
                    <div>
                      <h5>{item.location}</h5>
                      <h5>{`${t('home.from')}${item.lowestPrice} ${
                        ticketingCompany?.data[0]?.currency?.abbreviation
                      }`}</h5>
                    </div>
                    <TermsPolicyButton
                      text={t('home.buy')}
                      function={() => navigate(`${routes.landing}/${item.id}`)}
                    />
                  </div>
                );
              } else return null;
            })}
          </div>
        </div>
      )}
    </section>
  );
}

export default HomeComponent;
