import React from "react";

function LandingCardStar(props) {

  return (
    <svg width="435" height="283" viewBox="0 0 435 283" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M489.239 303.436C691.378 214.878 600.898 139.799 430.842 175.093C466.137 5.03955 391.057 -85.4419 302.5 116.696C213.942 -85.443 138.863 5.04069 174.157 175.093C4.10326 139.798 -86.3782 214.878 115.76 303.436C-86.3793 391.993 4.10441 467.072 174.157 431.778C138.862 601.832 213.942 692.313 302.5 490.175C391.057 692.315 466.136 601.834 430.842 431.778C600.896 467.074 691.377 391.993 489.239 303.436Z" fill="#816CFF"/>
    </svg>
  );
}

export default LandingCardStar;