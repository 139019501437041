import React from 'react';

function Download(props) {
  return (
    <svg
      onClick={() => props.onclick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 13V18C21 20 20 21 18 21H6C4 21 3 20 3 18V13C3 11 4 10 6 10H11.25V14.189L9.53003 12.469C9.23703 12.176 8.76199 12.176 8.46899 12.469C8.17599 12.762 8.17599 13.237 8.46899 13.53L11.469 16.53C11.538 16.599 11.6209 16.654 11.7129 16.692C11.8049 16.73 11.902 16.75 12 16.75C12.098 16.75 12.1951 16.73 12.2871 16.692C12.3791 16.654 12.462 16.599 12.531 16.53L15.531 13.53C15.824 13.237 15.824 12.762 15.531 12.469C15.238 12.176 14.763 12.176 14.47 12.469L12.75 14.189V10H18C20 10 21 11 21 13ZM12.75 3C12.75 2.586 12.414 2.25 12 2.25C11.586 2.25 11.25 2.586 11.25 3V10H12.75V3Z"
        fill="#D4CDFF"
      />
    </svg>
  );
}

export default Download;
