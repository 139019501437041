import React from "react";
import { stripeCheckoutSubscription } from "../../services/axiosService";
import { useQuery } from "@tanstack/react-query";
import UpgradePlanIcon from "../icons/UpgradePlanIcon";
import Button from "../shared/Button";
import Times from "../icons/Times";
import { AppContext } from "../../App";

const TYPE_SUBSCRIPTION = {
  FREE: "free",
  PRO: "pro",
  EMPRESA: "empresa",
};

const ModalSubscription = ({ isClosable, data }) => {
  const [subscriptionId, setSubscriptionId] = React.useState(null);
  const { state: authState } = React.useContext(AppContext);

  /* GET SUBSCRIPTION STRIPE */
  const { data: subscriptionDataStripe } = useQuery({
    queryKey: ["stripeSubscription"],
    queryFn: () => stripeCheckoutSubscription(subscriptionId, authState.token),
    enabled: subscriptionId !== null,
    onSuccess: (res) => {
      successCheckout(res.data);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /* BUY SUBSCRIPTION */
  const buySubscription = (type, id) => {
    const toslowercase = type.toLowerCase();
    switch (toslowercase) {
      case TYPE_SUBSCRIPTION.PRO:
        setSubscriptionId(id);
        break;
      case TYPE_SUBSCRIPTION.FREE:
        setSubscriptionId(id);
        break;
      case TYPE_SUBSCRIPTION.EMPRESA:
        setSubscriptionId(id);
        break;
      default:
        break;
    }
  };

  const successCheckout = (stripeData) => {
    window.open(stripeData.checkout_url);
  };

  return (
    <div
      id="modal-subscription-section"
      className="modal-background modal-background modal-background-footer modal-background-ticketer d-flex justify-content-center align-items-center"
    >
      <div className="modal-container">
        <div className="modal-subscription-container subscription-modal-box d-flex flex-column">
          <div className="sub-close-modal">
            <Times function={() => isClosable()} />
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center gap-4">
            <UpgradePlanIcon />
            <span className="sub-title-modal">Actualizar plan</span>
          </div>
          <div className="sub-modal-row">
            <h4>Quiero mi plan {data.name}</h4>
            <p>{data.description}</p>
          </div>
          <div className="sub-modal-row total">
            <div>
              <h4>Upgrade Plan</h4>
              <p>Lorem ipsum dolor sit amet,</p>
            </div>
            <div>
              <h6>${data.price}usd</h6>
            </div>
          </div>
          <div className="sub-modal-row total">
            <div>
              <h4>Total</h4>
              <p>Lorem ipsum dolor sit amet</p>
            </div>
            <div>
              <h5>
                ${data.price}
                <span>/mensual</span>
              </h5>
            </div>
          </div>
          <div>
            <p className="plan-included">¿Qué incluye mi plan?</p>
            <p className="plan-included-attributes">
              <strong>
                Incluye: 12 usuarios, 200 tickets gratis, soporte 24hs{" "}
              </strong>
            </p>
            <ul>
              {data.attributes.benefits_list.map((benefit, bIndex) => (
                <li key={bIndex}>{benefit}</li>
              ))}
            </ul>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button onClick={() => buySubscription(data.name, data.id)}>
              Cambiar a {data.name}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSubscription;
