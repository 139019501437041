import React from 'react';
import { useTranslation } from "react-i18next";
import Image from "../../assets/event2.png";
import LandingContactRings from '../icons/LandingContactRings';
import ContactForm from '../shared/ContactForm';

export default function TibetLandingComponent6({ landing }) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <section id="contact-landing-section" className="contact-landing-container d-flex justify-content-center align-items-center">
      { landing ? <LandingContactRings/> : null }
      <div className={`contact-landing-form-container ${landing ? null : "contact-width" } d-flex flex-column justify-content-center`}>
        <div className={`contact-landing-form-container-top d-flex flex-column justify-content-center align-items-center ${landing ? "align-items-lg-start" : null }`}>
          <h2>{t('landing.question1')}</h2>
          <h2>{t('landing.question2')}</h2>
        </div>
        <ContactForm/>
      </div>
      { landing ? 
        <div className="contact-landing-img-container">
          <img src={ Image } alt="img-landing-background"/>
        </div>
        : null
      }
    </section>
  )

}
