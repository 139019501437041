import React from 'react';

export default function PreviewButton(props) {

  return (
    <button className="preview-button flex-row justify-content-center align-items-center" 
      onClick={ props.function } id={ props.id } ref={ props.reference } disabled= { props.disabled }
    >
      { props.icon }{ props.text }
    </button>
  )

}