import React from "react";

function CopyAlt(props) {

  return (
    <svg onClick={ props.function }width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.115234" y="0.963867" width="40.0383" height="40.0383" rx="20.0192" fill="#AD9FFF"/>
      <path d="M17.8113 14.5942C15.261 14.5942 13.7387 16.1164 13.7387 18.6666V25.6401C13.7387 25.8131 13.5854 25.9584 13.4134 25.9416C11.9881 25.7993 11.2676 24.9374 11.2676 23.3717V14.7128C11.2676 12.9929 12.1375 12.123 13.8574 12.123H22.5161C24.0818 12.123 24.9438 12.8437 25.0861 14.269C25.1039 14.441 24.9587 14.5942 24.7857 14.5942H17.8113ZM26.4651 16.0769H25.1059H17.8161C16.0863 16.0769 15.2214 16.9418 15.2214 18.6716V25.9614V27.3205C15.2214 29.0503 16.0863 29.9152 17.8161 29.9152H26.4651C28.1949 29.9152 29.0598 29.0503 29.0598 27.3205V18.6716C29.0598 16.9418 28.1949 16.0769 26.4651 16.0769Z" fill="#442ECB"/>
    </svg>
  );
}

export default CopyAlt;