import React from "react";

function CreditCardCheck(props) {

  return (
    <svg width="108" height="82" viewBox="0 0 108 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M0 37.375V71.5C0 84.5 6.5 91 19.5 91H66.495C67.6 91 68.51 90.09 68.51 88.985C68.315 87.49 68.25 85.995 68.25 84.5C68.25 64.805 84.305 48.75 104 48.75C107.575 48.75 110.955 49.2699 114.205 50.2449C114.465 50.3099 114.725 50.375 114.985 50.375C116.09 50.375 117 49.5301 117 48.4251V37.375H0ZM45.5 69.875H26C23.335 69.875 21.125 67.665 21.125 65C21.125 62.335 23.335 60.125 26 60.125H45.5C48.165 60.125 50.375 62.335 50.375 65C50.375 67.665 48.165 69.875 45.5 69.875ZM104 58.5C89.635 58.5 78 70.135 78 84.5C78 98.865 89.635 110.5 104 110.5C118.365 110.5 130 98.865 130 84.5C130 70.135 118.365 58.5 104 58.5ZM114.4 81.3801L103.61 92.235C102.96 92.82 102.18 93.1451 101.27 93.1451C100.425 93.1451 99.58 92.82 98.995 92.235L93.6 86.7748C92.3 85.5398 92.3 83.4602 93.6 82.2252C94.835 80.9252 96.915 80.9252 98.15 82.2252L101.27 85.345L109.85 76.765C111.085 75.53 113.165 75.53 114.4 76.765C115.7 78.065 115.7 80.0801 114.4 81.3801ZM97.5 0H19.5C6.5 0 0 6.5 0 19.5V27.625H117V19.5C117 6.5 110.5 0 97.5 0Z" fill="#B2A6FF"/>
    </svg>
  );
}

export default CreditCardCheck;