import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AngleRightSmall from '../icons/AngleRightSmall.jsx';
import { AppContext } from '../../App.js';

function HomeBoxes(props) {
  const { ticketingCompany } = React.useContext(AppContext);
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const [data, setData] = useState(0);
  const [days, setDays] = useState(1);

  useEffect(() => {
    if (Object.keys(props.metrics).length) {
      var filteredKeys;
      if (props.item.id && props.home) {
        filteredKeys =
          props.select[props.item.id] === 0
            ? ['week']
            : props.select[props.item.id] === 1
            ? ['month']
            : props.select[props.item.id] === 2
            ? ['quarter']
            : props.select[props.item.id] === 3
            ? ['year']
            : null;
        if (props.item.id === 1) {
          setData(
            filteredKeys.reduce((obj, key) => props.metrics[key]?.sold, 0)
          );
          setDays(
            props.select[props.item.id] === 0
              ? 7
              : props.select[props.item.id] === 1
              ? 30
              : props.select[props.item.id] === 2
              ? 90
              : props.select[props.item.id] === 3
              ? 360
              : 1
          );
        } else if (props.item.id === 2) {
          setData(
            filteredKeys.reduce(
              (obj, key) => props.metrics[key]?.sumTotalSold,
              0
            )
          );
        }
      }
      if (!props.home) {
        filteredKeys =
          props.select[props.item.id] === 0
            ? ['week']
            : props.select[props.item.id] === 1
            ? ['month']
            : props.select[props.item.id] === 2
            ? ['quarter']
            : props.select[props.item.id] === 3
            ? ['year']
            : null;
        if (props.item.id === 0) {
          setData(
            filteredKeys.reduce((obj, key) => props.metrics[key]?.sold, 0)
          );
          setDays(
            props.select[props.item.id] === 0
              ? 7
              : props.select[props.item.id] === 1
              ? 30
              : props.select[props.item.id] === 2
              ? 90
              : props.select[props.item.id] === 3
              ? 360
              : 1
          );
        } else if (props.item.id === 1) {
          setData(
            filteredKeys.reduce(
              (obj, key) => props.metrics[key]?.sumTotalSold,
              0
            )
          );
        }
      }
    }
  }, [props.select, props.item.id, props.metrics, props.home]);

  const formatDiffPercentage = (diffPercentage) => {
    const hasDecimals = diffPercentage % 1 !== 0;
    const formattedPercentage = hasDecimals
      ? diffPercentage?.toFixed(3)
      : diffPercentage;
    return diffPercentage > 0 ? `+${formattedPercentage}` : formattedPercentage;
  };

  const getFn = (data, id) => {
    const division = data / days;
    if (division > 0) {
      return `Menos de 1 ${getPeriod(id)}`;
    } else if (division === 0) {
      return `0 ${getPeriod(id)}`;
    } else {
      t('home.noInfo');
    }
  };

  const getPeriod = (id) => {
    if (id === 0) {
      return 'por día'; /* CAMBIAR EL MENSAJE SI QUEREMOS PERSONALIZARLO POR PERIODO */
    } else if (id === 1) {
      return 'por día'; /* CAMBIAR EL MENSAJE SI QUEREMOS PERSONALIZARLO POR PERIODO */
    } else if (id === 2) {
      return 'por día'; /* CAMBIAR EL MENSAJE SI QUEREMOS PERSONALIZARLO POR PERIODO */
    } else {
      return 'por día'; /* CAMBIAR EL MENSAJE SI QUEREMOS PERSONALIZARLO POR PERIODO */
    }
  };

  return (
    <div className="home-section-dashboard-box d-flex flex-column justify-content-center">
      <div className="home-section-dashboard-icon d-flex flex-row justify-content-between align-items-center">
        {props.item.icon}
        {props.item.dropdown ? (
          <div
            className={
              props.selectOpen[props.item.id]
                ? 'home-section-dashboard-icon-select home-section-dashboard-icon-select-selected d-flex flex-row justify-content-between align-items-center'
                : 'home-section-dashboard-icon-select d-flex flex-row justify-content-between align-items-center'
            }
          >
            {props.select[props.item.id] === 0
              ? t('home.period1')
              : props.select[props.item.id] === 1
              ? t('home.period2')
              : props.select[props.item.id] === 2
              ? t('home.period3')
              : t('home.period4')}
            <AngleRightSmall
              function={() => props.updateSelectStatus(props.item.id)}
              rotate180={props.selectOpen[props.item.id]}
            />
            {props.selectOpen[props.item.id] ? (
              <ul>
                <li onClick={() => props.updateSelect(props.item.id, 0)}>
                  {t('home.period1')}
                </li>
                <li onClick={() => props.updateSelect(props.item.id, 1)}>
                  {t('home.period2')}
                </li>
                <li onClick={() => props.updateSelect(props.item.id, 2)}>
                  {t('home.period3')}
                </li>
                <li onClick={() => props.updateSelect(props.item.id, 3)}>
                  {t('home.period4')}
                </li>
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
      <p>
        {t(props.item.text)}
        <span>{t(props.item.altText)}</span>
      </p>

      <div className="home-section-dashboard-quantity d-flex flex-row justify-content-start align-items-center">
        <h3>
          {props.home
            ? props.item.id === 0
              ? props.metrics?.totalFreeTickets
                ? props.metrics?.totalFreeTickets
                : data
              : props.item.id === 2 && ticketingCompany.data[0]
              ? ticketingCompany.data[0]?.currency?.id !== 2
                ? `${data}${ticketingCompany.data[0]?.currency?.symbol}`
                : `${ticketingCompany.data[0]?.currency?.symbol}${data}`
              : data
              ? data
              : 0
            : props.item.id === 0
            ? data
              ? data
              : 0
            : props.item.id === 1
            ? data
              ? data
              : 0
            : props.metrics?.scanned
            ? props.metrics?.scanned
            : data}
          <span className={props.metrics?.freeTickets ? 'orange-number' : null}>
            {props.item.span ? `/${props.metrics?.freeTickets}` : null}
          </span>
        </h3>
        {!props.item.span ? (
          <div className={'orange-number orange-background'}>
            {props.home
              ? props.item.id === 1
                ? data
                  ? getFn(data, props.select[1])
                  : `0 ${getPeriod(props.select[1])}`
                : props.item.id === 2
                ? props.select[2] === 0
                  ? `${formatDiffPercentage(
                      props?.metrics?.week?.diffPercentage
                    )} %`
                  : props?.select[2] === 1
                  ? `${formatDiffPercentage(
                      props.metrics?.month?.diffPercentage
                    )} %`
                  : props?.select[2] === 2
                  ? `${formatDiffPercentage(
                      props?.metrics?.quarter?.diffPercentage
                    )} %`
                  : props?.select[2] === 3
                  ? `${formatDiffPercentage(
                      props?.metrics?.year?.diffPercentage
                    )} %`
                  : t('home.noInfo')
                : null
              : props.item.id === 0
              ? data
                ? getFn(data, props.select[1])
                : `0 ${getPeriod(props.select[1])}`
              : props.item.id === 1
              ? props.select[1] === 0
                ? `${formatDiffPercentage(
                    props?.metrics?.week?.diffPercentage
                  )} %`
                : props?.select[1] === 1
                ? `${formatDiffPercentage(
                    props.metrics?.month?.diffPercentage
                  )} %`
                : props?.select[1] === 2
                ? `${formatDiffPercentage(
                    props?.metrics?.quarter?.diffPercentage
                  )} %`
                : props?.select[1] === 3
                ? `${formatDiffPercentage(
                    props?.metrics?.year?.diffPercentage
                  )} %`
                : t('home.noInfo')
              : props.item.id === 2
              ? data
                ? null
                : t('home.noInfo')
              : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default HomeBoxes;
