import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index.js';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getEventById,
  createTicket,
  getTicketTypeById
} from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import TicketItem from '../../components/ui/TicketItem.jsx';
import TicketerButton from '../../components/ui/TicketerButton.jsx';
import {
  ticketDetailsFields,
  ticketDetailsFieldsTicketId
} from '../../components/dashboard/ticketerData.js';
import ArrowsRepeatCircle from '../../components/icons/ArrowsRepeatCircle.jsx';
import ArrowsRepeatCircleModal from '../../components/icons/ArrowsRepeatCircleModal.jsx';
import MinusCircle from '../../components/icons/MinusCircle.jsx';
import LikeCircle from '../../components/icons/LikeCircle.jsx';
import ContentField from '../../components/ui/ContentField.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import SuccessFailPopupLarge from '../../components/ui/SuccessFailPopupLarge.jsx';
import Star4 from '../../components/icons/Star4.jsx';

function TicketDetail(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    createTicketInfo,
    setDeleteEventModal,
    setTicketerSectionModal
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId, ticketId } = useParams();

  const [ticket, setTicket] = useState(
    ticketId
      ? queryClient
          .getQueryData(['tickets-model', eventId])
          ?.data?.tickets?.filter(
            (item) => Number(item.id) === Number(ticketId)
          )[0]
      : createTicketInfo
  );
  const [popup, setPopup] = useState(null);
  const [buttonsActive, setButtonsActive] = useState(ticketId ? false : true);
  const fieldsToShow = useRef(
    ticketId ? ticketDetailsFieldsTicketId : ticketDetailsFields
  );

  const { isLoading: mutateIsLoading, mutate } = useMutation(
    () => createTicket(authState.token, eventId, createTicketInfo),
    {
      onSuccess: (res) => {
        if (res.data) {
          setTicketerSectionModal(5);
          setButtonsActive(false);
        }
      },
      onError: (err) => {
        console.error(err);
        setPopup({
          state: 0,
          text:
            t('ticket.createTicketFail') +
              '. (' +
              err.response.data.message +
              ')' +
              ' ' +
              err.message ?? null
        });
      }
    }
  );

  const {
    isLoading,
    isError,
    data: events
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { isLoading: ticketsIsLoading } = useQuery({
    queryKey: ['tickets-model', ticketId],
    queryFn: () => getTicketTypeById(authState.token, ticketId),
    enabled: !!authState.isAuthenticated && !!ticketId,
    onSuccess: (res) => {
      setTicket(res.data);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });
  const modalFunction = (id) => {
    if (id) {
      setDeleteEventModal({
        action: 4,
        title: t('ticket.resumeTicketTitle'),
        text1: t('ticket.resumeTicketParagraph'),
        textButton1: t('events.cancelButton1'),
        textButton2: t('ticket.pauseTicket'),
        event: ticket?.eventName,
        svg: <ArrowsRepeatCircleModal />
      });
    } else {
      setDeleteEventModal({
        action: 3,
        title: t('ticket.pauseTicketTitle'),
        text1: t('ticket.pauseTicketParagraph1'),
        text3: t('ticket.pauseTicketParagraph2'),
        textButton1: t('events.cancelButton1'),
        textButton2: t('ticket.resumeTicket'),
        event: ticket?.eventName,
        svg: <LikeCircle />
      });
    }
  };

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <div className={`details-ticket-section d-flex justify-content-center`}>
          {ticket ? (
            <TicketItem
              key={ticket?.id}
              ticketStatus={ticket?.status}
              eventStatus={events?.data?.status}
              ticketImg={ticket?.image}
              eventImg={events?.data?.images.cover}
              currency={ticket?.currency?.symbol}
              ticketCategory={ticket?.category?.name}
              ticketName={ticket?.name}
              eventName={events?.data?.name}
              eventDate={events?.data?.dateEvent}
              eventAddress={events?.data?.location}
              ticketPrice={
                events?.data?.currency?.id === 2
                  ? events?.data?.currency?.symbol +
                    Number(ticket?.price).toFixed(2)
                  : Number(ticket?.price).toFixed(2) +
                    events?.data?.currency?.symbol
              }
              ticketContent={ticket?.extraContent}
              totalTickets={ticket?.quantity}
              availableTickets={null}
              secondaryMarketUser={null}
              buttonActive={false}
              function={null}
              ticketPaused={false}
              ticketActivationDate={null}
              saleOpen={null}
              setTicketSaleOpen={null}
            />
          ) : null}
          {popup ? (
            <SuccessFailPopupLarge
              text={popup.text}
              state={popup.state}
              function={() => setPopup(null)}
            />
          ) : null}
          <TicketingSectionComponent
            initialValues={null}
            validationSchema={null}
            ticketer={false}
            eventTitle={events?.data?.name}
            title={
              ticketId ? t('ticket.ticketDetail') : t('ticket.createTicket')
            }
            pagination={null}
            description={
              ticketId ? t('ticket.ticketDetail') : t('ticket.detailTicket')
            }
            footer={null}
            titleFunction={() => navigate(-1)}
            breadcrum={true}
            button={false}
            buttonIcon={true ? <MinusCircle /> : <ArrowsRepeatCircle />}
            buttonText={true ? t('ticket.pauseSale') : t('ticket.resumeSale')}
            buttonFunction={
              true ? () => modalFunction(0) : () => modalFunction(1)
            }
            buttonDisabled={false}
          >
            <div
              className={`ticket-detail-info d-flex flex-column justify-content-center`}
            >
              <p>{t('ticket.ticketDetailInfo')}</p>
              {fieldsToShow.current?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`modal-ticketer-item-content-info-container d-flex`}
                  >
                    <div className="modal-ticketer-item d-flex flex-column justify-content-between">
                      <div className="modal-ticketer-item-header d-flex justify-content-between">
                        <h4>{t(item.title)}</h4>
                        {buttonsActive ? (
                          <TicketerButton
                            text={t('ticketer.editInfo')}
                            function={
                              ticketId
                                ? null
                                : item.id === 1
                                ? () =>
                                    navigate(
                                      `${routes.events}/${eventId}${routes.createTicketsData}`
                                    )
                                : item.id === 2
                                ? () =>
                                    navigate(
                                      `${routes.events}/${eventId}${routes.createTicketsSale}`
                                    )
                                : item.id === 3
                                ? () =>
                                    navigate(
                                      `${routes.events}/${eventId}${routes.createTicketsAdvance}`
                                    )
                                : null
                            }
                          />
                        ) : null}
                      </div>
                      <div className="modal-ticketer-item-content d-flex flex-wrap">
                        {item.fields.map((item, index) => {
                          var fieldInfo = null;
                          for (var key in ticket) {
                            if (key.indexOf(item.key) !== -1) {
                              if (item.key === 'price') {
                                fieldInfo =
                                  events?.data?.currency?.id === 2
                                    ? events?.data?.currency?.symbol +
                                      ticket[key]
                                    : Number(ticket[key]).toFixed(2) +
                                      events?.data?.currency?.symbol;
                              } else if (
                                item.key === 'date' ||
                                item.key === 'accessDate'
                              ) {
                                !index
                                  ? (fieldInfo = ticket[key]?.split(
                                      ' ' || 'T'
                                    )[0])
                                  : (fieldInfo = ticket[key]
                                      ?.split(' ' || 'T')[1]
                                      ?.slice(0, 5));
                              } else if (item.key === 'publicationDate') {
                                !index
                                  ? (fieldInfo = ticket[key]?.split(
                                      ' ' || 'T'
                                    )[0])
                                  : (fieldInfo = ticket[key]
                                      ?.split(' ' || 'T')[1]
                                      ?.slice(0, 5));
                              } else if (item.key === 'resale') {
                                fieldInfo = ticket[key]
                                  ? t('ticket.withResale')
                                  : t('ticket.withoutResale');
                              } else if (item.key === 'category') {
                                fieldInfo = ticket[key].name;
                              } else {
                                fieldInfo = ticket[key];
                              }
                              break;
                            }
                          }
                          return (
                            <div
                              key={index}
                              className={`${
                                item.key === 'description'
                                  ? 'ticket-detail-field-long'
                                  : ''
                              } modal-ticketer-item-info ticket-detail-field d-flex flex-column`}
                            >
                              <h5>{t(item.text)}</h5>
                              <h4>{fieldInfo ? fieldInfo : '-'}</h4>
                            </div>
                          );
                        })}
                      </div>
                      {item.id === 3 ? (
                        <>
                          <div
                            className={`modal-ticketer-item-info d-flex flex-column`}
                          >
                            <h5>{t('ticket.ticketInfo14')}</h5>
                            <div className="modal-ticketer-item-content-picture d-flex align-items-center">
                              <div
                                className={`${
                                  ticket?.image
                                    ? 'modal-ticketer-item-content-picture-item'
                                    : 'ticket-detail-no-image'
                                } d-flex justify-content-center align-items-center`}
                              >
                                {ticket?.image ? (
                                  ticketId ? (
                                    <img
                                      src={ticket?.image}
                                      alt={item.id}
                                      className=""
                                    />
                                  ) : (
                                    <img
                                      src={ticket?.image}
                                      alt={item.id}
                                      className=""
                                    />
                                  )
                                ) : (
                                  <Star4 />
                                )}
                              </div>
                              <div className="modal-ticketer-item-content-data d-flex flex-column">
                                <h6>{`${'Imagen de portada'}`}</h6>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`modal-ticketer-item-info d-flex flex-column`}
                          >
                            <h5>
                              {Array.isArray(ticket?.extraContent) ||
                              !!ticket?.extraContent
                                ? ticket?.extraContent?.length ||
                                  !!ticket?.extraContent
                                  ? t('ticket.ticketInfo15')
                                  : t('ticket.ticketInfo16')
                                : t('ticket.ticketInfo16')}
                            </h5>
                            {ticketId ? (
                              ticket?.extraContent ? (
                                <>
                                  {Object.entries(ticket?.extraContent)?.map(
                                    (item, index) => {
                                      if (item) {
                                        return (
                                          <ContentField
                                            key={index}
                                            type={item[0]}
                                            text={`${item[1][0].title}.${item[1][0].type}`}
                                            radiusCircle={false}
                                          />
                                        );
                                      } else return null;
                                    }
                                  )}
                                </>
                              ) : null
                            ) : (
                              <>
                                {ticket?.extraContent?.map((item, index) => {
                                  if (item) {
                                    return (
                                      <ContentField
                                        key={index}
                                        type={item.type}
                                        text={item.fileName}
                                        radiusCircle={false}
                                      />
                                    );
                                  } else return null;
                                })}
                              </>
                            )}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              {ticketId ? null : buttonsActive ? (
                <div
                  className={`ticket-detail-buttons d-flex justify-content-end align-items-center`}
                >
                  <ButtonGroupTibet
                    button={{
                      text: mutateIsLoading
                        ? t('ticket.creatingTicket')
                        : t('ticket.createTicket'),
                      disabled:
                        mutateIsLoading || ticket?.isFree === true
                          ? !(
                              ticket?.name &&
                              ticket?.category &&
                              ticket?.description &&
                              ticket?.quantity
                            )
                          : !(
                              ticket?.name &&
                              ticket?.category &&
                              ticket?.description &&
                              ticket?.price &&
                              ticket?.quantity
                            ) || isLoading,
                      function:
                        ticket?.isFree === true
                          ? !(
                              ticket?.name &&
                              ticket?.category &&
                              ticket?.description &&
                              ticket?.quantity
                            ) ||
                            isLoading ||
                            mutateIsLoading
                            ? null
                            : () => mutate()
                          : !(
                              ticket?.name &&
                              ticket?.category &&
                              ticket?.description &&
                              ticket?.price &&
                              ticket?.quantity
                            ) ||
                            isLoading ||
                            mutateIsLoading
                          ? null
                          : () => mutate()
                    }}
                  />
                </div>
              ) : null}
            </div>
          </TicketingSectionComponent>
        </div>
      )}
    </>
  );
}

export default TicketDetail;
