import React from 'react';
import { useTranslation } from 'react-i18next';
import TermsPolicyButton from './TermsPolicyButton.jsx';
import TicketItemButtonsPlusMinus from './TicketItemButtonsPlusMinus.jsx';

export default function TicketItemButtons(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  return props.saleOpen ? (
    <TicketItemButtonsPlusMinus
      id={props.id}
      ticketSaleQuantity={props.ticketSaleQuantity}
      changeQuantity={props.changeQuantity}
    />
  ) : (
    <TermsPolicyButton
      text={
        props.secondaryMarketUser
          ? t('ticket.buyOne')
          : props.text
          ? props.text
          : null
      }
      disabled={props.disabled}
      function={props.buttonFunction}
    />
  );
}
