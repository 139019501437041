import React from "react";

function TicketBanner(props) {

  return (
    <svg preserveAspectRatio="none" viewBox="0 0 268 182" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4468_31857)">
        <rect width="100%" height="100%" fill="url(#paint0_linear_4468_31857)"/>
        <path d="M364.691 -146.476C347.06 -138.268 345.07 -102.752 357.15 -58.3694C339.269 -75.9953 322.274 -84.0915 310.285 -78.5103C292.653 -70.3026 290.664 -34.7866 302.744 9.59585C284.863 -8.0301 267.867 -16.1263 255.878 -10.545C238.247 -2.33734 236.257 33.1787 248.337 77.5612C230.456 59.9352 213.461 51.839 201.472 57.4203C183.84 65.628 181.851 101.144 193.93 145.526C176.05 127.9 159.054 119.804 147.065 125.386C129.433 133.593 127.444 169.109 139.524 213.492C121.643 195.866 104.648 187.77 92.6583 193.351C68.3148 204.683 73.7794 268.035 104.889 334.864C135.999 401.693 180.958 446.659 205.302 435.326C222.933 427.119 224.923 391.603 212.843 347.22C230.724 364.846 247.719 372.942 259.709 367.361C277.34 359.154 279.33 323.637 267.25 279.255C285.13 296.881 302.126 304.977 314.115 299.396C331.747 291.188 333.736 255.672 321.656 211.29C339.537 228.916 356.532 237.012 368.522 231.431C386.153 223.223 388.143 187.707 376.063 143.324C393.944 160.95 410.939 169.047 422.928 163.465C440.56 155.258 442.549 119.742 430.469 75.3591C448.35 92.9851 465.346 101.081 477.335 95.5C501.678 84.1677 496.214 20.8163 465.104 -46.0127C433.994 -112.842 389.059 -157.819 364.691 -146.476Z" fill="#C8BFFF"/>
        <path d="M53.3085 76.0363L42.1733 72.8553C31.9323 69.9276 24.0678 62.0672 21.14 51.8221L17.9592 40.6869C17.4569 38.9351 14.5332 38.9351 14.031 40.6869L10.8501 51.8221C7.92233 62.0672 0.057827 69.9317 -10.1832 72.8553L-21.3185 76.0363C-22.1964 76.2854 -22.8008 77.0898 -22.8008 78.0004C-22.8008 78.9109 -22.1964 79.7112 -21.3185 79.9644L-10.1832 83.1454C0.057827 86.0732 7.92233 93.9336 10.8501 104.179L14.031 115.314C14.2841 116.192 15.0844 116.796 15.995 116.796C16.9056 116.796 17.706 116.192 17.9592 115.314L21.14 104.179C24.0678 93.9336 31.9323 86.0691 42.1733 83.1454L53.3085 79.9644C54.1864 79.7153 54.7908 78.9109 54.7908 78.0004C54.7908 77.0898 54.1864 76.2895 53.3085 76.0363Z" fill="#6B53FF"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_4468_31857" x1="277.085" y1="-8.39483" x2="8.34743" y2="156.157" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBE8FF"/>
          <stop offset="1" stopColor="#432EC9"/>
        </linearGradient>
        <clipPath id="clip0_4468_31857">
          <rect width="100%" height="100%" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TicketBanner;