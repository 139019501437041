import React from "react";

function Pen(props) {

  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9942 11.1225C16.0986 11.2269 16.0986 11.3973 15.9942 11.5017L7.93029 19.5307H3.46875V15.0692L11.4978 7.00542C11.6022 6.90013 11.7718 6.90013 11.8771 7.00542L15.9942 11.1225ZM19.0037 5.97663L17.0229 3.99562C16.3233 3.29605 15.1884 3.29605 14.4888 3.99562L12.8129 5.67142C12.7085 5.77582 12.7085 5.94532 12.8129 6.04972L16.9497 10.1864C17.0541 10.2908 17.2237 10.2908 17.3281 10.1864L19.0037 8.51075C19.7033 7.81118 19.7033 6.6762 19.0037 5.97663Z" fill="#442ECB"/>
    </svg>
  );
}

export default Pen;