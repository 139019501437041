import React from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import TermsPolicyButton from "../ui/TermsPolicyButton.jsx";
import PaymentNokImage from "../icons/PaymentNokImage.jsx";

function SizeExceeded(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <div className="crop-modal-error d-flex flex-column justify-content-center align-items-center">
        <PaymentNokImage />
        <h3>{props.messageTitle ?? t("profile.sizeTitle")}</h3>
        <p>{props.messageText ?? t("profile.sizeText")}</p>
      </div>
      <div className={`crop-modal-error d-flex flex-column flex-sm-row`}>
        <TermsPolicyButton
          text={t("profile.sizeButton")}
          disabled={false}
          function={props.function}
        />
      </div>
    </>
  );
}

export default SizeExceeded;
