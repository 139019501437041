import React from "react";

function Globe(props) {

  return (
    <svg width="108" height="94" viewBox="0 0 108 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.3972 69.875C32.3722 89.895 39.1322 110.305 50.8972 128.44C23.3372 122.33 2.34227 98.735 0.197266 69.875H31.3972ZM50.8972 1.55994C23.3372 7.66994 2.34227 31.265 0.197266 60.125H31.3972C32.3722 40.105 39.1322 19.6949 50.8972 1.55994ZM66.3023 0H63.7022L61.7523 2.79495C49.4023 20.3449 42.1872 40.495 41.1472 60.125H88.8573C87.8173 40.495 80.6023 20.3449 68.2523 2.79495L66.3023 0ZM41.1472 69.875C42.1872 89.505 49.4023 109.655 61.7523 127.205L63.7022 130H66.3023L68.2523 127.205C80.6023 109.655 87.8173 89.505 88.8573 69.875H41.1472ZM98.6073 69.875C97.6323 89.895 90.8723 110.305 79.1073 128.44C106.667 122.33 127.662 98.735 129.807 69.875H98.6073ZM129.807 60.125C127.662 31.265 106.667 7.66994 79.1073 1.55994C90.8723 19.6949 97.6323 40.105 98.6073 60.125H129.807Z" fill="#E3DEFC"/>
    </svg>
  );
}

export default Globe;