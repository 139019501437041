import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate } from 'react-router-dom';
import {
  getCurrentTicketsByUser,
  getSentTicketsByUser,
  getOnSaleTicketsByUser,
  getCollectedTicketsByUser
} from '../../services/axiosService.js';
import routes from '../../routes/routes.js';
import DashboardBar from '../shared/DashboardBar';
import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '../shared/functions.js';
import MyTicketsList from '../shared/MyTicketsList.jsx';
import TermsPolicyButton from '../ui/TermsPolicyButton.jsx';
import GiftSvg3 from '../icons/GiftSvg3.jsx';
import SuccessFailPopupLarge from '../ui/SuccessFailPopupLarge.jsx';

function MyTicketsComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    elvis,
    publicElvisId
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  const subdomain = useMemo(() => getSubdomain(), []);

  const [recoveryStatus, setRecoveryStatus] = useState(false);
  const [popup, setPopup] = useState(false);

  const {
    isLoading: currentTicketsIsLoading,
    isError: currentTicketsIsError,
    data: currentTickets
  } = useQuery({
    queryKey: ['current-tickets'],
    queryFn: () => getCurrentTicketsByUser(authState.token, publicElvisId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER' &&
      !recoveryStatus,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: onSaleTicketsIsLoading,
    isError: onSaleTicketsIsError,
    error: onSaleTicketsError,
    data: onSaleTickets,
    isFetching: onSaleTicketsIsFetching
  } = useQuery({
    queryKey: ['onsale-tickets'],
    queryFn: () => getOnSaleTicketsByUser(authState.token, publicElvisId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER' &&
      !recoveryStatus,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: sentTicketsIsLoading,
    isError: sentTicketsIsError,
    error: sentTicketsError,
    data: sentTickets,
    isFetching: sentTicketsIsFetching
  } = useQuery({
    queryKey: ['sent-tickets'],
    queryFn: () => getSentTicketsByUser(authState.token, publicElvisId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER' &&
      !recoveryStatus,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: collectedTicketsIsLoading,
    isError: collectedTicketsIsError,
    error: collectedTicketsError,
    data: collectedTickets,
    isFetching: collectedTicketsIsFetching
  } = useQuery({
    queryKey: ['collected-tickets'],
    queryFn: () => getCollectedTicketsByUser(authState.token, publicElvisId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER' &&
      !recoveryStatus,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const navigateToTicketerSections = (id) => {
    var route;
    if (id === 1) {
      route = routes.info;
    }
    if (id === 2) {
      route = routes.payment;
    }
    if (id === 3) {
      route = routes.customize;
    }
    if (id === 4) {
      route = routes.domain;
    }
    if (id === 5) {
      route = routes.terms;
    }
    if (id === 6) {
      route = routes.privacy;
    }
    if (id === 7) {
      route = routes.social;
    }
    if (id === 8) {
      route = routes.infoContact;
    }
    if (id === 9) {
      route = routes.infoBilling;
    }
    navigate(route);
  };

  const sections = [
    { id: 0, title: t('myTickets.section1') },
    { id: 1, title: t('myTickets.section2') },
    { id: 2, title: t('myTickets.section3') },
    { id: 3, title: t('myTickets.section4') }
  ];

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <section
      id="my-tickets-section"
      className="my-tickets-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      <DashboardBar
        text={t('myTickets.title')}
        session={false}
        emoji={null}
        icon={null}
        buttonText={null}
        function={null}
        disabled={true}
        button={elvis}
        ticketer={elvis}
        icon2={null}
        buttonText2={null}
        function2={null}
        disabled2={true}
      />
      <div className="my-tickets-content d-flex flex-column justify-content-center">
        {recoveryStatus ? (
          <div
            className={`purchase-done-main d-flex flex-column justify-content-center align-items-center`}
          >
            <GiftSvg3 />
            <div
              className={`purchase-done-main-top d-flex flex-column justify-content-between align-items-center`}
            >
              <h3>{t('gift.giftTitle4')}</h3>
              <div
                className={`purchase-done-main-top-text d-flex flex-column justify-content-between align-items-center`}
              >
                <p>{t('gift.giftText7')}</p>
              </div>
            </div>
            <TermsPolicyButton
              text={t('gift.giftButton6')}
              disabled={false}
              function={() => setRecoveryStatus(false)}
            />
          </div>
        ) : (
          sections.map((section) => {
            if (section.id === 0) {
              return (
                <MyTicketsList
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  isLoading={currentTicketsIsLoading}
                  isError={currentTicketsIsError}
                  tickets={currentTickets?.data?.tickets}
                />
              );
            } else if (section.id === 1) {
              return (
                <MyTicketsList
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  isLoading={onSaleTicketsIsLoading}
                  isError={onSaleTicketsIsError}
                  tickets={onSaleTickets?.data?.tickets}
                />
              );
            } else if (section.id === 2) {
              return (
                <MyTicketsList
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  isLoading={sentTicketsIsLoading}
                  isError={sentTicketsIsError}
                  tickets={sentTickets?.data?.activity?.map(
                    (activity) => activity.ticket
                  )}
                  recoverable={sentTickets?.data?.activity?.map(
                    (activity) => activity.newUser?.enabled
                  )}
                  setRecoveryStatus={setRecoveryStatus}
                  setPopup={setPopup}
                />
              );
            } else if (section.id === 3) {
              return (
                <MyTicketsList
                  key={section.id}
                  id={section.id}
                  title={section.title}
                  isLoading={collectedTicketsIsLoading}
                  isError={collectedTicketsIsError}
                  tickets={collectedTickets?.data?.tickets}
                />
              );
            }
            return null;
          })
        )}
      </div>
    </section>
  );
}

export default MyTicketsComponent;
