import axios from 'axios';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Buffer } from 'buffer';
const { DateTime } = require('luxon');
var FormData = require('form-data');
const qs = require('qs');

const DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN;
const AK = process.env.REACT_APP_API_KEY;

export const setClientParameters = (
  userToken,
  webhook,
  dsn,
  centralAddress
) => {
  let data = qs.stringify({
    webhook: webhook,
    dsn: dsn,
    centralAddress: centralAddress
  });

  var config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/config`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const loginAdmin = (userId) => {
  let data = qs.stringify({
    userId: userId
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/admin/login`,
    headers: data.getHeaders
      ? data.getHeaders()
      : { 'Api-Key': AK, 'Content-Type': 'application/x-www-form-urlencoded' },
    data: data
  };

  return axios(config);
};

export const loginFirebaseAdmin = (token, displayName) => {
  let data = qs.stringify({
    idToken: token,
    displayName: displayName
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/admin/social-login`,
    headers: data.getHeaders
      ? data.getHeaders()
      : { 'Api-Key': AK, 'Content-Type': 'application/x-www-form-urlencoded' },
    data: data
  };

  return axios(config);
};

export const loginUser = (userId, companyId, apiKey) => {
  let data = qs.stringify({
    userId: userId,
    companyId: companyId
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/users/login`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': apiKey ? apiKey : AK,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    data: data
  };

  return axios(config);
};

export const loginFirebaseUser = (token, companyId, displayName, apiKey) => {
  let data = qs.stringify({
    idToken: token,
    companyId: companyId,
    displayName: displayName
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/users/social-login`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': apiKey ? apiKey : AK,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
    data: data
  };

  return axios(config);
};

export const createUser = (userToken, userId, enabled) => {
  let data = qs.stringify({
    userId: userId,
    enabled: enabled
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/users`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const getUsers = (userToken, limit, offset, name, userId, order) => {
  const allParams = {
    limit: limit,
    offset: offset,
    name: name,
    userId: userId,
    order: order
  };

  const filteredParams = Object.fromEntries(
    ['limit', 'offset', 'name', 'userId', 'order']
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/users`,
    params: filteredParams,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getUserById = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/users`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const acceptTerms = (userToken) => {
  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/users/terms/accept`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getCodeForUser = (userToken, userId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/users/${userId}/recover`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const patchUser = (userToken, name, recoveryEmail, phone, picture) => {
  var data = new FormData();
  data.append('name', name);
  data.append('recovery_email', recoveryEmail);
  data.append('phone', phone);
  data.append('picture', picture);

  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/users`,
    data: data,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        }
  };

  return axios(config);
};

export const patchUserById = (
  userToken,
  userId,
  name,
  recoveryEmail,
  phone,
  picture,
  enabled
) => {
  var data = new FormData();
  data.append('userId', userId);
  if (name) data.append('name', name);
  if (recoveryEmail) data.append('recovery_email', recoveryEmail);
  if (phone) data.append('phone', phone);
  if (picture) data.append('picture', picture);
  if (enabled) data.append('enabled', enabled);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/users`,
    data: data,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        }
  };

  return axios(config);
};

export const deletePictureUser = (userToken) => {
  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/user/picture/delete`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios(config);
};

export const deleteUser = (userToken, userId) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/users/${userId}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const createCategory = (userToken, name, category, enabled) => {
  let data = qs.stringify({
    name: name,
    category: category,
    enabled: enabled
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/categories`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const getCategories = (userToken, limit, offset, name, category) => {
  const allParams = {
    limit: limit,
    offset: offset,
    name: name,
    category: category
  };

  const filteredParams = Object.fromEntries(
    ['limit', 'offset', 'name', 'category']
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/categories`,
    params: filteredParams,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getCategoryById = (userToken, id) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/categories/${id}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const patchCategoryById = (userToken, id, name, category, enabled) => {
  let data = qs.stringify({
    name: name,
    category: category,
    enabled: enabled
  });

  var config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/categories/${id}`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const deleteCategory = (userToken, id) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/categories/${id}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const createEvent = (
  userToken,
  accessType,
  region,
  name,
  location,
  latitude,
  longitude,
  images,
  attributes,
  dateEvent,
  dateDoors,
  dateActivation,
  description,
  enabled,
  category
) => {
  var data = new FormData();
  /*data.append('accessType', accessType);
  data.append('region', region);
  data.append('name', name);
  data.append('location', location);
  data.append('latitude', latitude);
  data.append('longitude', longitude);
  data.append('images', images);
  data.append('attributes', attributes);
  data.append('dateEvent', dateEvent);
  data.append('dateDoors', dateDoors);
  data.append('dateActivation', dateActivation);
  data.append('description', description);
  data.append('enabled', enabled);
  data.append('category', category);*/

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/events`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const getRegions = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/regions`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getEvents = ({
  userToken = null,
  status = null,
  limit = null,
  offset = null,
  name = null,
  category = null,
  order = null,
  orderBy = null,
  apiKey = null,
  dateNow = null,
  dateNext = null,
  datePast = null
}) => {
  const allParams = {
    status: status,
    limit: limit,
    offset: offset,
    name: name,
    category: category,
    order: order,
    orderBy: orderBy,
    dateNow,
    dateNext,
    datePast
  };

  const filteredParams = Object.fromEntries(
    [
      'status',
      'limit',
      'offset',
      'name',
      'category',
      'order',
      'orderBy',
      'dateNow',
      'dateNext',
      'datePast'
    ]
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/events`,
    params: filteredParams,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getEventById = (userToken, id, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/events/${id}`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};
export const getNextEventsByUser = (userToken, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/user-next-events`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};
export const patchEvent = (userToken, id, values, cover, thumb) => {
  var data = new FormData();
  if (values) {
    if (values.name) data.append('name', values.name);
    if (values.description) data.append('description', values.description);
    if (values.location) data.append('location', values.location);
    if (values.address) data.append('address', values.address);
    if (values.address2) data.append('address2', values.address2);
    if (values.country) data.append('country', values.country);
    if (values.city) data.append('city', values.city);
    if (values.state) data.append('state', values.state);
    if (values.postalCode) data.append('postal_code', values.postalCode);
    if (values.startDate)
      data.append(
        'dateEvent',
        `${values.startDate.c.year}-${
          values.startDate.c.month < 10 ? '0' : ''
        }${values.startDate.c.month}-${values.startDate.c.day < 10 ? '0' : ''}${
          values.startDate.c.day
        } ${values.startDate.c.hour < 10 ? '0' : ''}${
          values.startDate.c.hour
        }:${values.startDate.c.minute < 10 ? '0' : ''}${
          values.startDate.c.minute
        }:00`
      );
    if (values.openingDate)
      data.append(
        'dateDoors',
        `${values.openingDate.c.year}-${
          values.openingDate.c.month < 10 ? '0' : ''
        }${values.openingDate.c.month}-${
          values.openingDate.c.day < 10 ? '0' : ''
        }${values.openingDate.c.day} ${
          values.openingDate.c.hour < 10 ? '0' : ''
        }${values.openingDate.c.hour}:${
          values.openingDate.c.minute < 10 ? '0' : ''
        }${values.openingDate.c.minute}:00`
      );
    if (values.finishDate)
      data.append(
        'dateEnd',
        `${values.finishDate.c.year}-${
          values.finishDate.c.month < 10 ? '0' : ''
        }${values.finishDate.c.month}-${
          values.finishDate.c.day < 10 ? '0' : ''
        }${values.finishDate.c.day} ${
          values.finishDate.c.hour < 10 ? '0' : ''
        }${values.finishDate.c.hour}:${
          values.finishDate.c.minute < 10 ? '0' : ''
        }${values.finishDate.c.minute}:00`
      );
    if (values.timezone) data.append('timezone', values.timezone);
    if (typeof values.fb === 'string') data.append('facebook', values.fb);
    if (typeof values.ig === 'string') data.append('instagram', values.ig);
    if (typeof values.tw === 'string') data.append('twitter', values.tw);
    if (typeof values.tt === 'string') data.append('tiktok', values.tt);
    if (values.status) data.append('status', values.status);
  }
  if (cover) data.append('cover', cover);
  if (thumb) data.append('thumb', thumb);

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/events/${id}`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const deletePicturesEvent = (userToken, id, cover, thumb) => {
  var data;
  if (thumb) {
    data = qs.stringify({
      thumb: thumb
    });
  } else if (cover) {
    data = qs.stringify({
      cover: cover
    });
  }

  var config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/events/${id}/images`,
    headers: {
      'Api-Key': AK,
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${userToken}`
    },
    data: data
  };

  return axios(config);
};

export const deleteEvent = (userToken, id) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/events/${id}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const buyTicket = (
  userToken,
  id,
  user,
  token,
  ticket,
  price,
  buyersBalance
) => {
  let data = qs.stringify({
    user: user,
    token: token,
    ticket: ticket,
    price: price,
    buyersBalance: buyersBalance
  });

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/events/${id}/buy`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const createTicketingCompany = (
  userToken,
  accessType,
  region,
  name,
  location,
  latitude,
  longitude,
  images,
  attributes,
  dateTicketingCompany,
  dateDoors,
  dateActivation,
  description,
  enabled,
  category,
  fb,
  ig,
  tw,
  tt
) => {
  var data = new FormData();
  data.append('accessType', accessType);
  data.append('region', region);
  data.append('name', name);
  data.append('location', location);
  data.append('latitude', latitude);
  data.append('longitude', longitude);
  data.append('images', images);
  data.append('attributes', attributes);
  data.append('dateTicketingCompany', dateTicketingCompany);
  data.append('dateDoors', dateDoors);
  data.append('dateActivation', dateActivation);
  data.append('description', description);
  data.append('enabled', enabled);
  data.append('category', category);
  data.append('facebook', fb);
  data.append('instagram', ig);
  data.append('twitter', tw);
  data.append('tiktok', tt);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/ticketing-companies`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const getTicketingCompanyById = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/ticketing-companies`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getCurrencies = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/currencies/all`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const patchTicketingCompany = (
  userToken,
  id,
  values,
  background,
  logo
) => {
  var data = new FormData();
  if (values) {
    if (values.name) data.append('comercialName', values.name);
    if (values.description) data.append('description', values.description);
    if (values.email) data.append('email', values.email);
    if (typeof values.altEmail === 'string')
      data.append('email2', values.altEmail);
    if (values.phone) data.append('phone', values.phone);
    if (values.social) data.append('companyName', values.social);
    if (values.address) data.append('address', values.address);
    if (values.cif) data.append('cif', values.cif);
    if (values.currency) data.append('currency', values.currency);
    if (values.domain) data.append('ourSubdomain', values.domain);
    if (values.terms) data.append('terms', values.terms);
    if (values.policy) data.append('privacyPolicy', values.policy);
    if (typeof values.fb === 'string') data.append('facebook', values.fb);
    if (typeof values.ig === 'string') data.append('instagram', values.ig);
    if (typeof values.tw === 'string') data.append('twitter', values.tw);
    if (typeof values.tt === 'string') data.append('tiktok', values.tt);
    if (values.isPublished) data.append('isPublished', values.isPublished);
  }
  if (logo) data.append('logo', logo);
  if (background) data.append('background', background);

  var config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/ticketing-companies/${id}`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const deletePicturesTicketingCompany = (
  userToken,
  id,
  background,
  logo
) => {
  var data;
  if (logo) {
    data = qs.stringify({
      logo: logo
    });
  } else if (background) {
    data = qs.stringify({
      background: background
    });
  }

  var config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/ticketing-companies/${id}/images`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const deleteTicketingCompany = (userToken, id) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/ticketing-companies/${id}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getGeneralStats = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/panel/general/statistics`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getEventStats = (userToken, eventId, categoryId) => {
  const allParams = { category: categoryId };

  const filteredParams = Object.fromEntries(
    ['category']
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/panel/event/statistics/${eventId}`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK },
    params: filteredParams
  };

  return axios(config);
};

export const createTicket = (userToken, eventId, ticket) => {
  /*function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
  }*/

  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
  }

  var data = new FormData();
  if (eventId) data.append('eventId', eventId);
  if (ticket.name) data.append('name', ticket.name);
  if (ticket.category?.name) data.append('categoryName', ticket.category?.name);
  if (ticket.description) data.append('description', ticket.description);
  if (ticket.price) data.append('price', ticket.price);
  if (ticket.quantity) data.append('quantity', ticket.quantity);
  if (ticket.resale) data.append('resale', ticket.resale);
  if (ticket.resalePercentage)
    data.append('commission', ticket.resalePercentage);
  if (ticket.date) data.append('publicationDate', ticket.date);
  if (ticket.sector) data.append('sector', ticket.sector);
  if (ticket.tribune) data.append('tribune', ticket.tribune);
  if (ticket.row) data.append('row', ticket.row);
  if (ticket.floor) data.append('floor', ticket.floor);
  if (ticket.gate) data.append('gate', ticket.gate);
  if (ticket.image)
    data.append('image', dataURLtoFile(ticket.image, ticket.imageName));
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContent[0])
      data.append(
        'extraContent1',
        dataURLtoFile(
          ticket.extraContent[0]?.file,
          ticket.extraContent[0]?.fileName
        )
      );
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContentTitle[0])
      data.append('extraContent1Title', ticket.extraContentTitle[0]);
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContent[1])
      data.append(
        'extraContent2',
        dataURLtoFile(
          ticket.extraContent[1]?.file,
          ticket.extraContent[1]?.fileName
        )
      );
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContentTitle[1])
      data.append('extraContent2Title', ticket.extraContentTitle[1]);
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContent[2])
      data.append(
        'extraContent3',
        dataURLtoFile(
          ticket.extraContent[2]?.file,
          ticket.extraContent[2]?.fileName
        )
      );
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContentTitle[2])
      data.append('extraContent3Title', ticket.extraContentTitle[2]);
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContent[3])
      data.append(
        'extraContent4',
        dataURLtoFile(
          ticket.extraContent[3]?.file,
          ticket.extraContent[3]?.fileName
        )
      );
  if (Array.isArray(ticket.extraContent))
    if (ticket.extraContentTitle[3])
      data.append('extraContent4Title', ticket.extraContentTitle[3]);

  /*for (var pair of data.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }*/

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/tickets-model`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const getTicketsTypeByEvent = (
  userToken,
  eventId,
  status,
  limit,
  offset,
  name,
  category,
  order,
  orderBy,
  apiKey
) => {
  const allParams = {
    eventId: eventId,
    status: status,
    limit: limit,
    offset: offset,
    name: name,
    category: category,
    order: order,
    orderBy: orderBy
  };

  const filteredParams = Object.fromEntries(
    [
      'eventId',
      'status',
      'limit',
      'offset',
      'name',
      'category',
      'order',
      'orderBy'
    ]
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets-model`,
    params: filteredParams,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getTicketTypeById = (userToken, ticketId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets-model/${ticketId}`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const getTicketsByEvent = (
  userToken,
  eventId,
  status,
  limit,
  offset,
  name,
  category,
  order,
  orderBy,
  onSaleByUser,
  apiKey
) => {
  const allParams = {
    event: eventId,
    status: status,
    limit: limit,
    offset: offset,
    name: name,
    category: category,
    order: order,
    orderBy: orderBy,
    onSaleByUser: onSaleByUser
  };

  const filteredParams = Object.fromEntries(
    [
      'event',
      'status',
      'limit',
      'offset',
      'name',
      'category',
      'order',
      'orderBy',
      'onSaleByUser'
    ]
      .filter((key) => key in allParams) // line can be removed to make it inclusive
      .map((key) => [key, allParams[key]])
  );

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets`,
    params: filteredParams,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getTicketById = (userToken, apiKey, ticketId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/${ticketId}`,
    headers: {
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const recoverTicketById = (userToken, apiKey, ticketId) => {
  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/tickets/${ticketId}/recover`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getFile = (url) => {
  var config = {
    method: 'get',
    url: `${url}`
  };

  return axios(config);
};

export const getTicketActivityById = (userToken, apiKey, ticketId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/activity?ticket=${ticketId}`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const sendTicketGift = (
  userToken,
  apiKey,
  ticketId,
  user,
  companyId
) => {
  let data = qs.stringify({
    ticketId: ticketId,
    companyId: companyId,
    userTo: user
  });

  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/tickets/transfer`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    },
    data: data
  };

  return axios(config);
};

export const putOnSaleTicket = (userToken, apiKey, ticketId, price) => {
  let data = qs.stringify({
    ticketId: ticketId,
    price: price
  });

  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/sales`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    },
    data: data
  };

  return axios(config);
};

export const withdrawOnSaleTicket = (userToken, apiKey, ticketId, price) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/sales/${ticketId}`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getCurrentTicketsByUser = (userToken, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/current`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getSentTicketsByUser = (userToken, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/sent`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getOnSaleTicketsByUser = (userToken, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/on-sale`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getCollectedTicketsByUser = (userToken, apiKey) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/tickets/collected`,
    headers: {
      Authorization: userToken ? `Bearer ${userToken}` : null,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const activateAllTicketsByEventId = (userToken, eventId) => {
  let data = qs.stringify({
    event: eventId
  });

  var config = {
    method: 'patch',
    url: `${DOMAIN}/es/v1/events/tickets/mass-activation`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const createDiscountByEventId = (userToken, eventId, values) => {
  var events = [];
  if (eventId) events.push(eventId);
  var categories = [];
  if (values.categoryName) categories.push(values.categoryName);

  var data = new FormData();
  data.append('events', JSON.stringify(events));
  data.append('categories', JSON.stringify(categories));
  data.append('code', values.code);
  data.append('quantity', values.quantity);
  data.append('amount', values.percentage);
  data.append('type', 1);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/promo-codes`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          Authorization: `Bearer ${userToken}`,
          'Api-Key': AK,
          'Content-Type': 'multipart/form-data'
        },
    data: data
  };

  return axios(config);
};

export const getDiscountByEventId = (userToken, eventId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/events/${eventId}/promo-codes`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK,
      'Content-Type': 'multipart/form-data'
    }
  };

  return axios(config);
};

export const patchDiscountById = (userToken, discountId, enabled) => {
  let data = qs.stringify({
    enabled: enabled
  });

  var config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/promo-codes/${discountId}`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const checkDiscountByCode = (userToken, apiKey, code, ticketModels) => {
  let data = qs.stringify({
    code: code,
    tickets: JSON.stringify(ticketModels)
  });

  var config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${DOMAIN}/es/v1/promo-codes/code`,
    headers: data.getHeaders
      ? data.getHeaders()
      : {
          'Api-Key': apiKey ? apiKey : AK,
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${userToken}`
        },
    data: data
  };

  return axios(config);
};

export const getCreateAccountStripeLink = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/stripe/sub-account-link`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const createAccountStripeCallback = (userToken, scope, code) => {
  var data = new FormData();
  data.append('scope', scope);
  data.append('code', code);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/stripe/oauth-callback`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK,
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };

  return axios(config);
};
export const getStripeInfo = (
  userToken,
  apiKey,
  companyId,
  dataArray,
  codeId,
  totalPrice,
  formValues
) => {
  var data = new FormData();
  data.append('companyId', companyId);
  data.append('tickets', JSON.stringify(dataArray));
  data.append('address', formValues.address);
  data.append('city', formValues.city);
  data.append('country', formValues.country);
  data.append('id', formValues.id);
  data.append('email', formValues.email);
  data.append('name', formValues.name);
  data.append('phone', formValues.phone);
  data.append('province', formValues.province);
  data.append('surname', formValues.surname);
  data.append('zip', formValues.zip);
  data.append('number', formValues.number);
  if (codeId) data.append('code', codeId);
  data.append('total', totalPrice);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/stripe/purchase`,
    data: data,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getStripeResale = (
  userToken,
  apiKey,
  companyId,
  ticketId,
  formValues
) => {
  var data = new FormData();
  data.append('companyId', companyId);
  data.append('ticket', ticketId);

  data.append('address', formValues?.address);
  data.append('city', formValues?.city);
  data.append('country', formValues?.country);
  data.append('id', formValues?.id);
  data.append('email', formValues?.email);
  data.append('name', formValues?.name);
  data.append('phone', formValues?.phone);
  data.append('province', formValues?.province);
  data.append('surname', formValues?.surname);
  data.append('zip', formValues?.zip);
  data.append('number', formValues?.number);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/stripe/sale`,
    data: data,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': apiKey ? apiKey : AK
    }
  };

  return axios(config);
};

export const getSubscriptionsType = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/subscriptions-type`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const chooseSubscriptionMode = (userToken, subscriptionTypeId) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/stripe/${subscriptionTypeId}/subscription`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getPreferenceIdMercagopago = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/mp/13/purchase?quantity=1`,
    headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': AK }
  };

  return axios(config);
};

export const getPublicElvisKey = (subdomain) => {
  if (subdomain === 'localhost:3001') subdomain = 'isra';

  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/ticketing-companies/${subdomain}?timestamp=${Date.now()}`,
    headers: { 'Api-Key': AK }
  };

  return axios(config);
};

export const sendLandingMail = (form) => {
  var data = new FormData();
  data.append('name', form.name);
  data.append('surname', form.surname);
  data.append('phone', form.phone);
  data.append('email', form.email);
  data.append('message', form.message);

  var config = {
    method: 'post',
    url: `${DOMAIN}/es/v1/mail/contact`,
    data: data,
    headers: data.getHeaders
      ? data.getHeaders()
      : { 'Api-Key': AK, 'Content-Type': 'application/x-www-form-urlencoded' }
  };

  return axios(config);
};

export const getSubscription = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/subscriptions-type`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const stripeCheckoutSubscription = (id, userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/stripe/${id}/subscription`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const stripeDeleteSubscription = (userToken) => {
  var config = {
    method: 'delete',
    url: `${DOMAIN}/es/v1/stripe/subscription/remove`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const subscriptionWithTicketCompanyId = (id, userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/subscriptions/${id}`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const subscriptionsStates = (userToken) => {
  var config = {
    method: 'get',
    url: `${DOMAIN}/es/v1/subscriptions`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

/* LLEGA UN LINK CON MESSAGE Y REDIRECCIONO AL LINK */
export const stripeConnectAccount = (userToken) => {
  var config = {
    method: 'POST',
    url: `${DOMAIN}/es/v1/stripe/connect-account`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

/* ELIMINAR CUENTA CONECTADA */
export const stripeDeleteConnectAccount = (userToken, account) => {
  var config = {
    method: 'DELETE',
    url: `${DOMAIN}/es/v1/stripe/account/${account}`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

/* VERIFICA CUENTA CONECTADA */
export const stripeAccountVerify = (userToken) => {
  var config = {
    method: 'GET',
    url: `${DOMAIN}/es/v1/stripe/account/verify`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

/* faqs */
export const faqs = (userToken) => {
  var config = {
    method: 'GET',
    url: `${DOMAIN}/es/v1/faqs`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const faqsId = (userToken, id) => {
  var config = {
    method: 'GET',
    url: `${DOMAIN}/es/v1/faqs/${id}`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const reportsTickets = (userToken, id) => {
  var config = {
    method: 'GET',
    url: `${DOMAIN}/es/v1/report/tickets`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};

export const qrLogin = (userToken) => {
  var config = {
    method: 'GET',
    url: `${DOMAIN}/en/v2/reader/login`,
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Api-Key': AK
    }
  };

  return axios(config);
};
