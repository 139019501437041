import React from "react";
import Navbar from "../../components/shared/Navbar";
import TibetLandingComponent1 from "../../components/landing/TibetLandingComponent1";
import TibetLandingComponent2 from "../../components/landing/TibetLandingComponent2";
import TibetLandingComponent3 from "../../components/landing/TibetLandingComponent3";
import TibetLandingComponent4 from "../../components/landing/TibetLandingComponent4";
import TibetLandingComponent5 from "../../components/landing/TibetLandingComponent5";
import TibetLandingComponent6 from "../../components/landing/TibetLandingComponent6";
import Footer from "../../components/shared/Footer";

export default function LandingPage() {
  return (
    <>
      <Navbar />
      <main className="landing-main d-flex flex-column justify-content-center align-items-center">
        <TibetLandingComponent1 />
        <TibetLandingComponent2 />
        <TibetLandingComponent3 />
        <TibetLandingComponent4 />
        <TibetLandingComponent5 />
        <TibetLandingComponent6 landing={true} />
        <Footer landing={true} />
      </main>
    </>
  );
}
