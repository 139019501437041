import React from "react";
import { useTranslation } from "react-i18next";
import EmptyEvents from "../icons/EmptyEvents";

function EmptyEventsInfo(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className="home-section-events-box-empty-icon d-flex flex-column justify-content-center align-items-center">
      { props.icon }
      <div className={`home-section-events-box-empty-text ${props.landing ? "mb-0" : ""} d-flex flex-column justify-content-center align-items-center`}>
        <h3>{ props.text1 }</h3>
        <h4>{ props.text2 }</h4>
        <p>{ props.text3 }<span>{ props.span }</span></p>
      </div>
    </div>
  );
}

export default EmptyEventsInfo;