import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import NavbarButton from "../ui/NavbarButton.jsx";
import LogoNew from "../icons/LogoNew";

export default function Navbar() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  const buttonToRegister = () => {
    navigate(routes.auth);
  };

  return (
    <header
      id="navbar-section"
      className="navbar-container d-flex flex-row justify-content-between align-items-center"
    >
      <div className="navbar-container-left d-flex flex-row align-items-center">
        <LogoNew />
      </div>
      <NavbarButton text={t("navbar.sign")} function={buttonToRegister} />
    </header>
  );
}
