import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import TermsPolicyButton from "../ui/TermsPolicyButton.jsx";
import routes from "../../routes/routes.js";
import Times from "../icons/Times.jsx";
import ContactForm from "../shared/ContactForm.jsx";

function ModalLanding() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { landingModal, setLandingModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  document.addEventListener("keyup", e => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setLandingModal(false);
    }
  });

  document.addEventListener("click", e => {
    if (e.target === document.querySelector(".modal-container-2")) {
      setLandingModal(false);
    }   
  });

  const backToFaq = () => {
    setLandingModal(false);
    navigate(routes.faq);
  }

  return (
    <div id="modal-landing-section" className={ landingModal ? "modal-background" : "d-none" }>
      <div className="modal-container-2 justify-content-center align-items-center">
        { landingModal === 1 ?
          <div className="modal-landing-container d-flex flex-column justify-content-center align-items-center">
            <Times function = { () => setLandingModal(false) }/>
            <div className="modal-landing-top d-flex flex-column justify-content-center align-items-center">
              <h2>{t("landing.thanks")}</h2>
              <p>{t("landing.thanksMessage")}</p>
            </div>
            <TermsPolicyButton text = { t("footer.faq") } disable = { false } function = { () => backToFaq() }/>
          </div>
          :
          landingModal === 2 ?
          <div className="modal-landing-pricing-container d-flex flex-column justify-content-center align-items-center">
            <Times function = { () => setLandingModal(false) }/>
            <div className="modal-landing-pricing-top d-flex flex-column justify-content-center align-items-center">
              <h2>{t("landing.customTitle")}</h2>
              <p>{t("landing.customMessage")}</p>
            </div>
            <ContactForm/>
          </div>
          :
          null
        }
      </div>
    </div>
  );
}

export default ModalLanding;