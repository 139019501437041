import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";

function ModalTermsLanding() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { termsLandingModal, setTermsLandingModal } =
    React.useContext(AppContext);

  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setTermsLandingModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".modal-container")) {
      setTermsLandingModal(false);
    }
  });

  var stringToHTML = function (id, str) {
    var dom = document.createElement("div");
    dom.innerHTML = str;
    if (document.getElementById(id).childNodes.length === 0) {
      document.getElementById(id).appendChild(dom);
    }
  };

  useEffect(() => {
    stringToHTML("terms-landing", termsLandingModal);
  }, []);

  return (
    <div
      id="modal-landing-terms"
      className={termsLandingModal ? "modal-background" : "d-none"}
    >
      <div className="modal-container justify-content-start justify-content-sm-center align-items-center">
        <div className="modal-ticketer-container modal-landing-terms d-flex flex-column align-items-center">
          <div className="modal-ticketer-container-bottom d-flex flex-column justify-content-between align-items-center">
            <div className="modal-ticketer-item-header d-flex justify-content-between">
              <h4>{t("ticketer.infoTerms1")}</h4>
            </div>
            <div className="modal-ticketer-item-content d-flex flex-column">
              <div id="terms-landing"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTermsLanding;
