import Event1 from "../../assets/event1.png"
import Event2 from "../../assets/event2.png"
import Qr from "../../assets/qrdummy.png"
import PapersText from "../icons/PapersText";
import CreditCardCheck from "../icons/CreditCardCheck";
import CalendarEditBig from "../icons/CalendarEditBig";
import Globe from "../icons/Globe";
import EmptyLogo from "../icons/EmptyLogo";
import EmptyImage from "../icons/EmptyImage";
import HeartPlus from "../icons/HeartPlus";
import GalleryPlus from "../icons/GalleryPlus";
import Calendar from "../icons/Calendar";
import LocationPinSmall from "../icons/LocationPinSmall";
import StopwatchSmall from "../icons/StopwatchSmall";
import EventCard1Svg from "../icons/EventCard1Svg";
import EventCard3Svg from "../icons/EventCard3Svg";
import EventCard2Svg from "../icons/EventCard2Svg";
import EventCard4Svg from "../icons/EventCard4Svg";

export const ticketerData = [
  {
    id: 1,
    icon: <PapersText/>,
    text: 'ticketer.info1',
    altText: 'ticketer.info2',
    button: 'ticketer.info3'
  },
  {
    id: 2,
    icon: <CreditCardCheck/>,
    text: 'ticketer.payment1',
    altText: 'ticketer.payment2',
    button: 'ticketer.payment3'
  },
  {
    id: 3,
    icon: <CalendarEditBig/>,
    text: 'ticketer.customize1',
    altText: 'ticketer.customize2',
    button: 'ticketer.customize3'
  },
  {
    id: 4,
    icon: <Globe/>,
    text: 'ticketer.domain1',
    altText: 'ticketer.domain2',
    button: 'ticketer.domain3'
  },
  {
    id: 5,
    icon: <PapersText/>,
    text: 'ticketer.terms1',
    altText: 'ticketer.terms2',
    button: 'ticketer.terms3'
  },
  {
    id: 6,
    icon: <PapersText/>,
    text: 'ticketer.policy1',
    altText: 'ticketer.policy2',
    button: 'ticketer.policy3'
  }
]

export const createEventData = [
  {
    id: 1,
    icon: <PapersText/>,
    text: 'events.createCardsTitle1',
    altText: 'events.createCardsDescription1',
    button: 'events.createCardsButton1'
  },
  {
    id: 2,
    icon: <CreditCardCheck/>,
    text: 'events.createCardsTitle2',
    altText: 'events.createCardsDescription2',
    button: 'events.createCardsButton2'
  },
  {
    id: 3,
    icon: <CalendarEditBig/>,
    text: 'events.createCardsTitle3',
    altText: 'events.createCardsDescription3',
    button: 'events.createCardsButton3'
  }
]

export const pictureFields = [{ id: 0, icon: <EmptyImage/>, text1: "Fondo de pantalla de inicio", text2: "1920x1080 px.", buttonText: "ticketerSection.pictureButton1", buttonTextAlt: "ticketerSection.pictureButton3", buttonIcon: <GalleryPlus/> }, 
{ id: 1, icon: <EmptyLogo/>, text1: "Logo de pantalla de inicio", text2: "360x360 px.", buttonText: "ticketerSection.pictureButton2", buttonTextAlt: "ticketerSection.pictureButton4", buttonIcon: <HeartPlus/> }
];

export const pictureEventFields = [{ id: 0, icon: <EmptyImage/>, text1: "Banner principal", text2: "1920x1080 px.", buttonText: "ticketerSection.pictureButton1", buttonTextAlt: "ticketerSection.pictureButton3", buttonIcon: <GalleryPlus/> }, 
{ id: 1, icon: <EmptyImage/>, text1: "Imagen miniatura", text2: "360x600 px.", buttonText: "ticketerSection.pictureButton1", buttonTextAlt: "ticketerSection.pictureButton3", buttonIcon: <GalleryPlus/> }
];

export const ticketerFields = [{ id: 1, fields: ['ticketer.infoTitle1', 'ticketer.infoTitle2', 'ticketer.infoTitle3'] }, 
{ id: 2, fields: ['ticketer.infoPayment1']},
{ id: 3, fields: ['ticketer.infoCustomize1', 'ticketer.infoCustomize2']},
{ id: 4, fields: ['ticketer.infoDomain1']},
{ id: 5, fields: ['ticketer.infoTerms1']},
{ id: 6, fields: ['ticketer.infoPolicy1']}
];

export const createEventsFields = [{ id: 1, fields: ['events.info3', 'events.info4', 'events.info5'] }, 
{ id: 2, fields: ['ticketer.tickets']},
{ id: 3, fields: ['ticketer.infoCustomize1', 'ticketer.infoCustomize2']}
];

export const infoFields = [[{ id: 0, text: 'ticketer.infoField1', field: "comercialName" }, { id: 1, text: 'ticketer.infoField2', field: "description" } ], 
[{ id: 2, text: 'ticketer.infoField3', field: "email" }, { id: 3, text: 'ticketer.infoField4', field: "email2" }, { id: 4, text: 'ticketer.infoField5', field: "phone" } ],
[{ id: 5, text: 'ticketer.infoField6', field: "companyName" }, { id: 6, text: 'ticketer.infoField7', field: "address" }, { id: 7, text: 'ticketer.infoField8', field: "cif" }, { id: 8, text: 'ticketer.infoField9', field: "currency" } ], 
];

export const infoCreateEventsFields = [[{ id: 0, text: 'events.infoField0', field: "name" }, { id: 1, text: 'events.infoField1', field: "description" } ], 
[{ id: 2, text: 'events.infoField2', field: "location" }, { id: 3, text: 'events.infoField3', field: "country" }, { id: 4, text: 'events.infoField4', field: "address" },
{ id: 5, text: 'events.infoField5', field: "address2" }, { id: 6, text: 'events.infoField6', field: "city" }, { id: 7, text: 'events.infoField7', field: "state" },
{ id: 8, text: 'events.infoField8', field: "postalCode" } ], [{ id: 9, text: 'events.infoField9', field: "dateEvent" }, { id: 10, text: 'events.infoField10', field: "dateEvent" },
{ id: 11, text: 'events.infoField11', field: "dateEnd" }, { id: 12, text: 'events.infoField12', field: "dateEnd" }, { id: 13, text: 'events.infoField13', field: "dateDoors" }, { id: 14, text: 'events.infoField15', field: "dateDoors" },
{ id: 15, text: 'events.infoField14', field: "timezone" }] 
];

export const eventDetailsFields = [{ id: 0, title: 'events.info6', icon: <Calendar/> },
  { id: 1, title: 'events.info4', icon: <LocationPinSmall/> },
  { id: 2, title: 'events.info7', icon: <StopwatchSmall/> },
  { id: 3, title: 'events.info8', icon: <StopwatchSmall/> }
];

export const eventDetailsCards = [{ id: 0, title: 'events.cardsTitle1', icon: "🥰", text: 'events.cardsText1', svg1: <EventCard1Svg/>, svg2: <EventCard3Svg/> },
  { id: 1, title: 'events.cardsTitle2', icon: "🤑", text: 'events.cardsText2', svg1: <EventCard2Svg/>, svg2: <EventCard4Svg/> }
];

export const ticketDetailsFields = [{ id: 1, title: 'ticket.ticketConfig1', fields: [{ text: 'ticket.ticketInfo1', key: "name" }, { text: 'ticket.ticketInfo2', key: "category" }, { text: 'ticket.ticketInfo3', key: "description" }]},
  { id: 2, title: 'ticket.ticketConfig2', fields: [{ text: 'ticket.ticketInfo4', key: "price" }, { text: 'ticket.ticketInfo5', key: "quantity" }, { text: 'ticket.ticketInfo6', key: "resale" }]},
  { id: 3, title: 'ticket.ticketConfig3', fields: [{ text: 'ticket.ticketInfo7', key: "date" }, { text: 'ticket.ticketInfo11', key: "date" }, { text: 'ticket.ticketInfo10', key: "sector" }]}
];

export const ticketDetailsFieldsTicketId = [{ id: 1, title: 'ticket.ticketConfig1', fields: [{ text: 'ticket.ticketInfo1', key: "name" }, { text: 'ticket.ticketInfo2', key: "category" }, { text: 'ticket.ticketInfo3', key: "description" }]},
  { id: 2, title: 'ticket.ticketConfig2', fields: [{ text: 'ticket.ticketInfo4', key: "price" }, { text: 'ticket.ticketInfo5', key: "amount" }, { text: 'ticket.ticketInfo6', key: "resale" }]},
  { id: 3, title: 'ticket.ticketConfig3', fields: [{ text: 'ticket.ticketInfo7', key: "publicationDate" }, { text: 'ticket.ticketInfo11', key: "publicationDate" }, { text: 'ticket.ticketInfo10', key: "sector" }]}
];