import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ProfileButton from "../ui/ProfileButton.jsx";
import LandingImg1 from '../../assets/landing/landingimg1.png'
import LandingImg2 from '../../assets/landing/landingimg2.png'
import LandingImg3 from '../../assets/landing/landingimg3.png'
import LandingImg4 from '../../assets/landing/landingimg4.png'
import LandingImg5 from '../../assets/landing/landingimg5.png'
import LandingImg6 from '../../assets/landing/landingimg6.png'

export default function TibetLandingComponent1() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  function scrollElement(){
    var element = document.getElementById("video-landing-section");
    element.scrollIntoView();
  }

  useEffect(() => {
    const parallaxEls = document.querySelectorAll("[data-speed]");
    window.addEventListener("scroll", scrollHandler);
    //console.log(parallaxEls);
    function scrollHandler() {
      for (const parallaxEl of parallaxEls) {
        const direction = parallaxEl.dataset.direction == "up" ? "-" : "";
        const transformY = this.pageYOffset * parallaxEl.dataset.speed;
        parallaxEl.style.transform = `translate3d(0,${direction}${transformY}px,0)`;
      }
    }
  }, [])
  

  return (
    <section id="main-landing-section" className="main-landing-container d-flex flex-column justify-content-center align-items-center">
      <img src={ LandingImg1 } alt={`main-landing-1`} className="" data-direction="down" data-speed="0.075"/>
      <img src={ LandingImg2 } alt={`main-landing-1`} className="" data-direction="down" data-speed="0.04"/>
      <img src={ LandingImg3 } alt={`main-landing-1`} className="" data-direction="up" data-speed="0.06"/>
      <img src={ LandingImg4 } alt={`main-landing-1`} className="" data-direction="down" data-speed="0.06"/>
      <img src={ LandingImg6 } alt={`main-landing-1`} className="" data-direction="down" data-speed="0.03"/>
      <img src={ LandingImg5 } alt={`main-landing-1`} className="" data-direction="up" data-speed="0.09"/>
      <div className="main-landing-text d-flex flex-column justify-content-center align-items-center">
        <h1>{t('landing.title1')}<span>{t('landing.title2')}</span>{t('landing.title3')}<span>{t('landing.title4')}</span></h1>
        <p>{t('landing.description')}</p>
      </div>
      <ProfileButton
        icon = { null } 
        text = { t('landing.button') } 
        function = { () => scrollElement() }
        disabled = { false }
        parallaxDirection = { "down" }
        parallaxSpeed = { "0.015" }
      />
    </section>
  )

}
