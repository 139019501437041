import { useContext } from 'react';
import axios from 'axios';
import Download from './icons/Download';
import { AppContext } from '../App';
import { useTranslation } from 'react-i18next';

export const DownloadCSV = () => {
  const { state: authState } = useContext(AppContext);
  const [t] = useTranslation('global');

  const download = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/es/v1/report/tickets`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${authState.token}`,
            'Api-Key': process.env.REACT_APP_API_KEY
          }
        }
      );

      // Create a new Blob object using the response data
      const blob = new Blob([response.data], { type: 'text/csv' });
      // Create a link element
      const link = document.createElement('a');
      // Set the download attribute with a filename
      link.href = window.URL.createObjectURL(blob);
      link.download = 'data.csv';
      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error fetching the CSV file', error);
    }
  };

  return (
    <div className="d-flex gap-2">
      <h4 onClick={() => download()}>{t('events.cardsDownload')}</h4>
      <Download />
    </div>
  );
};
