import React from 'react';
import { useTranslation } from 'react-i18next';

export default function HistoryItem(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  return (
    <div
      className={`ticket-sale-detail-info-history-bottom d-flex justify-content-between align-items-center`}
    >
      <div
        className={`ticket-sale-detail-info-history-bottom-children d-flex flex-column justify-content-between`}
      >
        <h6>
          {t('landingElvis.owner')}#{props.order}
        </h6>
        <h6>{props.date}</h6>
      </div>
      <div
        className={`ticket-sale-detail-info-history-bottom-children d-flex flex-column justify-content-between`}
      >
        {/*<h6>{ props.ownUser === props.email ? <span>{t('secondary.you')}</span> : null }{ props.email }</h6>*/}
        <h6>
          {props.price
            ? props.currency?.id === 2
              ? props.currency?.symbol + Number(props.price).toFixed(2)
              : Number(props.price).toFixed(2) + props.currency?.symbol
            : null}
        </h6>
      </div>
    </div>
  );
}
