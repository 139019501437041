import React from "react";

function HeartPlus(props) {

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2344 20.815C13.3034 20.905 13.2924 21.009 13.2854 21.048C13.2704 21.136 13.2344 21.195 13.1414 21.243C12.8144 21.415 12.6194 21.5 12.6194 21.5C12.6194 21.5 4.95938 18.02 3.78838 11.97C3.06838 8.25 4.63847 4.52002 8.63847 4.50002C11.6185 4.49002 12.6185 7.49002 12.6185 7.49002C12.6185 7.49002 13.6184 4.49002 16.5984 4.50002C20.6084 4.52002 22.1684 8.25 21.4484 11.97C21.3884 12.27 21.3184 12.5601 21.2284 12.8401C21.1674 12.9951 21.0615 13.045 20.9415 13.045C20.8615 13.045 20.7794 13.006 20.7284 12.97C19.8484 12.36 18.7785 12 17.6185 12C14.5885 12 12.1185 14.47 12.1185 17.5C12.1195 18.747 12.5334 19.895 13.2344 20.815ZM21.6194 17.5C21.6194 19.71 19.8294 21.5 17.6194 21.5C15.4094 21.5 13.6194 19.71 13.6194 17.5C13.6194 15.29 15.4094 13.5 17.6194 13.5C19.8294 13.5 21.6194 15.29 21.6194 17.5ZM19.3694 17.5C19.3694 17.22 19.1494 17 18.8694 17H18.1194V16.25C18.1194 15.97 17.8994 15.75 17.6194 15.75C17.3394 15.75 17.1194 15.97 17.1194 16.25V17H16.3694C16.0894 17 15.8694 17.22 15.8694 17.5C15.8694 17.78 16.0894 18 16.3694 18H17.1194V18.75C17.1194 19.03 17.3394 19.25 17.6194 19.25C17.8994 19.25 18.1194 19.03 18.1194 18.75V18H18.8694C19.1494 18 19.3694 17.78 19.3694 17.5Z" fill="#816CFF"/>
    </svg>
  );
}

export default HeartPlus;