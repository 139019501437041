import React from "react";

function LandingContactRings(props) {

  return (
    <svg width="237" height="302" viewBox="0 0 237 302" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M77.2819 170.3C96.9077 226.927 131.408 264.272 154.341 253.71C177.274 243.149 179.955 188.682 160.329 132.054C140.703 75.4267 106.202 38.0826 83.2696 48.6439C60.3368 59.2052 57.656 113.672 77.2819 170.3Z" stroke="#816CFF" strokeWidth="3.36584" strokeMiterlimit="10"/>
      <path d="M92.1504 198.078C125.013 242.082 163.582 256.766 178.298 230.874C193.013 204.982 178.303 148.32 145.441 104.316C112.579 60.3111 74.0091 45.6278 59.2934 71.5196C44.5777 97.4114 59.2883 154.073 92.1504 198.078Z" stroke="#816CFF" strokeWidth="3.36584" strokeMiterlimit="10"/>
      <path d="M118.325 222.319C173.633 222.319 218.47 190.077 218.47 150.304C218.47 110.531 173.633 78.2886 118.325 78.2886C63.0159 78.2886 18.1792 110.531 18.1792 150.304C18.1792 190.077 63.0159 222.319 118.325 222.319Z" stroke="#816CFF" strokeWidth="1.68292" strokeMiterlimit="10"/>
      <path d="M146.793 214.979C196.439 187.765 223.946 136.763 208.232 101.063C192.519 65.3626 139.536 58.4833 89.8906 85.6975C40.2454 112.912 12.7381 163.914 28.4514 199.614C44.1647 235.314 97.1482 242.194 146.793 214.979Z" stroke="#816CFF" strokeWidth="1.68292" strokeMiterlimit="10"/>
    </svg>
  );
}

export default LandingContactRings;