import React from 'react';

export default function EventButton(props) {

  return (
    <button className="event-button d-flex flex-row justify-content-center align-items-center" 
      onClick={ props.function } id={ props.id } ref={ props.reference }
    >
      { props.text }{ props.icon }
    </button>
  )

}