import React from "react";

function ClipboardList(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 5.5V4.5C8 3.5 8.5 3 9.5 3H14.5C15.5 3 16 3.5 16 4.5V5.5C16 6.5 15.5 7 14.5 7H9.5C8.5 7 8 6.5 8 5.5ZM20 8V18C20 20 19 21 17 21H7C5 21 4 20 4 18V8C4 6.258 4.75189 5.27701 6.26489 5.05701C6.38589 5.03901 6.5 5.13899 6.5 5.26099V5.49902C6.5 7.31902 7.68 8.49902 9.5 8.49902H14.5C16.32 8.49902 17.5 7.31902 17.5 5.49902V5.26099C17.5 5.13899 17.6151 5.03901 17.7351 5.05701C19.2481 5.27701 20 6.258 20 8ZM8.75 16C8.75 15.586 8.414 15.25 8 15.25C7.586 15.25 7.25 15.586 7.25 16C7.25 16.414 7.586 16.75 8 16.75C8.414 16.75 8.75 16.414 8.75 16ZM8.75 12C8.75 11.586 8.414 11.25 8 11.25C7.586 11.25 7.25 11.586 7.25 12C7.25 12.414 7.586 12.75 8 12.75C8.414 12.75 8.75 12.414 8.75 12ZM16.75 16C16.75 15.586 16.414 15.25 16 15.25H11C10.586 15.25 10.25 15.586 10.25 16C10.25 16.414 10.586 16.75 11 16.75H16C16.414 16.75 16.75 16.414 16.75 16ZM16.75 12C16.75 11.586 16.414 11.25 16 11.25H11C10.586 11.25 10.25 11.586 10.25 12C10.25 12.414 10.586 12.75 11 12.75H16C16.414 12.75 16.75 12.414 16.75 12Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default ClipboardList;