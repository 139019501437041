import React from "react";

function TicketerBanner(props) {

  return (
    <>
      <svg className="d-block d-sm-none" preserveAspectRatio="none" viewBox="0 0 375 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2616_168348)">
          <rect width="100%" height="100%" fill="url(#paint0_linear_2616_168348)"/>
          <path d="M121.881 112.195C73.4301 39.7026 -24.5626 20.2717 -96.9994 68.6859L78.4095 331.131C150.846 282.717 170.332 184.687 121.881 112.195Z" fill="#816CFF"/>
          <path d="M172.197 -175.123C173.876 -160.926 198.59 -151.418 232.894 -149.717C216.198 -141.249 206.467 -131.223 207.609 -121.569C209.288 -107.372 234.002 -97.8645 268.306 -96.1631C251.61 -87.6957 241.879 -77.6692 243.02 -68.0151C244.699 -53.818 269.413 -44.3107 303.718 -42.6093C287.022 -34.1419 277.291 -24.1155 278.432 -14.4614C280.111 -0.264318 304.825 9.24296 339.13 10.9444C322.434 19.4118 312.702 29.4383 313.844 39.0924C315.523 53.2895 340.237 62.7967 374.542 64.4982C357.846 72.9656 348.114 82.992 349.256 92.6461C351.574 112.248 397.774 122.907 452.463 116.44C507.151 109.973 549.59 88.8308 547.272 69.229C545.593 55.0319 520.879 45.5246 486.575 43.8231C503.271 35.3558 513.002 25.3293 511.861 15.6753C510.182 1.47818 485.468 -8.02909 451.163 -9.73056C467.859 -18.1979 477.59 -28.2245 476.449 -37.8785C474.77 -52.0756 450.056 -61.5828 415.751 -63.2843C432.447 -71.7516 442.179 -81.7782 441.037 -91.4322C439.358 -105.629 414.644 -115.137 380.339 -116.838C397.035 -125.305 406.767 -135.332 405.625 -144.986C403.946 -159.183 379.232 -168.69 344.928 -170.392C361.624 -178.859 371.355 -188.886 370.213 -198.54C367.895 -218.142 321.695 -228.801 267.007 -222.334C212.318 -215.866 169.876 -194.744 172.197 -175.123Z" fill="#DAD5FF"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_2616_168348" x1="387.712" y1="-7.4262" x2="120.942" y2="250.95" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBE8FF"/>
          <stop offset="1" stopColor="#432EC9"/>
        </linearGradient>
        <clipPath id="clip0_2616_168348">
          <rect width="100%" height="100%" fill="white"/>
        </clipPath>
        </defs>
      </svg>
      <svg className="d-none d-sm-block" preserveAspectRatio="none" viewBox="0 0 1440 202" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4402_40072)">
          <rect width="100%" height="100%" fill="url(#paint0_linear_4402_40072)"/>
          <path d="M1314.8 139.762C1215.74 86.7403 1092.55 124.095 1039.57 223.077L1398.19 415.031C1451.17 316.049 1413.86 192.783 1314.8 139.762Z" fill="#B2A6FF"/>
          <path d="M208.817 85.2076C133.771 -27.0755 -18.0102 -57.172 -130.208 17.8168L141.483 424.318C253.681 349.329 283.863 197.491 208.817 85.2076Z" fill="#816CFF"/>
          <path d="M1382.64 -189.633C1363.81 -175.09 1371.01 -132.178 1397.37 -82.4765C1371.29 -98.6903 1348.83 -103.772 1336.03 -93.8831C1317.21 -79.3403 1324.4 -36.4282 1350.77 13.2732C1324.68 -2.94064 1302.23 -8.02241 1289.42 1.86664C1270.6 16.4093 1277.79 59.3217 1304.16 109.023C1278.08 92.8092 1255.62 87.7273 1242.82 97.6164C1223.99 112.159 1231.18 155.071 1257.55 204.773C1231.47 188.559 1209.01 183.477 1196.21 193.366C1177.38 207.909 1184.58 250.821 1210.95 300.523C1184.86 284.309 1162.4 279.227 1149.6 289.116C1123.61 309.195 1147.2 383.311 1202.32 454.669C1257.44 526.026 1323.2 567.566 1349.19 547.487C1368.02 532.945 1360.82 490.032 1334.45 440.331C1360.54 456.545 1382.99 461.627 1395.8 451.738C1414.62 437.195 1407.43 394.283 1381.06 344.581C1407.14 360.795 1429.6 365.877 1442.4 355.988C1461.23 341.445 1454.04 298.533 1427.67 248.832C1453.75 265.046 1476.21 270.127 1489.01 260.238C1507.84 245.695 1500.64 202.783 1474.27 153.082C1500.36 169.296 1522.82 174.378 1535.62 164.488C1554.44 149.946 1547.25 107.033 1520.88 57.3321C1546.96 73.5459 1569.42 78.6277 1582.22 68.7387C1608.22 48.6597 1584.63 -25.4566 1529.51 -96.8139C1474.39 -168.171 1408.66 -209.732 1382.64 -189.633Z" fill="#DAD5FF"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_4402_40072" x1="1488.81" y1="-9.31734" x2="1285.96" y2="592.002" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBE8FF"/>
          <stop offset="1" stopColor="#432EC9"/>
        </linearGradient>
        <clipPath id="clip0_4402_40072">
          <rect width="100%" height="100%" fill="white"/>
        </clipPath>
        </defs>
      </svg>
    </>
  );
}

export default TicketerBanner;