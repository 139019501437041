import React from "react";

function GiftSvg1(props) {

  return (
    <svg width="130" height="117" viewBox="0 0 130 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.1289" y="58.3586" width="69.1382" height="54.2698" rx="8.54935" stroke="#816CFF" strokeWidth="0.743422" strokeDasharray="1.49 1.49"/>
      <path d="M86.7472 85.4949C104.833 85.4949 119.495 70.8333 119.495 52.7474C119.495 34.6615 104.833 20 86.7472 20C68.6613 20 53.9999 34.6615 53.9999 52.7474C53.9999 70.8333 68.6613 85.4949 86.7472 85.4949Z" fill="#E3DEFC"/>
      <path d="M103.97 11.4485C107.132 11.4485 109.695 8.88564 109.695 5.72423C109.695 2.56282 107.132 0 103.97 0C100.809 0 98.2463 2.56282 98.2463 5.72423C98.2463 8.88564 100.809 11.4485 103.97 11.4485Z" fill="#816CFF"/>
      <path d="M21.2043 16.3542V26.0187" stroke="#FF8C5A" strokeWidth="1.09416" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M26.0472 21.1758H16.3827" stroke="#FF8C5A" strokeWidth="1.09416" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M80.0985 32.9164H40.9052C33.2208 32.9164 29.3785 36.7586 29.3785 44.443V79.0264C29.3785 86.7108 33.2208 90.553 40.9052 90.553H46.0927C47.9083 90.553 49.6168 91.4072 50.7027 92.8597L55.8902 99.7764C58.1969 102.851 62.8068 102.851 65.1135 99.7764L70.301 92.8597C71.3904 91.4072 73.0988 90.553 74.911 90.553H80.0985C87.7829 90.553 91.6252 86.7108 91.6252 79.0264V44.443C91.6252 36.7586 87.7829 32.9164 80.0985 32.9164ZM67.4168 68.5788C67.4168 68.6894 67.3995 68.7966 67.3649 68.9038L63.6127 80.3302C63.1632 81.506 62.0564 82.1976 60.8805 82.1976C60.3272 82.1976 59.774 82.0592 59.2898 81.7134C57.7682 80.7105 56.8687 78.9813 56.8687 77.1484V71.2348H49.7102C44.246 71.2348 43.3468 67.5689 44.5227 64.0414L48.1883 53.0785C48.9145 50.8652 49.71 49.3435 53.3758 49.3435H66.3792C66.9532 49.3435 67.4168 49.8068 67.4168 50.3809V68.5788ZM76.9272 64.8713C76.9272 66.3757 75.6994 67.6036 74.195 67.6036H71.9128C71.3387 67.6036 70.8752 67.14 70.8752 66.5659V50.3809C70.8752 49.8068 71.3387 49.3435 71.9128 49.3435H74.195C75.7029 49.3435 76.9272 50.5676 76.9272 52.0755V64.8713Z" fill="#442ECB"/>
      <path d="M5.7334 103H15V112.6C15 112.821 14.8213 113 14.5999 113H9.33333C6.66667 113 5.33333 111.667 5.33333 109V103.4C5.33333 103.179 5.51207 103 5.7334 103ZM26.2666 103H17V112.6C17 112.821 17.1787 113 17.4001 113H22.6667C25.3333 113 26.6667 111.667 26.6667 109V103.4C26.6667 103.179 26.4879 103 26.2666 103ZM28 96.3333V100.6C28 100.821 27.8213 101 27.5999 101H17V96.3333H15V101H4.40007C4.17873 101 4 100.821 4 100.6V96.3333C4 95.2293 4.896 94.3333 6 94.3333H7.41992C6.99459 93.5093 6.83601 92.5266 7.21468 91.4586C7.59201 90.3946 8.45327 89.5133 9.5306 89.1759C11.0213 88.7093 12.5547 89.1972 13.4933 90.3372C13.536 90.3892 15.036 92.3852 16.0013 93.6679C16.9653 92.3852 18.464 90.3932 18.5 90.3479C19.4453 89.1986 20.9653 88.7146 22.452 89.1679C23.492 89.4866 24.3373 90.3106 24.7373 91.3226C25.18 92.4426 25.0253 93.4733 24.5827 94.332H26.0026C27.1039 94.3333 28 95.2293 28 96.3333ZM13.9974 94.3333C13.0947 93.1333 12.004 91.6826 11.9307 91.5879C11.6253 91.2159 11.1707 90.9999 10.6667 90.9999C9.748 90.9999 9 91.7479 9 92.6666C9 93.5853 9.748 94.3333 10.6667 94.3333H13.9974ZM23 92.6666C23 91.7479 22.252 90.9999 21.3333 90.9999C20.8293 90.9999 20.3746 91.2159 20.0519 91.6092C19.9893 91.6919 18.9026 93.1359 18.0026 94.3333H21.3333C22.252 94.3333 23 93.5853 23 92.6666Z" fill="#FF5F00"/>
    </svg>
  );
}

export default GiftSvg1;