import React from "react";

function LandingSmile(props) {

  return (
    <svg width="249" height="249" viewBox="0 0 249 249" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M67.7634 91.4441C65.7913 81.2486 64.0843 72.4421 62.0975 62.1727C70.2843 63.7286 77.6435 64.9013 84.8842 66.6063C89.9084 67.7821 92.6142 66.5222 94.0817 61.3326C96.0384 54.3539 98.6453 47.5517 101.46 39.2713C107.264 44.4514 112.449 48.7456 117.219 53.4541C122.048 58.2216 126.186 58.7778 131.186 53.4505C135.653 48.6855 140.859 44.6289 146.953 39.1819C150.447 49.1242 153.5 57.7664 156.876 67.4132C166.381 65.7011 175.797 63.989 186.293 62.0834C184.481 72.1065 182.774 81.494 180.977 91.5023C190.241 94.7565 198.944 97.8045 207.558 100.823C207.276 102.257 207.335 103.025 206.98 103.469C201.623 110.6 191.848 117.811 192.034 124.742C192.221 131.91 202.073 138.8 208.466 146.787C199.477 150.08 190.518 153.343 180.627 156.948C182.541 166.464 184.425 175.772 186.5 186.189C175.993 184.267 166.861 182.624 156.856 180.789C153.671 189.765 150.545 198.504 146.827 208.929C141.628 204.324 136.769 200.68 132.693 196.281C127.215 190.361 122.56 188.993 116.495 195.741C112.382 200.328 107.059 203.852 101.453 208.545C98.1206 199.238 94.9945 190.552 91.5293 180.875C81.9357 182.617 72.5639 184.314 62.0243 186.235C63.9837 176.093 65.7352 167.016 67.6649 156.963C58.5188 153.709 49.5353 150.528 39.1481 146.832C44.3554 141.061 48.6165 135.676 53.5864 131.058C58.9852 126.041 58.5599 121.947 53.4352 117.239C48.6206 112.811 44.1316 108.029 38.1654 102.065C49.0171 98.1492 57.991 94.9302 67.7634 91.4441Z" fill="#8C79FF"/>
      <path opacity="0.5" d="M110.297 104.079L119.815 113.598L128.264 105.149L118.746 95.6306L110.297 104.079ZM93.3997 120.977L102.918 130.495L111.367 122.046L101.848 112.528L93.3997 120.977ZM99.2274 143.06L105.858 149.691C117.141 150.493 129.44 146.964 139.599 136.804C149.706 126.698 153.77 113.864 153.021 102.528L146.391 95.8971C146.551 106.752 142.487 120.12 132.701 129.906C122.916 139.691 110.029 143.274 99.2274 143.06Z" fill="#8C79FF"/>
    </svg>
  );
}

export default LandingSmile;