import React from "react";

function ArrowsRepeatCircleModal(props) {

  return (
    <svg width="129" height="130" viewBox="0 0 129 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.9964 0.164062C-4.40933 0.164062 -42.8369 38.5916 -42.8369 85.9974C-42.8369 133.403 -4.40933 171.831 42.9964 171.831C90.4022 171.831 128.83 133.403 128.83 85.9974C128.83 38.5916 90.4022 0.164062 42.9964 0.164062ZM-2.06608 73.1224C-2.06608 62.4705 6.60308 53.8099 17.2464 53.8099H66.0767L64.1886 51.9218C61.6737 49.4069 61.6737 45.3295 64.1886 42.8146C66.7035 40.2997 70.7809 40.2997 73.2958 42.8146L86.1708 55.6896C86.7631 56.2818 87.234 56.9934 87.5602 57.783C88.2125 59.3538 88.2125 61.1305 87.5602 62.7013C87.234 63.4909 86.7631 64.2046 86.1708 64.7968L73.2958 77.6718C72.0427 78.925 70.3944 79.5599 68.7464 79.5599C67.0984 79.5599 65.4502 78.9336 64.197 77.6718C61.6821 75.1569 61.6821 71.0795 64.197 68.5646L66.0851 66.6765H17.2464C13.7015 66.6765 10.8089 69.5605 10.8089 73.114V85.989C10.8089 89.5425 7.92492 92.4265 4.37142 92.4265C0.817917 92.4265 -2.06608 89.5425 -2.06608 85.989V73.1224ZM88.0589 98.8724C88.0589 109.524 79.3897 118.185 68.7464 118.185H19.9161L21.8042 120.073C24.3191 122.588 24.3191 126.665 21.8042 129.18C20.5511 130.433 18.9028 131.068 17.2548 131.068C15.6068 131.068 13.9585 130.442 12.7054 129.18L-0.169617 116.305C-0.761868 115.713 -1.2328 115.001 -1.55896 114.212C-2.2113 112.641 -2.2113 110.864 -1.55896 109.294C-1.2328 108.504 -0.761868 107.79 -0.169617 107.198L12.7054 94.323C15.2203 91.8081 19.2977 91.8081 21.8126 94.323C24.3275 96.8379 24.3275 100.915 21.8126 103.43L19.9245 105.318H68.7464C72.2913 105.318 75.1839 102.434 75.1839 98.8808V86.0058C75.1839 82.4523 78.0679 79.5683 81.6214 79.5683C85.1749 79.5683 88.0589 82.4523 88.0589 86.0058V98.8724Z" fill="#EBEBEB"/>
    </svg>
  );
}

export default ArrowsRepeatCircleModal;