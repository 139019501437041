import React from "react";

function AngleLeft(props) {

  return (
    <svg onClick={ props.function ? props.function : null } width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0003 20.0003C14.7443 20.0003 14.4883 19.9023 14.2933 19.7073L7.29325 12.7073C6.90225 12.3163 6.90225 11.6842 7.29325 11.2933L14.2933 4.29325C14.6842 3.90225 15.3163 3.90225 15.7073 4.29325C16.0983 4.68425 16.0983 5.31631 15.7073 5.70731L9.41434 12.0003L15.7073 18.2933C16.0983 18.6842 16.0983 19.3163 15.7073 19.7073C15.5123 19.9023 15.2563 20.0003 15.0003 20.0003Z" fill="#1C1C1C"/>
    </svg>
  );
}

export default AngleLeft;