import React from "react";

function ShieldHeartSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 5C16 4 14.2221 3.111 12.0181 2C9.77807 3.111 8 4 3 5C3 6.137 3 9.70192 3 10.8889C3 17.5559 8.667 20.889 12 22C15.333 20.889 21 17.5559 21 10.8889C21 9.66392 21 6.194 21 5ZM15.925 11.543C15.403 14.212 12 15.75 12 15.75C12 15.75 8.59695 14.213 8.07495 11.543C7.75495 9.90597 8.45396 8.258 10.231 8.25C11.555 8.244 11.999 9.56812 11.999 9.56812C11.999 9.56812 12.4431 8.243 13.7671 8.25C15.5491 8.259 16.245 9.90597 15.925 11.543Z" fill="#816CFF"/>
    </svg>
  );
}

export default ShieldHeartSmall;