import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import { queryClient } from "../../index.js";
import {
  patchTicketingCompany,
  deletePicturesTicketingCompany,
} from "../../services/axiosService.js";
import { PictureUploadComponent } from "../../components/shared/PictureUploadComponent.jsx";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import SparkleSmall from "../../components/icons/SparkleSmall.jsx";
import { pictureFields } from "../../components/dashboard/ticketerData.js";
import PreviewPicturesDashboard from "../../components/shared/PreviewPicturesDashboard.jsx";
import SuccessFailPopupLarge from "../../components/ui/SuccessFailPopupLarge.jsx";

function CustomizeFirstPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, setImageModal } = React.useContext(AppContext);

  var navigate = useNavigate();
  const [popup, setPopup] = useState(null);
  const [isLoadingArray, setIsLoadingArray] = useState([false, false]);

  const navigateRoute = (route) => {
    navigate(route === "back" ? -1 : route);
  };

  const { mutate } = useMutation(
    (pictures) =>
      patchTicketingCompany(
        authState.token,
        props.ticketingCompany?.id,
        null,
        pictures.background,
        pictures.logo
      ),
    {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueryData(["ticketingCompany"], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    0: {
                      ...oldData.data[0],
                      logo: res.data.logo,
                      background: res.data.background,
                    },
                  },
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        console.error(err);
        setPopup({
          state: 0,
          text: `${err.response.data.message} 😖`,
        });
      },
      onSettled: () => {
        setIsLoadingArray([false, false]);
      },
    }
  );

  const { mutate: deletePicture } = useMutation(
    (values) =>
      deletePicturesTicketingCompany(
        authState.token,
        props.ticketingCompany?.id,
        values.background,
        values.logo
      ),
    {
      onSuccess: (res) => {
        if (res) {
          queryClient.setQueryData(["ticketingCompany"], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    0: {
                      ...oldData.data[0],
                      logo: res.data.logo,
                      background: res.data.background,
                    },
                  },
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        console.error(err);
      },
      onSettled: () => {
        setIsLoadingArray([false, false]);
      },
    }
  );

  function onSelectFile(e, id) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 1572864) {
        setImageModal({
          sizeExceeded: true,
          message: t("profile.sizeTextUploadImage"),
          messageTitle: t("profile.sizeTitleUploadImage"),
        });
      } else {
        if (id) {
          setIsLoadingArray([false, true]);
          mutate({ background: null, logo: e.target.files[0] });
        } else {
          setIsLoadingArray([true, false]);
          mutate({ background: e.target.files[0], logo: null });
        }
      }
    }
    e.target.value = "";
  }

  return (
    <>
      <div className="ticketer-customize-picture-container d-flex flex-column">
        {pictureFields.map((item) => {
          return (
            <PictureUploadComponent
              key={item.id}
              id={item.id}
              icon={item.icon}
              text1={item.text1}
              text2={item.text2}
              buttonIcon={item.buttonIcon}
              buttonText={item.buttonText}
              buttonTextAlt={item.buttonTextAlt}
              picture={
                item.id
                  ? props.ticketingCompany?.logo
                  : props.ticketingCompany?.background
              }
              uploadFunction={onSelectFile}
              deleteFunction={deletePicture}
              isLoading={isLoadingArray}
            />
          );
        })}
        <div className="disclaimer-message">
          {t("ticketer.disclaimerPersonalizeTicket")}
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t("ticketer.next"),
          disabled: false,
          function: () => navigateRoute(routes.social),
        }}
      />
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
    </>
  );
}

function CustomizeFirstPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch } = React.useContext(AppContext);

  var navigate = useNavigate();

  const [resetIframe, setResetIframe] = useState(0);

  const { data: ticketingCompany } = useQuery({
    queryKey: ["ticketingCompany"],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setResetIframe(resetIframe + 1);
  }, [ticketingCompany?.data[0].logo, ticketingCompany?.data[0].background]);

  return (
    <div className="w-100 ticketer-customizate d-flex justify-content-between align-items-streech">
      <TicketingSectionComponent
        initialValues={null}
        validationSchema={null}
        ticketer={true}
        title={t("ticketer.customize1")}
        pagination={"1/2"}
        description={t("ticketer.infoCustomize3")}
        footer={{ text: t("ticketer.adviceCustomize"), icon: <SparkleSmall /> }}
        nextPage={true ? true : false}
      >
        <CustomizeFirstPageChildren
          ticketingCompany={ticketingCompany?.data[0]}
        />
      </TicketingSectionComponent>
      <div className="ticketer-customizate-preview d-none d-xl-flex justify-content-center align-items-center">
        <PreviewPicturesDashboard
          ticketingCompany={ticketingCompany}
          url={routes.ticketerPreviewCustomize}
        />
      </div>
    </div>
  );
}

export default CustomizeFirstPage;
