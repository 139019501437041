import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { useQuery } from "@tanstack/react-query";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import GlobeSmall from "../../components/icons/GlobeSmall.jsx";
import TermsPolicyButton from "../../components/ui/TermsPolicyButton.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";

function DomainFirstPage(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setTicketerSectionModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  const navigateRoute = (route) => {
    navigate(route === "back" ? -1 : route);
  }

  const { state: authState, dispatch, ticketerModal, setTicketerModal, role } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const domainItems = [{ id: 0, text1: t('ticketerSection.domainTitle1'), text2: t('ticketerSection.domainText1'), button: t('ticketerSection.domainButton1')},
    { id: 1, text1: t('ticketerSection.domainTitle2'), text2: t('ticketerSection.domainText2'), button: t('ticketerSection.domainButton2')}
  ]

  return (
    <TicketingSectionComponent
      initialValues = { null }
      validationSchema = { null }
      ticketer = { true }
      title = { t('ticketer.domain1') }
      pagination = { null }
      description = { t('ticketer.infoDomain3') }
      footer = { { text: t('ticketer.adviceDomain'), icon: <GlobeSmall/> } }
    >
      <div className="ticketer-sections-domain-container d-flex flex-wrap justify-content-center align-items-center">
        { domainItems.map(item => {
          return (
            <div key={ item.id } className="ticketer-sections-domain-item d-flex flex-column justify-content-center">
              <h4>{ item.text1 }</h4>
              <p className={ item.id ? null : "domain-purple" }>{ item.id ? item.text2 : ticketingCompany?.data[0]?.ourSubdomain ? ticketingCompany?.data[0]?.ourSubdomain : "" }</p>
              <TermsPolicyButton text = { item.button } disabled = { false } function = { item.id ? () => setTicketerSectionModal(1) : () => navigateRoute(routes.domainChange) }/>
            </div>
          )
        })}
      </div>
      <ButtonGroupTibet button = { null }/>
    </TicketingSectionComponent>
  );
}

export default DomainFirstPage;