import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../App.js";
import { useMutation } from "@tanstack/react-query";
import { patchEvent, activateAllTicketsByEventId } from "../../services/axiosService.js";
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Times from "../icons/Times.jsx";
import routes from "../../routes/routes.js";

function ModalDeleteEvent() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, deleteEventModal, setDeleteEventModal } = React.useContext(AppContext);

  var navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  var initialData = {
    sentence: "",
  }

  var matchedSentence = deleteEventModal.action === 2 ?
    `Cancelar ${deleteEventModal.event?.name}`
    :
    deleteEventModal.action === 3 ?
    `Pausar ${deleteEventModal.event?.name}`
    :
    deleteEventModal.action === 4 ?
    `Reanudar ${deleteEventModal.event?.name}`
    :
    deleteEventModal.action === 5 ?
    `Activar ${deleteEventModal.event?.name}`
    : null;

  const dataSchema = Yup.object().shape(
    {
      sentence: Yup.string().required(t('register.error2')).matches(matchedSentence, t('events.cancelError'))
    }
  );

  const toApp = (route) => {
    setDeleteEventModal(false);
    navigate(route);
  }

  const sendInfo = (values, actions) => {
    if (deleteEventModal.action === 2 && values.sentence === `Cancelar ${deleteEventModal.event?.name}`) {
      cancelEvent({ status: 4 });
    } else if (deleteEventModal.action === 3 && values.sentence === `Pausar ${deleteEventModal.event?.name}`) {
      console.log("Matched")
    } else if (deleteEventModal.action === 4 && values.sentence === `Reanudar ${deleteEventModal.event?.name}`) {
      console.log("Matched")
    } else if (deleteEventModal.action === 5 && values.sentence === `Activar ${deleteEventModal.event?.name}`) {
      activateEvent();
    }
  }

  const { isLoading: cancelEventIsLoading, isError: cancelEventIsError, error: cancelEventError, mutate: cancelEvent } =
  useMutation((values) => patchEvent(authState.token, deleteEventModal.event?.id, values, null, null), {
    onSuccess: (res) => {
      if (res) {
        setDeleteEventModal(false);
      }
    },
    onError: (err) => {
      console.error(err);
    }
  });

  const { isLoading: activateEventIsLoading, isError: activateEventIsError, error: activateEventError, mutate: activateEvent } =
  useMutation(() => activateAllTicketsByEventId(authState.token, deleteEventModal.event.id), {
    onSuccess: (res) => {
      if (res) {
        setDeleteEventModal(false);
        navigate(routes.events);
      }
    },
    onError: (err) => {
      console.error(err);
    }
  });

  document.addEventListener("keyup", e => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setDeleteEventModal(false);
    }
  });

  document.addEventListener("click", e => {
    if (e.target === document.querySelector(".modal-container")) {
      setDeleteEventModal(false);
    }   
  });

  return (
    <div id="modal-delete-section" className={ deleteEventModal ? "modal-background" : "d-none" }>
      <div className="modal-container modal-relative justify-content-center align-items-center">
        <div className={`modal-terms-container ${deleteEventModal.action === 1 ? "modal-delete-container" : "modal-cancel-container"} sign-mail-box d-flex flex-column justify-content-center ${deleteEventModal.action === 1 ? "align-items-center" : null}`}>
          { deleteEventModal.action === 1 ?
            <>
              <Times function = { () => setDeleteEventModal(false) }/>
              { deleteEventModal.image }
              <h3>{ deleteEventModal.text1 }</h3>
              <p>{ deleteEventModal.text2 }<span>{ deleteEventModal.span }</span></p>
              <button className="register-email-form-button" onClick={ deleteEventModal.function }>
                { deleteEventModal.textButton }
              </button>
            </>
            :
            <> 
              { deleteEventModal.svg }
              <div className="modal-cancel-button-text d-flex flex-column justify-content-center">
                <h2>{ deleteEventModal.title }</h2>
                <p>{ deleteEventModal.text1 }</p>
                <p>{ deleteEventModal.text3 }</p>
                { deleteEventModal.list1 && deleteEventModal.list2 ?
                  <ul>
                    <li>{ deleteEventModal.list1 }</li>
                    <li>{ deleteEventModal.list2 }</li>
                  </ul>
                  : null
                }
                <h4>{ deleteEventModal.text2 }</h4>
              </div>
              <Formik
                // *** Initial values that the form will take
                enableReinitialize = {true}
                initialValues = { initialData } 
                // *** Yup Validation Schema ***
                validationSchema = {dataSchema }
                validateOnChange={ true }
                validateOnBlur={ true }
                // ** onSubmit Event
                onSubmit={(values, actions) => {
                  sendInfo(values, actions);
                }}
              >
              {/* We obtain props from Formik */}
              {props => {
                const {
                  dirty,
                  touched,
                  errors,
                  isValid,
                  setFieldTouched,
                  isSubmitting,
                } = props;
                i18n.on("languageChanged", () => {
                  Object.keys(errors).forEach(fieldName => {
                    setFieldTouched(fieldName);
                  });
                });
                return (
                  <Form className="w-100 cancel-event-form d-flex flex-column">
                    <div className="w-100 cancel-event-form-input d-flex flex-column">
                      <Field 
                        className={ errors.sentence && touched.sentence ? "register-email-form-email red-border" : dirty ? "register-email-form-email purple-border" : "register-email-form-email" } 
                        id="cancel-sentence" type="text" name="sentence" placeholder={`${t('events.cancelWrite1')}${deleteEventModal.action === 2 ? "Cancelar" : deleteEventModal.action === 3 ? "Pausar" : deleteEventModal.action === 4 ? "Reanudar" : deleteEventModal.action === 5 ? "Activar" : null} ${deleteEventModal.event?.name}${deleteEventModal.action === 2 ? t('events.cancelWrite2') : deleteEventModal.action === 3 ? t('ticket.pauseConfirm') : deleteEventModal.action === 4 ? t('ticket.resumeConfirm') : deleteEventModal.action === 5 ? t('ticket.activateConfirm') : null}`}
                      />
                      { errors.sentence && touched.sentence ? 
                        <ErrorMessage name="sentence">{msg => <div className="register-email-form-email-error">{msg}</div>}</ErrorMessage>
                        :
                        <div className=""></div>
                      }
                    </div>
                    <div className="modal-cancel-button-container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center">
                      <button className="register-email-form-button" onClick={ () => setDeleteEventModal(false) }>
                        { deleteEventModal.textButton1 }
                      </button>
                      <button className="register-email-form-button" type="submit"
                        disabled={ !((isValid && dirty) && !(errors.sentence && touched.sentence)) }
                      >
                        { deleteEventModal.textButton2 }
                      </button>
                    </div>
                  </Form>  
                )}}
              </Formik>
            </>
          }
          </div>
      </div>
    </div>
  );
}

export default ModalDeleteEvent;