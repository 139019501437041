import React from "react";

function DeleteImage(props) {

  return (
    <svg width="97" height="85" viewBox="0 0 97 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="5.18468" y="41.2765" width="38.447" height="40.447" rx="6.35902" stroke="#816CFF" strokeWidth="0.552959" strokeDasharray="1.11 1.11"/>
      <path d="M66.0584 8.51539C68.4098 8.51539 70.3161 6.60915 70.3161 4.25769C70.3161 1.90623 68.4098 0 66.0584 0C63.7069 0 61.8008 1.90623 61.8008 4.25769C61.8008 6.60915 63.7069 8.51539 66.0584 8.51539Z" fill="#CEC8F3"/>
      <path d="M10.4941 16V23.1885" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.0967 19.5879H6.9082" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <rect x="35.9082" y="16" width="40" height="48" rx="6.14998" fill="#E0DBFF"/>
      <path d="M85.4155 40.8664L78.5333 27.9953C77.2 25.5016 73.6177 25.5016 72.2833 27.9953L65.4011 40.8664C64.1782 43.1539 65.8386 45.9163 68.4376 45.9163H82.379C84.977 45.9163 86.6385 43.1528 85.4155 40.8664ZM74.6271 33.4163C74.6271 32.985 74.9771 32.635 75.4083 32.635C75.8396 32.635 76.1896 32.985 76.1896 33.4163V37.5829C76.1896 38.0142 75.8396 38.3642 75.4083 38.3642C74.9771 38.3642 74.6271 38.0142 74.6271 37.5829V33.4163ZM75.4292 41.7496C74.8542 41.7496 74.3822 41.2829 74.3822 40.7079C74.3822 40.1329 74.8437 39.6663 75.4187 39.6663H75.4292C76.0052 39.6663 76.4708 40.1329 76.4708 40.7079C76.4708 41.2829 76.0042 41.7496 75.4292 41.7496Z" fill="#FF8C5A"/>
      <path d="M64.0314 30.2268C64.0314 31.4845 63.0107 32.5052 61.753 32.5052H13.1475C11.8899 32.5052 10.8691 31.4845 10.8691 30.2268C10.8691 28.9691 11.8899 27.9484 13.1475 27.9484H25.949C26.2315 27.3955 26.4502 26.7698 26.6841 26.0711L27.2978 24.2271C27.9175 22.3679 29.6582 21.1133 31.6206 21.1133H43.2799C45.2423 21.1133 46.983 22.3679 47.6027 24.2271L48.2164 26.0711C48.4503 26.7698 48.669 27.3955 48.9515 27.9484H61.753C63.0107 27.9484 64.0314 28.9691 64.0314 30.2268ZM57.3785 35.543C57.904 35.543 58.3232 35.9896 58.2868 36.5152L56.2545 67.2885C55.9203 72.0883 53.3989 75.7944 47.141 75.7944H27.7595C21.5016 75.7944 18.9802 72.0883 18.646 67.2885L16.6137 36.5152C16.5803 35.9896 16.9965 35.543 17.522 35.543H57.3785ZM33.653 45.416C33.653 44.1705 32.6201 43.1376 31.3746 43.1376C30.1291 43.1376 29.0962 44.1705 29.0962 45.416V60.6052C29.0962 61.8507 30.1291 62.8836 31.3746 62.8836C32.6201 62.8836 33.653 61.8507 33.653 60.6052V45.416ZM45.8043 45.416C45.8043 44.1705 44.7715 43.1376 43.5259 43.1376C42.2804 43.1376 41.2476 44.1705 41.2476 45.416V60.6052C41.2476 61.8507 42.2804 62.8836 43.5259 62.8836C44.7715 62.8836 45.8043 61.8507 45.8043 60.6052V45.416Z" fill="#442ECB"/>
    </svg>
  );
}

export default DeleteImage;