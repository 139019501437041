import React from "react";

function GlobeSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.83026 13.25C6.98026 16.33 8.02026 19.47 9.83026 22.26C5.59026 21.32 2.36027 17.69 2.03027 13.25H6.83026ZM9.83026 2.73999C5.59026 3.67999 2.36027 7.31 2.03027 11.75H6.83026C6.98026 8.67 8.02026 5.52999 9.83026 2.73999ZM12.2003 2.5H11.8003L11.5003 2.92999C9.60027 5.62999 8.49026 8.73 8.33026 11.75H15.6703C15.5103 8.73 14.4003 5.62999 12.5003 2.92999L12.2003 2.5ZM8.33026 13.25C8.49026 16.27 9.60027 19.37 11.5003 22.07L11.8003 22.5H12.2003L12.5003 22.07C14.4003 19.37 15.5103 16.27 15.6703 13.25H8.33026ZM17.1703 13.25C17.0203 16.33 15.9803 19.47 14.1703 22.26C18.4103 21.32 21.6403 17.69 21.9703 13.25H17.1703ZM21.9703 11.75C21.6403 7.31 18.4103 3.67999 14.1703 2.73999C15.9803 5.52999 17.0203 8.67 17.1703 11.75H21.9703Z" fill="#816CFF"/>
    </svg>
  );
}

export default GlobeSmall;