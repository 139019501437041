import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import TermsPolicyButton from '../ui/TermsPolicyButton';

export default function ButtonGroupTibet(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  const navigateRoute = (route) => {
    navigate(route === "back" ? -1 : route);
  }

  return (
    <div className="terms-and-policy-text-buttons d-flex flex-column flex-lg-row justify-content-end align-items-center">
      <TermsPolicyButton text = { t('ticketer.back') } disabled = { false } function = { props.backButton?.function ? props.backButton.function : () => navigateRoute("back") }/>
      { props.button ? 
        <TermsPolicyButton 
          text = { props.button.text } 
          disabled = { props.button.disabled } 
          function = { props.button.function }/> 
        : null 
      }
    </div>
  )

}
