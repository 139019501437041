import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from 'react-router-dom';
import ModalTermsEvents from "../../components/icons/ModalTermsEvents.jsx";
import TermsPolicyButton from "../../components/ui/TermsPolicyButton.jsx";
import routes from "../../routes/routes.js";

export default function LandingPurchaseDone(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  return (
    <main id={`purchase-done-main`} className={`purchase-done-main d-flex flex-column justify-content-center align-items-center`}>
      <ModalTermsEvents/>
      <div className={`purchase-done-main-top d-flex flex-column justify-content-between align-items-center`}>
        <h3>{t('landingElvis.purchaseDone1')}</h3>
        {/*<div className={`purchase-done-main-top-text d-flex flex-column justify-content-between align-items-center`}>
          <p>{t('landingElvis.purchaseDone3')}<span>{ "email@email.com" }</span>{t('landingElvis.purchaseDone4')}</p>
          <p>{t('landingElvis.purchaseDone5')}</p>
          <p>{t('landingElvis.purchaseDone6')}<span>{ "email@email.com" }</span>.</p>
        </div>*/}
      </div>
      <TermsPolicyButton 
        text = { t('landingElvis.purchaseDoneButton1') } 
        disabled = { false } 
        function = { () => navigate(routes.ticketerMyTickets) }
      />
    </main>
  )

}
