import React from "react";
import { useTranslation } from "react-i18next";
import TermsPolicyButton from "../../components/ui/TermsPolicyButton.jsx";
import Pen from "../../components/icons/Pen.jsx";
import TrashPlus from "../../components/icons/TrashPlus.jsx";

export function PictureUploadComponent(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div key={ props.id } className="ticketer-customize-picture-item d-flex align-items-center">
      <div className="ticketer-customize-picture-item-picture d-flex justify-content-center align-items-center">
        { props.picture && !props.isLoading[props.id] ? <img src={ props.picture } alt={ props.id } className=""/> : props.icon }
      </div>
      <div className="ticketer-customize-picture-item-data d-flex flex-column justify-content-between">
        <div className="d-flex flex-column">
          <h5 className="d-none d-lg-block">{ `${props.text1 } ${props.text2 ? `(${props.text2})` : ""}` }</h5>
          <h5 className="d-lg-none">{ `${props.text1 } ` }</h5>
          <h5 className="d-lg-none">{ props.text2 ? `(${props.text2})` : "" }</h5>
        </div>
        <div className="d-flex justify-content-between">
          <label className="d-flex flex-row justify-content-center align-items-center">
            { props.picture ? <Pen/> : props.buttonIcon }
            { props.picture ? t(props.buttonTextAlt) : t(props.buttonText) }
            { props.isLoading[props.id] ? null : <input style={{ display: "none"}} type="file" accept="image/*" onChange={ (e) => props.uploadFunction(e, props.id) }></input> }
          </label>
          { props.picture ? 
            <TermsPolicyButton
              icon = { <TrashPlus/> }
              text = { null }
              function = { props.isLoading[props.id] ? null : () => props.deleteFunction(props.id ? {background: false, logo: true} : {background: true, logo: false}) }
              disabled = { false }
            />  
            : null      
          }
        </div>
      </div>
    </div>
  );
}