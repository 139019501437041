import React from 'react';

function LogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="33"
      fill="none"
      viewBox="0 0 150 33"
    >
      <path
        fill="url(#paint0_linear_7503_11460)"
        d="M28.017 25.314c-5.748-3.413-9.18-9.28-9.293-15.97.042-2.46-1.869-3.672-3.779-3.645-1.91-.029-3.819 1.183-3.777 3.645-.114 6.69-3.545 12.557-9.293 15.97-4.177 2.48-.38 9.015 3.814 6.525a26.554 26.554 0 009.258-9.191 26.555 26.555 0 009.257 9.19c4.191 2.488 7.996-4.04 3.814-6.524h-.001z"
      ></path>
      <path
        fill="#C6BDFE"
        d="M28.782 12.267L17.599 1.083c-1.443-1.443-3.897-1.445-5.342 0C8.54 4.796 4.826 8.511 1.11 12.226c-3.442 3.443 1.899 8.786 5.342 5.342l8.473-8.473 8.513 8.512c3.443 3.442 8.786-1.899 5.342-5.342l.001.002z"
      ></path>
      <path
        fill="#130E39"
        d="M46.016 13.27h2.988v4.097h-2.988v5.729c0 1.264.677 1.602 1.694 1.602.492 0 .955-.093 1.232-.155v3.881c-.185.093-.956.432-2.434.432-3.173 0-5.113-1.88-5.113-4.928v-6.56h-2.71V13.27h.769c1.601 0 2.372-1.078 2.372-2.495V8.803h4.19v4.467zM54.64 5.848c1.54 0 2.772 1.232 2.772 2.741 0 1.51-1.233 2.742-2.773 2.742s-2.71-1.233-2.71-2.742 1.232-2.741 2.71-2.741zM52.33 28.58v-15.31h4.681V28.58H52.33v.001zM61.108 28.58V6.277h4.59v8.44c.647-.955 2.34-1.817 4.435-1.817 4.435 0 7.116 3.389 7.116 7.978 0 4.588-3.019 8.04-7.3 8.04-2.034 0-3.604-.893-4.312-2.064v1.724H61.107zm8.009-11.46c-1.818 0-3.482 1.202-3.482 3.79s1.664 3.82 3.481 3.82c1.818 0 3.45-1.264 3.45-3.82s-1.632-3.79-3.45-3.79zM94.19 24.175c-.74 2.65-3.174 4.867-7.054 4.867-4.22 0-7.977-3.019-7.977-8.162 0-5.144 3.665-8.07 7.64-8.07 4.743 0 7.669 2.926 7.669 7.854 0 .647-.061 1.356-.092 1.448H83.717c.093 1.725 1.664 2.957 3.482 2.957 1.694 0 2.649-.8 3.08-2.002l3.912 1.11-.001-.002zm-4.251-5.083c-.061-1.17-.862-2.587-3.081-2.587-1.972 0-2.957 1.448-3.05 2.587h6.13zM103.091 13.27h2.987v4.097h-2.987v5.729c0 1.264.677 1.602 1.694 1.602.492 0 .955-.093 1.232-.155v3.881c-.184.093-.955.432-2.433.432-3.174 0-5.114-1.88-5.114-4.928v-6.56h-2.71V13.27h.769c1.601 0 2.372-1.078 2.372-2.495V8.803h4.19v4.467z"
      ></path>
      <path
        fill="#C6BDFE"
        d="M109.105 31.037v-12.83h1.964v1.618c.561-1.035 1.792-1.856 3.475-1.856 3.108 0 4.769 2.395 4.769 5.416 0 3.021-1.791 5.482-4.834 5.482-1.598 0-2.806-.756-3.368-1.683v3.853h-2.007.001zm5.072-11.277c-1.856 0-3.086 1.554-3.086 3.625 0 2.071 1.23 3.669 3.086 3.669 1.857 0 3.065-1.532 3.065-3.669 0-2.136-1.166-3.625-3.065-3.625zM124.405 22.653l2.805-.41c.626-.087.798-.41.798-.776 0-1.014-.669-1.857-2.244-1.857-1.426 0-2.224.885-2.352 2.093l-1.943-.453c.216-1.986 2.008-3.368 4.252-3.368 3.108 0 4.316 1.77 4.316 3.8v5.2c0 .907.087 1.445.129 1.705h-1.985c-.044-.26-.107-.648-.107-1.404-.454.735-1.489 1.727-3.346 1.727-2.115 0-3.474-1.468-3.474-3.108 0-1.857 1.359-2.893 3.151-3.152v.003zm3.605 1.489v-.475l-3.173.475c-.864.129-1.511.625-1.511 1.554 0 .777.647 1.51 1.683 1.51 1.661 0 2.999-.798 2.999-3.064h.002zM134.053 25.242c.129 1.08.95 1.943 2.417 1.943 1.144 0 1.77-.648 1.77-1.382 0-.647-.475-1.144-1.339-1.338l-1.769-.388c-1.62-.345-2.59-1.446-2.59-2.914 0-1.77 1.661-3.28 3.69-3.28 2.849 0 3.734 1.856 3.95 2.783l-1.792.67c-.087-.541-.518-1.727-2.158-1.727-1.035 0-1.726.669-1.726 1.381 0 .626.388 1.08 1.187 1.252l1.683.366c1.877.41 2.871 1.554 2.871 3.086 0 1.533-1.23 3.216-3.799 3.216-2.849 0-4.057-1.835-4.23-3.021l1.835-.648v.002zM143.806 25.242c.129 1.08.95 1.943 2.417 1.943 1.144 0 1.77-.648 1.77-1.382 0-.647-.475-1.144-1.338-1.338l-1.77-.388c-1.619-.345-2.589-1.446-2.589-2.914 0-1.77 1.661-3.28 3.69-3.28 2.849 0 3.734 1.856 3.949 2.783l-1.791.67c-.087-.541-.519-1.727-2.158-1.727-1.036 0-1.727.669-1.727 1.381 0 .626.389 1.08 1.188 1.252l1.683.366c1.877.41 2.87 1.554 2.87 3.086 0 1.533-1.229 3.216-3.798 3.216-2.849 0-4.057-1.835-4.231-3.021l1.835-.648v.002z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_7503_11460"
          x1="14.947"
          x2="14.947"
          y1="32.395"
          y2="5.699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C7BEFE"></stop>
          <stop offset="0.11" stopColor="#C2B9F8"></stop>
          <stop offset="0.25" stopColor="#B3ABE8"></stop>
          <stop offset="0.41" stopColor="#9C94CE"></stop>
          <stop offset="0.58" stopColor="#7B74A9"></stop>
          <stop offset="0.77" stopColor="#514B7A"></stop>
          <stop offset="0.95" stopColor="#1E1941"></stop>
          <stop offset="1" stopColor="#100C32"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoSvg;
