import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App.js";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getEvents, createEvent } from "../../services/axiosService.js";
import DashboardBar from "../shared/DashboardBar";
import EmptyItemsBox from "../shared/EmptyItemsBox.jsx";
import CalendarEdit from "../icons/CalendarEdit";
import PenLine from "../icons/PenLine";
import routes from "../../routes/routes.js";
import EmptyEvents from "../icons/EmptyEvents.jsx";
import SpinnerPurple from "../icons/SpinnerPurple.jsx";
import EventsComponentItemList from "./EventsComponentItemList.jsx";

function EventsComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, elvis } = React.useContext(AppContext);

  var navigate = useNavigate();

  const sections = [
    t("events.section1"),
    t("events.section2"),
    t("events.section3"),
    t("events.section4"),
    /* t("events.section5"), */
  ];

  const [sectionSelected, setSectionSelected] = useState(0);

  const {
    isLoading,
    isError,
    data: events,
    refetch,
  } = useQuery({
    queryKey: ["events"],
    queryFn: () =>
      getEvents({
        userToken: authState.token,
        dateNext: sectionSelected === 1 ? true : null,
      }),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: createNewEvent } = useMutation(
    () => createEvent(authState.token),
    {
      onSuccess: (res) => {
        if (res) {
          navigate(routes.events + "/" + res.data.id);
        }
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [sectionSelected]);

  return (
    <section
      id="events-section"
      className="events-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <DashboardBar
        text={t("events.title")}
        session={false}
        emoji="events"
        icon={<CalendarEdit />}
        buttonText={t("home.create")}
        function={() => createNewEvent()}
        disabled={false}
        button={elvis}
      />
      <div className="events-section-content d-flex flex-column">
        <p>{t("events.paragraph")}</p>
        <div className="events-section-bar d-flex justify-content-between">
          <h3>{t("events.barTitle")}</h3>
          <div className="events-section-bar-right d-flex justify-content-end flex-wrap">
            {sections.map((section, index) => {
              return (
                <button
                  key={index}
                  className={`events-section-bar-button ${
                    sectionSelected === index
                      ? "events-section-bar-button-active"
                      : null
                  } d-flex justify-content-center align-items-center`}
                  onClick={() => setSectionSelected(index)}
                >
                  {section}
                </button>
              );
            })}
          </div>
        </div>
        <div
          className={`events-section-showevents d-flex flex-column ${
            isLoading ? "align-items-center" : ""
          }`}
        >
          {events?.data?.events?.filter((item) => item.status === 3).length ? (
            <div className="events-section-pending d-flex align-items-center">
              <PenLine />
              {t("events.pending1")}
              <div className="d-flex justify-content-center align-items-center">
                {
                  events?.data?.events?.filter((item) => item.status === 3)
                    .length
                }
              </div>
              {events?.data?.events?.filter((item) => item.status === 3)
                .length === 1
                ? t("events.pending2")
                : t("events.pending3")}
            </div>
          ) : null}
          {isLoading ? (
            <SpinnerPurple />
          ) : isError ? (
            <h3 className="fetching-error">
              {t("home.errorRetrievingEvents")}
            </h3>
          ) : events?.data?.events?.length ? (
            sectionSelected === 0 ? (
              events.data.events
                ?.sort((x, y) => x.status - y.status)
                .map((item, index) => (
                  <>
                    <EventsComponentItemList
                      key={index}
                      item={item}
                      elvis={elvis}
                    />
                  </>
                ))
            ) : sectionSelected === 1 ? (
              events?.data.events
                ?.sort((x, y) => x.status - y.status)
                .filter((f) => f.isFinished === false && f.status === 1)
                .map((item, index) => (
                  <>
                    <EventsComponentItemList
                      key={index}
                      item={item}
                      elvis={elvis}
                    />
                  </>
                ))
            ) : sectionSelected === 2 ? (
              events?.data.events
                ?.sort((x, y) => x.status - y.status)
                .filter((f) => f.status === 2)
                .map((item, index) => (
                  <>
                    <EventsComponentItemList
                      key={index}
                      item={item}
                      elvis={elvis}
                    />
                  </>
                ))
            ) : (
              sectionSelected === 3 &&
              events?.data.events
                ?.sort((x, y) => x.status - y.status)
                .filter((f) => f.status === 3)
                .map((item, index) => (
                  <>
                    <EventsComponentItemList
                      key={index}
                      item={item}
                      elvis={elvis}
                    />
                  </>
                ))
            )
          ) : (
            <EmptyItemsBox
              icon={<EmptyEvents />}
              text1={t("home.empty1")}
              text2={t("home.empty2")}
              text3={t("home.empty3")}
              span={null}
              buttonIcon={<CalendarEdit />}
              buttonText={t("home.create")}
              buttonFunction={() => createNewEvent()}
              buttonDisabled={false}
            />
          )}
        </div>
      </div>
    </section>
  );
}

export default EventsComponent;
