import React from "react";

function FileHeart(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.75 6V3.75L19.25 8.25H17C15.42 8.25 14.75 7.58 14.75 6ZM20 9.75V18C20 20 19 21 17 21H8C6 21 5 20 5 18V6C5 4 6 3 8 3H13.25V6C13.25 8.42 14.58 9.75 17 9.75H20ZM14.0471 11.25C12.8891 11.245 12.5 12.393 12.5 12.393C12.5 12.393 12.1109 11.245 10.9529 11.25C9.39788 11.257 8.78592 12.685 9.06592 14.104C9.52192 16.417 12.5 17.75 12.5 17.75C12.5 17.75 15.4781 16.418 15.9341 14.104C16.2141 12.685 15.6051 11.257 14.0471 11.25Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default FileHeart;