import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TicketItemButtonsPlusMinus(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  return (
    <div
      className={`ticket-item-sale d-flex justify-content-between align-items-center`}
    >
      <button
        onClick={(event) => {
          props.changeQuantity(props.id, 0);
          event.stopPropagation();
        }}
      >
        -
      </button>
      <div className={`d-flex justify-content-center align-items-center`}>
        {props.ticketSaleQuantity}
      </div>
      <button
        onClick={(event) => {
          props.changeQuantity(props.id, 1);
          event.stopPropagation();
        }}
      >
        +
      </button>
    </div>
  );
}
