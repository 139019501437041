import React from "react";
import EmptyEventsInfo from "./EmptyEventsInfo.jsx";
import ProfileButton from "../ui/ProfileButton.jsx";

function EmptyItemsBox(props) {

  return (
    <div className="home-section-events-box d-flex flex-column justify-content-start justify-content-lg-center align-items-center">
      <div className={`home-section-events-box-empty d-flex flex-column justify-content-center align-items-center`}>
        {//TODO ICONO MAL
        }
        <EmptyEventsInfo
          icon = { props.icon }
          text1 = { props.text1 }
          text2 = { props.text2 }
          text3 = { props.text3 }
          span = { props.span }
          landing = { props.landing }
        />
        { props.landing ?
          null
          :
          <ProfileButton
            icon = { props.buttonIcon } 
            text = { props.buttonText } 
            function = { props.buttonFunction }
            disabled = { props.buttonDisabled }
          />
        }
      </div>
    </div>
  );
}

export default EmptyItemsBox;