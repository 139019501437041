import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getEventById,
  getTicketingCompanyById
} from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import BellExclamation from '../../components/icons/BellExclamation.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';

function TicketsEventSecondPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const { values, errors, touched, isValid, setFieldError } =
    useFormikContext();
  const { createTicketInfo, setCreateTicketInfo } =
    React.useContext(AppContext);

  var navigate = useNavigate();
  const [freeCheckbox, setFreeCheckbox] = useState(false);
  const [resaleCheckbox, setResaleCheckbox] = useState(false);
  const [countFreeTickets, setCountFreeTickets] = useState();
  const [saveQuantity, setQuantity] = useState();

  const nextPage = (route) => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === props.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          price: values.price,
          isFree: values.isFree,
          quantity: values.quantity,
          resale: values.resale,
          resalePercentage: values.resalePercentage
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        price: values.price,
        isFree: values.isFree,
        quantity: values.quantity,
        resale: values.resale,
        resalePercentage: values.resalePercentage
      });
      navigate(route === 'back' ? -1 : route);
    } else if (createTicketInfo.eventId !== props.eventId) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  };

  const handlePrice = (e) => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo?.eventId === props?.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          price: e?.target?.value
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        price: e?.target?.value
      });
    }
    /* setFieldValue('price', e.target?.value); */
  };

  const freeCheckboxFunction = () => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo?.eventId === props?.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          isFree: !freeCheckbox,
          price: null
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        isFree: !freeCheckbox,
        price: null
      });
    }
    if (!freeCheckbox) {
      values.price = 0;
      setFieldError('price', '');
      setCountFreeTickets(props?.ticketingCompany?.freeTicketsAvailable);
    } else {
      setCountFreeTickets(
        props?.ticketingCompany?.freeTicketsAvailable - saveQuantity
      );
    }
    values.isFree = !freeCheckbox;
    setFreeCheckbox(!freeCheckbox);
  };

  const countTickets = (e) => {
    setQuantity(e?.target?.value);
    setCountFreeTickets(
      props?.ticketingCompany?.freeTicketsAvailable - e?.target?.value
    );

    if (
      Number(e.target?.value) >
        Number(props?.ticketingCompany?.freeTicketsAvailable) &&
      props?.ticketingCompany?.freeTicketsAvailable > 0
    ) {
      setFieldError(
        'quantity',
        `La cantidad supera los ${props?.ticketingCompany?.freeTicketsAvailable} tickets dispobibles sin comisión de tu plan. Debes usar primero los tickets sin comisión. Luego podrás incorporar los tickets que desees.`
      );
      setCountFreeTickets(props?.ticketingCompany?.freeTicketsAvailable);
    }
  };

  const countTicketsValue = (e) => {
    setCountFreeTickets(0);
    setFieldError('quantity');
  };

  useEffect(() => {
    if (values?.quantity) {
      setCountFreeTickets(
        props?.ticketingCompany?.freeTicketsAvailable - values?.quantity
      );
    } else {
      setCountFreeTickets(
        props?.ticketingCompany?.freeTickets -
          props?.ticketingCompany?.freeTicketsUsed
      );
    }

    if (createTicketInfo?.isFree === true) {
      setFreeCheckbox(true);
      values.isFree = !freeCheckbox;
    } else {
      setFreeCheckbox(false);
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo?.eventId === props?.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          quantity: values?.quantity
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        quantity: values?.quantity
      });
    }
  }, [values?.quantity]);

  useEffect(() => {
    setResaleCheckbox(values?.resale);
  }, [values.price, values.isFree, values.resale]);

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100 info-inside-input-container">
          <h4>{t('ticket.createTicketField4')} *</h4>
          <p>{t('ticket.createTicketFieldDescription4')}</p>
          <h6 className="info-inside-input">
            {props.ticketingCompany?.currency?.abbreviation}
          </h6>
          <Field
            className={
              errors.price && touched.price
                ? 'ticketer-field ticketer-field-padding red-border'
                : touched.price
                ? 'ticketer-field ticketer-field-padding purple-border'
                : 'ticketer-field ticketer-field-padding'
            }
            id="event-ticket-price"
            type="number"
            name="price"
            placeholder={createTicketInfo?.isFree ? 0 : ''}
            onChange={(e) => handlePrice(e)}
            /* defaultValue={createTicketInfo?.isFree ? 0 : ''} */
            min="0.01"
            step="0.01"
            disabled={freeCheckbox}
          />
          {errors.price && touched.price ? (
            <ErrorMessage name="price">
              {(msg) => <div className="ticketer-field-error">{msg}</div>}
            </ErrorMessage>
          ) : null}

          {/* IS FREE */}
          <div
            className={`checkbox-register-container d-flex align-items-center ${
              props?.ticketingCompany?.freeTicketsAvailable === 0
                ? 'disabled-input text-disabled'
                : ''
            }`}
          >
            <input
              className="checkbox-register-mail"
              type="checkbox"
              id="checkbox-register-mail"
              checked={freeCheckbox}
              disabled={props?.ticketingCompany?.freeTicketsAvailable === 0}
              onChange={() => freeCheckboxFunction()}
            />
            <span className="checkmark d-flex flex-row justify-content-center align-items-center"></span>
            <label className="checkbox-label" htmlFor="checkbox">
              {t('ticket.createTicketCheckbox1', {
                total: props?.ticketingCompany?.freeTickets
              })}
            </label>
          </div>
        </div>

        {/* CANTIDAD DE TICKETS */}
        {props?.ticketingCompany?.freeTicketsAvailable !== 0 ||
        props?.ticketingCompany?.freeTicketsAvailable !== null ? (
          <>
            <div className={`w-100`}>
              <h4>{t('ticket.createTicketField5')} *</h4>
              <p>
                {t('ticket.createTicketFieldDescription5', {
                  total: `${
                    props?.ticketingCompany?.freeTicketsAvailable === 0
                      ? 0
                      : countFreeTickets
                  } / ${props?.ticketingCompany?.freeTickets}`
                })}
              </p>
              <h5 className="mt-3">{t('ticket.createTicketLittleField1')}</h5>
              <Field
                className={
                  errors.quantity && touched.quantity
                    ? 'ticketer-field red-border'
                    : touched.quantity
                    ? 'ticketer-field purple-border'
                    : 'ticketer-field'
                }
                id="event-ticket-quantity"
                type="number"
                name="quantity"
                placeholder={t('ticket.createTicketField5')}
                onWheel={(e) => e.currentTarget.blur()}
                onBlur={(e) => countTickets(e)}
                step="1"
                min="0"
                max={props?.ticketingCompany?.freeTicketsAvailable}
              />
            </div>
            {errors?.quantity && (
              <span className="ticketer-field-error">{errors?.quantity}</span>
            )}
          </>
        ) : (
          <div className={`w-100`}>
            <h4>{t('ticket.createTicketField5')} *</h4>
            <p>
              {t('ticket.createTicketFieldDescription5', {
                total: `${
                  props?.ticketingCompany?.freeTicketsAvailable -
                  countFreeTickets
                } / ${props?.ticketingCompany?.freeTicketsAvailable}`
              })}
            </p>
            <h5 className="mt-3">{t('ticket.createTicketLittleField1')}</h5>
            <Field
              className={
                errors.quantity && touched.quantity
                  ? 'ticketer-field red-border'
                  : touched.quantity
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-ticket-quantity"
              type="number"
              name="quantity"
              placeholder={t('ticket.createTicketField5')}
              /* onWheel={(e) => e.currentTarget.blur()} */
              onBlur={(e) => countTicketsValue(e)}
              min="0"
              step="1"
            />
          </div>
        )}
        {errors.quantity && touched.quantity ? (
          <ErrorMessage name="quantity">
            {(msg) => <div className="ticketer-field-error">{msg}</div>}
          </ErrorMessage>
        ) : null}

        {/*  REVENTA */}
        <div
          className={`w-100 mt-4 ${
            freeCheckbox ? 'disabled-input text-disabled' : ''
          } info-inside-input-container`}
        >
          <h4>{t('ticket.createTicketField6')}</h4>
          <p>{t('ticket.createTicketFieldDescription6')}</p>
          <div className="checkbox-percentage d-flex align-items-center">
            <input
              disabled={freeCheckbox}
              className="checkbox-register-mail"
              type="checkbox"
              id="checkbox-register-mail"
              checked={resaleCheckbox}
              onChange={() => {
                setResaleCheckbox(!resaleCheckbox);
                values.resale = !resaleCheckbox;
              }}
            />
            <span className="checkmark d-flex flex-row justify-content-center align-items-center"></span>
            <label className="checkbox-label" htmlFor="checkbox">
              {t('ticket.createTicketCheckbox2')}
            </label>
          </div>
          {resaleCheckbox ? (
            <div className="info-inside-input-container">
              <h5>{t('ticket.ticketInfo17')}</h5>
              <h6 className="info-inside-input-percentaje">{'%'}</h6>
              <Field
                onWheel={(e) => e.currentTarget.blur()}
                className={
                  errors.resalePercentage && touched.resalePercentage
                    ? 'ticketer-field ticketer-field-padding red-border'
                    : touched.resalePercentage
                    ? 'ticketer-field ticketer-field-padding purple-border'
                    : 'ticketer-field ticketer-field-padding'
                }
                id="event-ticket-resalePercentage"
                type="number"
                name="resalePercentage"
                placeholder=""
                min="0"
                max="5"
                step="0.1"
              />
              {errors.resalePercentage && touched.resalePercentage ? (
                <ErrorMessage name="resalePercentage">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
              <p>{t('ticket.createTicketFieldDescription11')}</p>
            </div>
          ) : null}
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.next'),
          disabled:
            createTicketInfo?.isFree === true
              ? !values.quantity ||
                values?.quantity > props?.ticketingCompany?.freeTicketsAvailable
              : !(
                  isValid &&
                  values.price &&
                  values.quantity &&
                  (!values.resale || (values.resale && values.resalePercentage))
                ),
          function: () =>
            nextPage(
              `${routes.events}/${props.eventId}${routes.createTicketsAdvance}`
            )
        }}
      />
    </>
  );
}

function TicketsEventSecondPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    createTicketInfo
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const [schemaIssFree, setSchemaIsFree] = useState(false);

  useEffect(() => {
    setSchemaIsFree(createTicketInfo?.isFree);
  }, [createTicketInfo?.isFree]);

  const { data: ticketingCompany } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading,
    isError,
    data: events
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialSecondTicketData = {
    price: createTicketInfo?.price
      ? createTicketInfo?.price
      : createTicketInfo?.isFree === true
      ? 0
      : createTicketInfo?.price,
    isFree: createTicketInfo?.isFree ? createTicketInfo?.isFree : false,
    quantity: createTicketInfo?.quantity ? createTicketInfo?.quantity : 0,
    resale: createTicketInfo?.resale ? createTicketInfo?.resale : false,
    resalePercentage: createTicketInfo?.resalePercentage
      ? createTicketInfo?.resalePercentage
      : ''
  };

  const dataSecondTicketSchema = Yup.object().shape({
    price: Yup.number()
      .required(t('register.error2'))
      .min(0.01, t('ticket.errorPrice1')),
    quantity: Yup.number()
      .required(t('register.error2'))
      .positive(t('ticket.errorQuantity'))
      .max(
        200,
        `La cantidad supera los ${ticketingCompany?.data[0]?.freeTicketsAvailable} tickets disponibles sin comisión de tu plan. Debes usar primero los tickets sin comisión. Luego podrás incorporar los tickets que desees.`
      )
      .integer(),
    resale: Yup.boolean(),
    resalePercentage: Yup.number()
      .min(0.1, t('ticket.createTicketCheckbox2Error2'))
      .max(5, t('ticket.createTicketCheckbox2Error'))
  });

  const dataSecondTicketSchemaWithoutComision = Yup.object().shape({
    price: Yup.number()
      .required(t('register.error2'))
      .min(0.01, t('ticket.errorPrice1')),
    quantity: Yup.number()
      .required(t('register.error2'))
      .positive(t('ticket.errorQuantity'))
      .integer(),
    resale: Yup.boolean(),
    resalePercentage: Yup.number()
      .min(0.1, t('ticket.createTicketCheckbox2Error2'))
      .max(5, t('ticket.createTicketCheckbox2Error'))
  });

  const dataSecondTicketSchemaIsFree = Yup.object().shape({
    price: Yup.number().default(0),
    quantity: Yup.number()
      .required(t('register.error2'))
      .min(1, t('ticket.errorQuantity'))
      .integer(),
    resale: Yup.boolean(),
    resalePercentage: Yup.number()
      .min(0.1, t('ticket.createTicketCheckbox2Error2'))
      .max(5, t('ticket.createTicketCheckbox2Error'))
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <TicketingSectionComponent
          initialValues={initialSecondTicketData}
          validationSchema={
            schemaIssFree
              ? dataSecondTicketSchemaIsFree
              : ticketingCompany?.data[0]?.freeTicketsAvailable > 0 &&
                !schemaIssFree
              ? dataSecondTicketSchema
              : !schemaIssFree && dataSecondTicketSchemaWithoutComision
          }
          ticketer={false}
          eventTitle={events?.data?.name}
          title={t('ticket.createTicket')}
          pagination={'2/4'}
          description={t('ticket.createTicketTitle2')}
          footer={{
            text: t('ticket.createTicketText2'),
            icon: <BellExclamation />
          }}
        >
          <TicketsEventSecondPageChildren
            eventId={eventId}
            ticketingCompany={ticketingCompany?.data[0]}
          />
        </TicketingSectionComponent>
      )}
    </>
  );
}

export default TicketsEventSecondPage;
