import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteEvent,
  patchEvent,
  getTicketsTypeByEvent
} from '../../services/axiosService.js';
import TicketerItem from '../ui/TicketerItem.jsx';
import DashboardBar from '../shared/DashboardBar';
import CalendarEdit from '../icons/CalendarEdit';
import CancelDeleteEventComponent from '../shared/CancelDeleteEventComponent.jsx';
import Eye from '../icons/Eye.jsx';
import DeleteImage from '../icons/DeleteImage.jsx';
import { createEventData } from './ticketerData.js';
import routes from '../../routes/routes.js';

function EventsCreateComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setDeleteEventModal,
    ticketingCompany,
    setTicketerSectionModal
  } = React.useContext(AppContext);

  const sections = [
    { id: 0, title: t('ticketer.required') },
    { id: 1, title: t('ticketer.optional') }
  ];

  var navigate = useNavigate();
  let { eventId } = useParams();

  const [fieldsStatus, setFieldsStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  const { mutate: publishEvent } = useMutation(
    (values) =>
      patchEvent(authState.token, props.event?.id, values, null, null),
    {
      onSuccess: (res) => {
        if (res) {
          navigate(routes.events);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const { mutate } = useMutation(
    () => deleteEvent(authState.token, props.event?.id),
    {
      onSuccess: (res) => {
        if (res) {
          setDeleteEventModal(false);
          navigate(-1);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const { data: tickets } = useQuery({
    queryKey: ['tickets-model', eventId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price'
      ),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (props.event) {
      var info =
        props.event?.name &&
        props.event?.description &&
        props.event?.location &&
        props.event?.address &&
        props.event?.country &&
        props.event?.state &&
        props.event?.postalCode &&
        props.event?.dateEvent &&
        props.event?.dateDoors &&
        props.event?.dateEnd &&
        props.event?.timezone
          ? true
          : false;
      var tickets = props.event?.ticketsTotal ? true : false;
      var customize =
        props.event?.images.cover && props.event?.images.thumb ? true : false;
      setFieldsStatus([info, tickets, customize]);
    }
  }, [props.event]);

  return (
    <>
      <DashboardBar
        text={t('events.title')}
        session={false}
        emoji="create-event"
        icon={<CalendarEdit />}
        buttonText={t('ticketer.publish')}
        function={
          fieldsStatus[0] && fieldsStatus[1]
            ? ticketingCompany?.data[0]?.isPublished
              ? () => publishEvent({ status: 1 })
              : () => setTicketerSectionModal(4)
            : null
        }
        disabled={!(fieldsStatus[0] && fieldsStatus[1])}
        button={props.event?.status === 3}
        ticketer={false}
        icon2={<Eye />}
        buttonText2={t('events.seeEvent')}
        function2={null}
        disabled2={false}
        breadcrum={true}
        breadcrumFunction={() =>
          props.navigateToTicketerSections(routes.events)
        }
        span={
          props.event?.name ? `/ ${props.event?.name}` : t('events.spanCreate')
        }
      />
      <div className="ticketer-section-content d-flex flex-column justify-content-center">
        {sections.map((section) => {
          return (
            <div
              key={section.id}
              className="ticketer-section-top create-event-section-top d-flex flex-column justify-content-center"
            >
              <div className="create-event-section-title d-flex flex-column justify-content-center">
                <h3>{section.title}</h3>
                <p>{t(`events.createText${section.id}`)}</p>
              </div>
              <div className="ticketer-section-container create-event-section-container d-flex flex-wrap align-items-center">
                {createEventData.map((card, index) => {
                  if (section.id ? card.id >= 3 : card.id < 3) {
                    return (
                      <TicketerItem
                        key={card.id}
                        id={card.id}
                        icon={card.icon}
                        text={t(card.text)}
                        altText={t(card.altText)}
                        button={t(card.button)}
                        required={section.id ? false : true}
                        success={fieldsStatus[index]}
                        function={props.navigateToTicketerSections}
                        tickets={
                          card.text === 'events.createCardsTitle2'
                            ? true
                            : false
                        }
                        data={
                          card.id === 2 ? tickets?.data?.tickets : props.event
                        }
                      />
                    );
                  } else return null;
                })}
              </div>
            </div>
          );
        })}
      </div>
      <CancelDeleteEventComponent
        text1={t('events.deleteEvent1')}
        text2={t('events.deleteEvent2')}
        function={() =>
          setDeleteEventModal({
            function: mutate,
            action: 1,
            text1: t('events.deleteModal1'),
            text2: t('events.deleteModal2'),
            span: t('events.deleteModal3'),
            textButton: t('events.deleteModal1'),
            image: <DeleteImage />
          })
        }
      />
    </>
  );
}

export default EventsCreateComponent;
