import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getEventById,
  getPublicElvisKey,
  getTicketsTypeByEvent,
  getStripeInfo,
  checkDiscountByCode,
  getTicketById,
  getStripeResale
} from '../../services/axiosService.js';
import HomeEventHorizontal from '../../components/shared/HomeEventHorizontal.jsx';
import AngleLeft from '../../components/icons/AngleLeft.jsx';
import * as Yup from 'yup';
import { Formik, validateYupSchema } from 'formik';
import { getSubdomain } from '../../components/shared/functions.js';
import TermsPolicyButton from '../../components/ui/TermsPolicyButton.jsx';
import CheckoutTickets from '../../components/ui/CheckoutTickets.jsx';
import CheckoutDiscount from '../../components/ui/CheckoutDiscount.jsx';
import CheckoutFinal from '../../components/ui/CheckoutFinal.jsx';
import LandingCheckoutFormField from '../../components/ui/LandingCheckoutFormField.jsx';
import CheckoutResume from '../../components/ui/CheckoutResume.jsx';
import AdviceFooter from '../../components/ui/AdviceFooter.jsx';
import FileList from '../../components/icons/FileList.jsx';
import CheckoutTicketSecondary from '../../components/ui/CheckoutTicketSecondary.jsx';
import SuccessFailPopupLarge from '../../components/ui/SuccessFailPopupLarge.jsx';

export default function LandingCheckout(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setTermsLandingModal,
    ticketSaleOpen,
    setTicketSaleOpen,
    ticketingCompany,
    ticketSaleQuantity,
    setTicketSaleQuantity,
    publicElvisId,
    setPublicElvisId,
    setTicketingCompany
  } = React.useContext(AppContext);

  let { ticketResaleId, eventElvisId } = useParams();
  var navigate = useNavigate();
  const subdomain = useMemo(() => getSubdomain(), []);

  const [page, setPage] = useState(0);
  const [code, setCode] = useState('');
  const [finalCode, setFinalCode] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [popup, setPopup] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [validationErrors, setValidationErrors] = useState(false);

  var initialValues = {
    name: '',
    surname: '',
    email: authState.userId,
    id: '',
    phone: '',
    country: '',
    province: '',
    city: '',
    zip: '',
    address: '',
    number: ''
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  /* const validationSchema = Yup.object().shape({
    name: Yup.string(),
    surname: Yup.string(),
    email: Yup.string().email(t("register.error1")),
    id: Yup.string(),
    phone: Yup.string().matches(phoneRegExp, t("profile.profilePhoneError")),
    country: Yup.string(),
    province: Yup.string(),
    city: Yup.string(),
    zip: Yup.string(),
    address: Yup.string(),
    number: Yup.string(),
  }); */

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('register.error2')),
    surname: Yup.string().required(t('register.error2')),
    email: Yup.string().email(t('register.error1')),
    id: Yup.string().required(t('register.error2')),
    phone: Yup.string()
      .matches(phoneRegExp, t('profile.profilePhoneError'))
      .required(t('register.error2')),
    country: Yup.string().required(t('register.error2')),
    province: Yup.string().required(t('register.error2')),
    city: Yup.string().required(t('register.error2')),
    zip: Yup.string().required(t('register.error2')),
    address: Yup.string().required(t('register.error2')),
    number: Yup.string().required(t('register.error2'))
  });

  const { mutate: checkCode } = useMutation(
    (values) =>
      checkDiscountByCode(authState.token, publicElvisId, code, values.data),
    {
      onSuccess: (res) => {
        if (res.data) {
          setPopup({ state: 1, text: t('ticket.checkingDiscountSuccess') });
          setFinalCode(res.data);
        }
      },
      onError: (err) => {
        setPopup({
          state: 0,
          text:
            t('ticket.checkingDiscountFail') +
            '. (' +
            err.response.data.message +
            ')'
        });
        console.error(err);
      }
    }
  );

  const { isLoading: paymentIsLoading, mutate: stripeInfo } = useMutation(
    !ticketResaleId
      ? (values) =>
          getStripeInfo(
            authState.token,
            publicElvisId,
            companyApiKey.data.id,
            values.data,
            finalCode.code,
            totalPrice,
            values.values
          )
      : (values) =>
          getStripeResale(
            authState.token,
            publicElvisId,
            companyApiKey.data.id,
            ticketResaleId,
            values.values
          ),
    {
      onSuccess: (res) => {
        if (res?.data?.checkout_completed) {
          navigate(routes.landingPurchaseDone);
        }
        if (res?.data?.checkout_url) {
          let redirectUrl = res.data.checkout_url;
          window.location.href = redirectUrl;
        }
      },
      onError: (err) => {
        setPopup({
          state: 0,
          text: t('ticket.sellTicketFail')
        });
        console.error(err.response.status);
      }
    }
  );

  const { data: events } = useQuery({
    queryKey: ['events', eventElvisId],
    queryFn: () => getEventById(null, eventElvisId, publicElvisId),
    enabled: !!subdomain && !!publicElvisId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { data: companyApiKey } = useQuery({
    queryKey: ['companyApiKey'],
    queryFn: () => getPublicElvisKey(subdomain),
    enabled: !!subdomain,
    onSuccess: async (res) => {
      if (res) {
        setTicketingCompany({ data: [res.data] });
        setPublicElvisId(res.data.apiKey);
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const { data: tickets } = useQuery({
    queryKey: ['tickets-model', eventElvisId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventElvisId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price',
        publicElvisId
      ),
    enabled: !!subdomain && !!publicElvisId && !ticketResaleId,
    onSuccess: async (res) => {
      if (res) {
      }
    },
    onError: (err) => {
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { data: resaleTicket } = useQuery({
    queryKey: ['ticket', ticketResaleId],
    queryFn: () =>
      getTicketById(authState.token, publicElvisId, ticketResaleId),
    enabled: !!subdomain && !!publicElvisId && !!ticketResaleId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const changeQuantity = (id, direction) => {
    setTicketSaleQuantity(
      tickets?.data?.tickets?.map(function callback(
        currentValue,
        index,
        array
      ) {
        var quantity = ticketSaleQuantity.filter(
          (ticket) => ticket.id === currentValue.id
        )[0].quantity;
        return {
          id: currentValue.id,
          eventId: currentValue.event.id,
          quantity:
            Number(currentValue.id) === Number(id)
              ? direction
                ? quantity <
                  Number(
                    tickets?.data?.tickets?.filter(
                      (ticket) => Number(ticket.id) === Number(currentValue.id)
                    )[0]?.amount
                  )
                  ? quantity + 1
                  : quantity
                : quantity
                ? quantity - 1
                : quantity
              : quantity
        };
      })
    );
    sessionStorage.setItem(
      'quantity_cart',
      JSON.stringify(
        tickets?.data?.tickets?.map(function callback(
          currentValue,
          index,
          array
        ) {
          var quantity = ticketSaleQuantity.filter(
            (ticket) => ticket.id === currentValue.id
          )[0].quantity;
          return {
            id: currentValue.id,
            eventId: currentValue.event.id,
            quantity:
              Number(currentValue.id) === Number(id)
                ? direction
                  ? quantity <
                    Number(
                      tickets?.data?.tickets?.filter(
                        (ticket) =>
                          Number(ticket.id) === Number(currentValue.id)
                      )[0]?.amount
                    )
                    ? quantity + 1
                    : quantity
                  : quantity
                  ? quantity - 1
                  : quantity
                : quantity
          };
        })
      )
    );
  };

  const checkCodeFunction = () => {
    let data = [];
    for (let i = 0; i < ticketSaleQuantity.length; i++) {
      if (ticketSaleQuantity[i].quantity) {
        data.push(Number(ticketSaleQuantity[i].id));
      }
    }
    checkCode({ data: { models: data } });
  };

  useEffect(() => {
    var otherEvent =
      eventElvisId !== ticketSaleOpen[0]?.eventId ||
      eventElvisId !== ticketSaleQuantity[0]?.eventId
        ? true
        : false;
    if (otherEvent) {
      navigate(routes.landing);
    } else {
      if (tickets?.data?.tickets.length) {
        var oldEvent = false;
        var ticketThresholdCrossed = false;
        var badData = false;
        for (let i = 0; i < tickets?.data?.tickets.length; i++) {
          var openFiltered = ticketSaleOpen.filter(
            (item) => item.id === tickets?.data?.tickets[i].id
          );
          var quantityFiltered = ticketSaleQuantity.filter(
            (item) => item.id === tickets?.data?.tickets[i].id
          );
          if (openFiltered?.length) oldEvent = true;
          if (quantityFiltered[0]?.quantity > tickets?.data?.tickets[i].amount)
            ticketThresholdCrossed = true;
          if (
            typeof openFiltered[0]?.id !== 'number' &&
            typeof openFiltered[0]?.saleOpen !== 'boolean'
          )
            badData = true;
          if (
            typeof quantityFiltered[0]?.id !== 'number' &&
            typeof quantityFiltered[0]?.quantity !== 'number'
          )
            badData = true;
        }
        if (
          ticketSaleOpen.length === 0 ||
          !oldEvent ||
          ticketThresholdCrossed ||
          badData ||
          otherEvent
        ) {
          setTicketSaleOpen(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                saleOpen: false
              };
            })
          );
          sessionStorage.setItem(
            'opened_cart',
            JSON.stringify(
              tickets?.data?.tickets?.map(function callback(
                currentValue,
                index,
                array
              ) {
                return {
                  id: currentValue.id,
                  eventId: currentValue.event.id,
                  saleOpen: false
                };
              })
            )
          );
        }
        if (
          ticketSaleQuantity.length === 0 ||
          !oldEvent ||
          ticketThresholdCrossed ||
          badData ||
          otherEvent
        ) {
          setTicketSaleQuantity(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                quantity: 0
              };
            })
          );
          sessionStorage.setItem(
            'quantity_cart',
            JSON.stringify(
              tickets?.data?.tickets?.map(function callback(
                currentValue,
                index,
                array
              ) {
                return {
                  id: currentValue.id,
                  eventId: currentValue.event.id,
                  quantity: 0
                };
              })
            )
          );
        }
      }
    }
  }, [tickets]);

  useEffect(() => {
    if (ticketSaleQuantity && tickets?.data?.tickets?.length) {
      var total = 0;
      for (let i = 0; i < tickets?.data?.tickets.length; i++) {
        var quantityFiltered = ticketSaleQuantity.filter(
          (item) => Number(item.id) === Number(tickets?.data?.tickets[i].id)
        )[0];
        if (
          !!finalCode?.amount &&
          finalCode?.models.includes(Number(quantityFiltered.id))
        ) {
          total =
            total +
            (quantityFiltered?.quantity *
              Number(tickets?.data?.tickets[i].price) *
              (100 - Number(finalCode.amount))) /
              100;
        } else {
          total =
            total +
            quantityFiltered?.quantity *
              Number(tickets?.data?.tickets[i].price);
        }
      }
      setTotalPrice(total.toFixed(2));
    }
  }, [ticketSaleQuantity, tickets, finalCode]);

  useEffect(() => {
    manualValidateForm();
    if (searchParams.get('error')) {
      setPopup({ state: 0, text: t('ticket.sellTicketFail') });
    }
    const param = searchParams.get('error');
    if (param) {
      searchParams.delete('error');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  const fields = [
    { id: 0, title: 'landingElvis.checkoutForm1', field: 'name' },
    { id: 1, title: 'landingElvis.checkoutForm2', field: 'surname' },
    { id: 2, title: 'landingElvis.checkoutForm3', field: 'email' },
    { id: 3, title: 'landingElvis.checkoutForm4', field: 'id' },
    { id: 4, title: 'landingElvis.checkoutForm5', field: 'phone' },
    { id: 5, title: 'landingElvis.checkoutForm6', field: 'country' },
    { id: 6, title: 'landingElvis.checkoutForm7', field: 'province' },
    { id: 7, title: 'landingElvis.checkoutForm8', field: 'city' },
    { id: 8, title: 'landingElvis.checkoutForm9', field: 'zip' },
    { id: 9, title: 'landingElvis.checkoutForm10', field: 'address' },
    { id: 10, title: 'landingElvis.checkoutForm11', field: 'number' }
  ];

  const handlePageOne = () => {
    setPage(1);
  };
  const handlePageTwo = () => {
    setPage(2);
  };

  useEffect(() => {
    if (page === 1) {
      const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
      scrollToTop();
      setTimeout(() => {
        manualValidateForm({});
      }, 6000);
    }
    if (page === 2) {
      const scrollToTop = () => {
        window.scrollTo(0, 0);
      };
      scrollToTop();
    }
  }, [page]);

  const manualValidateForm = async (values) => {
    try {
      await validateYupSchema(values, validationSchema, true);
      setValidationErrors(false); // Sin errores de validación
    } catch (error) {
      setValidationErrors(true); // Almacena los errores de validación en el estado
    }
  };

  return (
    <main
      id={`checkout-main-${eventElvisId}`}
      className={`checkout-main d-flex flex-column justify-content-between align-items-center`}
    >
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      <div
        className={`ticket-sale-detail-title d-flex align-items-center`}
        onClick={
          page
            ? () => setPage(page - 1)
            : () => navigate(`${routes.landing}/${eventElvisId}`)
        }
      >
        {/* arrow back */}
        <AngleLeft />
        <h3>{t('landingElvis.resume')}</h3>
      </div>
      <div
        className={`checkout-main-progress d-flex justify-content-between align-items-center`}
      >
        <div
          className={`checkout-main-progress-line d-flex align-items-center`}
        ></div>
        <div
          className={`checkout-main-progress-div circle-active d-flex flex-column justify-content-between align-items-center`}
        >
          <div
            className={`checkout-main-progress-circle d-flex justify-content-center align-items-center`}
          >
            1
          </div>
          <p>{t('landingElvis.saleProgress1')}</p>
        </div>
        <div
          className={`checkout-main-progress-div ${
            page === 1 || page === 2 ? 'circle-active' : ''
          } d-flex flex-column justify-content-between align-items-center`}
        >
          <div
            className={`checkout-main-progress-circle d-flex justify-content-center align-items-center`}
          >
            2
          </div>
          <p>{t('landingElvis.saleProgress2')}</p>
        </div>
        <div
          className={`checkout-main-progress-div ${
            page === 2 ? 'circle-active' : ''
          } d-flex flex-column justify-content-between align-items-center`}
        >
          <div
            className={`checkout-main-progress-circle d-flex justify-content-center align-items-center`}
          >
            3
          </div>
          <p>{t('landingElvis.saleProgress3')}</p>
        </div>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={manualValidateForm}
        validateOnBlur={true}
        onSubmit={(values, actions) => {
          //submitTicketerForm(values, actions);
          if (!ticketResaleId) {
            let data = [];
            for (let i = 0; i < ticketSaleQuantity.length; i++) {
              if (ticketSaleQuantity[i].quantity) {
                data.push({
                  model: Number(ticketSaleQuantity[i].id),
                  quantity: Number(ticketSaleQuantity[i].quantity)
                });
              }
            }
            stripeInfo({ data: data, values: values });
          } else {
            stripeInfo({ values: values });
          }
        }}
      >
        {(props) => {
          const {
            errors,
            touched,
            values,
            setFieldTouched,
            submitForm,
            validateForm
          } = props;
          i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });

          return (
            <div
              className={`checkout-main-container d-flex flex-column justify-content-between align-items-center`}
            >
              {page !== 1 ? (
                <>
                  <HomeEventHorizontal
                    key={null}
                    item={events?.data}
                    function={null}
                    elvis={true}
                    landing={true}
                    secondaryMarketPrice={
                      ticketResaleId ? resaleTicket?.data?.price : null
                    }
                    secondaryMarketCurrency={
                      ticketResaleId ? resaleTicket?.data?.currency : null
                    }
                  />
                </>
              ) : null}
              {/* CON DISCOUNT */}
              {page === 0 ? (
                <>
                  {!ticketResaleId ? (
                    <>
                      <div
                        className={`checkout-main-field d-flex flex-column justify-content-between align-items-center`}
                      >
                        <h3>{t('landingElvis.checkoutTitle1')}</h3>
                        {ticketSaleQuantity?.every(
                          (option) => option.quantity === 0
                        ) ? (
                          <p>{t('ticket.noSelectedTickets')}</p>
                        ) : (
                          ticketSaleOpen.map(
                            (item, index) =>
                              ticketSaleQuantity[index].quantity > 0 && (
                                <CheckoutTickets
                                  key={item.id}
                                  id={tickets?.data?.tickets[index].id}
                                  category={
                                    tickets?.data?.tickets[index].category?.name
                                  }
                                  price={tickets?.data?.tickets[index].price}
                                  quantity={ticketSaleQuantity[index].quantity}
                                  currency={
                                    tickets?.data?.tickets[index].currency
                                  }
                                  changeQuantity={changeQuantity}
                                />
                              )
                          )
                        )}
                      </div>
                      <div
                        className={`checkout-main-field d-flex flex-column justify-content-between align-items-center`}
                      >
                        <h3>{t('landingElvis.checkoutTitle2')}</h3>
                        <CheckoutDiscount
                          code={code}
                          setCode={setCode}
                          function={() => checkCodeFunction()}
                        />
                      </div>
                    </>
                  ) : (
                    <CheckoutTicketSecondary
                      id={resaleTicket?.data?.id}
                      ticket={resaleTicket?.data}
                      owner={ticketingCompany}
                    />
                  )}
                  <div
                    className={`checkout-main-field d-flex flex-column justify-content-between align-items-center`}
                  >
                    <h3>{t('landingElvis.checkoutTitle3')}</h3>
                    <CheckoutFinal
                      tickets={
                        ticketResaleId
                          ? resaleTicket?.data
                          : tickets?.data?.tickets
                      }
                      ticketSaleOpen={ticketResaleId ? null : ticketSaleOpen}
                      ticketSaleQuantity={
                        ticketResaleId ? null : ticketSaleQuantity
                      }
                      code={ticketResaleId ? null : finalCode}
                      secondary={ticketResaleId ? true : false}
                      totalPrice={
                        ticketResaleId
                          ? Number(resaleTicket?.data?.price)
                          : totalPrice
                      }
                    />
                  </div>
                </>
              ) : page === 1 ? (
                <>
                  <div
                    className={`checkout-main-form d-flex flex-column justify-content-between align-items-center`}
                  >
                    {fields.map((item, index) => {
                      return (
                        <LandingCheckoutFormField
                          key={item.id}
                          title={item.title}
                          error={errors[item.field]}
                          touched={touched[item.field]}
                          name={item.field}
                        />
                      );
                    })}
                  </div>
                </>
              ) : (
                page === 2 && (
                  <>
                    {!ticketResaleId ? null : (
                      <CheckoutTicketSecondary
                        id={resaleTicket?.data?.id}
                        ticket={resaleTicket?.data}
                        owner={ticketingCompany}
                      />
                    )}
                    <div
                      className={`checkout-main-field d-flex flex-column justify-content-between align-items-center`}
                    >
                      <h3>{t('landingElvis.checkoutTitle4')}</h3>
                      <CheckoutResume
                        values={values}
                        function={() => {
                          setPage(1);
                        }}
                      />
                    </div>
                    <div
                      className={`checkout-main-field d-flex flex-column justify-content-between align-items-center`}
                    >
                      <h3>{t('landingElvis.checkoutTitle5')}</h3>
                      <CheckoutFinal
                        tickets={
                          ticketResaleId
                            ? resaleTicket?.data
                            : tickets?.data?.tickets
                        }
                        ticketSaleOpen={ticketResaleId ? null : ticketSaleOpen}
                        ticketSaleQuantity={
                          ticketResaleId ? null : ticketSaleQuantity
                        }
                        code={ticketResaleId ? null : finalCode}
                        secondary={ticketResaleId ? true : false}
                        totalPrice={
                          ticketResaleId
                            ? Number(resaleTicket?.data?.price)
                            : totalPrice
                        }
                      />
                    </div>
                    <AdviceFooter
                      footer={{
                        text: (
                          <div
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline'
                            }}
                            onClick={() =>
                              setTermsLandingModal(
                                companyApiKey?.data?.terms ?? null
                              )
                            }
                          >
                            {t('landingElvis.resumeFooter', {
                              terms: 'Términos y Condiciones',
                              ticketera: subdomain
                            })}
                          </div>
                        ),
                        icon: <FileList />
                      }}
                    />
                  </>
                )
              )}
              {ticketSaleQuantity
                ?.map((item) => item.quantity)
                ?.reduce((prev, next) => prev + next) ||
              !!resaleTicket?.data ? (
                !paymentIsLoading ? (
                  page === 0 ? (
                    <TermsPolicyButton
                      text={t('landingElvis.next')}
                      disabled={false}
                      function={() => {
                        handlePageOne();
                      }}
                    />
                  ) : page === 1 ? (
                    <TermsPolicyButton
                      text={t('landingElvis.next')}
                      disabled={Object.keys(errors).length > 0}
                      function={() => {
                        manualValidateForm();
                        validateForm().then((errors) => {
                          if (!errors || !Object.keys(errors).length > 0) {
                            handlePageTwo();
                          }
                        });
                      }}
                    />
                  ) : (
                    page === 2 && (
                      <TermsPolicyButton
                        text={t('landingElvis.next')}
                        disabled={false}
                        function={() => submitForm()}
                      />
                    )
                  )
                ) : null
              ) : null}
            </div>
          );
        }}
      </Formik>
    </main>
  );
}
