import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import routes from '../../routes/routes';
import { getSubdomain } from '../shared/functions.js';
import RegisterButton from '../ui/RegisterButton';
import Google from '../icons/Google';
import Check from '../icons/Check';
import { loginAdmin, loginUser } from '../../services/axiosService';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';

function RegisterEmail(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const subdomain = useMemo(() => getSubdomain(), []);
  var navigate = useNavigate();
  var location = useLocation().pathname;

  const {
    state: authState,
    userData,
    setUserData,
    ticketingCompany,
    publicElvisId,
    registerWithGoogle,
    setFirebaseLoading
  } = React.useContext(AppContext);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('register.error1'))
      .required(t('register.error2'))
  });

  const { isLoading, isSuccess, isError, error, mutate } = useMutation(
    subdomain
      ? (values) => loginUser(values.email, subdomain, publicElvisId)
      : (values) => loginAdmin(values.email),
    {
      onSuccess: (res) => {
        if (res.data.token) {
          setUserData({ ...userData, userId: res.data.userId });
          setTimeout(() => {
            navigate(subdomain ? location + '/sent' : routes.authEmailOk);
          }, 750);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const googleButtonFunction = () => {
    setFirebaseLoading(true);
    sessionStorage.setItem('authOngoing', JSON.stringify(true));
    registerWithGoogle();
  };

  return (
    <section
      id="register-email-section"
      className="register-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <h3>
        {t('register.title') +
          (subdomain
            ? ticketingCompany.data[0]
              ? ticketingCompany.data[0]?.comercialName
              : ''
            : 'Tibet')}
      </h3>
      <Formik
        // *** Initial values that the form will take
        enableReinitialize={true}
        initialValues={{ email: '' }}
        // *** Yup Validation Schema ***
        validationSchema={loginSchema}
        validateOnChange={true}
        validateOnBlur={true}
        // ** onSubmit Event
        onSubmit={(values, actions) => {
          mutate(values);
        }}
      >
        {/* We obtain props from Formik */}
        {(props) => {
          const {
            dirty,
            touched,
            errors,
            isValid,
            setFieldTouched,
            isSubmitting
          } = props;
          i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });
          return (
            <Form className="register-email-form d-flex flex-column">
              <h5>{t('register.mail')}</h5>
              {isValid && dirty ? <Check /> : null}
              <Field
                className={
                  errors.email && touched.email
                    ? 'register-email-form-email red-border'
                    : dirty
                    ? 'register-email-form-email purple-border'
                    : 'register-email-form-email'
                }
                id="register-email-form-email"
                type="email"
                name="email"
                placeholder=""
              />
              {errors.email && touched.email ? (
                <ErrorMessage name="email">
                  {(msg) => (
                    <div className="register-email-form-email-error mb-2">
                      {msg}
                    </div>
                  )}
                </ErrorMessage>
              ) : (
                <div className=""></div>
              )}
              {isValid && !isLoading && !isSuccess ? (
                <button
                  disabled={
                    isLoading ? true : isSuccess ? true : isValid ? false : true
                  }
                  id="register-email-form-button"
                  type="submit"
                  className="register-email-form-button"
                >
                  {t('register.send')}
                </button>
              ) : (
                <button
                  id="register-email-form-button"
                  type="button"
                  className={
                    isSuccess
                      ? 'register-email-form-button register-ok-button'
                      : 'register-email-form-button'
                  }
                >
                  {isLoading ? (
                    <SpinnerPurple />
                  ) : isSuccess ? (
                    <Check />
                  ) : (
                    t('register.send')
                  )}
                </button>
              )}
              {isError ? (
                <div className="register-email-form-email-error">
                  {t('register.mailError') + ' ' + error}
                </div>
              ) : null}
            </Form>
          );
        }}
      </Formik>
      <hr></hr>
      <RegisterButton
        icon={<Google />}
        text={t('register.google')}
        function={googleButtonFunction}
      />
      {/*<RegisterButton icon = { <Facebook/> } text = { t('register.facebook') } function = { loginFb }/>*/}
    </section>
  );
}

export default RegisterEmail;
