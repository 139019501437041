import React from 'react';
import Sidebar from '../../components/shared/Sidebar';
import { useLocation } from 'react-router-dom';
import DashboardFooter from '../../components/shared/DashboardFooter';

const StripeAccountCancelLayout = (props) => {
  const location = useLocation();
  return (
    <main
      className={`dashboard-main d-flex flex-column flex-lg-row justify-content-between align-items-start`}
    >
      <Sidebar />
      <div
        className={`dashboard-pages ${
          location.pathname.startsWith('/dashboard/events')
            ? 'events-mobile'
            : ''
        } d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-start`}
      >
        {props.main}
      </div>
      <DashboardFooter />
    </main>
  );
};

export default StripeAccountCancelLayout;
