import React, { useEffect } from "react";
import Navbar from "../../components/shared/Navbar";
import Footer from "../../components/shared/Footer";
import TibetLandingTermsComponent from "../../components/landing/TibetLandingTermsComponent";

export default function LandingTerms() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar/>
      <main className="landing-main faq-main d-flex flex-column justify-content-center align-items-center">
        <TibetLandingTermsComponent/>
        <Footer landing={ false }/>
      </main>
    </>
  )

}
