import React from "react";

function Gift(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.30005 13.4999H11.25V20.6999C11.25 20.8659 11.116 20.9999 10.95 20.9999H7C5 20.9999 4 19.9999 4 17.9999V13.7999C4 13.6339 4.13405 13.4999 4.30005 13.4999ZM19.7 13.4999H12.75V20.6999C12.75 20.8659 12.884 20.9999 13.05 20.9999H17C19 20.9999 20 19.9999 20 17.9999V13.7999C20 13.6339 19.866 13.4999 19.7 13.4999ZM21 8.49991V11.6999C21 11.8659 20.866 11.9999 20.7 11.9999H12.75V8.49991H11.25V11.9999H3.30005C3.13405 11.9999 3 11.8659 3 11.6999V8.49991C3 7.67191 3.672 6.99991 4.5 6.99991H5.56494C5.24594 6.38191 5.12701 5.6449 5.41101 4.8439C5.69401 4.0459 6.33995 3.38493 7.14795 3.13193C8.26595 2.78193 9.416 3.1479 10.12 4.0029C10.152 4.0419 11.277 5.53889 12.001 6.50089C12.724 5.53889 13.848 4.0449 13.875 4.0109C14.584 3.1489 15.724 2.78589 16.839 3.12589C17.619 3.36489 18.253 3.98291 18.553 4.74191C18.885 5.58191 18.769 6.35493 18.437 6.99893H19.502C20.328 6.99993 21 7.67191 21 8.49991ZM10.498 6.99991C9.82105 6.09991 9.003 5.01189 8.948 4.94089C8.719 4.66189 8.378 4.49991 8 4.49991C7.311 4.49991 6.75 5.06091 6.75 5.74991C6.75 6.43891 7.311 6.99991 8 6.99991H10.498ZM17.25 5.74991C17.25 5.06091 16.689 4.49991 16 4.49991C15.622 4.49991 15.2809 4.66188 15.0389 4.95688C14.9919 5.01888 14.177 6.10191 13.502 6.99991H16C16.689 6.99991 17.25 6.43891 17.25 5.74991Z" fill="#816CFF"/>
    </svg>
  );
}

export default Gift;