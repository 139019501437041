import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  chooseSubscriptionMode,
  getStripeInfo,
  getSubscriptionsType,
  getTicketingCompanyById
} from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import GlobeSmall from '../../components/icons/GlobeSmall.jsx';
import TermsPolicyButton from '../../components/ui/TermsPolicyButton.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import SettingsSmall from '../../components/icons/SettingsSmall';
import TicketerButton from '../../components/ui/TicketerButton';
import axios from 'axios';

function SimulateSubscription(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState } = React.useContext(AppContext);
  const [initSubscription, setInitSubscription] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState(null);

  var navigate = useNavigate();

  const {
    isLoading,
    isError,
    error,
    data: subscriptionsMode,
    isFetching
  } = useQuery({
    queryKey: ['simulateStripeSubscriptions'],
    queryFn: () => getSubscriptionsType(authState.token),
    enabled: !!authState.isAuthenticated && !!initSubscription,
    onSuccess: (res) => {
      setSubscriptionOptions(res.data);
      setSelectedSubscription(res.data[0].id);
    },
    onError: (err) => {
      console.log(err);
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    modeIsLoading,
    modeIsError,
    modeError,
    data: ticketingCompany,
    modeIsFetching
  } = useQuery({
    queryKey: ['simulateStripeMode'],
    queryFn: () => chooseSubscriptionMode(authState.token, subscriptionId),
    enabled: !!authState.isAuthenticated && !!subscriptionId,
    onSuccess: (res) => {
      let redirectUrl = res.data.checkout_url;
      window.location.href = redirectUrl;
    },
    onError: (err) => {
      console.log(err);
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  function handleSubscription(subscriptionId) {
    setSubscriptionId(subscriptionId);
  }

  return (
    <TicketingSectionComponent>
      <div className="ticketer-sections-domain-container d-flex flex-wrap justify-content-center align-items-center">
        {isLoading && subscriptionOptions.length == 0 && (
          <TicketerButton
            text={'Iniciar simulación de subscripcion'}
            function={() => setInitSubscription(true)}
          />
        )}

        {!isLoading && subscriptionOptions.length > 0 && (
          <div>
            <select
              value={selectedSubscription}
              onChange={(e) => setSelectedSubscription(e.target.value)}
            >
              {subscriptionOptions.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </select>
            <button onClick={() => handleSubscription(selectedSubscription)}>
              Elegir subscripción
            </button>
          </div>
        )}
      </div>
      <ButtonGroupTibet button={null} />
    </TicketingSectionComponent>
  );
}

export default SimulateSubscription;
