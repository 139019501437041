import React from "react";

function Envelope(props) {

  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5 6.25H7.5C5 6.25 3.75 7.5 3.75 10V21.25C3.75 23.75 5 25 7.5 25H22.5C25 25 26.25 23.75 26.25 21.25V10C26.25 7.5 25 6.25 22.5 6.25ZM22.4261 12.0075L16.2863 16.4725C15.9013 16.7525 15.45 16.8925 15 16.8925C14.55 16.8925 14.0974 16.7525 13.7137 16.4738L7.57385 12.0075C7.1551 11.7038 7.06255 11.1162 7.36755 10.6975C7.6713 10.28 8.25487 10.185 8.67737 10.4913L14.8175 14.9562C14.9275 15.035 15.0737 15.0362 15.1837 14.9562L21.3239 10.4913C21.7451 10.185 22.3299 10.28 22.6337 10.6975C22.9387 11.1175 22.8449 11.7038 22.4261 12.0075Z" fill="#9A9FA5"/>
    </svg>
  );
}

export default Envelope;