import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { faqsId } from '../../services/axiosService';
import { AppContext } from '../../App';
import { Link, useParams } from 'react-router-dom';
import routes from '../../routes/routes';
import ArrowRight from '../icons/ArrowRight';

const FaqsComponentId = () => {
  const { state: authState } = React.useContext(AppContext);
  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ['faqsid'],
    queryFn: () => faqsId(authState.token, id),
    onError: (err) => {
      console.error(err.response.status);
    }
  });

  return (
    <div className="ticketer-section d-flex flex-column justify-content-start">
      <div className="d-flex gap-1 align-items-center">
        <span className="faqsid-link">
          <Link to={routes.faqs}>
            <ArrowRight />
            Preguntas Frequentes / {data && data?.data?.category?.name}
          </Link>{' '}
        </span>
        / <span className="breadcumb-faq">{data && data?.data?.question}</span>
      </div>
      {data?.data && (
        <div className="faqsid">
          <span className="faqsid-header">{data?.data?.question}</span>
          <div style={{ marginTop: '2rem' }}>
            <div
              className="faqsid-answer"
              dangerouslySetInnerHTML={{ __html: data?.data?.answer }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqsComponentId;
