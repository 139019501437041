import React from "react";

function EventCard1Svg(props) {

  return (
    <svg width="130" height="88" viewBox="0 0 130 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2698 -18.8666C12.6266 -34.2361 13.8594 -36.5181 27.3213 -39.6141C31.1487 -40.4199 34.7558 -42.0475 37.8934 -44.3847C41.031 -46.7219 43.6244 -49.7129 45.4944 -53.1512C51.9391 -63.8581 55.6521 -65.0834 67.2198 -60.1213C70.9175 -58.4199 74.9421 -57.5481 79.0118 -57.5669C83.0814 -57.5858 87.0978 -58.4948 90.7796 -60.2303C102.288 -65.1642 105.978 -63.9332 112.432 -53.0616C114.333 -49.6346 116.942 -46.6527 120.085 -44.3147C123.228 -41.9767 126.833 -40.3364 130.66 -39.5032C144.156 -36.1644 146.068 -33.3878 144.456 -19.8163C143.516 -11.9045 146.115 -5.80002 151.091 0.0490723C160.3 10.8744 160.333 13.9663 151.011 24.9905C148.372 27.8954 146.428 31.3632 145.327 35.1314C144.226 38.8995 143.996 42.869 144.655 46.7393C146.147 57.2115 143.286 61.8824 133.229 63.9386C123.231 65.9827 116.108 70.6783 111.433 79.7323C107.292 87.7535 100.66 90.1371 92.4217 86.0073C88.2884 83.7492 83.6564 82.5619 78.9476 82.5536C74.2387 82.5452 69.6026 83.7162 65.4614 85.9597C57.2767 90.1015 50.5888 87.8601 46.4362 79.7798C44.4079 75.5376 41.3449 71.8751 37.5297 69.1301C33.7145 66.3851 29.2701 64.646 24.6064 64.0734C16.1539 62.6843 11.8408 57.1235 13.1052 48.5048C13.9693 43.7378 13.6266 38.8302 12.1084 34.2299C10.5902 29.6296 7.94489 25.4832 4.41394 22.1694C-2.0691 15.6291 -1.02653 8.52244 5.03831 2.06672C10.8847 -4.15645 15.0439 -10.9146 13.2698 -18.8666Z" fill="#B5A9FF"/>
    </svg>
  );
}

export default EventCard1Svg;