import React from "react";

function TicketerEventBanner(props) {

  return (
    <svg preserveAspectRatio="none" viewBox="0 0 1440 477" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3276_210533)">
        <rect width="100%" height="100%" fill="url(#paint0_linear_3276_210533)"/>
        <path d="M1407.78 -131.383C1366.25 -109.544 1365.6 -23.7204 1399.9 81.8265C1354.75 41.4382 1312.84 23.9124 1284.6 38.7633C1243.07 60.6029 1242.42 146.426 1276.72 251.973C1231.57 211.585 1189.67 194.059 1161.42 208.91C1119.89 230.749 1119.25 316.572 1153.54 422.119C1108.39 381.731 1066.49 364.205 1038.25 379.056C996.718 400.896 996.072 486.719 1030.37 592.266C985.218 551.877 943.314 534.352 915.073 549.203C873.542 571.042 872.896 656.865 907.192 762.412C862.041 722.024 820.139 704.498 791.897 719.349C734.555 749.502 755.125 901.536 837.904 1058.95C920.684 1216.37 1034.28 1319.49 1091.63 1289.33C1133.16 1267.49 1133.8 1181.67 1099.51 1076.12C1144.66 1116.51 1186.56 1134.04 1214.8 1119.19C1256.33 1097.35 1256.98 1011.52 1222.68 905.976C1267.83 946.364 1309.74 963.89 1337.98 949.039C1379.51 927.199 1380.16 841.376 1345.86 735.829C1391.01 776.218 1432.91 793.743 1461.15 778.892C1502.69 757.053 1503.33 671.23 1469.03 565.683C1514.19 606.071 1556.09 623.597 1584.33 608.746C1625.86 586.907 1626.51 501.083 1592.21 395.536C1637.36 435.925 1679.26 453.45 1707.51 438.6C1764.85 408.446 1744.28 256.413 1661.5 98.9943C1578.72 -58.424 1465.18 -161.567 1407.78 -131.383Z" fill="#B2A6FF"/>
        <path d="M259.788 287.714C143.156 127.079 -81.0417 91.2085 -241.001 207.349L181.241 788.9C341.2 672.76 376.419 448.35 259.788 287.714Z" fill="#6B53FF"/>
        <path d="M-131.758 576.574L-142.959 21.5871C8.78911 19.6031 134.038 140.844 137.119 293.482C140.2 446.121 19.9462 572.433 -131.758 576.574Z" stroke="#563EEA" strokeWidth="4"/>
      </g>
      <defs>
        <filter id="filter0_ddi_3276_210533" x="1170" y="31" width="205" height="78" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="7.5"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3276_210533"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_3276_210533" result="effect2_dropShadow_3276_210533"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3276_210533" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="7.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
          <feBlend mode="normal" in2="shape" result="effect3_innerShadow_3276_210533"/>
        </filter>
        <linearGradient id="paint0_linear_3276_210533" x1="1488.81" y1="-22.0018" x2="718.056" y2="945.548" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EBE8FF"/>
          <stop offset="1" stopColor="#432EC9"/>
        </linearGradient>
        <clipPath id="clip0_3276_210533">
          <rect width="100%" height="100%" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TicketerEventBanner;