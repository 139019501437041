import React from "react";

function LikeCircle(props) {

  return (
    <svg width="122" height="132" viewBox="0 0 122 132" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M34.4998 0.416016C-13.5963 0.416016 -52.5835 39.4032 -52.5835 87.4994C-52.5835 135.595 -13.5963 174.583 34.4998 174.583C82.596 174.583 121.583 135.595 121.583 87.4994C121.583 39.4032 82.596 0.416016 34.4998 0.416016ZM8.37484 121.897C8.37484 123.343 7.2075 124.51 5.76192 124.51H0.0151825C-3.78165 124.51 -6.86474 121.427 -6.86474 117.63V85.4094C-6.86474 81.6213 -3.77295 78.5295 0.0151825 78.5295H5.76192C7.2075 78.5295 8.37484 79.6969 8.37484 81.1424V121.897ZM74.7238 87.4994L65.4925 115.105C63.6637 120.678 61.6608 124.51 52.43 124.51H19.6865C18.241 124.51 17.0747 123.343 17.0747 121.897V76.0739C17.0747 75.7952 17.1183 75.5253 17.2054 75.2554L26.6536 46.4829C27.7857 43.5221 30.5727 41.7806 33.5335 41.7806C34.9269 41.7806 36.3199 42.1291 37.539 42.9999C41.3707 45.5253 43.6345 49.8795 43.6345 54.495V69.3859H61.6613C75.4205 69.3859 77.6846 78.6169 74.7238 87.4994Z" fill="#EBEBEB"/>
    </svg>
  );
}

export default LikeCircle;