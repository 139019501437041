import React from "react";
import { useTranslation } from "react-i18next";

export default function CheckoutResume(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div
      className={`checkout-resume d-flex flex-column justify-content-between align-items-center`}
    >
      <div className={`checkout-resume-field d-flex flex-column`}>
        <h4>{t("landingElvis.checkoutForm1")}</h4>
        <h3>{props.values.name}</h3>
      </div>
      <div className={`checkout-resume-field d-flex flex-column`}>
        <h4>{t("landingElvis.checkoutForm2")}</h4>
        <h3>{props.values.surname}</h3>
      </div>
      <div className={`checkout-resume-field d-flex flex-column`}>
        <h4>{t("landingElvis.checkoutForm4")}</h4>
        <h3>{props.values.id}</h3>
      </div>
      <div className={`checkout-resume-field d-flex flex-column`}>
        <h4>{t("landingElvis.checkoutForm3")}</h4>
        <h3>{props.values.email}</h3>
      </div>
      <div className={`checkout-resume-field d-flex flex-column`}>
        <h4>{t("landingElvis.checkoutForm5")}</h4>
        <h3>{props.values.phone}</h3>
      </div>
      <div className={`checkout-resume-field d-flex justify-content-end`}>
        <h4 onClick={props.function}>{t("landingElvis.edit")}</h4>
      </div>
    </div>
  );
}
