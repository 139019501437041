import React from "react";

function GalleryPlus(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.448 8.10774L21.248 16.6888C21.091 17.7818 20.664 18.5578 19.991 19.0008C19.758 19.1208 19.5031 18.9999 19.5031 18.7569V10.1658C19.5031 7.55684 17.9451 5.99885 15.3361 5.99885C15.3361 5.99885 6.22395 5.99885 5.92395 5.99885C5.62395 5.99885 5.61694 5.72871 5.63794 5.60871C5.79594 4.66271 6.17 3.9627 6.75 3.5257C7.357 3.0687 8.17702 2.9098 9.19202 3.0528L19.7531 4.53278C20.7701 4.67678 21.518 5.05784 21.976 5.66584C22.433 6.27284 22.592 7.09474 22.448 8.10774ZM18.002 10.1649V18.8328C18.002 20.6108 17.113 21.4998 15.335 21.4998H4.66699C2.88899 21.4998 2 20.6108 2 18.8328V10.1649C2 8.38687 2.88899 7.49787 4.66699 7.49787H15.335C17.114 7.49787 18.002 8.38687 18.002 10.1649ZM13.25 14.4998C13.25 14.0858 12.914 13.7498 12.5 13.7498H10.75V11.9998C10.75 11.5858 10.414 11.2498 10 11.2498C9.586 11.2498 9.25 11.5858 9.25 11.9998V13.7498H7.5C7.086 13.7498 6.75 14.0858 6.75 14.4998C6.75 14.9138 7.086 15.2498 7.5 15.2498H9.25V16.9998C9.25 17.4138 9.586 17.7498 10 17.7498C10.414 17.7498 10.75 17.4138 10.75 16.9998V15.2498H12.5C12.914 15.2498 13.25 14.9138 13.25 14.4998Z" fill="#816CFF"/>
    </svg>
  );
}

export default GalleryPlus;