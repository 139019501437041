import React from "react";
import { useTranslation } from "react-i18next";
import TermsPolicyButton from "./TermsPolicyButton";

export default function CheckoutDiscount(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const codeChange = (e) => {
    e.preventDefault();
    props.setCode(e.target.value);
  };

  return (
    <div
      className={`checkout-discount-code d-flex flex-column justify-content-between align-items-center`}
    >
      <h4>{t("landingElvis.codeTitle")}</h4>
      <input
        type="text"
        className="ticketer-field"
        name="dicount"
        placeholder={t("landingElvis.codePlaceholder")}
        onChange={(e) => codeChange(e)}
      ></input>
      <TermsPolicyButton
        text={t("landingElvis.codeApply")}
        disabled={props.code === "" ? true : false}
        function={props.function}
      />
    </div>
  );
}
