import React from "react";

function TrashPlus(props) {

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.06 8.25H5.94C5.767 8.25 5.62899 8.39701 5.64099 8.57001L6.31 18.7C6.42 20.28 7.25 21.5 9.31 21.5H15.69C17.75 21.5 18.58 20.28 18.69 18.7L19.359 8.57001C19.37 8.39701 19.233 8.25 19.06 8.25ZM15 15.25H13.25V17C13.25 17.414 12.914 17.75 12.5 17.75C12.086 17.75 11.75 17.414 11.75 17V15.25H10C9.586 15.25 9.25 14.914 9.25 14.5C9.25 14.086 9.586 13.75 10 13.75H11.75V12C11.75 11.586 12.086 11.25 12.5 11.25C12.914 11.25 13.25 11.586 13.25 12V13.75H15C15.414 13.75 15.75 14.086 15.75 14.5C15.75 14.914 15.414 15.25 15 15.25ZM21.25 6.5C21.25 6.914 20.914 7.25 20.5 7.25H4.5C4.086 7.25 3.75 6.914 3.75 6.5C3.75 6.086 4.086 5.75 4.5 5.75H8.71399C8.80699 5.568 8.87899 5.362 8.95599 5.132L9.15799 4.52499C9.36199 3.91299 9.93499 3.5 10.581 3.5H14.419C15.065 3.5 15.638 3.91299 15.842 4.52499L16.044 5.132C16.121 5.362 16.193 5.568 16.286 5.75H20.5C20.914 5.75 21.25 6.086 21.25 6.5Z" fill="#816CFF"/>
    </svg>
  );
}

export default TrashPlus;