import React from "react";

function CouponHeart(props) {

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.25 15.832V11.082C33.25 7.91536 31.6667 6.33203 28.5 6.33203H9.5C6.33333 6.33203 4.75 7.91536 4.75 11.082V15.832C6.49167 15.832 7.91667 17.257 7.91667 18.9987C7.91667 20.7404 6.49167 22.1654 4.75 22.1654V26.9154C4.75 30.082 6.33333 31.6654 9.5 31.6654H28.5C31.6667 31.6654 33.25 30.082 33.25 26.9154V22.1654C31.5083 22.1654 30.0833 20.7404 30.0833 18.9987C30.0833 17.257 31.5083 15.832 33.25 15.832ZM25.2147 18.2767C24.3882 22.5026 19 24.9362 19 24.9362C19 24.9362 13.6118 22.5026 12.7853 18.2767C12.2787 15.6832 13.3856 13.0754 16.2008 13.0612C18.2971 13.0517 19 15.148 19 15.148C19 15.148 19.7029 13.0501 21.7992 13.0612C24.6192 13.0754 25.7213 15.6832 25.2147 18.2767Z" fill="url(#paint0_linear_4359_23556)" fillOpacity="0.8"/>
      <defs>
        <linearGradient id="paint0_linear_4359_23556" x1="3.52299" y1="8.01755" x2="35.807" y2="48.7984" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7D68FF"/>
          <stop offset="0.229167" stopColor="#8976FF" stopOpacity="0.770833"/>
          <stop offset="1" stopColor="#B2A6FF" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CouponHeart;