import React from "react";

function ImagePlus(props) {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.25 11V17C22.25 19.418 20.918 20.75 18.5 20.75H6.5C4.082 20.75 2.75 19.418 2.75 17V7C2.75 4.582 4.082 3.25 6.5 3.25H13.5C13.914 3.25 14.25 3.586 14.25 4C14.25 4.414 13.914 4.75 13.5 4.75H6.5C4.923 4.75 4.25 5.423 4.25 7V16.25L6.79004 13.71C7.18004 13.32 7.81996 13.32 8.20996 13.71L9.15002 14.6499C9.34002 14.8399 9.65998 14.8399 9.84998 14.6499L14.79 9.70996C15.18 9.31996 15.82 9.31996 16.21 9.70996L20.75 14.25V11C20.75 10.586 21.086 10.25 21.5 10.25C21.914 10.25 22.25 10.586 22.25 11ZM8.49304 7.75C7.80404 7.75 7.25 8.31 7.25 9C7.25 9.69 7.81395 10.25 8.50195 10.25C9.19095 10.25 9.75 9.69 9.75 9C9.75 8.31 9.19195 7.75 8.50195 7.75H8.49304ZM17.5 5.75H18.75V7C18.75 7.414 19.086 7.75 19.5 7.75C19.914 7.75 20.25 7.414 20.25 7V5.75H21.5C21.914 5.75 22.25 5.414 22.25 5C22.25 4.586 21.914 4.25 21.5 4.25H20.25V3C20.25 2.586 19.914 2.25 19.5 2.25C19.086 2.25 18.75 2.586 18.75 3V4.25H17.5C17.086 4.25 16.75 4.586 16.75 5C16.75 5.414 17.086 5.75 17.5 5.75Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default ImagePlus;