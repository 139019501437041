import React from "react";

function HeartElvis(props) {

  return (
    <svg width="151" height="150" viewBox="0 0 151 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="75.5" cy="75" r="75" fill="#EFF0F2"/>
      <path d="M75 30.75C50.8369 30.75 31.25 50.3369 31.25 74.5C31.25 98.6631 50.8369 118.25 75 118.25C99.1631 118.25 118.75 98.6631 118.75 74.5C118.75 50.3369 99.1631 30.75 75 30.75ZM95.4533 74.2901C92.7365 88.3032 75 96.375 75 96.375C75 96.375 57.2635 88.3032 54.5467 74.2901C52.8798 65.6932 56.5202 57.0438 65.7864 57C72.6858 56.965 75 63.9211 75 63.9211C75 63.9211 77.3142 56.965 84.2136 57C93.4973 57.0438 97.1202 65.6932 95.4533 74.2901Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default HeartElvis;