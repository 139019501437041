import React from 'react';
import {
  stripeCheckoutSubscription,
  stripeDeleteSubscription
} from '../../services/axiosService';
import { useMutation, useQuery } from '@tanstack/react-query';
import UpgradePlanIcon from '../icons/UpgradePlanIcon';
import Times from '../icons/Times';
import { AppContext } from '../../App';
import { Stack } from '@mui/material';
import Check from '../icons/Check';

const TYPE_SUBSCRIPTION = {
  FREE: 'free',
  PRO: 'pro',
  EMPRESA: 'empresa'
};

const ModalSubscription = ({ isClosable, data }) => {
  const [subscriptionId, setSubscriptionId] = React.useState(null);
  const { state: authState } = React.useContext(AppContext);

  /* GET SUBSCRIPTION STRIPE */
  const { data: subscriptionDataStripe } = useQuery({
    queryKey: ['stripeSubscription'],
    queryFn: () => stripeCheckoutSubscription(subscriptionId, authState.token),
    enabled: subscriptionId !== null,
    onSuccess: (res) => {
      successCheckout(res.data);
      isClosable();
    },
    onError: (err) => {
      console.log(err);
    }
  });

  /* BUY SUBSCRIPTION */
  const buySubscription = (type, id) => {
    const toslowercase = type.toLowerCase();
    switch (toslowercase) {
      case TYPE_SUBSCRIPTION.PRO:
        setSubscriptionId(id);
        break;
      case TYPE_SUBSCRIPTION.FREE:
        setSubscriptionId(id);
        break;
      case TYPE_SUBSCRIPTION.EMPRESA:
        setSubscriptionId(id);
        break;
      default:
        break;
    }
  };

  const successCheckout = (stripeData) => {
    window.open(stripeData.checkout_url);
  };
  const isCompany = data.name === 'Empresa';
  const isPro = data.name === 'Pro';
  const isFree = data.name === 'Free';

  /* DELETE SUBSCRIPTION */
  const { mutate, isLoading: cancelPlanLoading } = useMutation(
    () => stripeDeleteSubscription(authState.token),
    {
      onSuccess: (res) => {
        if (res.data) {
          isClosable();
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );
  return (
    <div
      id="modal-subscription-section support"
      className="modal-background modal-background modal-background-footer modal-background-ticketer d-flex justify-content-center align-items-center"
    >
      <div className="modal-container">
        <div className="modal-subscription-container subscription-modal-box d-flex flex-column">
          <div className="sub-close-modal">
            <Times function={() => isClosable()} />
          </div>
          <div className="d-flex justify-content-center flex-column align-items-center gap-4">
            <UpgradePlanIcon />
            <span className="sub-title-modal">
              Cancelar suscripción {isCompany && 'Empresa'}
            </span>
            <p>
              Sabemos que quieres cancelar tu suscripción y vamos a extrañarte
              😢
            </p>
          </div>

          {/* HEADER DEL MODAL */}
          <div className="sub-modal-row">
            <Stack
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexDirection={'row'}
            >
              <div>
                <h4>Mi plan</h4>
                <p>
                  <strong>{data.price}USD</strong>/{data.period}
                </p>
              </div>
              <button
                disabled={cancelPlanLoading}
                onClick={isCompany ? null : mutate}
                className="buttonCancel"
              >
                {isCompany ? 'Contactar con soporte' : 'Cancelar plan'}
              </button>
            </Stack>
          </div>

          {cancelPlanLoading ? (
            <p>Cargando...</p>
          ) : (
            <>
              {/* EL PLAN ACTUAL Y QUE INCLUYE*/}
              <div className="sub-modal-row total cancelModal">
                <div className="w-100">
                  <Stack
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flexDirection={'row'}
                  >
                    <div>
                      {isCompany && <h4>Cambiar a plan Pro</h4>}

                      {!isCompany && (
                        <h4>
                          {data.name === 'Pro'
                            ? 'Cambiar a plan Free'
                            : 'Cambiar a plan Pro'}
                        </h4>
                      )}
                      <p>Quiero cancelar mi plan</p>
                    </div>

                    {isCompany && (
                      <button
                        onClick={() => buySubscription('pro', 1)}
                        className="changePlan-link"
                      >
                        Cambiar plan
                      </button>
                    )}
                    {isPro && (
                      <button
                        onClick={() => buySubscription('free', 2)}
                        className="changePlan-link"
                      >
                        Cambiar plan
                      </button>
                    )}
                    {isFree && (
                      <button
                        onClick={() => buySubscription('pro', 1)}
                        className="changePlan-link"
                      >
                        Cambiar plan
                      </button>
                    )}
                  </Stack>

                  {/* QUE INCLUYE */}

                  {isCompany && (
                    <div className="mt-4">
                      <p className="plan-included">
                        <strong>¿Qué incluye el plan Pro?</strong>
                      </p>
                      <p className="plan-included-attributes">
                        <strong>
                          Incluye: 12 usuarios, 200 tickets gratis, soporte 24hs{' '}
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <Check />
                          Lorem ipsum dolor sit amet,
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit ametSit amet
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit amet
                        </li>
                      </ul>
                    </div>
                  )}

                  {isPro && (
                    <div className="mt-4">
                      <p className="plan-included">
                        <strong>¿Qué incluye el plan Free?</strong>
                      </p>
                      <p className="plan-included-attributes">
                        <strong>
                          Incluye: 2 usuarios, 200 tickets gratis, soporte 24hs{' '}
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <Check />
                          Lorem ipsum dolor sit amet,
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit ametSit amet
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit amet
                        </li>
                      </ul>
                    </div>
                  )}

                  {isFree && (
                    <div className="mt-4">
                      <p className="plan-included">
                        <strong>¿Qué incluye el plan Pro?</strong>
                      </p>
                      <p className="plan-included-attributes">
                        <strong>
                          Incluye: 2 usuarios, 200 tickets gratis, soporte 24hs{' '}
                        </strong>
                      </p>
                      <ul>
                        <li>
                          <Check />
                          Lorem ipsum dolor sit amet,
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit ametSit amet
                        </li>
                        <li>
                          <Check />
                          Sit amet, consectetur sit ametSit amet, consectetur
                          sit amet
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {/* SECTION SUPPORT */}
              {!isCompany && (
                <div className="sub-modal-row support">
                  <div>
                    <div className="label">
                      <img src="/rocket-launch.png" /> Plan Empresa
                    </div>
                    <Stack
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      flexDirection={'row'}
                      gap={2}
                    >
                      <span className="support-title">
                        Conocer más sobre el plan Empresa
                      </span>
                      <button className="support-link">
                        Contactar a Soporte
                      </button>
                    </Stack>
                  </div>
                </div>
              )}

              {/* SECTION SUPPORT COMPAMY */}
              {isCompany && (
                <div className="sub-modal-row isCompany">
                  <div>
                    <Stack
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      flexDirection={'row'}
                      gap={2}
                    >
                      <div>
                        <h4>Cambiar al plan Free</h4>
                        <p>Quiero cancelar mi plan</p>
                      </div>

                      <button
                        onClick={() => buySubscription('free', 2)}
                        className="changePlan-link"
                      >
                        {isCompany ? 'Cambiar plan' : 'Cancelar plan'}
                      </button>
                    </Stack>
                  </div>
                </div>
              )}

              {/* FOOTER */}
              <div className="mt-3">
                <p className="sub-footer-text">
                  Si leiste todo y aún no estás seguro, puedes conservar tu plan
                  como hasta ahora. 🤗
                </p>
              </div>

              <div className="d-flex justify-content-center mt-4">
                <button
                  className="subscription-cancel-button"
                  onClick={isClosable}
                >
                  Conservar mi plan
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSubscription;
