import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getEventById,
  getPublicElvisKey,
  getTicketsTypeByEvent,
  getTicketById,
  getTicketActivityById
} from '../../services/axiosService.js';
import { getSubdomain } from '../../components/shared/functions.js';
import AngleLeft from '../../components/icons/AngleLeft.jsx';
import TicketItemButtons from '../../components/ui/TicketItemButtons.jsx';
import Checkout from '../../components/ui/Checkout.jsx';
import TicketOpened from '../../components/ui/TicketOpened.jsx';

export default function LandingTicketDetail(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    elvis,
    ticketSaleOpen,
    setTicketSaleOpen,
    ticketSaleQuantity,
    setTicketSaleQuantity,
    publicElvisId,
    setPublicElvisId,
    setTicketingCompany
  } = React.useContext(AppContext);

  let { ticketToSaleId, eventElvisId, ticketResaleId } = useParams();
  var navigate = useNavigate();
  const subdomain = useMemo(() => getSubdomain(), []);

  const [ticket, setTicket] = useState(null);
  const [fieldStatus, setFieldStatus] = useState([false, false, false]);
  const [cartDataLoading, setCartDataLoading] = useState(true);

  const { data: events } = useQuery({
    queryKey: ['events', eventElvisId],
    queryFn: () => getEventById(null, eventElvisId, publicElvisId),
    enabled: !!subdomain && !!publicElvisId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: companyApiKeyIsLoading,
    isError: companyApiKeyIsError,
    error: companyApiKeyError,
    data: companyApiKey,
    isFetching: companyApiKeyIsFetching
  } = useQuery({
    queryKey: ['companyApiKey'],
    queryFn: () => getPublicElvisKey(subdomain),
    enabled: !!subdomain,
    onSuccess: async (res) => {
      if (res) {
        setTicketingCompany({ data: [res.data] });
        setPublicElvisId(res.data.apiKey);
      }
    },
    onError: (err) => {
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const { data: tickets } = useQuery({
    queryKey: ['tickets-model', eventElvisId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventElvisId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price',
        publicElvisId
      ),
    enabled: !!subdomain && !!publicElvisId && !!ticketToSaleId,
    onSuccess: async (res) => {
      if (res) {
      }
    },
    onError: (err) => {
      console.error(err.response.status);
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const { data: resaleTicket } = useQuery({
    queryKey: ['ticket', ticketResaleId],
    queryFn: () =>
      getTicketById(authState.token, publicElvisId, ticketResaleId),
    enabled: !!subdomain && !!publicElvisId && !!ticketResaleId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
      if (err.response.status === 404) {
        navigate(routes.landing);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
    retry: false
  });

  const { data: ticketActivity } = useQuery({
    queryKey: ['ticket-activity', ticketResaleId],
    queryFn: () =>
      getTicketActivityById(authState.token, publicElvisId, ticketResaleId),
    enabled: !!subdomain && !!publicElvisId && !!ticketResaleId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const openSale = (id) => {
    setTicketSaleOpen(
      tickets?.data?.tickets?.map(function callback(
        currentValue,
        index,
        array
      ) {
        var prevStatus = ticketSaleOpen.filter(
          (ticket) => ticket.id === currentValue.id
        )[0].saleOpen;
        return {
          id: currentValue.id,
          eventId: currentValue.event.id,
          saleOpen:
            Number(currentValue.id) === Number(id) ? !prevStatus : prevStatus
        };
      })
    );
    sessionStorage.setItem(
      'opened_cart',
      JSON.stringify(
        tickets?.data?.tickets?.map(function callback(
          currentValue,
          index,
          array
        ) {
          var prevStatus = ticketSaleOpen.filter(
            (ticket) => ticket.id === currentValue.id
          )[0].saleOpen;
          return {
            id: currentValue.id,
            eventId: currentValue.event.id,
            saleOpen:
              Number(currentValue.id) === Number(id) ? !prevStatus : prevStatus
          };
        })
      )
    );
  };

  const changeQuantity = (id, direction) => {
    setTicketSaleQuantity(
      tickets?.data?.tickets?.map(function callback(
        currentValue,
        index,
        array
      ) {
        var quantity = ticketSaleQuantity.filter(
          (ticket) => ticket.id === currentValue.id
        )[0].quantity;
        return {
          id: currentValue.id,
          eventId: currentValue.event.id,
          quantity:
            Number(currentValue.id) === Number(id)
              ? direction
                ? quantity <
                  Number(
                    tickets?.data?.tickets?.filter(
                      (ticket) => Number(ticket.id) === Number(currentValue.id)
                    )[0]?.amount
                  )
                  ? quantity + 1
                  : quantity
                : quantity
                ? quantity - 1
                : quantity
              : quantity
        };
      })
    );
    sessionStorage.setItem(
      'quantity_cart',
      JSON.stringify(
        tickets?.data?.tickets?.map(function callback(
          currentValue,
          index,
          array
        ) {
          var quantity = ticketSaleQuantity.filter(
            (ticket) => ticket.id === currentValue.id
          )[0].quantity;
          return {
            id: currentValue.id,
            eventId: currentValue.event.id,
            quantity:
              Number(currentValue.id) === Number(id)
                ? direction
                  ? quantity <
                    Number(
                      tickets?.data?.tickets?.filter(
                        (ticket) =>
                          Number(ticket.id) === Number(currentValue.id)
                      )[0]?.amount
                    )
                    ? quantity + 1
                    : quantity
                  : quantity
                  ? quantity - 1
                  : quantity
                : quantity
          };
        })
      )
    );
  };

  useEffect(() => {
    if (events?.data.isFinished) {
      navigate('/');
    }
  }, [events]);

  useEffect(() => {
    setCartDataLoading(true);
    var otherEvent =
      eventElvisId !== ticketSaleOpen[0]?.eventId ||
      eventElvisId !== ticketSaleQuantity[0]?.eventId
        ? true
        : false;
    if (tickets?.data?.tickets.length) {
      setTicket(
        tickets?.data?.tickets?.filter(
          (ticket) => Number(ticket.id) === Number(ticketToSaleId)
        )[0]
      );
      var oldEvent = false;
      var ticketThresholdCrossed = false;
      var badData = false;
      for (let i = 0; i < tickets?.data?.tickets.length; i++) {
        var openFiltered = ticketSaleOpen.filter(
          (item) => item.id === tickets?.data?.tickets[i].id
        );
        var quantityFiltered = ticketSaleQuantity.filter(
          (item) => item.id === tickets?.data?.tickets[i].id
        );
        if (openFiltered?.length) oldEvent = true;
        if (quantityFiltered[0]?.quantity > tickets?.data?.tickets[i].amount)
          ticketThresholdCrossed = true;
        if (
          typeof openFiltered[0]?.id !== 'number' &&
          typeof openFiltered[0]?.saleOpen !== 'boolean'
        )
          badData = true;
        if (
          typeof quantityFiltered[0]?.id !== 'number' &&
          typeof quantityFiltered[0]?.quantity !== 'number'
        )
          badData = true;
      }
      if (
        ticketSaleOpen.length === 0 ||
        !oldEvent ||
        ticketThresholdCrossed ||
        badData ||
        otherEvent
      ) {
        setTicketSaleOpen(
          tickets?.data?.tickets?.map(function callback(
            currentValue,
            index,
            array
          ) {
            return {
              id: currentValue.id,
              eventId: currentValue.event.id,
              saleOpen: false
            };
          })
        );
        sessionStorage.setItem(
          'opened_cart',
          JSON.stringify(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                saleOpen: false
              };
            })
          )
        );
      }
      if (
        ticketSaleQuantity.length === 0 ||
        !oldEvent ||
        ticketThresholdCrossed ||
        badData ||
        otherEvent
      ) {
        setTicketSaleQuantity(
          tickets?.data?.tickets?.map(function callback(
            currentValue,
            index,
            array
          ) {
            return {
              id: currentValue.id,
              eventId: currentValue.event.id,
              quantity: 0
            };
          })
        );
        sessionStorage.setItem(
          'quantity_cart',
          JSON.stringify(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                quantity: 0
              };
            })
          )
        );
      }
      setCartDataLoading(false);
    }
  }, [tickets]);

  useEffect(() => {
    if (resaleTicket?.data) {
      setTicket(resaleTicket?.data);
    }
  }, [resaleTicket]);

  return (
    <main
      id={`ticket-sale-detail-${eventElvisId}`}
      className={`ticket-sale-detail-main d-flex flex-column justify-content-between align-items-center ${
        ticketSaleQuantity
          .map((item) => item.quantity)
          .reduce((prev, curr) => prev + curr, 0)
          ? 'padding-bottom'
          : ''
      }`}
    >
      {ticketSaleQuantity
        .map((item) => item.quantity)
        .reduce((prev, curr) => prev + curr, 0) && !cartDataLoading ? (
        <Checkout ticketSaleQuantity={ticketSaleQuantity} />
      ) : null}
      <div
        className={`ticket-sale-detail-title d-flex align-items-center`}
        onClick={() => navigate(`${routes.landing}/${eventElvisId}`)}
      >
        <AngleLeft />
        <h3>{t('landingElvis.yourTicket')}</h3>
      </div>
      <TicketOpened
        event={events?.data}
        fieldStatus={fieldStatus}
        setFieldStatus={setFieldStatus}
        ticket={ticket}
        historyShowed={ticketActivity}
        qrShowed={false}
        landing={true}
        onGift={false}
        secondaryMarket={!!ticketResaleId}
        ownUser={authState.userId ? authState.userId : null}
      />
      <div
        className={`ticket-sale-detail d-flex flex-column justify-content-between align-items-center`}
      >
        <TicketItemButtons
          id={Number(ticketToSaleId)}
          saleOpen={
            ticketSaleOpen.length
              ? ticketSaleOpen?.filter(
                  (ticket) => Number(ticket.id) === Number(ticketToSaleId)
                )[0]?.saleOpen
              : false
          }
          ticketSaleQuantity={
            ticketSaleQuantity.length
              ? ticketSaleQuantity?.filter(
                  (ticket) => Number(ticket.id) === Number(ticketToSaleId)
                )[0]?.quantity
              : 0
          }
          changeQuantity={changeQuantity}
          buttonFunction={
            ticket?.amount !== '0'
              ? ticketResaleId
                ? authState.isAuthenticated
                  ? (event) => {
                      if (window.location.pathname.includes('resale')) {
                        window.location.replace(
                          `/landing/${eventElvisId}/checkout/${ticketResaleId}`
                        );
                      } else {
                        navigate(
                          window.location.pathname +
                            '/checkout/' +
                            ticketResaleId
                        );
                      }
                      event.stopPropagation();
                    }
                  : (event) => {
                      navigate(routes.auth);
                      event.stopPropagation();
                    }
                : (event) => {
                    openSale(Number(ticketToSaleId));
                    event.stopPropagation();
                  }
              : null
          }
          disabled={
            ticket?.amount !== '0' && events?.data.isFinished === false
              ? false
              : true
          }
          text={ticket?.amount !== '0' ? true : false}
          secondaryMarketUser={true}
        />
        {ticket && ticketToSaleId ? (
          <p>
            {t('ticket.available1') + ticket?.amount + t('ticket.available2')}
          </p>
        ) : null}
      </div>
    </main>
  );
}
