import React from "react";

function ClipboardCheck(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 6V5C8 4 8.5 3.5 9.5 3.5H14.5C15.5 3.5 16 4 16 5V6C16 7 15.5 7.5 14.5 7.5H9.5C8.5 7.5 8 7 8 6ZM20 8.5V18.5C20 20.5 19 21.5 17 21.5H7C5 21.5 4 20.5 4 18.5V8.5C4 6.758 4.75189 5.77701 6.26489 5.55701C6.38589 5.53901 6.5 5.63899 6.5 5.76099V5.99902C6.5 7.81902 7.68 8.99902 9.5 8.99902H14.5C16.32 8.99902 17.5 7.81902 17.5 5.99902V5.76099C17.5 5.63899 17.6151 5.53901 17.7351 5.55701C19.2481 5.77701 20 6.758 20 8.5ZM15.03 11.636C14.737 11.343 14.262 11.343 13.969 11.636L11.166 14.439L10.0291 13.303C9.73605 13.01 9.26102 13.01 8.96802 13.303C8.67502 13.596 8.67502 14.071 8.96802 14.364L10.635 16.031C10.781 16.177 10.973 16.251 11.165 16.251C11.357 16.251 11.5491 16.178 11.6951 16.031L15.0281 12.698C15.3231 12.404 15.323 11.929 15.03 11.636Z" fill="#816CFF"/>
    </svg>
  );
}

export default ClipboardCheck;