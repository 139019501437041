import React from "react";

function ArrowRight(props) {

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.0887 10.5899L12.2562 16.4224C12.0937 16.5849 11.8803 16.6666 11.667 16.6666C11.4537 16.6666 11.2403 16.5849 11.0778 16.4224C10.752 16.0966 10.752 15.5699 11.0778 15.2441L15.4886 10.8332H2.50033C2.03949 10.8332 1.66699 10.4599 1.66699 9.99991C1.66699 9.53991 2.03949 9.16658 2.50033 9.16658H15.4886L11.0778 4.75577C10.752 4.42993 10.752 3.90322 11.0778 3.57738C11.4036 3.25155 11.9304 3.25155 12.2562 3.57738L18.0887 9.4099C18.1662 9.4874 18.2271 9.57901 18.2696 9.68151C18.3537 9.88484 18.3537 10.115 18.2696 10.3183C18.2271 10.4208 18.1662 10.5124 18.0887 10.5899Z" fill="#816CFF"/>
    </svg>
  );
}

export default ArrowRight;