import React from "react";

function BadgeCheck(props) {

  return (
    <svg width="118" height="128" viewBox="0 0 118 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M109.352 66.9189L105.072 62.6375C102.343 59.9094 100.809 56.2008 100.809 52.3369V46.308C100.809 30.231 87.7809 17.2025 71.7039 17.2025H65.6753C61.8113 17.2025 58.1121 15.6687 55.3744 12.9406L51.0926 8.65916C39.7242 -2.7093 21.2978 -2.7093 9.92937 8.65916L5.64761 12.9406C2.91957 15.6687 -0.788166 17.2025 -4.65208 17.2025H-10.6807C-26.7577 17.2025 -39.7867 30.231 -39.7867 46.308V52.3369C-39.7867 56.2008 -41.3203 59.8997 -44.0483 62.6375L-48.3301 66.9189C-59.6986 78.2874 -59.6986 96.7137 -48.3301 108.082L-44.0483 112.364C-41.3203 115.092 -39.7867 118.8 -39.7867 122.664V128.693C-39.7867 144.77 -26.7577 157.799 -10.6807 157.799H-4.65208C-0.788166 157.799 2.90987 159.332 5.64761 162.06L9.92937 166.342C21.2978 177.71 39.7242 177.71 51.0926 166.342L55.3744 162.06C58.1024 159.332 61.8113 157.799 65.6753 157.799H71.7039C87.7809 157.799 100.809 144.77 100.809 128.693V122.664C100.809 118.8 102.343 115.101 105.072 112.364L109.352 108.082C120.721 96.7137 120.721 78.2874 109.352 66.9189ZM64.7722 73.2293L25.9389 112.063C24.5215 113.48 22.6572 114.198 20.7932 114.198C18.9292 114.198 17.0649 113.49 15.6475 112.063L-3.76918 92.646C-6.61372 89.8014 -6.61372 85.1899 -3.76918 82.3454C-0.924641 79.5008 3.68716 79.5008 6.5317 82.3454L20.8027 96.6166L54.4903 62.9287C57.3348 60.0842 61.9466 60.0842 64.7912 62.9287C67.6163 65.7733 67.6168 70.3945 64.7722 73.2293Z" fill="#EBEBEB"/>
    </svg>
  );
}

export default BadgeCheck;