import React from "react";

function EmptyEvents(props) {

  return (
    <svg width="95" height="92" viewBox="0 0 95 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="47.2837" cy="57.6626" r="33.0824" stroke="#C9C9C9" strokeWidth="0.668331" strokeDasharray="1.34 1.34"/>
      <circle cx="28.947" cy="33.9822" r="28.447" fill="#F1F1F1"/>
      <path d="M77.1045 45.9436C77.1045 54.7822 69.931 61.9557 61.0924 61.9557H50.6845V51.9031L52.9902 54.209C53.4577 54.6765 54.0727 54.9134 54.6875 54.9134C55.3024 54.9134 55.9174 54.6797 56.3849 54.209C57.3232 53.2707 57.3232 51.7494 56.3849 50.8111L49.9801 44.4063C49.7591 44.1853 49.4929 44.0092 49.1982 43.8875C48.6122 43.6441 47.9493 43.6441 47.3633 43.8875C47.0686 44.0092 46.8032 44.1853 46.5822 44.4063L40.1774 50.8111C39.2391 51.7494 39.2391 53.2707 40.1774 54.209C41.1157 55.1473 42.6369 55.1473 43.5752 54.209L45.8809 51.9031V61.9557H40.2767C28.78 61.9557 19.4609 52.6366 19.4609 41.1399C19.4609 29.6433 28.78 20.3242 40.2767 20.3242C45.5478 20.3242 50.3578 22.2872 54.0214 25.5152C55.565 26.8763 56.9036 28.4615 57.9893 30.2196C59.014 30.0274 60.0387 29.9315 61.0955 29.9315C69.931 29.9315 77.1045 37.1049 77.1045 45.9436ZM45.8809 78.1278C45.8809 79.4536 46.9569 80.5296 48.2827 80.5296C49.6085 80.5296 50.6845 79.4536 50.6845 78.1278V61.9557H45.8809V78.1278Z" fill="#C9C9C9"/>
      <path d="M29.3276 10.7375C32.0565 10.7375 34.2687 8.52536 34.2687 5.7965C34.2687 3.06764 32.0565 0.855469 29.3276 0.855469C26.5988 0.855469 24.3867 3.06764 24.3867 5.7965C24.3867 8.52536 26.5988 10.7375 29.3276 10.7375Z" fill="#DEDEDE"/>
      <path d="M32.0956 82.5512L30.2317 82.0188C28.5174 81.5287 27.2009 80.2129 26.7108 78.4979L26.1784 76.634C26.0943 76.3408 25.6049 76.3408 25.5208 76.634L24.9884 78.4979C24.4983 80.2129 23.1818 81.5294 21.4676 82.0188L19.6036 82.5512C19.4566 82.5929 19.3555 82.7276 19.3555 82.88C19.3555 83.0324 19.4566 83.1664 19.6036 83.2088L21.4676 83.7412C23.1818 84.2313 24.4983 85.5471 24.9884 87.262L25.5208 89.126C25.5632 89.273 25.6972 89.3741 25.8496 89.3741C26.002 89.3741 26.136 89.273 26.1784 89.126L26.7108 87.262C27.2009 85.5471 28.5174 84.2306 30.2317 83.7412L32.0956 83.2088C32.2426 83.1671 32.3437 83.0324 32.3437 82.88C32.3437 82.7276 32.2426 82.5936 32.0956 82.5512Z" fill="#EAEAEA"/>
      <path d="M80.9893 15.0604L78.686 14.4024C76.5676 13.7968 74.9409 12.1708 74.3353 10.0516L73.6773 7.74832C73.5734 7.38598 72.9686 7.38598 72.8647 7.74832L72.2068 10.0516C71.6012 12.1708 69.9744 13.7976 67.856 14.4024L65.5527 15.0604C65.3711 15.1119 65.2461 15.2783 65.2461 15.4666C65.2461 15.655 65.3711 15.8205 65.5527 15.8729L67.856 16.5309C69.9744 17.1365 71.6012 18.7624 72.2068 20.8816L72.8647 23.1849C72.9171 23.3665 73.0827 23.4915 73.271 23.4915C73.4594 23.4915 73.6249 23.3665 73.6773 23.1849L74.3353 20.8816C74.9409 18.7624 76.5676 17.1356 78.686 16.5309L80.9893 15.8729C81.1709 15.8214 81.2959 15.655 81.2959 15.4666C81.2959 15.2783 81.1709 15.1127 80.9893 15.0604Z" fill="#DEDEDE"/>
      <path d="M91.2757 62.7781C93.0568 62.7781 94.5007 61.3342 94.5007 59.5531C94.5007 57.772 93.0568 56.3281 91.2757 56.3281C89.4946 56.3281 88.0508 57.772 88.0508 59.5531C88.0508 61.3342 89.4946 62.7781 91.2757 62.7781Z" fill="#DEDEDE"/>
    </svg>
  );
}

export default EmptyEvents;