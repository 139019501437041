import { useContext } from 'react';
import { stripeAccountVerify } from '../../services/axiosService';
import { AppContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import routes from '../../routes/routes';

const PaymentStripeVerify = () => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['stripeAccountVerify'],
    queryFn: () => stripeAccountVerify(state.token),
    onSuccess: (res) => {
      if (res) {
        navigate(routes.paymentSuccess);
      }
    },
    onError: (err) => {
      navigate(routes.paymentFailed);
    },
    refetchOnMount: true
  });

  return (
    <div
      style={{ height: '100vh' }}
      className="d-flex align-items-center justify-content-center w-100"
    >
      Verificando...
    </div>
  );
};

export default PaymentStripeVerify;
