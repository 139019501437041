import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CheckoutFinal(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const secondaryPrice = (tickets) => {
    if (tickets?.currency.id === 1) {
      return tickets?.currency.symbol + Number(tickets?.price).toFixed(2);
    } else {
      return Number(tickets?.price).toFixed(2) + tickets?.currency.symbol;
    }
  };

  return (
    <div
      className={`checkout-final d-flex flex-column justify-content-between align-items-center`}
    >
      <div
        className={`checkout-tickets-top d-flex justify-content-between align-items-center`}
      >
        <div
          className={`checkout-tickets-top-left d-flex flex-column justify-content-between`}
        >
          {!props.secondary ? (
            <>
              {props.ticketSaleQuantity
                .filter((f) => f.quantity > 0)
                .map((i) =>
                  props.tickets
                    ?.filter((f) => f.id === i.id)
                    .map((i) => (
                      <h4 key={i.id}>
                        {t('landingElvis.checkoutTicket') +
                          props.tickets?.filter(
                            (ticket) => Number(ticket.id) === Number(i.id)
                          )[0].category?.name}
                      </h4>
                    ))
                )}
            </>
          ) : (
            <h4>
              {t('landingElvis.checkoutTicket') + props.tickets?.category?.name}
            </h4>
          )}
        </div>
        <div
          className={`checkout-tickets-top-right d-flex flex-column justify-content-between`}
        >
          {!props.secondary ? (
            props.ticketSaleQuantity
              .filter((f) => f.quantity > 0)
              .map((i) =>
                props.tickets
                  ?.filter((f) => f.id === i.id)
                  .map((item) => (
                    <h4 key={item.id}>
                      {props.ticketSaleQuantity?.filter(
                        (ticket) => Number(ticket.id) === Number(item.id)
                      )[0].quantity +
                        t('landingElvis.checkoutFinal1') +
                        `\xa0`}
                      <span>
                        {props.tickets
                          ? !!props.code?.amount &&
                            props.code?.models.includes(
                              Number(
                                Number(
                                  props.tickets?.filter(
                                    (ticket) =>
                                      Number(ticket.id) === Number(item.id)
                                  )[0].id
                                )
                              )
                            )
                            ? props.tickets[0]?.currency_id === 1
                              ? props.tickets[0]?.currency?.symbol +
                                (
                                  (Number(
                                    props.tickets?.filter(
                                      (ticket) =>
                                        Number(ticket.id) === Number(item.id)
                                    )[0].price
                                  ) *
                                    props.ticketSaleQuantity?.filter(
                                      (ticket) =>
                                        Number(ticket.id) === Number(item.id)
                                    )[0].quantity *
                                    (100 - props.code?.amount)) /
                                  100
                                ).toFixed(2)
                              : (
                                  (Number(
                                    props.tickets?.filter(
                                      (ticket) =>
                                        Number(ticket.id) === Number(item.id)
                                    )[0].price
                                  ) *
                                    props.ticketSaleQuantity?.filter(
                                      (ticket) =>
                                        Number(ticket.id) === Number(item.id)
                                    )[0].quantity *
                                    (100 - props.code?.amount)) /
                                  100
                                ).toFixed(2) +
                                props.tickets[0]?.currency?.symbol
                            : props.tickets[0]?.currency_id === 1
                            ? props.tickets[0]?.currency?.symbol +
                              (
                                Number(
                                  props.tickets?.filter(
                                    (ticket) =>
                                      Number(ticket.id) === Number(item.id)
                                  )[0].price
                                ) *
                                props.ticketSaleQuantity?.filter(
                                  (ticket) =>
                                    Number(ticket.id) === Number(item.id)
                                )[0].quantity
                              ).toFixed(2)
                            : (
                                Number(
                                  props.tickets?.filter(
                                    (ticket) =>
                                      Number(ticket.id) === Number(item.id)
                                  )[0].price
                                ) *
                                props.ticketSaleQuantity?.filter(
                                  (ticket) =>
                                    Number(ticket.id) === Number(item.id)
                                )[0].quantity
                              ).toFixed(2) + props.tickets[0]?.currency?.symbol
                          : '-'}
                      </span>
                    </h4>
                  ))
              )
          ) : (
            <h4>
              {1 + t('landingElvis.checkoutFinal1') + `\xa0`}
              <span>{props.tickets ? secondaryPrice(props.tickets) : '-'}</span>
            </h4>
          )}
        </div>
      </div>
      <div className={`checkout-tickets-separator`}></div>
      {!props.secondary ? (
        <>
          <div
            className={`checkout-tickets-middle d-flex justify-content-between align-items-center`}
          >
            <h4>{t('landingElvis.checkoutFinal2')}</h4>
            <h4>{props.code?.code ? props.code?.code : 'N/A'}</h4>
          </div>
          <div className={`checkout-tickets-separator`}></div>
        </>
      ) : null}
      <div
        className={`checkout-tickets-bottom d-flex justify-content-between align-items-center`}
      >
        <h3>{t('landingElvis.checkoutFinal3')}</h3>
        {!props.secondary ? (
          <h3>
            {props.tickets
              ? props.totalPrice
                ? props.tickets[0]?.currency_id === 2
                  ? props.tickets[0]?.currency?.symbol +
                    Number(props.totalPrice).toFixed(2)
                  : Number(props.totalPrice).toFixed(2) +
                    props.tickets[0]?.currency?.symbol
                : props.tickets[0]?.currency_id === 2
                ? props.tickets[0]?.currency?.symbol + '0'
                : '0' + props.tickets[0]?.currency?.symbol
              : null}
          </h3>
        ) : (
          <h3>
            {props.tickets
              ? props.tickets?.currency.id === 2
                ? props.tickets?.currency.symbol +
                  Number(props.tickets?.price)?.toFixed(2)
                : Number(props.tickets?.price)?.toFixed(2) +
                  props.tickets?.currency.symbol
              : props.tickets?.currency.id === 2
              ? props.tickets?.currency.symbol + '0'
              : '0' + props.tickets?.currency.symbol}
          </h3>
        )}
      </div>
    </div>
  );
}
