import React from "react";

function TiktokSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path d="M10.7733 17.8161C12.6394 17.8161 14.1521 16.3033 14.1521 14.4373V10.6065C14.6491 10.8939 15.2261 11.0585 15.8415 11.0585H16.4046V9.36908H15.8415C14.9085 9.36908 14.1521 8.61271 14.1521 7.67969H12.4627V14.4373C12.4627 15.3703 11.7063 16.1267 10.7733 16.1267C9.84029 16.1267 9.08393 15.3703 9.08393 14.4373C9.08393 13.5042 9.84029 12.7479 10.7733 12.7479V11.0585C8.90727 11.0585 7.39453 12.5712 7.39453 14.4373C7.39453 16.3033 8.90727 17.8161 10.7733 17.8161Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default TiktokSmall;