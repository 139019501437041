import React from 'react';
import { useTranslation } from 'react-i18next';
import TicketItemButtonsPlusMinus from './TicketItemButtonsPlusMinus';

export default function CheckoutTickets(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  return (
    <div
      className={`checkout-tickets d-flex flex-column justify-content-between align-items-center`}
    >
      <div
        className={`checkout-tickets-top d-flex justify-content-between align-items-center`}
      >
        <div
          className={`checkout-tickets-top-left d-flex flex-column justify-content-between`}
        >
          <h4>{t('landingElvis.checkoutTickets1')}</h4>
          <h3>
            {props.category
              ? t('landingElvis.checkoutTicket') + props.category
              : ''}
          </h3>
        </div>
        <div
          className={`checkout-tickets-top-right d-flex justify-content-between align-items-center`}
        >
          <TicketItemButtonsPlusMinus
            id={props.id}
            ticketSaleQuantity={props.quantity}
            changeQuantity={props.changeQuantity}
          />
        </div>
      </div>
      <div className={`checkout-tickets-separator`}></div>
      <div
        className={`checkout-tickets-middle d-flex justify-content-between align-items-center`}
      >
        <h4>{t('landingElvis.checkoutTickets2')}</h4>
        <h4>
          {props?.price === null && props.currency?.id === 2
            ? props.currency?.symbol + '0.00'
            : props?.price === null && props.currency?.id !== 2
            ? '0.00' + props.currency?.symbol
            : props.currency?.id === 2
            ? props.currency?.symbol + props.price
            : props.currency?.id
            ? props.price + props.currency?.symbol
            : null}
        </h4>
      </div>
      <div className={`checkout-tickets-separator`}></div>
      <div
        className={`checkout-tickets-bottom d-flex justify-content-between align-items-center`}
      >
        <h3>{t('landingElvis.checkoutTickets3')}</h3>
        <h3>
          {props.price && props.quantity
            ? props.currency?.id === 2
              ? props.currency?.symbol +
                (props.price * props.quantity).toFixed(2)
              : (props.price * props.quantity).toFixed(2) +
                props.currency?.symbol
            : props.currency?.id === 2
            ? props.currency?.symbol + '0'
            : '0' + props.currency?.symbol}
        </h3>
      </div>
    </div>
  );
}
