import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { getEventById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import TriangleExclamationSmall from "../../components/icons/TriangleExclamationSmall";
import SpinnerPurple from "../../components/icons/SpinnerPurple.jsx";

function InfoEventFirstPageChildren(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, isValid, values, submitForm } = useFormikContext();

  var navigate = useNavigate();

  const [count, setCount] = useState(0);
  const MAX_CHARACTERS = 1000;

  const nextPage = async (route) => {
    await submitForm();
    if (!(props.events?.data?.name && props.events?.data?.description
      && props.events?.data?.location && props.events?.data?.address && props.events?.data?.country && props.events?.data?.state && props.events?.data?.postalCode 
      && props.events?.data?.dateEvent && props.events?.data?.dateDoors && props.events?.data?.dateEnd && props.events?.data?.timezone)) {
      navigate(route === "back" ? -1 : route);
    }
  }

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100">
          <h5>{t('events.infoField0') + "*"}</h5>
          <Field 
            className={ errors.name && touched.name ? "ticketer-field red-border" : touched.name ? "ticketer-field purple-border" : "ticketer-field" } 
            id="event-name" type="text" name="name" placeholder=""
          />
          { errors.name && touched.name ? 
            <ErrorMessage name="name">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
            :
            null
          }
        </div>
        <div className="w-100">
          <h5>{t('events.infoField1') + "*"}</h5>
          <Field 
            className={ errors.description && touched.description ? "ticketer-field red-border" : touched.description ? "ticketer-field purple-border" : "ticketer-field" } 
            id="event-description" type="text" name="description" placeholder={t('ticketerSection.ckeditorPlaceholder')} 
            as="textarea" maxLength={ MAX_CHARACTERS } onKeyUp={ (e) => setCount(e.target.value.length) }
          />
          { errors.description && touched.description ? 
            <ErrorMessage name="description">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
            :
            null
          }
          <h5 className="description-counter">{ count } / { MAX_CHARACTERS }</h5>
        </div>
      </div>
      <ButtonGroupTibet button = { { text: t('ticketer.save'), disabled: !(isValid && values.name && values.description), function: () => nextPage(`${routes.events}/${props.eventId}${routes.createLocation}`) } }/>
    </>
  );
}

function InfoEventFirstPage(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, ticketerModal, setTicketerModal, role } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const { isLoading, isError, error, data: events, isFetching } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialFirstInfoData = {
    name: events?.data?.name ? events?.data?.name : "",
    description: events?.data?.description ? events?.data?.description : ""
  }

  const dataFirstInfoSchema = Yup.object().shape(
    {
      name: Yup.string().required(t('register.error2')),
      description: Yup.string().required(t('register.error2')).max(1000, t('events.characters'))
    }
  );

  return (
    <>
      { isLoading ?
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple/>
        </div>
        :
        isError ?
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
        :
        <TicketingSectionComponent
          initialValues = { initialFirstInfoData }
          validationSchema = { dataFirstInfoSchema }
          ticketer = { false }
          title = { t('events.infoData') }
          pagination = { "1/3" }
          description = { t('events.infoData1') }
          footer = { { text: t('events.adviceInfo1'), icon: <TriangleExclamationSmall/> } }
          nextPage={ events?.data?.name && events?.data?.description
            && events?.data?.location && events?.data?.address && events?.data?.country && events?.data?.state && events?.data?.postalCode 
            && events?.data?.dateEvent && events?.data?.dateDoors && events?.data?.dateEnd && events?.data?.timezone ? false : true }
        >
          <InfoEventFirstPageChildren initialValues = { initialFirstInfoData } eventId = { eventId } events = { events }/>
        </TicketingSectionComponent>
      }
    </>
  );
}

export default InfoEventFirstPage;