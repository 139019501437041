import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import { getEventById } from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import LocationPinSmall from '../../components/icons/LocationPinSmall.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';

function InfoEventSecondPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { errors, touched, isValid, values, submitForm } = useFormikContext();

  var navigate = useNavigate();

  const [count, setCount] = useState(0);
  const MAX_CHARACTERS = 1000;

  const nextPage = (route) => {
    submitForm();
    navigate(route === 'back' ? -1 : route);
  };

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100">
          <h5>{t('events.infoField2') + '*'}</h5>
          <Field
            className={
              errors.location && touched.location
                ? 'ticketer-field red-border'
                : touched.location
                ? 'ticketer-field purple-border'
                : 'ticketer-field'
            }
            id="event-location"
            type="text"
            name="location"
            placeholder=""
          />
          {errors.location && touched.location ? (
            <ErrorMessage name="location">
              {(msg) => <div className="ticketer-field-error">{msg}</div>}
            </ErrorMessage>
          ) : null}
        </div>
        <div className="w-100 ticketer-field-info-email d-flex flex-row">
          <div>
            <h5>{t('events.infoField4') + '*'}</h5>
            <Field
              className={
                errors.address && touched.address
                  ? 'ticketer-field red-border'
                  : touched.address
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-address"
              type="text"
              name="address"
              placeholder=""
            />
            {errors.address && touched.address ? (
              <ErrorMessage name="address">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <h5>{t('events.infoField5')}</h5>
            <Field
              className={
                errors.address2 && touched.address2
                  ? 'ticketer-field red-border'
                  : touched.address2
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-address2"
              type="text"
              name="address2"
              placeholder=""
            />
            {errors.address2 && touched.address2 ? (
              <ErrorMessage name="address2">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
        <div className="w-100 ticketer-field-info-email d-flex flex-row">
          <div>
            <h5>{t('events.infoField3') + '*'}</h5>
            <Field
              className={
                errors.country && touched.country
                  ? 'ticketer-field red-border'
                  : touched.country
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-country"
              type="text"
              name="country"
              placeholder=""
            />
            {errors.country && touched.country ? (
              <ErrorMessage name="country">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <h5>{t('events.infoField6') + '*'}</h5>
            <Field
              className={
                errors.city && touched.city
                  ? 'ticketer-field red-border'
                  : touched.city
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-city"
              type="text"
              name="city"
              placeholder=""
            />
            {errors.city && touched.city ? (
              <ErrorMessage name="city">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
        <div className="w-100 ticketer-field-info-email d-flex flex-row">
          <div>
            <h5>{t('events.infoField7') + '*'}</h5>
            <Field
              className={
                errors.state && touched.state
                  ? 'ticketer-field red-border'
                  : touched.state
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-state"
              type="text"
              name="state"
              placeholder=""
            />
            {errors.state && touched.state ? (
              <ErrorMessage name="state">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <h5>{t('events.infoField8') + '*'}</h5>
            <Field
              className={
                errors.postalCode && touched.postalCode
                  ? 'ticketer-field red-border'
                  : touched.postalCode
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-postalCode"
              type="text"
              name="postalCode"
              placeholder=""
            />
            {errors.postalCode && touched.postalCode ? (
              <ErrorMessage name="postalCode">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.save'),
          disabled: !(
            isValid &&
            values.location &&
            values.address &&
            values.country &&
            values.city &&
            values.state &&
            values.postalCode
          ),
          function: () =>
            nextPage(
              `${routes.events}/${props.eventId}${routes.createDateTime}`
            )
        }}
      />
    </>
  );
}

function InfoEventSecondPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    ticketerModal,
    setTicketerModal,
    role
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const {
    isLoading,
    isError,
    error,
    data: events,
    isFetching
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialSecondInfoData = {
    location: events?.data?.location ? events?.data?.location : '',
    address: events?.data?.address ? events?.data?.address : '',
    address2: events?.data?.address2 ? events?.data?.address2 : '',
    country: events?.data?.country ? events?.data?.country : '',
    city: events?.data?.city ? events?.data?.city : '',
    state: events?.data?.state ? events?.data?.state : '',
    postalCode: events?.data?.postalCode ? events?.data?.postalCode : ''
  };

  const dataSecondInfoSchema = Yup.object().shape({
    location: Yup.string().required(t('register.error2')),
    address: Yup.string().required(t('register.error2')),
    address2: Yup.string(),
    country: Yup.string().required(t('register.error2')),
    city: Yup.string().required(t('register.error2')),
    state: Yup.string().required(t('register.error2')),
    postalCode: Yup.string().required(t('register.error2'))
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <TicketingSectionComponent
          initialValues={initialSecondInfoData}
          validationSchema={dataSecondInfoSchema}
          ticketer={false}
          title={t('events.infoData')}
          pagination={'2/3'}
          description={t('events.info4')}
          footer={{ text: t('events.adviceInfo2'), icon: <LocationPinSmall /> }}
          nextPage={
            events?.data?.name &&
            events?.data?.description &&
            events?.data?.location &&
            events?.data?.address &&
            events?.data?.country &&
            events?.data?.state &&
            events?.data?.postalCode &&
            events?.data?.dateEvent &&
            events?.data?.dateDoors &&
            events?.data?.dateEnd &&
            events?.data?.timezone
              ? false
              : true
          }
        >
          <InfoEventSecondPageChildren
            initialValues={initialSecondInfoData}
            eventId={eventId}
            events={events}
          />
        </TicketingSectionComponent>
      )}
    </>
  );
}

export default InfoEventSecondPage;
