import React from "react";

function SaleModal2(props) {

  return (
    <svg width="105" height="89" viewBox="0 0 105 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="8.79089" y="44.741" width="47.8406" height="43.841" rx="6.89263" stroke="#816CFF" strokeWidth="0.599359" strokeDasharray="1.2 1.2"/>
      <path d="M71.5382 9.22995C74.087 9.22995 76.1533 7.16375 76.1533 4.61497C76.1533 2.06619 74.087 0 71.5382 0C68.9894 0 66.9233 2.06619 66.9233 4.61497C66.9233 7.16375 68.9894 9.22995 71.5382 9.22995Z" fill="#816CFF"/>
      <path d="M88.448 87.7372C90.4669 87.7372 92.1037 86.1006 92.1037 84.0816C92.1037 82.0627 90.4669 80.426 88.448 80.426C86.4291 80.426 84.7925 82.0627 84.7925 84.0816C84.7925 86.1006 86.4291 87.7372 88.448 87.7372Z" fill="#442ECB"/>
      <path d="M4.81055 13.1842V20.9759" stroke="#FF8C5A" strokeWidth="0.882129" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M8.71404 17.0763H0.922363" stroke="#FF8C5A" strokeWidth="0.882129" strokeMiterlimit="10" strokeLinecap="round"/>
      <rect x="29.8203" y="16.8832" width="54.2172" height="51.9952" rx="6.66605" fill="#E0DBFF"/>
      <path d="M57.2466 22.1019H24.0923C17.4614 22.1019 14.146 25.4173 14.146 32.0482V65.2024C14.146 71.8333 17.4614 75.1487 24.0923 75.1487H57.2466C63.8774 75.1487 67.1929 71.8333 67.1929 65.2024V32.0482C67.1929 25.4173 63.8774 22.1019 57.2466 22.1019ZM38.4591 35.3636C38.4591 34.1435 39.4494 33.1533 40.6694 33.1533C41.8895 33.1533 42.8797 34.1435 42.8797 35.3636V48.8347C42.8797 50.0548 41.8895 51.045 40.6694 51.045C39.4494 51.045 38.4591 50.0548 38.4591 48.8347V35.3636ZM40.7284 61.887C39.1017 61.887 37.7663 60.5667 37.7663 58.94C37.7663 57.3132 39.0722 55.9929 40.6989 55.9929H40.7284C42.3581 55.9929 43.6755 57.3132 43.6755 58.94C43.6755 60.5667 42.3552 61.887 40.7284 61.887Z" fill="#442ECB"/>
      <path d="M100.042 51.8812L92.5823 37.9301C91.1371 35.2271 87.2542 35.2271 85.8078 37.9301L78.3481 51.8812C77.0226 54.3606 78.8223 57.3548 81.6394 57.3548H96.7507C99.5666 57.3548 101.368 54.3595 100.042 51.8812ZM88.3482 43.8059C88.3482 43.3385 88.7276 42.9591 89.1951 42.9591C89.6625 42.9591 90.0419 43.3385 90.0419 43.8059V48.3222C90.0419 48.7897 89.6625 49.169 89.1951 49.169C88.7276 49.169 88.3482 48.7897 88.3482 48.3222V43.8059ZM89.2177 52.8385C88.5944 52.8385 88.0828 52.3327 88.0828 51.7095C88.0828 51.0862 88.5831 50.5804 89.2064 50.5804H89.2177C89.842 50.5804 90.3467 51.0862 90.3467 51.7095C90.3467 52.3327 89.8409 52.8385 89.2177 52.8385Z" fill="#FF8C5A"/>
    </svg>
  );
}

export default SaleModal2;