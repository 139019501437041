import React from 'react';

export default function UploadTicket(props) {

  return (
    <button className="upload-ticket d-flex flex-row justify-content-center align-items-center" 
      onClick={ props.function } id={ props.id } ref={ props.reference }
    >
      { props.icon }{ props.text }
    </button>
  )

}