import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import { getEventById } from '../../services/axiosService.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import ReceiveStar from '../../components/icons/ReceiveStar.jsx';
import FilePlus from '../../components/icons/FilePlus.jsx';
import ImagePlus from '../../components/icons/ImagePlus.jsx';
import UploadTicket from '../../components/ui/UploadTicket.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';
import { PictureUploadComponent } from '../../components/shared/PictureUploadComponent.jsx';
import EmptyImage from '../../components/icons/EmptyImage.jsx';
import ContentField from '../../components/ui/ContentField.jsx';
const { DateTime } = require('luxon');

function TicketsEventThirdPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    values,
    errors,
    touched,
    setFieldError,
    setFieldValue,
    setFieldTouched
  } = useFormikContext();
  const {
    createTicketInfo,
    setCreateTicketInfo,
    setContentModal,
    setImageModal
  } = React.useContext(AppContext);

  const MAX_EXCLUSIVE_CONTENT = Number(
    process.env.REACT_APP_MAX_EXCLUSIVE_CONTENT
  );

  var navigate = useNavigate();

  const [isLoadingArray, setIsLoadingArray] = useState([false]);

  const nextPage = (route) => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === props.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          date: values.date
            ? `${values.date.c.year}-${values.date.c.month < 10 ? '0' : ''}${
                values.date.c.month
              }-${values.date.c.day < 10 ? '0' : ''}${values.date.c.day} ${
                values.date.c.hour < 10 ? '0' : ''
              }${values.date.c.hour}:${values.date.c.minute < 10 ? '0' : ''}${
                values.date.c.minute
              }:00`
            : '',
          sector: values.sector,
          gate: values.gate,
          tribune: values.tribune,
          floor: values.floor,
          row: values.row
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        date: values.date
          ? `${values.date.c.year}-${values.date.c.month < 10 ? '0' : ''}${
              values.date.c.month
            }-${values.date.c.day < 10 ? '0' : ''}${values.date.c.day} ${
              values.date.c.hour < 10 ? '0' : ''
            }${values.date.c.hour}:${values.date.c.minute < 10 ? '0' : ''}${
              values.date.c.minute
            }:00`
          : '',
        sector: values.sector,
        gate: values.gate,
        tribune: values.tribune,
        floor: values.floor,
        row: values.row
      });
      navigate(route === 'back' ? -1 : route);
    } else if (createTicketInfo.eventId !== props.eventId) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  };

  function onSelectFile(e, id) {
    setIsLoadingArray([true]);
    if (e.target.files && e.target.files.length > 0) {
      if (
        (Object.keys(createTicketInfo).length === 0 ||
          createTicketInfo.eventId === props.eventId) &&
        e.target.files[0].size < 1572864
      ) {
        const reader = new FileReader();
        reader.fileName = e.target.files[0].name;
        reader.addEventListener('load', () => {
          sessionStorage.setItem(
            'ticket_draft',
            JSON.stringify({
              ...createTicketInfo,
              eventId: props.eventId,
              image: reader.result,
              imageName: reader.fileName
            })
          );
          setCreateTicketInfo({
            ...createTicketInfo,
            eventId: props.eventId,
            image: reader.result,
            imageName: reader.fileName
          });
        });
        reader.readAsDataURL(e.target.files[0]);
      } else if (createTicketInfo.eventId !== props.eventId) {
        setCreateTicketInfo({});
        sessionStorage.removeItem('ticket_draft');
      } else if (e.target.files[0].size > 1572864) {
        setImageModal({
          sizeExceeded: true,
          message: t('profile.sizeTextUploadImage'),
          messageTitle: t('profile.sizeTitleUploadImage')
        });
      }
    }
    e.target.value = '';
    setIsLoadingArray([false]);
  }

  function onDelete() {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === props.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          image: '',
          imageName: ''
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        image: '',
        imageName: ''
      });
    } else if (createTicketInfo.eventId !== props.eventId) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  }

  function onDeleteContent(index) {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === props.eventId
    ) {
      var contentVarible = createTicketInfo?.extraContent;
      var contentTitleVarible = createTicketInfo?.extraContentTitle;
      contentVarible.splice(index, 1);
      contentTitleVarible.splice(index, 1);
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          extraContent: contentVarible,
          extraContentTitle: contentTitleVarible
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        extraContent: contentVarible,
        extraContentTitle: contentTitleVarible
      });
    } else if (createTicketInfo.eventId !== props.eventId) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  }

  return (
    <>
      <div className="ticketer-field-info-container create-ticket-third-page d-flex flex-column">
        <div className="w-100">
          <h4>{t('ticket.createTicketField7')}</h4>
          <p>{t('ticket.createTicketFieldDescription7')}</p>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale="es"
            localeText={
              esES.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <div className="w-100 d-flex">
              <div className="">
                <h5>{t('ticket.createTicketLittleField2')}</h5>
                <DatePicker
                  value={values.date}
                  disablePast
                  onChange={(newValue) => {
                    setFieldTouched('date', newValue ? true : false);
                    setFieldValue('date', new DateTime(newValue));
                  }}
                  onError={(reason, value) => {
                    if (reason) {
                      setFieldError('date', t('ticket.errorDate'));
                    } else {
                      setFieldError('date', '');
                    }
                  }}
                />
                {errors.date && touched.date ? (
                  <ErrorMessage name="date">
                    {(msg) => <div className="ticketer-field-error">{msg}</div>}
                  </ErrorMessage>
                ) : null}
              </div>
              <div className="">
                <h5>{t('ticket.createTicketLittleField3')}</h5>
                <TimePicker
                  value={values.date}
                  views={['hours', 'minutes']}
                  onChange={(newValue) => {
                    setFieldTouched('date', newValue ? true : false);
                    setFieldValue('date', new DateTime(newValue));
                  }}
                  onError={(reason, value) => {
                    if (value?.invalid || !value) {
                      setFieldError('date', t('register.error2'));
                    } else {
                      setFieldError('date', '');
                    }
                  }}
                />
                {errors.date && touched.date ? (
                  <ErrorMessage name="date">
                    {(msg) => <div className="ticketer-field-error">{msg}</div>}
                  </ErrorMessage>
                ) : null}
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div className="w-100">
          <h4>{t('ticket.createTicketField8')}</h4>
          <p>{t('ticket.createTicketFieldDescription8')}</p>

          <div className="w-100 mb-3 d-flex">
            <div className="">
              <h5>{t('ticket.createTicketLittleField4')}</h5>
              <Field
                className={
                  errors.gate && touched.gate
                    ? 'ticketer-field red-border'
                    : touched.gate
                    ? 'ticketer-field purple-border'
                    : 'ticketer-field'
                }
                id="event-ticket-gate"
                type="text"
                name="gate"
                placeholder=""
              />
              {errors.gate && touched.gate ? (
                <ErrorMessage name="gate">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
            <div className="">
              <h5>{t('ticket.createTicketLittleField5')}</h5>
              <Field
                className={
                  errors.tribune && touched.tribune
                    ? 'ticketer-field red-border'
                    : touched.tribune
                    ? 'ticketer-field purple-border'
                    : 'ticketer-field'
                }
                id="event-ticket-tribune"
                type="text"
                name="tribune"
                placeholder=""
              />
              {errors.tribune && touched.tribune ? (
                <ErrorMessage name="tribune">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
          </div>
          <div className="w-100 mb-3 d-flex">
            <div className="">
              <h5>{t('ticket.createTicketLittleField6')}</h5>
              <Field
                className={
                  errors.floor && touched.floor
                    ? 'ticketer-field red-border'
                    : touched.floor
                    ? 'ticketer-field purple-border'
                    : 'ticketer-field'
                }
                id="event-ticket-floor"
                type="text"
                name="floor"
                placeholder=""
              />
              {errors.floor && touched.floor ? (
                <ErrorMessage name="floor">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
            <div className="">
              <h5>{t('ticket.createTicketLittleField7')}</h5>
              <Field
                className={
                  errors.row && touched.row
                    ? 'ticketer-field red-border'
                    : touched.row
                    ? 'ticketer-field purple-border'
                    : 'ticketer-field'
                }
                id="event-ticket-row"
                type="text"
                name="row"
                placeholder=""
              />
              {errors.row && touched.row ? (
                <ErrorMessage name="row">
                  {(msg) => <div className="ticketer-field-error">{msg}</div>}
                </ErrorMessage>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex">
          <div className="w-100 mb-3">
            <h5>{t('ticket.createTicketLittleField8')}</h5>
            <Field
              className={
                errors.sector && touched.sector
                  ? 'ticketer-field red-border'
                  : touched.sector
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="event-ticket-sector"
              type="text"
              name="sector"
              placeholder=""
            />
            {errors.sector && touched.sector ? (
              <ErrorMessage name="sector">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div className="w-100 mb-3 d-flex"></div>
        </div>

        <div className="w-100">
          <h4>{t('ticket.createTicketField9')}</h4>
          <p>{t('ticket.createTicketFieldDescription9')}</p>
          {createTicketInfo?.image ? (
            <PictureUploadComponent
              id={1}
              icon={<EmptyImage />}
              text1={
                createTicketInfo?.imageName?.length > 20
                  ? createTicketInfo?.imageName.slice(0, 20) + '...'
                  : createTicketInfo?.imageName
              }
              text2={null}
              buttonIcon={null}
              buttonText={null}
              buttonTextAlt={t('ticketerSection.pictureButton3')}
              picture={createTicketInfo?.image}
              uploadFunction={onSelectFile}
              deleteFunction={onDelete}
              isLoading={isLoadingArray}
            />
          ) : (
            <label className="upload-ticket d-flex flex-row justify-content-center align-items-center">
              <ImagePlus />
              {t('ticket.createTicketUploadButton1')}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => onSelectFile(e)}
              ></input>
            </label>
          )}
        </div>
        <div className="w-100">
          <h4>{t('ticket.createTicketField10')}</h4>
          <p>{t('ticket.createTicketFieldDescription10')}</p>
          <div className="third-page-content d-flex flex-column">
            {createTicketInfo?.extraContent ? (
              createTicketInfo?.extraContent?.map((item, index) => {
                if (item) {
                  return (
                    <ContentField
                      key={index}
                      type={item.type}
                      text={item.fileName}
                      radiusCircle={false}
                      width={100}
                      deleteEnabled={true}
                      deleteFunction={() => onDeleteContent(index)}
                    />
                  );
                } else return null;
              })
            ) : (
              <h4>{t('ticket.ticketInfo16')}</h4>
            )}
            {createTicketInfo?.extraContent?.length < MAX_EXCLUSIVE_CONTENT ||
            !createTicketInfo?.extraContent ? (
              <UploadTicket
                icon={<FilePlus />}
                text={t('ticket.createTicketUploadButton2')}
                function={() => setContentModal(props.eventId)}
                disabled={false}
              />
            ) : null}
          </div>
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.save'),
          disabled: false,
          function: () =>
            nextPage(
              `${routes.events}/${props.eventId}${routes.createTicketsResume}`
            )
        }}
      />
    </>
  );
}

function TicketsEventThirdPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    createTicketInfo
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const {
    isLoading,
    isError,
    data: events
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialThirdTicketData = {
    date: createTicketInfo?.date
      ? DateTime.fromFormat(
          String(createTicketInfo?.date),
          'yyyy-MM-dd HH:mm:ss'
        )
      : '',
    sector: createTicketInfo?.sector ? createTicketInfo?.sector : '',
    row: createTicketInfo?.row ? createTicketInfo?.row : '',
    gate: createTicketInfo?.gate ? createTicketInfo?.gate : '',
    tribune: createTicketInfo?.tribune ? createTicketInfo?.tribune : '',
    floor: createTicketInfo?.floor ? createTicketInfo?.floor : ''
  };

  const dataSecondThirdSchema = Yup.object().shape({
    date: Yup.string(),
    sector: Yup.string(),
    tribune: Yup.string(),
    row: Yup.string(),
    gate: Yup.string(),
    floor: Yup.string()
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <TicketingSectionComponent
          initialValues={initialThirdTicketData}
          validationSchema={dataSecondThirdSchema}
          ticketer={false}
          eventTitle={events?.data?.name}
          title={t('ticket.createTicket')}
          pagination={'3/4'}
          description={t('ticket.createTicketTitle3')}
          footer={{
            text: t('ticket.createTicketText3'),
            icon: <ReceiveStar />
          }}
        >
          <TicketsEventThirdPageChildren eventId={eventId} />
        </TicketingSectionComponent>
      )}
    </>
  );
}

export default TicketsEventThirdPage;
