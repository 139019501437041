const routes = {
  // Roots
  home: '/',
  landing: '/landing',
  auth: '/auth',
  dashboard: '/dashboard',
  faq: '/faq',
  faqs: '/dashboard/faqs',
  faqsId: '/dashboard/faqs/:id',
  tibetTerms: '/terms',
  // Nested Dashboard pages
  authEmail: '/auth/mail',
  authEmailOk: '/auth/mail/sent',
  google: '/auth/google/signIn',
  fb: '/auth/fb/signIn',
  homepage: '/dashboard/home',
  profile: '/dashboard/profile',
  events: '/dashboard/events',
  eventsId: '/dashboard/events/:eventId',
  eventsCreatePicture: '/dashboard/events/:eventId/customize-images',
  eventsCreateMiniature: '/dashboard/events/:eventId/customize-miniature',
  eventsCreateSocial: '/dashboard/events/:eventId/customize-social',
  eventsCreateData: '/dashboard/events/:eventId/info-data',
  eventsCreateLocation: '/dashboard/events/:eventId/location',
  eventsCreateDateTime: '/dashboard/events/:eventId/date-and-time',
  eventsCreateNewTickets: '/dashboard/events/:eventId/tickets',
  createPicture: '/customize-images',
  createMiniature: '/customize-miniature',
  createSocial: '/customize-social',
  createData: '/info-data',
  createLocation: '/location',
  createDateTime: '/date-and-time',
  createNewTickets: '/tickets',
  eventsIdCreateTickets: '/dashboard/events/:eventId/create/tickets',
  eventsIdCheckTicketsId: '/dashboard/events/:eventId/tickets/:ticketId',
  createTickets: '/create/tickets',
  tickets: '/tickets',
  eventsCreateTicketsData: '/dashboard/events/:eventId/create/tickets-data',
  eventsCreateTicketsSale: '/dashboard/events/:eventId/create/tickets-sale',
  eventsCreateTicketsAdvance:
    '/dashboard/events/:eventId/create/tickets-advanced',
  eventsCreateTicketsResume: '/dashboard/events/:eventId/create/tickets-resume',
  eventsCreateTicketsId: '/dashboard/events/:eventId/create/tickets/:ticketId',
  createTicketsData: '/create/tickets-data',
  createTicketsSale: '/create/tickets-sale',
  createTicketsAdvance: '/create/tickets-advanced',
  createTicketsResume: '/create/tickets-resume',
  createTicketsId: '/create/tickets/:ticketId',
  ticketer: '/dashboard/ticketer',
  ticketerMyTickets: '/dashboard/my-tickets',
  ticketerMyTicketsId: '/dashboard/my-tickets/:ticketId',
  ticketerEvent: '/landing/:eventElvisId',
  ticketSaleId: '/landing/:eventElvisId/:ticketToSaleId',
  ticketResaleId: '/landing/:eventElvisId/resale/:ticketResaleId',
  checkout: '/landing/:eventElvisId/checkout',
  checkoutResale: '/landing/:eventElvisId/checkout/:ticketResaleId',
  landingPurchaseDone: '/landing/purchase-done',
  ticketerPreview: '/dashboard/preview',
  ticketerPreviewCustomize: '/dashboard/preview-customize',
  eventPreviewCustomizeNoId: '/dashboard/preview-event-customize',
  eventPreviewCustomize: '/dashboard/preview-event-customize/:eventElvisId',
  terms: '/dashboard/ticketer/terms-and-conditions',
  privacy: '/dashboard/ticketer/privacy-policy',
  domain: '/dashboard/ticketer/domain',
  domainChange: '/dashboard/ticketer/domain-change',
  customize: '/dashboard/ticketer/customize-images',
  social: '/dashboard/ticketer/customize-social',
  payment: '/dashboard/ticketer/payment',
  info: '/dashboard/ticketer/company-info',
  infoContact: '/dashboard/ticketer/contact-info',
  infoBilling: '/dashboard/ticketer/billing-info',
  marketplace: '/dashboard/marketplace',
  manageSubscriptions: '/dashboard/manage-subscriptions',
  myGift: '/landing/my-gift',
  simulateStripePurchase: '/simulate/stripe',
  simulateStripeOk: '/simulate/stripe/ok',
  simulateStripeKo: '/simulate/stripe/ko',
  simulateStripeSubscription: '/simulate/stripe/subscription',
  simulateCheckoutMercadopago: '/simulate/mercadopago',
  paymentStripeAccountVerify: '/dashboard/ticketer/payment/stripe/verify',
  paymentSuccess: '/dashboard/ticketer/payment?success=ok',
  paymentFailed: '/dashboard/ticketer/payment?failed=true',
  reader: '/dashboard/reader'
};

export default routes;
