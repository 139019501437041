import React from 'react';

export default function NavbarButton(props) {

  return (
    <button className="navbar-button d-flex flex-row justify-content-between align-items-center" 
      onClick={ props.function }
    >
      { props.text }
    </button>
  )

}