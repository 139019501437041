import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { recoverTicketById } from '../../services/axiosService.js';
import routes from '../../routes/routes.js';
import TicketItem from '../ui/TicketItem.jsx';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';

function MyTicketsList(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState, publicElvisId } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { isLoading, mutate } = useMutation(
    (values) =>
      recoverTicketById(authState.token, publicElvisId, values.itemId),
    {
      onSuccess: (res) => {
        if (res.data) {
          props.setPopup(false);
          props.setRecoveryStatus(true);
        }
      },
      onError: (err) => {
        console.error(err);
        props.setPopup({
          state: 0,
          text:
            t('myTickets.recoverError') +
            '. (' +
            err.response.data.message +
            ')'
        });
      }
    }
  );

  return (
    <div className="my-tickets-content-top d-flex flex-column justify-content-center">
      <h3>{props.title}</h3>
      {props.isLoading ? (
        <SpinnerPurple />
      ) : props.isError ? (
        <h4 className="text-error-red">{t('myTickets.error')}</h4>
      ) : props.tickets?.length ? (
        <div
          className={`my-tickets-container d-flex flex-sm-wrap justify-content-sm-start justify-content-start`}
        >
          {props.tickets?.map((item, index) => {
            return (
              <>
                <TicketItem
                  key={item.id}
                  propsId={props.id}
                  ticketStatus={item.status}
                  eventStatus={item.event?.status ? item.event?.status : null}
                  currency={item.currency?.symbol}
                  ticketImg={item.image ? item.image : null}
                  eventImg={
                    item.event?.images?.cover ? item.event?.images?.cover : null
                  }
                  ticketCategory={
                    item.category?.name ? item.category?.name : null
                  }
                  ticketName={item.name ? item.name : null}
                  eventName={item.event?.name ? item.event?.name : null}
                  eventDate={
                    item.event?.dateEvent ? item.event?.dateEvent : null
                  }
                  eventAddress={
                    item.event?.location ? item.event?.location : null
                  }
                  ticketPrice={
                    item.price
                      ? item.currency?.id === 2
                        ? item.currency?.symbol + Number(item.price).toFixed(2)
                        : Number(item.price).toFixed(2) + item.currency?.symbol
                      : null
                  }
                  ticketContent={item.extraContent ? item.extraContent : null}
                  totalTickets={null}
                  availableTickets={null}
                  secondaryMarketUser={item.ticketsBuyers ?? null}
                  buttonActive={
                    !isLoading
                      ? props.id === 2
                        ? !props.recoverable[index]
                        : false
                      : false
                  }
                  buttonMethod={props.id === 2 ? 'recover' : false}
                  buttonFunction={
                    !isLoading
                      ? props.id === 2
                        ? !props.recoverable[index]
                          ? () => mutate({ itemId: item.id })
                          : null
                        : null
                      : null
                  }
                  function={
                    props.id === 2 || props.id === 3
                      ? null
                      : () => navigate(`${routes.ticketerMyTickets}/${item.id}`)
                  }
                  ticketPaused={false}
                  ticketActivationDate={
                    item.event?.dateActivation
                      ? item.event?.dateActivation
                      : null
                  }
                  saleOpen={null}
                  setTicketSaleOpen={null}
                />
              </>
            );
          })}
        </div>
      ) : (
        <h4>{t('myTickets.noTickets')}</h4>
      )}
    </div>
  );
}

export default MyTicketsList;
