import React from "react";

function UpgradePlanIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="75"
      fill="none"
      viewBox="0 0 86 75"
    >
      <circle
        cx="28.699"
        cy="43.922"
        r="27.421"
        stroke="#816CFF"
        strokeDasharray="1.11 1.11"
        strokeWidth="0.555"
      ></circle>
      <path
        fill="#E0DBFF"
        d="M26.292 64.275c13.57 0 24.569-11 24.569-24.568 0-13.57-11-24.569-24.569-24.569-13.569 0-24.568 11-24.568 24.569 0 13.569 11 24.568 24.568 24.568z"
      ></path>
      <path
        fill="#816CFF"
        d="M69.993 64.14a3.076 3.076 0 100-6.152 3.076 3.076 0 000 6.152z"
      ></path>
      <path
        stroke="#F27327"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.158"
        d="M74.9 19.84v10.233M80.026 24.946H69.794"
      ></path>
      <path
        stroke="#FFDCC7"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.742"
        d="M4.271 8.616v6.556M7.556 11.889H1"
      ></path>
      <path
        fill="#442ECB"
        d="M61.904 25.483c-.108-.418-.549-.63-.962-.506-1.399.418-2.906.569-4.455.41-4.774-.48-8.846-4.132-9.82-8.83-.526-2.538-.178-4.956.75-7.014.179-.396.023-.869-.377-1.034-2.886-1.196-6.06-1.835-9.38-1.835-14.055 0-25.417 11.602-25.021 25.745.363 13.06 11.25 23.943 24.308 24.308 14.143.396 25.745-10.966 25.745-25.021a24.588 24.588 0 00-.788-6.223zM43.177 41.391a6.49 6.49 0 01-3.692 1.832v.999a1.878 1.878 0 01-3.754 0v-1.004a6.557 6.557 0 01-5.467-5.74 1.877 1.877 0 011.657-2.075c1.031-.13 1.957.626 2.075 1.657a2.812 2.812 0 002.793 2.503h1.753c.746 0 1.449-.293 1.982-.826a2.787 2.787 0 00.826-1.982c0-1.29-.876-2.408-2.13-2.726l-4.025-.999a6.614 6.614 0 01-3.59-2.34 6.533 6.533 0 01-1.381-4.028c0-3.257 2.39-5.947 5.507-6.456V19.19a1.878 1.878 0 013.754 0v.991a6.55 6.55 0 015.58 5.752 1.877 1.877 0 01-1.657 2.076 1.871 1.871 0 01-2.075-1.657 2.812 2.812 0 00-2.794-2.504h-1.752a2.81 2.81 0 00-2.225 4.524c.395.51.926.856 1.547 1.011l4.025.999a6.568 6.568 0 014.97 6.368 6.532 6.532 0 01-1.926 4.64zm20.844-25.88l-5.006 5.006c-.173.173-.38.31-.61.405-.231.095-.474.145-.72.145-.245 0-.487-.05-.718-.145a1.875 1.875 0 01-.61-.405L51.35 15.51a1.878 1.878 0 012.656-2.656l1.802 1.802V6.674a1.878 1.878 0 013.755 0v7.983l1.802-1.802a1.878 1.878 0 012.656 2.655z"
      ></path>
      <path
        fill="#FF8C5A"
        d="M13.097 55.695c-4.03 0-7.301 3.27-7.301 7.3 0 4.03 3.27 7.301 7.3 7.301 4.03 0 7.302-3.27 7.302-7.3 0-4.03-3.271-7.301-7.301-7.301zm2.942 5.986l-3.41 3.403a.518.518 0 01-.386.16.542.542 0 01-.387-.16l-1.701-1.702a.55.55 0 010-.773.55.55 0 01.773 0l1.315 1.314 3.022-3.016a.538.538 0 01.774 0 .55.55 0 010 .774z"
      ></path>
    </svg>
  );
}

export default UpgradePlanIcon;
