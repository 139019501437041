import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import TermsPolicyButton from '../../components/ui/TermsPolicyButton.jsx';
import GiftSvg1 from '../../components/icons/GiftSvg1.jsx';
import GiftSvg2 from '../../components/icons/GiftSvg2.jsx';
import routes from '../../routes/routes.js';

export default function LandingGift(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  var navigate = useNavigate();

  const [page, setPage] = useState(0);

  return (
    <main
      id={`gift-main`}
      className={`gift-main d-flex flex-column justify-content-center align-items-center`}
    >
      {!page ? (
        <>
          <GiftSvg2 />
          <div
            className={`gift-main-top d-flex flex-column justify-content-between align-items-center`}
          >
            <h3>{t('gift.giftTitle1')}</h3>
            <div
              className={`gift-main-top-text d-flex flex-column justify-content-between align-items-center`}
            >
              <p>
                <span>{'contacto@tibetpass.com'}</span>
                {t('gift.giftText1') + 'EVENT NAME' + t('gift.giftText2')}
              </p>
            </div>
          </div>
          <div
            className={`gift-main-bottom d-flex flex-column justify-content-between align-items-center`}
          >
            <TermsPolicyButton
              text={t('gift.giftButton1')}
              disabled={false}
              function={() => null}
            />
            <TermsPolicyButton
              text={t('gift.giftButton2')}
              disabled={false}
              function={() => setPage(1)}
            />
          </div>
        </>
      ) : (
        <>
          <GiftSvg1 />
          <div
            className={`gift-main-top d-flex flex-column justify-content-between align-items-center`}
          >
            <h3>{t('gift.giftTitle2')}</h3>
            <div
              className={`gift-main-top-text d-flex flex-column justify-content-between align-items-center`}
            >
              <p>
                {t('gift.giftText3')}
                <span>{'contacto@tibetpass.com'}</span>
                {t('gift.giftText4')}
              </p>
            </div>
          </div>
          <div
            className={`gift-main-bottom d-flex flex-column justify-content-between align-items-center`}
          >
            <TermsPolicyButton
              text={t('gift.giftButton3')}
              disabled={false}
              function={() => navigate(routes.landing)}
            />
          </div>
        </>
      )}
    </main>
  );
}
