import React from "react";

function EventCard2Svg(props) {

  return (
    <svg width="114" height="96" viewBox="0 0 114 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M70.0957 -42.8068C77.0758 -36.7627 82.5644 -29.2013 86.1261 -20.7224C92.6378 -5.2209 92.5717 -5.80503 109.37 -5.29209C117.734 -4.94594 125.992 -3.15122 133.772 0.0112798C146.618 5.17642 148.615 15.7419 138.078 24.3845C129.547 31.0078 120.272 36.632 110.433 41.148C108.187 41.7531 106.232 43.1183 104.903 45.01C103.573 46.9018 102.952 49.2025 103.144 51.5186C102.867 60.4228 102 69.2917 100.547 78.0677C100.072 80.9444 99.1619 83.7276 97.8462 86.3228C92.8475 96.9201 82.5499 99.349 74.8327 90.436C67.6353 81.9744 61.5872 72.6137 56.847 62.5992C55.938 59.8671 54.1193 57.5156 51.6936 55.9365C49.268 54.3574 46.382 53.646 43.5164 53.9208C33.2055 53.6143 22.9644 51.944 13.063 48.9538C-0.852562 44.5713 -3.46552 32.8654 7.60552 23.8477C16.4828 17.0331 26.1392 11.2778 36.3803 6.69775C38.3575 5.95736 40.0298 4.59237 41.1433 2.80999C42.2568 1.02761 42.7505 -1.07477 42.5497 -3.17799C43.2257 -14.925 45.0384 -26.5658 47.9627 -37.9393C50.8339 -47.9625 59.9733 -49.7981 70.0957 -42.8068Z" fill="#FFAF80"/>
    </svg>
  );
}

export default EventCard2Svg;