import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoCircle from '../icons/InfoCircle';
import { styled, Tooltip, tooltipClasses } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#DADADC',
    color: '#484848',
    textAlign: 'center',
    maxWidth: 300,
    padding: '12px, 16px, 12px, 16px',
    borderRadius: '10px',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#DADADC', // Color de la flecha, debe coincidir con el background del tooltip
    '&::before': {
      border: '1px solid #DADADC' // Opcional: añade un borde a la flecha
    }
  }
}));

export default function CheckoutTicketSecondary(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  return (
    <>
      <div
        className={`checkout-tickets d-flex flex-column justify-content-between align-items-center`}
      >
        <div
          className={`checkout-tickets-top d-flex justify-content-between align-items-center`}
        >
          <div
            className={`checkout-tickets-top-left d-flex flex-column justify-content-between`}
          >
            <h4>{t('landingElvis.secondaryTicket')}</h4>
          </div>
          <div
            className={`checkout-tickets-top-right d-flex justify-content-between align-items-center`}
          >
            <h3>{props.ticket?.user.userId}</h3>
          </div>
        </div>
        <div
          className={`checkout-tickets-top d-flex justify-content-between align-items-center`}
        >
          <div
            className={`checkout-tickets-top-left d-flex flex-column justify-content-between`}
          >
            <h4>{t('landingElvis.secondaryOwner')}</h4>
          </div>
          <div
            className={`checkout-tickets-top-right d-flex justify-content-between align-items-center`}
          >
            <h3>{props.owner?.data[0]?.email}</h3>
          </div>
        </div>
        <div className={`checkout-tickets-separator`}></div>
        <div className={`checkout-tickets-bottom d-flex align-items-center`}>
          <HtmlTooltip
            arrow
            title={
              <React.Fragment>
                La compra de reventa es una compra segura ya que se genera
                dentro de la plataforma y es oficial.
              </React.Fragment>
            }
          >
            <div className="d-flex align-items-center gap-2">
              <InfoCircle />
              <h4>{t('landingElvis.secondaryText')}</h4>
            </div>
          </HtmlTooltip>
        </div>
      </div>
      <div
        className={`checkout-tickets d-flex flex-column justify-content-between align-items-center`}
      >
        <div
          className={`checkout-tickets-top d-flex justify-content-between align-items-center`}
        >
          <div
            className={`checkout-tickets-top-left d-flex flex-column justify-content-between`}
          >
            <h4>{t('landingElvis.checkoutTickets1')}</h4>
            <h3>
              {t('landingElvis.checkoutTicket') + props.ticket?.category.name}
            </h3>
          </div>
          <div
            className={`checkout-tickets-top-right d-flex justify-content-between align-items-center`}
          ></div>
        </div>
        <div className={`checkout-tickets-separator`}></div>
        <div
          className={`checkout-tickets-bottom d-flex justify-content-between align-items-center`}
        >
          <h3>{t('landingElvis.checkoutTickets3')}</h3>
          <h3>
            {props.ticket?.currency.id === 1
              ? props.ticket?.currency.symbol +
                String(Number(props.ticket?.price).toFixed(2))
              : String(Number(props.ticket?.price).toFixed(2)) +
                props.ticket?.currency.symbol}
          </h3>
        </div>
      </div>
    </>
  );
}
