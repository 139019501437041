import React from 'react';
import { useTranslation } from 'react-i18next';
const { DateTime } = require('luxon');

function EventDetailDataLanding(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const eventDate = DateTime.fromFormat(
    String(props.event?.dateEvent),
    'yyyy-MM-dd HH:mm:ss',
    { locale: 'es' }
  );
  const openDate = DateTime.fromFormat(
    String(props.event?.dateDoors),
    'yyyy-MM-dd HH:mm:ss',
    { locale: 'es' }
  );

  const getTimezone = props?.event?.timezone;
  const addSplit = getTimezone?.split('/');

  return (
    <>
      <div className="detail-event-info-data-item d-flex flex-column">
        <div className="detail-event-info-data-item-text d-flex flex-column">
          <div className="detail-event-info-data-item-text-top d-flex">
            {props.item.icon}
            <h5>{t(props.item.title)}</h5>
          </div>
          <h4
            className="elipsis-text"
            style={props.item?.id === 0 ? { textTransform: 'capitalize' } : {}}
          >
            {props.item?.id === 0
              ? props.event?.dateEvent
                ? `${eventDate?.weekdayLong}, ${eventDate?.day} ${eventDate?.monthLong}, ${eventDate?.year}`
                : null
              : props.item?.id === 1
              ? props.event?.location
              : props.item?.id === 2
              ? props.event?.dateEvent
                ? `${
                    eventDate?.hour < 10
                      ? `0${eventDate?.hour}`
                      : eventDate?.hour
                  }:${
                    eventDate?.minute < 10
                      ? `0${eventDate?.minute}`
                      : eventDate?.minute
                  } (${props.event?.gmt.replace('UTC', 'GMT')}) ${
                    addSplit?.[addSplit?.length - 1].replace('_', ' ') ?? null
                  }`
                : null
              : props.item?.id === 3
              ? props.event?.dateDoors
                ? `${
                    openDate?.hour < 10 ? `0${openDate?.hour}` : openDate?.hour
                  }:${
                    openDate?.minute < 10
                      ? `0${openDate?.minute}`
                      : openDate?.minute
                  } (${props.event?.gmt.replace('UTC', 'GMT')}) ${
                    addSplit?.[addSplit?.length - 1].replace('_', ' ') ?? null
                  }`
                : null
              : null}
          </h4>
        </div>
      </div>
      {props.item.id !== 3 ? (
        <div className="detail-event-separator"></div>
      ) : null}
    </>
  );
}

export default EventDetailDataLanding;
