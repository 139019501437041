import React from "react";

function Polygon2(props) {

  return (
    <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.42736 0.980645C7.58695 -0.127477 9.41305 -0.127478 10.5726 0.980644L15.6484 5.83109C17.6045 7.70038 16.2814 11 13.5757 11H3.42426C0.718596 11 -0.60449 7.70038 1.35162 5.83109L6.42736 0.980645Z" fill="#816CFF"/>
    </svg>
  );
}

export default Polygon2;