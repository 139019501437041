import React from "react";

function TwitterSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path d="M10.7733 17.8161C12.6394 17.8161 14.1521 16.3033 14.1521 14.4373V10.6065C14.6491 10.8939 15.2261 11.0585 15.8415 11.0585H16.4046V9.36908H15.8415C14.9085 9.36908 14.1521 8.61271 14.1521 7.67969H12.4627V14.4373C12.4627 15.3703 11.7063 16.1267 10.7733 16.1267C9.84029 16.1267 9.08393 15.3703 9.08393 14.4373C9.08393 13.5042 9.84029 12.7479 10.7733 12.7479V11.0585C8.90727 11.0585 7.39453 12.5712 7.39453 14.4373C7.39453 16.3033 8.90727 17.8161 10.7733 17.8161Z" fill="#A5A9B8"/>
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path d="M13.7506 8.24414C12.5658 8.24414 11.6054 9.25263 11.6054 10.4967C11.6054 10.6847 11.6273 10.8673 11.6687 11.0418C10.5295 11.0418 8.81364 10.8115 7.38064 9.37581C7.19008 9.1849 6.83871 9.31254 6.85193 9.58196C7.0643 13.9107 8.91817 15.1734 9.87287 15.252C9.25638 15.8609 8.36086 16.3415 7.45973 16.5572C7.22191 16.6141 7.16316 16.8745 7.39571 16.9501C8.04051 17.1598 8.96345 17.2406 9.46023 17.2542C12.9746 17.2542 15.8311 14.2962 15.8947 10.6218C16.3561 10.3218 16.6516 9.67027 16.8177 9.20873C16.8581 9.09633 16.6739 8.96536 16.5636 9.01127C16.219 9.15477 15.7814 9.18879 15.4086 9.06733C15.0152 8.56471 14.4185 8.24414 13.7506 8.24414Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default TwitterSmall;