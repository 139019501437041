import React from 'react';
import Calendar from '../icons/Calendar';

export default function DateButton(props) {

  return (
    <div className={ props.black ? 
      "home-section-events-vertical-date black-date d-flex flex-column justify-content-center align-items-center"
      :
      "home-section-events-vertical-date d-flex flex-column justify-content-center align-items-center" }
    >
      { !(props.day && props.month) ?
        <Calendar/>
        :
        <>
          <h3>{ props.day }</h3>
          <h4>{ props.month }</h4>
        </>
      }
    </div>
  )

}