import React from 'react';
import { Helmet } from 'react-helmet';

const MetaPixel = () => {
  return (
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-PQVZR2HELY"
      ></script>
      <script>
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-PQVZR2HELY');
      `}
      </script>
    </Helmet>
  );
};

export default MetaPixel;
