import Home from '../icons/Home';
import CalendarCheckAlt from '../icons/CalendarCheckAlt';
import ClipboardList from '../icons/ClipboardList';
import PackageHeart from '../icons/PackageHeart';
import FileHeart from '../icons/FileHeart';
import routes from '../../routes/routes';
import WalletIcon from '../icons/Wallet';
import InfoCircle from '../icons/InfoCircle';
import ScanQr from '../icons/ScanQr';

export const navDataElvis = [
  {
    id: 0,
    icon: <Home />,
    text: 'sidebar.home',
    link: routes.homepage
  },
  {
    id: 1,
    icon: <CalendarCheckAlt />,
    text: 'sidebar.elvis1',
    link: routes.events
  },
  {
    id: 2,
    icon: <ClipboardList />,
    text: 'sidebar.elvis2',
    link: routes.ticketer
  },
  /* {
    id: 3,
    icon: <WalletIcon />,
    text: 'sidebar.elvis6',
    link: routes.manageSubscriptions
  }, */
  {
    id: 4,
    icon: <InfoCircle />,
    text: 'FAQs',
    link: routes.faqs
  },
  {
    id: 5,
    icon: <ScanQr />,
    text: 'Reader',
    link: routes.reader
  }
];

export const navDataFlora = [
  {
    id: 0,
    icon: <Home />,
    text: 'sidebar.home',
    link: routes.homepage
  },
  {
    id: 1,
    icon: <FileHeart />,
    text: 'sidebar.flora',
    link: routes.ticketerMyTickets
  }
];

/*
,
  {
  id: 3,
  icon: <PackageHeart/>,
  text: 'sidebar.elvis3',
  link: routes.marketplace
  }

  */
