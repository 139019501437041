import React from 'react';
import { AppContext } from "../../App.js";

export default function UserPicture(props) {

  const { userData } = React.useContext(AppContext);

  return (
    <div className="user-picture d-flex justify-content-center align-items-center">
      { userData?.picture ? <img src={ userData.picture } alt="author-pic" className=""/> : userData?.name ? userData?.name.split(' ').map(function(item, index){if (index < 2) return item[0]; else return null}).join('') : userData?.userId ? userData?.userId.charAt(0) : null }
    </div>
  )

}