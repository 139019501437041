import React from "react";
import { useTranslation } from "react-i18next";
import ProfileButton from "../ui/ProfileButton";
import PreviewButton from "../ui/PreviewButton";
import AngleLeft from "../icons/AngleLeft"

function DashboardBar(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className={`${props.altColor ? "alt-buttons": ""} bar-section-buttons d-flex flex-row justify-content-between align-items-center`}>
      <div className={`bar-section-buttons-left ${props.emoji} d-flex flex-row align-items-center`}
        onClick={ props.breadcrumFunction } style={{ cursor: props.span ? "pointer" : "default" }}
      >
        { props.breadcrum ? <AngleLeft/> : null }
        { props.text.length > 20 ? props.text.substring(0, 20) + "..." : props.text }
        <span style={{ display: props.span ? "block" : "none" }}>{ props.span }</span>
      </div>
      <div className="bar-section-buttons-right d-flex flex-row align-items-center">
        { props.session ? <h6 className="d-none d-lg-block" onClick={ props.h6function }>{t('profile.closeSession')}</h6> : null }
        {/*<h6 className="d-none d-lg-block" onClick={ () => i18n.changeLanguage("es") }>{t('profile.closeSession')} ES</h6>*/}
        { props.ticketer ? 
          <PreviewButton 
            icon = { props.icon2 } 
            text = { props.buttonText2 } 
            function = { props.function2 }
            disabled = { props.disabled2 }
          />
          : 
          null
        }
        { props.button ? 
          <ProfileButton 
            icon = { props.icon } 
            text = { props.buttonText } 
            function = { props.function }
            disabled = { props.disabled }
          />
          : 
          null
        }
      </div>
    </div>
  );
}

export default DashboardBar;