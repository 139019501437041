import React from "react";

function LandingCardSparkle(props) {

  return (
    <svg width="579" height="442" viewBox="0 0 579 442" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M1207.48 -22.9082L1030.82 -70.0338C868.347 -113.408 743.576 -229.857 697.127 -381.635L646.662 -546.6C638.693 -572.552 592.309 -572.552 584.34 -546.6L533.875 -381.635C487.426 -229.857 362.655 -113.347 200.18 -70.0338L23.5165 -22.9082C9.58825 -19.2181 0 -7.30164 0 6.18835C0 19.6783 9.58825 31.5343 23.5165 35.2849L200.18 82.4106C362.655 125.784 487.426 242.234 533.875 394.012L584.34 558.977C588.357 571.983 601.053 580.936 615.5 580.936C629.947 580.936 642.645 571.983 646.662 558.977L697.127 394.012C743.576 242.234 868.347 125.724 1030.82 82.4106L1207.48 35.2849C1221.41 31.5948 1231 19.6783 1231 6.18835C1231 -7.30164 1221.41 -19.1576 1207.48 -22.9082Z" fill="#816CFF"/>
    </svg>
  );
}

export default LandingCardSparkle;