import React from "react";

function RocketLaunchSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.20774 19.8551C6.41674 20.6461 3.66272 21.3491 3.11872 21.4821C3.07872 21.4921 3.03974 21.4961 2.99974 21.4961C2.86674 21.4961 2.73774 21.4431 2.64274 21.3461C2.51874 21.2201 2.47173 21.0381 2.51673 20.8671C2.66773 20.3001 3.45874 17.4291 4.24574 16.6421C4.68874 16.1991 5.26375 15.9711 5.84175 15.9771C6.40075 15.9901 6.93773 16.2271 7.35273 16.6421C8.19873 17.4891 8.13374 18.9301 7.20774 19.8551ZM17.2077 14.7281L16.9497 16.2991C16.6497 18.1891 15.3497 19.7991 13.5697 20.5091L11.1897 21.4591C11.1297 21.4891 11.0597 21.4991 10.9997 21.4991C10.8897 21.4991 10.7697 21.4591 10.6797 21.3791C10.5397 21.2691 10.4697 21.0791 10.5097 20.8991L11.1727 17.5631C11.0757 17.5821 10.9837 17.6081 10.8847 17.6251C10.7627 17.6461 10.6397 17.6561 10.5177 17.6561C9.93374 17.6561 9.35973 17.4191 8.92973 16.9901L7.00773 15.0671C6.48773 14.5471 6.25072 13.8171 6.37272 13.1121C6.38972 13.0151 6.41573 12.9241 6.43373 12.8281L3.11872 13.4871C3.08872 13.4971 3.04875 13.4971 3.01875 13.4971C2.86875 13.4971 2.72874 13.4371 2.63874 13.3171C2.51874 13.1771 2.48873 12.9771 2.55873 12.8071L3.51875 10.4171C4.21875 8.64708 5.82873 7.34709 7.71873 7.04709L9.26972 6.78809C11.8307 3.85109 15.8527 2.24609 20.9977 2.24609C21.4117 2.24609 21.7477 2.58209 21.7477 2.99609C21.7497 8.14509 20.1457 12.1671 17.2077 14.7281ZM18.9687 9.69409C18.8147 7.18409 16.8137 5.18408 14.3037 5.02908C11.4047 6.27808 8.66075 8.70008 7.85075 13.3691C7.81275 13.5901 7.89574 13.8341 8.06874 14.0061L9.99073 15.9291C10.1627 16.1011 10.4077 16.1831 10.6287 16.1461C15.2987 15.3371 17.7207 12.5941 18.9687 9.69409ZM13.4997 8.24908C12.2567 8.24908 11.2497 9.25608 11.2497 10.4991C11.2497 11.7421 12.2567 12.7491 13.4997 12.7491C14.7427 12.7491 15.7497 11.7421 15.7497 10.4991C15.7497 9.25608 14.7427 8.24908 13.4997 8.24908Z" fill="#816CFF"/>
    </svg>
  );
}

export default RocketLaunchSmall;