import React, { useState } from "react";

const ButtonSwitch = ({ textLeft, textRight, handleButtonClick }) => {
  const [activeButton, setActiveButton] = useState("left");

  const onClickHandleButton = (button) => {
    setActiveButton(button);
    handleButtonClick(button);
  };

  return (
    <div className="button-switch">
      <button
        className={activeButton === "left" ? "active" : ""}
        onClick={() => onClickHandleButton("left")}
      >
        <span>{textLeft}</span>
      </button>
      <button
        className={activeButton === "right" ? "active" : ""}
        onClick={() => onClickHandleButton("right")}
      >
        <span>{textRight}</span>
      </button>
    </div>
  );
};

export default ButtonSwitch;
