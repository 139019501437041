import React from "react";

function AdviceFooter({ footer }) {
  
  return (
    <div className="ticketer-sections-advice d-flex justify-content-center align-items-center">
      { footer.icon }
      { footer.text }
    </div>
  );
}

export default AdviceFooter;