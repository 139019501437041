import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../index.js';
import {
  getTicketingCompanyById,
  getPublicElvisKey
} from '../../services/axiosService.js';
import { getUserById } from '../../services/axiosService';
import { getSubdomain } from '../../components/shared/functions.js';
import Sidebar from '../../components/shared/Sidebar';
import DashboardFooter from '../../components/shared/DashboardFooter';
import routes from '../../routes/routes.js';
import jwtDecode from 'jwt-decode';

function DashboardLayout(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    userData,
    setUserData,
    setTermsModal,
    setTicketingCompany,
    setPublicElvisId
  } = React.useContext(AppContext);

  const subdomain = useMemo(() => getSubdomain(), []);
  var navigate = useNavigate();
  let location = useLocation();

  // NO ESTOY CMPROBANDO SI ES VALIDO EL TOKEN, SOLO COMPRUEBO SI HAY TOKEN, Y COMO SI HAY TOKEN NO ME SACA DE LA SESIÓN
  useEffect(() => {
    const isToken = localStorage.getItem('token');
    if (isToken) {
      const decoded = jwtDecode(isToken);
      const currentTimestamp = Math.floor(Date.now() / 1000); // Fecha actual en segundos
      const expirationTimestamp = decoded?.exp;

      if (expirationTimestamp > currentTimestamp) {
        return;
      } else {
        localStorage.removeItem('token');
        navigate('/auth');
      }
    }
  }, []);

  const { data } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: !!subdomain
      ? () => getPublicElvisKey(subdomain)
      : () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated || !!subdomain,
    onSuccess: (res) => {
      setTicketingCompany(
        !!subdomain ? { data: [res.data] } : { data: [res.data[0]] }
      );
      setPublicElvisId(res.data.apiKey);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        if (authState.isAuthenticated) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      }
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true
  });

  const getUserDataFunction = async (token) => {
    const user = await queryClient.fetchQuery({
      queryKey: ['user'],
      queryFn: () => getUserById(token),
      enabled: !!authState.isAuthenticated,
      onError: (err) => {
        console.error(err.response.status);
        if (err.response.status === 401) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      }
    });
    if (user.data) {
      setUserData(user.data);
      if (user.data.enabled === false && authState.roles[0] !== 'ROLE_USER') {
        setTermsModal(true);
      }
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated && !userData) {
      getUserDataFunction(authState.token);
    }
  }, []);

  return (
    <main
      className={`dashboard-main d-flex flex-column flex-lg-row justify-content-between align-items-start`}
    >
      <Sidebar />
      <div
        className={`dashboard-pages ${
          location.pathname.startsWith('/dashboard/events')
            ? 'events-mobile'
            : ''
        } d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-start`}
      >
        {props.main}
      </div>
      <DashboardFooter />
    </main>
  );
}

export default DashboardLayout;
