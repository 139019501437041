import React from "react";
import { useTranslation } from "react-i18next";

export default function EventStatus(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div
      className={`event-status ${
        props.state ? t(`event-state-color${props.state}`) : null
      } d-flex flex-row justify-content-center align-items-center`}
      onClick={props.function}
      id={props.id}
      ref={props.reference}
    >
      {props.state ? t(`events.state${props.state}`) : null}
      {/* props.state === 1 ? {t('events.state1')} : props.state === 2 ? {t('events.state2')} : props.state === 3 ? {t('events.state1')} : props.state === 1 ? {t('events.state1')} : null */}
    </div>
  );
}
