import React from "react";
import DateButton from "../ui/DateButton";
import Star4 from "../icons/Star4";
import { useTranslation } from "react-i18next";
import EventStatus from "../ui/EventStatus";
import EventButton from "../ui/EventButton";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import AngleRightSmall from "../icons/AngleRightSmall";
import { DateTime } from "luxon";

const EventsComponentItemList = ({ item, elvis }) => {
  const [t, i18n] = useTranslation("global");
  var navigate = useNavigate();

  const eventDate = DateTime.fromFormat(
    String(item.dateEvent),
    "yyyy-MM-dd HH:mm:ss"
  );

  return (
    <div
      key={item.id}
      className="events-section-item d-flex justify-content-between align-items-center"
    >
      <div className="events-section-item-left d-flex justify-content-start align-items-center">
        <DateButton
          day={eventDate?.c?.day}
          month={eventDate?.monthShort}
          black={false}
        />
        <div className="events-section-item-line"></div>
        <div className="events-section-item-info d-flex justify-content-start align-items-center">
          <div className="events-section-item-info-image d-flex justify-content-center align-items-center">
            {item?.images?.thumb ? (
              <img
                src={item?.images?.thumb}
                alt={`event-${item?.id}`}
                className=""
              />
            ) : (
              <Star4 />
            )}
          </div>
          <div className="events-section-item-info-text d-flex flex-column justify-content-center">
            <h2>{item?.name ? item?.name : t("events.nullTitle")}</h2>
            <h5>{item?.location ? item?.location : t("events.nullAddress")}</h5>
          </div>
        </div>
        {elvis ? (
          <>
            <div className="events-section-item-line"></div>
            <div className="events-section-item-tickets">
              <h3>{t("home.sold")}</h3>
              <h4>
                {item?.ticketsSold ? item?.ticketsSold : 0}
                <span>
                  {item?.ticketsTotal ? `/${item?.ticketsTotal}` : `/0`}
                </span>
              </h4>
            </div>
            <div className="events-section-item-line"></div>
          </>
        ) : null}
      </div>
      <div className="events-section-item-right d-flex justify-content-end align-items-center">
        <EventStatus state={item?.status} />
        <EventButton
          function={() => navigate(`${routes.events}/${item?.id}`)}
          text={t("home.see")}
          icon={<AngleRightSmall />}
        />
      </div>
    </div>
  );
};

export default EventsComponentItemList;
