import React from "react";

function Coupon(props) {

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.25 15.832V11.082C33.25 7.91536 31.6667 6.33203 28.5 6.33203H25.4126C25.1497 6.33203 24.9375 6.54418 24.9375 6.80701V11.8737C24.9375 12.5229 24.3992 13.0612 23.75 13.0612C23.1008 13.0612 22.5625 12.5229 22.5625 11.8737V6.80701C22.5625 6.54418 22.3503 6.33203 22.0874 6.33203H9.5C6.33333 6.33203 4.75 7.91536 4.75 11.082V15.832C6.49167 15.832 7.91667 17.257 7.91667 18.9987C7.91667 20.7404 6.49167 22.1654 4.75 22.1654V26.9154C4.75 30.082 6.33333 31.6654 9.5 31.6654H22.0874C22.3503 31.6654 22.5625 31.4532 22.5625 31.1904V26.1237C22.5625 25.4745 23.1008 24.9362 23.75 24.9362C24.3992 24.9362 24.9375 25.4745 24.9375 26.1237V31.1904C24.9375 31.4532 25.1497 31.6654 25.4126 31.6654H28.5C31.6667 31.6654 33.25 30.082 33.25 26.9154V22.1654C31.5083 22.1654 30.0833 20.7404 30.0833 18.9987C30.0833 17.257 31.5083 15.832 33.25 15.832ZM24.9375 20.9779C24.9375 21.6334 24.4055 22.1654 23.75 22.1654C23.0945 22.1654 22.5625 21.6334 22.5625 20.9779V17.0195C22.5625 16.364 23.0945 15.832 23.75 15.832C24.4055 15.832 24.9375 16.364 24.9375 17.0195V20.9779Z" fill="url(#paint0_linear_3251_11635)" fillOpacity="0.8"/>
      <defs>
        <linearGradient id="paint0_linear_3251_11635" x1="3.52299" y1="8.01755" x2="35.807" y2="48.7984" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7D68FF"/>
          <stop offset="0.229167" stopColor="#8976FF" stopOpacity="0.770833"/>
          <stop offset="1" stopColor="#B2A6FF" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Coupon;