import React from "react";

function ShieldExclamationSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0181 2.5C9.77807 3.611 8 4.5 3 5.5C3 6.637 3 10.2019 3 11.3889C3 18.0559 8.667 21.389 12 22.5C15.333 21.389 21 18.0559 21 11.3889C21 10.1639 21 6.694 21 5.5C16 4.5 14.2221 3.611 12.0181 2.5ZM11.25 9C11.25 8.586 11.586 8.25 12 8.25C12.414 8.25 12.75 8.586 12.75 9V12C12.75 12.414 12.414 12.75 12 12.75C11.586 12.75 11.25 12.414 11.25 12V9ZM12.02 16C11.468 16 11.0149 15.552 11.0149 15C11.0149 14.448 11.458 14 12.01 14H12.02C12.573 14 13.02 14.448 13.02 15C13.02 15.552 12.572 16 12.02 16Z" fill="#816CFF"/>
    </svg>
  );
}

export default ShieldExclamationSmall;