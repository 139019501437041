import React from "react";

function TermsImage(props) {

  return (
    <svg width="114" height="117" viewBox="0 0 114 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="44.2345" cy="77.5372" r="38.3122" stroke="#816CFF" strokeWidth="0.776102" strokeDasharray="1.55 1.55"/>
      <path d="M40.8726 105.971C59.8308 105.971 75.1995 90.6023 75.1995 71.6442C75.1995 52.686 59.8308 37.3174 40.8726 37.3174C21.9145 37.3174 6.5459 52.686 6.5459 71.6442C6.5459 90.6023 21.9145 105.971 40.8726 105.971Z" fill="#E0DBFF"/>
      <path d="M8.59151 63.0963C10.965 63.0963 12.8892 61.1721 12.8892 58.7986C12.8892 56.4251 10.965 54.501 8.59151 54.501C6.21798 54.501 4.29395 56.4251 4.29395 58.7986C4.29395 61.1721 6.21798 63.0963 8.59151 63.0963Z" fill="#816CFF"/>
      <path d="M79.6343 70.9891C78.8262 71.7753 78.4568 72.9083 78.6457 74.0151L81.18 88.7159C81.6939 91.6982 78.5579 93.9731 75.8743 92.5676L62.5927 85.6148C61.5957 85.0921 60.4098 85.0921 59.4128 85.6148L46.1392 92.559C43.4468 93.9689 40.3066 91.6892 40.8249 88.7025L43.355 74.0108C43.5482 72.8996 43.175 71.7707 42.3669 70.9845L31.2942 60.2413C29.2606 58.2692 30.3802 54.8214 33.1868 54.4174L48.5026 52.2036C49.6182 52.0411 50.5797 51.3428 51.0804 50.337L57.7126 36.9541C59.0566 34.2397 62.9396 34.2397 64.288 36.9541L70.9202 50.337C71.4209 51.3428 72.3824 52.0411 73.498 52.2036L88.8138 54.4174C91.6204 54.8258 92.7449 58.2692 90.707 60.2413L79.6343 70.9891ZM60.9982 26.0176C62.8165 26.0176 64.2923 24.5418 64.2923 22.7235V13.9391C64.2923 12.1208 62.8165 10.645 60.9982 10.645C59.1798 10.645 57.704 12.1208 57.704 13.9391V22.7235C57.704 24.5418 59.1798 26.0176 60.9982 26.0176ZM87.3512 43.5863C89.1695 43.5863 90.6453 42.1105 90.6453 40.2921V13.9391C90.6453 12.1208 89.1695 10.645 87.3512 10.645C85.5328 10.645 84.057 12.1208 84.057 13.9391V40.2921C84.057 42.1105 85.5328 43.5863 87.3512 43.5863ZM34.6452 43.5863C36.4635 43.5863 37.9393 42.1105 37.9393 40.2921V13.9391C37.9393 12.1208 36.4635 10.645 34.6452 10.645C32.8268 10.645 31.351 12.1208 31.351 13.9391V40.2921C31.351 42.1105 32.8268 43.5863 34.6452 43.5863Z" fill="#442ECB"/>
      <path d="M103.428 70.7627V85.0582" stroke="#F27327" strokeWidth="1.61846" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M110.589 77.895H96.2939" stroke="#F27327" strokeWidth="1.61846" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M10.105 28.2119V37.3721" stroke="#FFDCC7" strokeWidth="1.03706" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M14.6938 32.7832H5.53369" stroke="#FFDCC7" strokeWidth="1.03706" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M13.4472 89.9551C7.39351 89.9551 2.48633 94.8623 2.48633 100.916C2.48633 106.97 7.39351 111.877 13.4472 111.877C19.5009 111.877 24.4081 106.97 24.4081 100.916C24.4081 94.8623 19.5009 89.9551 13.4472 89.9551ZM17.7735 97.3724L13.6862 106.059C13.4549 106.55 12.738 106.493 12.5879 105.97L11.9905 103.879C11.7823 103.15 11.2134 102.581 10.4845 102.373L8.39311 101.775C7.87028 101.626 7.81219 100.908 8.30434 100.677L16.9908 96.5897C17.4895 96.3551 18.008 96.8737 17.7735 97.3724Z" fill="#FF8C5A"/>
    </svg>
  );
}

export default TermsImage;