import React from "react";

function SaleSvg1(props) {

  return (
    <svg width="125" height="112" viewBox="0 0 125 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7022 11.0706C23.7592 11.0706 26.2376 8.59237 26.2376 5.53531C26.2376 2.47824 23.7592 0 20.7022 0C17.6451 0 15.167 2.47824 15.167 5.53531C15.167 8.59237 17.6451 11.0706 20.7022 11.0706Z" fill="#816CFF"/>
      <rect x="0.560127" y="36.4879" width="84.9576" height="62.7643" rx="8.26719" stroke="#E3DEFC" strokeWidth="0.718886" strokeDasharray="1.44 1.44"/>
      <rect x="12.0708" y="32.5176" width="77.9346" height="55.2252" rx="10.3225" fill="#8C79FF"/>
      <path d="M24.2805 99.0593C23.0697 105.304 15.1658 108.902 15.1658 108.902C15.1658 108.902 7.26184 105.304 6.04998 99.0593C5.30677 95.2276 6.93054 91.3732 11.0585 91.3536C14.1336 91.3391 15.1658 94.4379 15.1658 94.4379C15.1658 94.4379 16.1981 91.3381 19.2721 91.3536C23.4094 91.3732 25.0237 95.2287 24.2805 99.0593Z" fill="#FF5F00"/>
      <path d="M109.692 73.5546L108.284 73.1525C106.99 72.7824 105.996 71.7889 105.626 70.4939L105.224 69.0865C105.16 68.8651 104.791 68.8651 104.727 69.0865L104.325 70.4939C103.955 71.7889 102.961 72.7829 101.667 73.1525L100.259 73.5546C100.148 73.586 100.072 73.6877 100.072 73.8028C100.072 73.9179 100.148 74.0191 100.259 74.0511L101.667 74.4531C102.961 74.8232 103.955 75.8167 104.325 77.1117L104.727 78.5191C104.759 78.6301 104.86 78.7065 104.975 78.7065C105.091 78.7065 105.192 78.6301 105.224 78.5191L105.626 77.1117C105.996 75.8167 106.99 74.8227 108.284 74.4531L109.692 74.0511C109.803 74.0196 109.879 73.9179 109.879 73.8028C109.879 73.6877 109.803 73.5866 109.692 73.5546Z" fill="#F27327"/>
      <path d="M122.092 47.5443L119.511 46.8071C117.138 46.1287 115.316 44.3072 114.637 41.9331L113.9 39.3528C113.784 38.9469 113.106 38.9469 112.99 39.3528L112.253 41.9331C111.575 44.3072 109.752 46.1297 107.379 46.8071L104.799 47.5443C104.595 47.602 104.455 47.7884 104.455 47.9994C104.455 48.2104 104.595 48.3959 104.799 48.4545L107.379 49.1917C109.752 49.8701 111.575 51.6916 112.253 54.0657L112.99 56.646C113.049 56.8495 113.234 56.9895 113.445 56.9895C113.656 56.9895 113.842 56.8495 113.9 56.646L114.637 54.0657C115.316 51.6916 117.138 49.8692 119.511 49.1917L122.092 48.4545C122.295 48.3968 122.435 48.2104 122.435 47.9994C122.435 47.7884 122.295 47.6029 122.092 47.5443Z" fill="#FF8C5A"/>
      <path d="M74.5195 103.224C76.5149 103.224 78.1325 101.607 78.1325 99.6114C78.1325 97.6161 76.5149 95.9985 74.5195 95.9985C72.5242 95.9985 70.9067 97.6161 70.9067 99.6114C70.9067 101.607 72.5242 103.224 74.5195 103.224Z" fill="#B2A6FF"/>
      <path d="M94.9507 40.5V29.25C94.9507 21.75 91.2007 18 83.7007 18H38.7007C31.2007 18 27.4507 21.75 27.4507 29.25V40.5C31.5757 40.5 34.9507 43.875 34.9507 48C34.9507 52.125 31.5757 55.5 27.4507 55.5V66.75C27.4507 74.25 31.2007 78 38.7007 78H83.7007C91.2007 78 94.9507 74.25 94.9507 66.75V55.5C90.8257 55.5 87.4507 52.125 87.4507 48C87.4507 43.875 90.8257 40.5 94.9507 40.5ZM76.598 46.08L70.8609 51.63C70.4409 52.035 70.2492 52.62 70.3505 53.1938L71.6633 60.7875C71.9296 62.3288 70.3058 63.5025 68.9108 62.7788L62.0256 59.1863C61.5081 58.9163 60.8933 58.9163 60.3758 59.1863L53.4942 62.775C52.0992 63.5025 50.4718 62.325 50.738 60.7837L52.0509 53.1938C52.1484 52.62 51.9567 52.035 51.5405 51.63L45.8033 46.08C44.7496 45.06 45.3307 43.2788 46.7857 43.0725L54.7242 41.9288C55.3017 41.8463 55.8008 41.4862 56.0595 40.965L59.4983 34.0538C60.1958 32.6513 62.2093 32.6513 62.9068 34.0538L66.3455 40.965C66.6042 41.4862 67.1033 41.8463 67.6808 41.9288L75.6193 43.0725C77.0743 43.2825 77.6518 45.06 76.598 46.08Z" fill="#3623AA"/>
    </svg>
  );
}

export default SaleSvg1;