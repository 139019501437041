import React from "react";

function SparkleSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1371 11.519L18.4101 10.74C15.9021 10.023 13.9761 8.09802 13.2591 5.58902L12.4801 2.86203C12.3571 2.43303 11.6411 2.43303 11.5181 2.86203L10.7391 5.58902C10.0221 8.09802 8.09607 10.024 5.58807 10.74L2.86105 11.519C2.64605 11.58 2.49805 11.777 2.49805 12C2.49805 12.223 2.64605 12.419 2.86105 12.481L5.58807 13.26C8.09607 13.977 10.0221 15.902 10.7391 18.411L11.5181 21.138C11.5801 21.353 11.7761 21.501 11.9991 21.501C12.2221 21.501 12.4181 21.353 12.4801 21.138L13.2591 18.411C13.9761 15.902 15.9021 13.976 18.4101 13.26L21.1371 12.481C21.3521 12.42 21.5001 12.223 21.5001 12C21.5001 11.777 21.3521 11.581 21.1371 11.519Z" fill="#816CFF"/>
    </svg>
  );
}

export default SparkleSmall;