import React from "react";

function EmptyImage(props) {

  return (
    <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="87" height="87" rx="8" fill="#EFF0F2"/>
      <path d="M57.0957 23.6738H29.9052C24.4263 23.6738 21.4082 26.692 21.4082 32.1709V54.8296C21.4082 60.3085 24.4263 63.3267 29.9052 63.3267H57.0957C62.5746 63.3267 65.5928 60.3085 65.5928 54.8296V32.1709C65.5928 26.692 62.5746 23.6738 57.0957 23.6738ZM29.9052 27.0726H57.0957C60.669 27.0726 62.194 28.5976 62.194 32.1709V48.5985L51.9068 38.3113C51.0231 37.4276 49.5731 37.4276 48.6894 38.3113L37.4959 49.5046C37.0653 49.9351 36.3404 49.9351 35.9099 49.5046L33.7798 47.3748C32.8961 46.4911 31.4461 46.4911 30.5624 47.3748L24.807 53.1302V32.1709C24.807 28.5976 26.332 27.0726 29.9052 27.0726ZM31.6046 36.7026C31.6046 35.1392 32.86 33.8703 34.4212 33.8703H34.4439C36.0051 33.8703 37.2715 35.1392 37.2715 36.7026C37.2715 38.2661 36.0074 39.535 34.4439 39.535C32.8827 39.535 31.6046 38.2661 31.6046 36.7026Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default EmptyImage;