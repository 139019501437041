import React from "react";

function ModalTermsProfile(props) {

  return (
    <svg width="87" height="78" viewBox="0 0 87 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4837 7.81037C16.606 7.81037 18.3265 6.08994 18.3265 3.96768C18.3265 1.84543 16.606 0.125 14.4837 0.125C12.3615 0.125 10.6411 1.84543 10.6411 3.96768C10.6411 6.08994 12.3615 7.81037 14.4837 7.81037Z" fill="#816CFF"/>
      <rect x="0.49953" y="25.4551" width="58.9787" height="43.5718" rx="5.73919" stroke="#816CFF" strokeWidth="0.49906" strokeDasharray="1 1"/>
      <rect x="8.49121" y="22.6978" width="54.1032" height="38.3381" rx="7.16599" fill="#E0DBFF"/>
      <path d="M51.8414 55.7957H28.5519C22.9205 55.7957 19.8184 52.6936 19.8184 47.0622V23.7727C19.8184 18.1413 22.9205 15.0391 28.5519 15.0391H35.5388C36.5029 15.0391 37.2855 15.8217 37.2855 16.7859C37.2855 17.75 36.5029 18.5326 35.5388 18.5326H28.5519C24.8792 18.5326 23.3118 20.0999 23.3118 23.7727V47.0622C23.3118 50.7349 24.8792 52.3023 28.5519 52.3023H51.8414C55.5141 52.3023 57.0815 50.7349 57.0815 47.0622V40.0753C57.0815 39.1111 57.864 38.3286 58.8282 38.3286C59.7924 38.3286 60.5749 39.1111 60.5749 40.0753V47.0622C60.5749 52.6936 57.4728 55.7957 51.8414 55.7957ZM62.508 16.8557L58.7583 13.1061C57.4308 11.8019 55.3115 11.8019 53.984 13.1294L50.84 16.2968L59.3174 24.7741L62.4847 21.63C63.8122 20.3025 63.8122 18.1832 62.508 16.8557ZM48.3713 18.7655L33.2098 33.9968V42.4043H41.6173L56.8487 27.2428L48.3713 18.7655Z" fill="#442ECB"/>
      <path d="M16.966 68.892C16.1254 73.2274 10.6384 75.7247 10.6384 75.7247C10.6384 75.7247 5.15139 73.2274 4.3101 68.892C3.79415 66.2319 4.9214 63.5561 7.78708 63.5425C9.92183 63.5325 10.6384 65.6837 10.6384 65.6837C10.6384 65.6837 11.355 63.5318 13.4891 63.5425C16.3612 63.5561 17.4819 66.2327 16.966 68.892Z" fill="#FF8C5A"/>
      <path d="M76.2609 51.1897L75.2838 50.9106C74.3852 50.6537 73.6951 49.964 73.4382 49.065L73.1591 48.0879C73.115 47.9342 72.8585 47.9342 72.8144 48.0879L72.5353 49.065C72.2784 49.964 71.5883 50.6541 70.6897 50.9106L69.7126 51.1897C69.6356 51.2116 69.5825 51.2822 69.5825 51.3621C69.5825 51.442 69.6356 51.5122 69.7126 51.5344L70.6897 51.8135C71.5883 52.0704 72.2784 52.7602 72.5353 53.6591L72.8144 54.6362C72.8366 54.7133 72.9068 54.7663 72.9867 54.7663C73.0666 54.7663 73.1369 54.7133 73.1591 54.6362L73.4382 53.6591C73.6951 52.7602 74.3852 52.0701 75.2838 51.8135L76.2609 51.5344C76.3379 51.5126 76.3909 51.442 76.3909 51.3621C76.3909 51.2822 76.3379 51.212 76.2609 51.1897Z" fill="#F27327"/>
      <path d="M84.8686 33.1289L83.0773 32.6172C81.4299 32.1462 80.1647 30.8817 79.6937 29.2336L79.182 27.4423C79.1012 27.1605 78.6309 27.1605 78.5501 27.4423L78.0384 29.2336C77.5674 30.8817 76.3022 32.1469 74.6548 32.6172L72.8635 33.1289C72.7222 33.169 72.625 33.2984 72.625 33.4449C72.625 33.5914 72.7222 33.7201 72.8635 33.7609L74.6548 34.2726C76.3022 34.7436 77.5674 36.0081 78.0384 37.6562L78.5501 39.4475C78.5908 39.5887 78.7196 39.6859 78.866 39.6859C79.0125 39.6859 79.1413 39.5887 79.182 39.4475L79.6937 37.6562C80.1647 36.0081 81.4299 34.7429 83.0773 34.2726L84.8686 33.7609C85.0099 33.7208 85.1071 33.5914 85.1071 33.4449C85.1071 33.2984 85.0099 33.1697 84.8686 33.1289Z" fill="#FF8C5A"/>
      <path d="M51.845 71.7847C53.2302 71.7847 54.3531 70.6618 54.3531 69.2767C54.3531 67.8915 53.2302 66.7686 51.845 66.7686C50.4598 66.7686 49.3369 67.8915 49.3369 69.2767C49.3369 70.6618 50.4598 71.7847 51.845 71.7847Z" fill="#B2A6FF"/>
    </svg>
  );
}

export default ModalTermsProfile;