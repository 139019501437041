import React from "react";

function EmptyTickets(props) {

  return (
    <svg width="106" height="82" viewBox="0 0 106 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.2249 81.2254C34.006 81.2254 35.45 79.7815 35.45 78.0004C35.45 76.2193 34.006 74.7754 32.2249 74.7754C30.4438 74.7754 29 76.2193 29 78.0004C29 79.7815 30.4438 81.2254 32.2249 81.2254Z" fill="#DEDEDE"/>
      <rect x="32.4267" y="25.2021" width="62.8178" height="55.1466" rx="3.62105" stroke="#DADADC" strokeWidth="0.853425" strokeDasharray="1.28 1.28"/>
      <path d="M103.615 62.3749L101.342 61.7257C99.252 61.1282 97.647 59.524 97.0495 57.4332L96.4004 55.1607C96.2979 54.8032 95.7012 54.8032 95.5987 55.1607L94.9496 57.4332C94.3521 59.524 92.7471 61.129 90.6571 61.7257L88.3845 62.3749C88.2054 62.4257 88.082 62.5899 88.082 62.7757C88.082 62.9615 88.2054 63.1249 88.3845 63.1765L90.6571 63.8257C92.7471 64.4232 94.3521 66.0274 94.9496 68.1182L95.5987 70.3907C95.6504 70.5699 95.8137 70.6932 95.9995 70.6932C96.1854 70.6932 96.3487 70.5699 96.4004 70.3907L97.0495 68.1182C97.647 66.0274 99.252 64.4224 101.342 63.8257L103.615 63.1765C103.794 63.1257 103.917 62.9615 103.917 62.7757C103.917 62.5899 103.794 62.4265 103.615 62.3749Z" fill="#C9C9C9"/>
      <rect y="3.00195" width="63" height="52" rx="4.26867" fill="#F0F0F0"/>
      <path d="M78 34.1084V24.1084C78 17.4417 74.6667 14.1084 68 14.1084H28C21.3333 14.1084 18 17.4417 18 24.1084V34.1084C21.6667 34.1084 24.6667 37.1084 24.6667 40.7751C24.6667 44.4417 21.6667 47.4417 18 47.4417V57.4417C18 64.1084 21.3333 67.4417 28 67.4417H68C74.6667 67.4417 78 64.1084 78 57.4417V47.4417C74.3333 47.4417 71.3333 44.4417 71.3333 40.7751C71.3333 37.1084 74.3333 34.1084 78 34.1084ZM61.0835 39.2551C59.3435 48.1518 48 53.2751 48 53.2751C48 53.2751 36.6565 48.1518 34.9165 39.2551C33.8498 33.7951 36.1802 28.3051 42.1069 28.2751C46.5202 28.2551 48 32.6684 48 32.6684C48 32.6684 49.4798 28.2517 53.8932 28.2751C59.8298 28.3051 62.1502 33.7951 61.0835 39.2551Z" fill="#C9C9C9"/>
    </svg>
  );
}

export default EmptyTickets;