import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import HomeEventHorizontal from '../../components/shared/HomeEventHorizontal.jsx';
import AngleWithCircle from '../../components/icons/AngleWithCircle.jsx';
import ContentField from '../../components/ui/ContentField.jsx';
import HistoryItem from '../../components/ui/HistoryItem.jsx';
import TermsPolicyButton from './TermsPolicyButton.jsx';
import ScanQr from '../icons/ScanQr.jsx';
import Expand from '../icons/Expand.jsx';
import Polygon2 from '../icons/Polygon2.jsx';
import InfoCircle from '../icons/InfoCircle.jsx';
import User from '../icons/User.jsx';
import TicketOpenedLocation from './TicketOpenedLocation.jsx';
import QRCode from 'react-qr-code';
import { DateTime } from 'luxon';

export default function TicketOpened({
  event,
  fieldStatus,
  setFieldStatus,
  ticket,
  ownUser,
  qrShowed,
  onGift,
  setContentStatus,
  setSaleStatus,
  setGiftStatus,
  landing,
  historyShowed,
  secondaryMarket
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { elvis, setQrModal } = React.useContext(AppContext);

  const [qrCodeState, setQrCodeState] = useState(false);

  const activationDate = DateTime.fromFormat(
    String(ticket?.event?.dateActivation),
    'yyyy-MM-dd HH:mm:ss',
    { locale: 'es' }
  );

  return (
    <div
      className={`ticket-opened-sale-detail d-flex flex-column justify-content-between align-items-center`}
    >
      <div
        className={`ticket-opened-sale-top d-flex flex-column justify-content-between align-items-center`}
      >
        {!qrCodeState ? (
          <HomeEventHorizontal
            key={null}
            item={event}
            function={null}
            elvis={true}
            landing={true}
            secondaryMarketPrice={secondaryMarket ? ticket?.price : null}
            secondaryMarketCurrency={secondaryMarket ? ticket?.currency : null}
            ticket={ticket ? ticket : null}
          />
        ) : (
          <div
            className={`ticket-opened-qr-img d-flex justify-content-center align-items-center`}
          >
            <div
              className={`ticket-opened-qr-img-max d-flex justify-content-center align-items-center`}
              onClick={() => setQrModal(qrShowed)}
            >
              <Expand />
            </div>
            <QRCode
              size={128}
              style={{ height: 'auto', maxWidth: '50%', width: '100%' }}
              value={qrShowed}
              viewBox={`0 0 128 128`}
            />
          </div>
        )}
        {ticket?.onSaleUser ? (
          elvis || landing ? null : (
            <div
              className={`ticket-sale-detail-sale d-flex justify-content-center align-items-center`}
            >
              {elvis || landing ? (
                <>
                  {/*<>
                  {t('ticket.sellFor')} 
                  <User/>
                  <span>{ ticket?.user?.name ? ticket?.user?.name + (ticket?.user?.surname ? " " + ticket?.user?.surname : "" ) : ticket?.user?.userId }</span>
                </>*/}
                </>
              ) : (
                <>
                  <div className={`green-dot`}></div>
                  {t('ticket.ticketStatus1')}
                </>
              )}
            </div>
          )
        ) : onGift ? (
          <div
            className={`ticket-sale-detail-sale d-flex justify-content-center align-items-center`}
          >
            <div className={`yellow-dot`}></div>
            {t('ticket.ticketStatus2')}
          </div>
        ) : !landing ? (
          <div
            className={`ticket-opened-qr-button d-flex flex-column justify-content-between align-items-center`}
          >
            <TermsPolicyButton
              text={
                qrCodeState ? t('myTickets.qrButton2') : t('myTickets.qrButton')
              }
              icon={<ScanQr />}
              disabled={!qrShowed}
              function={() => setQrCodeState(!qrCodeState)}
            />
            {activationDate && !qrShowed ? (
              <div
                className={`ticket-opened-qr-text d-flex justify-content-center align-items-center`}
              >
                <Polygon2 />
                <InfoCircle />
                {t('myTickets.qrAvailable') +
                  (activationDate
                    ? `${
                        activationDate?.c.day < 10
                          ? `0${activationDate?.c.day}`
                          : activationDate?.c.day
                      }-${
                        activationDate?.c.month < 10
                          ? `0${activationDate?.c.month}`
                          : activationDate?.c.month
                      }-${activationDate?.c.year}`
                    : '')}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div
        className={`ticket-sale-detail-info d-flex flex-column justify-content-between align-items-center`}
      >
        <TicketOpenedLocation
          fieldStatus={fieldStatus}
          setFieldStatus={setFieldStatus}
          ticket={ticket}
        />
        <div
          className={`ticket-sale-detail-info-description ${
            fieldStatus[1] ? 'active' : ''
          } d-flex flex-column justify-content-between align-items-center`}
        >
          <div
            className={`ticket-sale-detail-info-bar d-flex justify-content-between align-items-center`}
          >
            <h3>{t('landingElvis.info2')}</h3>
            <AngleWithCircle
              function={() =>
                setFieldStatus([
                  fieldStatus[0],
                  !fieldStatus[1],
                  fieldStatus[2]
                ])
              }
            />
          </div>
          <p>
            {ticket?.description
              ? ticket?.description
              : event?.description
              ? event?.description
              : '-'}
          </p>
        </div>
        {historyShowed ? (
          <div
            className={`ticket-sale-detail-info-history ${
              fieldStatus[2] ? 'active' : ''
            } d-flex flex-column justify-content-between align-items-center`}
          >
            <div
              className={`ticket-sale-detail-info-bar d-flex justify-content-between align-items-center`}
            >
              <h3>{t('landingElvis.info3')}</h3>
              <AngleWithCircle
                function={() =>
                  setFieldStatus([
                    fieldStatus[0],
                    fieldStatus[1],
                    !fieldStatus[2]
                  ])
                }
              />
            </div>
            {historyShowed?.data?.activity?.map((item, index) => {
              const activityDate = DateTime.fromISO(item.createdAt);
              if (item.operation !== 'create' && activityDate?.c) {
                return (
                  <HistoryItem
                    key={index}
                    order={index}
                    date={`${
                      activityDate?.c.day < 10
                        ? `0${activityDate?.c.day}`
                        : activityDate?.c.day
                    }-${
                      activityDate?.c.month < 10
                        ? `0${activityDate?.c.month}`
                        : activityDate?.c.month
                    }-${activityDate?.c.year}`}
                    email={item.newUser?.userId}
                    price={Number(item.ticket?.purchasePrice)}
                    currency={ticket?.currency}
                    ownUser={ownUser}
                  />
                );
              } else return null;
            })}
          </div>
        ) : null}
      </div>
      <div
        className={`ticket-sale-detail-content d-flex flex-column justify-content-between align-items-center`}
      >
        <h3>{t('landingElvis.content')}</h3>
        {!Array.isArray(ticket?.extraContent) ? (
          <>
            {ticket?.extraContent?.photo?.map((item, index) => {
              return (
                <ContentField
                  key={index}
                  type={'image'}
                  text={item.title + '.' + item.type}
                  functionClick={() => setContentStatus(item)}
                />
              );
            })}
            {ticket?.extraContent?.video?.map((item, index) => {
              return (
                <ContentField
                  key={index}
                  type={'video'}
                  text={item.title + '.' + item.type}
                  functionClick={() => setContentStatus(item)}
                />
              );
            })}
            {ticket?.extraContent?.audio?.map((item, index) => {
              return (
                <ContentField
                  key={index}
                  type={'audio'}
                  text={item.title + '.' + item.type}
                  functionClick={() => setContentStatus(item)}
                />
              );
            })}
            {ticket?.extraContent?.document?.map((item, index) => {
              return (
                <ContentField
                  key={index}
                  type={'document'}
                  text={item.title + '.' + item.type}
                  functionClick={() => setContentStatus(item)}
                />
              );
            })}
          </>
        ) : (
          <h4>{t('ticket.ticketInfo16')}</h4>
        )}
      </div>
      {elvis || landing || onGift ? null : !ticket?.accessCode && ticket ? (
        <div
          className={`ticket-sale-detail-buttons d-flex flex-column justify-content-between align-items-center`}
        >
          {ticket?.onSaleUser ? (
            <TermsPolicyButton
              text={t('myTickets.cancelSaleTicket')}
              icon={null}
              disabled={false}
              function={() => setSaleStatus(1)}
            />
          ) : (
            <>
              {ticket?.resale ? (
                <TermsPolicyButton
                  text={t('myTickets.sellTicket')}
                  icon={null}
                  disabled={false}
                  function={() => setSaleStatus(1)}
                />
              ) : null}
              <TermsPolicyButton
                text={t('myTickets.giveTicket')}
                icon={null}
                disabled={false}
                function={() => setGiftStatus(1)}
              />
            </>
          )}
        </div>
      ) : null}
    </div>
  );
}
