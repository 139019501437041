import React from "react";

function LogoNew(props) {

  return (
    <svg width="200" height="43" viewBox="0 0 200 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.469 33.6019C29.8396 29.0711 25.2854 21.2831 25.1341 12.4035C25.1899 9.13914 22.6534 7.52859 20.1187 7.56462C17.5839 7.52679 15.0492 9.13553 15.105 12.4035C14.9537 21.2831 10.3995 29.0711 2.7701 33.6019C-2.77496 36.8932 2.26387 45.5675 7.83234 42.2617C13.0045 39.1919 17.1678 34.9854 20.1205 30.0619C23.0731 34.9854 27.2364 39.1919 32.4086 42.2617C37.9716 45.5639 43.0213 36.8986 37.4708 33.6019H37.469Z" fill="url(#paint0_linear_4627_25791)"/>
      <path d="M38.4854 16.2821C33.5367 11.3333 28.5879 6.38456 23.641 1.43761C21.726 -0.4774 18.4688 -0.481003 16.5502 1.43761C11.6177 6.36654 6.68694 11.2973 1.7562 16.228C-2.81243 20.7984 4.27652 27.891 8.84695 23.3188C12.5959 19.5698 16.3448 15.8209 20.0938 12.0719C23.8607 15.8389 27.6277 19.604 31.3929 23.371C35.9633 27.9396 43.0559 20.8507 38.4836 16.2803L38.4854 16.2821Z" fill="#C6BDFE"/>
      <path d="M61.6917 19.4166H65.6587V24.8553H61.6917V32.4595C61.6917 34.1367 62.5907 34.5853 63.94 34.5853C64.594 34.5853 65.2083 34.4628 65.5758 34.3799V39.5322C65.3308 39.6547 64.3075 40.1051 62.3457 40.1051C58.1337 40.1051 55.5576 37.61 55.5576 33.5638V24.8553H51.96V19.4166H52.9814C55.1072 19.4166 56.1305 17.9862 56.1305 16.1054V13.4878H61.6917V19.4166Z" fill="white"/>
      <path d="M73.1391 9.56396C75.1838 9.56396 76.8196 11.1997 76.8196 13.203C76.8196 15.2063 75.1838 16.8421 73.1391 16.8421C71.0944 16.8421 69.5415 15.2063 69.5415 13.203C69.5415 11.1997 71.1773 9.56396 73.1391 9.56396ZM70.073 39.7393V19.4164H76.2882V39.7375H70.073V39.7393Z" fill="white"/>
      <path d="M81.7251 39.7374V10.135H87.8178V21.3386C88.6771 20.0703 90.9254 18.9264 93.7051 18.9264C99.5925 18.9264 103.15 23.4248 103.15 29.5157C103.15 35.6066 99.1439 40.1878 93.4601 40.1878C90.7615 40.1878 88.6771 39.0024 87.7367 37.4477V39.7374H81.7269H81.7251ZM92.3558 24.5273C89.9436 24.5273 87.7349 26.1216 87.7349 29.5571C87.7349 32.9926 89.9436 34.6266 92.3558 34.6266C94.768 34.6266 96.9353 32.9494 96.9353 29.5571C96.9353 26.1649 94.768 24.5273 92.3558 24.5273Z" fill="white"/>
      <path d="M125.637 33.8915C124.655 37.4081 121.425 40.3517 116.274 40.3517C110.673 40.3517 105.685 36.3452 105.685 29.5175C105.685 22.6897 110.551 18.8057 115.826 18.8057C122.122 18.8057 126.006 22.6897 126.006 29.231C126.006 30.0903 125.925 31.0307 125.884 31.1532H111.736C111.859 33.4429 113.945 35.0787 116.357 35.0787C118.605 35.0787 119.874 34.0158 120.447 32.4215L125.639 33.8933L125.637 33.8915ZM119.994 27.1449C119.913 25.592 118.851 23.7112 115.905 23.7112C113.287 23.7112 111.98 25.6334 111.857 27.1449H119.994Z" fill="white"/>
      <path d="M137.451 19.4166H141.416V24.8553H137.451V32.4595C137.451 34.1367 138.35 34.5853 139.699 34.5853C140.353 34.5853 140.968 34.4628 141.335 34.3799V39.5322C141.09 39.6547 140.067 40.1051 138.105 40.1051C133.893 40.1051 131.317 37.61 131.317 33.5638V24.8553H127.719V19.4166H128.741C130.866 19.4166 131.89 17.9862 131.89 16.1054V13.4878H137.451V19.4166Z" fill="white"/>
      <path d="M145.435 43.0001V25.9686H148.042V28.1178C148.786 26.7432 150.42 25.6533 152.654 25.6533C156.779 25.6533 158.984 28.833 158.984 32.8432C158.984 36.8533 156.606 40.1195 152.567 40.1195C150.447 40.1195 148.843 39.116 148.098 37.8856V43.0001H145.433H145.435ZM152.167 28.0313C149.703 28.0313 148.071 30.094 148.071 32.8432C148.071 35.5923 149.703 37.7126 152.167 37.7126C154.632 37.7126 156.235 35.6787 156.235 32.8432C156.235 30.0076 154.687 28.0313 152.167 28.0313Z" fill="#C6BDFE"/>
      <path d="M165.744 31.8704L169.468 31.3263C170.298 31.211 170.527 30.7823 170.527 30.2959C170.527 28.9501 169.639 27.8314 167.547 27.8314C165.656 27.8314 164.597 29.006 164.425 30.6093L161.847 30.0076C162.134 27.372 164.512 25.5381 167.492 25.5381C171.617 25.5381 173.22 27.8873 173.22 30.5805V37.4839C173.22 38.6873 173.336 39.4025 173.392 39.7466H170.756C170.698 39.4025 170.614 38.8873 170.614 37.8838C170.012 38.8585 168.637 40.1754 166.173 40.1754C163.366 40.1754 161.561 38.2279 161.561 36.0499C161.561 33.5854 163.366 32.2109 165.744 31.8668V31.8704ZM170.529 33.8467V33.2161L166.317 33.8467C165.171 34.0178 164.312 34.6772 164.312 35.9094C164.312 36.9417 165.171 37.9145 166.546 37.9145C168.751 37.9145 170.527 36.8552 170.527 33.8467H170.529Z" fill="#C6BDFE"/>
      <path d="M178.548 35.3077C178.72 36.7399 179.809 37.8856 181.757 37.8856C183.276 37.8856 184.106 37.0263 184.106 36.0517C184.106 35.1924 183.476 34.533 182.33 34.2754L179.981 33.7602C177.831 33.3026 176.543 31.8416 176.543 29.8923C176.543 27.5432 178.748 25.5381 181.442 25.5381C185.223 25.5381 186.398 28.0026 186.684 29.233L184.306 30.1211C184.191 29.4041 183.618 27.8296 181.442 27.8296C180.067 27.8296 179.15 28.7178 179.15 29.6635C179.15 30.494 179.665 31.0957 180.726 31.3245L182.96 31.8109C185.452 32.355 186.771 33.8737 186.771 35.9076C186.771 37.9415 185.138 40.1754 181.728 40.1754C177.947 40.1754 176.343 37.7397 176.113 36.1652L178.548 35.3059V35.3077Z" fill="#C6BDFE"/>
      <path d="M191.497 35.3077C191.668 36.7399 192.758 37.8856 194.705 37.8856C196.224 37.8856 197.054 37.0263 197.054 36.0517C197.054 35.1924 196.424 34.533 195.278 34.2754L192.929 33.7602C190.78 33.3026 189.492 31.8416 189.492 29.8923C189.492 27.5432 191.697 25.5381 194.39 25.5381C198.171 25.5381 199.346 28.0026 199.632 29.233L197.254 30.1211C197.139 29.4041 196.566 27.8296 194.39 27.8296C193.015 27.8296 192.098 28.7178 192.098 29.6635C192.098 30.494 192.614 31.0957 193.675 31.3245L195.909 31.8109C198.4 32.355 199.719 33.8737 199.719 35.9076C199.719 37.9415 198.087 40.1754 194.676 40.1754C190.895 40.1754 189.292 37.7397 189.061 36.1652L191.497 35.3059V35.3077Z" fill="#C6BDFE"/>
        <defs>
        <linearGradient id="paint0_linear_4627_25791" x1="20.1205" y1="43.0003" x2="20.1205" y2="7.56462" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C7BEFE"/>
          <stop offset="0.11" stopColor="#C2B9F8"/>
          <stop offset="0.25" stopColor="#B3ABE8"/>
          <stop offset="0.41" stopColor="#9C94CE"/>
          <stop offset="0.58" stopColor="#7B74A9"/>
          <stop offset="0.77" stopColor="#514B7A"/>
          <stop offset="0.95" stopColor="#1E1941"/>
          <stop offset="1" stopColor="#100C32"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoNew;