import React from "react";

function User(props) {

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.83936 4.83268C5.83936 3.36202 7.03536 2.16602 8.50602 2.16602C9.97669 2.16602 11.1727 3.36202 11.1727 4.83268C11.1727 6.30335 9.97669 7.49935 8.50602 7.49935C7.03536 7.49935 5.83936 6.30335 5.83936 4.83268ZM9.83333 8.83268H7.16667C4.46 8.83268 3.5 10.8147 3.5 12.512C3.5 14.03 4.30737 14.8327 5.83537 14.8327H11.1646C12.6926 14.8327 13.5 14.03 13.5 12.512C13.5 10.8147 12.54 8.83268 9.83333 8.83268Z" fill="#646464"/>
    </svg>
  );
}

export default User;