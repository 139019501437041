import React from "react";

function CloudUpload(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 10.95C21 13.71 18.76 15.95 16 15.95H12.75V12.8109L13.47 13.5309C13.616 13.6769 13.808 13.7509 14 13.7509C14.192 13.7509 14.384 13.6779 14.53 13.5309C14.823 13.2379 14.823 12.7629 14.53 12.4699L12.53 10.4699C12.461 10.4009 12.3779 10.3459 12.2859 10.3079C12.1029 10.2319 11.8959 10.2319 11.7129 10.3079C11.6209 10.3459 11.538 10.4009 11.469 10.4699L9.46899 12.4699C9.17599 12.7629 9.17599 13.2379 9.46899 13.5309C9.76199 13.8239 10.237 13.8239 10.53 13.5309L11.25 12.8109V15.95H9.5C5.91 15.95 3 13.04 3 9.44995C3 5.85995 5.91 2.94995 9.5 2.94995C11.146 2.94995 12.648 3.56292 13.792 4.57092C14.274 4.99592 14.692 5.49092 15.031 6.03992C15.351 5.97992 15.671 5.94995 16.001 5.94995C18.76 5.94995 21 8.18995 21 10.95ZM11.25 20.9999C11.25 21.4139 11.586 21.7499 12 21.7499C12.414 21.7499 12.75 21.4139 12.75 20.9999V15.95H11.25V20.9999Z" fill="#816CFF"/>
    </svg>
  );
}

export default CloudUpload;