import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormikContext, Field, ErrorMessage } from "formik";
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import { socialMediaData } from "../../components/dashboard/socialMediaData.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import StarShootingSmall from "../../components/icons/StarShootingSmall.jsx";
import CheckCircle from "../../components/icons/CheckCircle.jsx";

function CustomizeSecondPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, values, submitForm } = useFormikContext();

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
          <div>
            <div className="ticketer-field-social d-flex align-items-center">
              {socialMediaData[0].icon}
              <h5>{socialMediaData[0].name}</h5>
            </div>
            {dirty && touched.fb && values.fb.trim() !== "" ? (
              <CheckCircle />
            ) : null}
            <Field
              className={
                errors.fb && touched.fb
                  ? "ticketer-field ticketer-field-thick red-border"
                  : touched.fb
                  ? "ticketer-field ticketer-field-thick purple-border"
                  : "ticketer-field ticketer-field-thick"
              }
              id="ticketer-fb"
              type="text"
              name="fb"
              placeholder=""
            />
            {errors.fb && touched.fb ? (
              <ErrorMessage name="fb">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <div className="ticketer-field-social d-flex align-items-center">
              {socialMediaData[1].icon}
              <h5>{socialMediaData[1].name}</h5>
            </div>
            {dirty && touched.ig && values.ig.trim() !== "" ? (
              <CheckCircle />
            ) : null}
            <Field
              className={
                errors.ig && touched.ig
                  ? "ticketer-field ticketer-field-thick red-border"
                  : touched.ig
                  ? "ticketer-field ticketer-field-thick purple-border"
                  : "ticketer-field ticketer-field-thick"
              }
              id="ticketer-ig"
              type="text"
              name="ig"
              placeholder=""
            />
            {errors.ig && touched.ig ? (
              <ErrorMessage name="ig">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
        <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
          <div>
            <div className="ticketer-field-social d-flex align-items-center">
              {socialMediaData[2].icon}
              <h5>{socialMediaData[2].name}</h5>
            </div>
            {dirty && touched.tw && values.tw.trim() !== "" ? (
              <CheckCircle />
            ) : null}
            <Field
              className={
                errors.tw && touched.tw
                  ? "ticketer-field ticketer-field-thick red-border"
                  : touched.tw
                  ? "ticketer-field ticketer-field-thick purple-border"
                  : "ticketer-field ticketer-field-thick"
              }
              id="ticketer-tw"
              type="text"
              name="tw"
              placeholder=""
            />
            {errors.tw && touched.tw ? (
              <ErrorMessage name="tw">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <div className="ticketer-field-social d-flex align-items-center">
              {socialMediaData[3].icon}
              <h5>{socialMediaData[3].name}</h5>
            </div>
            {dirty && touched.tt && values.tt.trim() !== "" ? (
              <CheckCircle />
            ) : null}
            <Field
              className={
                errors.tt && touched.tt
                  ? "ticketer-field ticketer-field-thick red-border"
                  : touched.tt
                  ? "ticketer-field ticketer-field-thick purple-border"
                  : "ticketer-field ticketer-field-thick"
              }
              id="ticketer-tt"
              type="text"
              name="tt"
              placeholder=""
            />
            {errors.tt && touched.tt ? (
              <ErrorMessage name="tt">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t("ticketer.finish"),
          disabled: false,
          function: submitForm,
        }}
      />
    </>
  );
}

function CustomizeSecondPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const {
    state: authState,
    dispatch,
    ticketerModal,
    setTicketerModal,
    role,
  } = React.useContext(AppContext);

  var navigate = useNavigate();

  const {
    isLoading,
    isError,
    error,
    data: ticketingCompany,
    isFetching,
  } = useQuery({
    queryKey: ["ticketingCompany"],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  var initialSecondCustomizeData = {
    fb: ticketingCompany?.data[0]?.facebook
      ? ticketingCompany?.data[0]?.facebook
      : "",
    ig: ticketingCompany?.data[0]?.instagram
      ? ticketingCompany?.data[0]?.instagram
      : "",
    tw: ticketingCompany?.data[0]?.twitter
      ? ticketingCompany?.data[0]?.twitter
      : "",
    tt: ticketingCompany?.data[0]?.tiktok
      ? ticketingCompany?.data[0]?.tiktok
      : "",
  };

  const dataSecondCustomizeSchema = Yup.object().shape({
    fb: Yup.string(),
    ig: Yup.string(),
    tw: Yup.string(),
    tt: Yup.string(),
  });

  return (
    <TicketingSectionComponent
      initialValues={initialSecondCustomizeData}
      validationSchema={dataSecondCustomizeSchema}
      ticketer={true}
      title={t("ticketer.customize1")}
      pagination={"2/2"}
      description={t("ticketer.infoCustomize2")}
      footer={{
        text: t("ticketer.adviceCustomizeSocial"),
        icon: <StarShootingSmall />,
      }}
    >
      <CustomizeSecondPageChildren />
    </TicketingSectionComponent>
  );
}

export default CustomizeSecondPage;
