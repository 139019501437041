import React from 'react';
import { useTranslation } from "react-i18next";
import CountUp from 'react-countup';
import TibetLandingComponentTitle from '../shared/TibetLandingComponentTitle';

export default function TibetLandingComponent4() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <section id="metrics-landing-section" className="metrics-landing-container d-flex flex-column justify-content-center align-items-center">
      <TibetLandingComponentTitle
        text1 = { t('landing.metricsTitle1') }
        text2 = { t('landing.metricsTitle2') }
      />
      <div className="metrics-landing-cards-container d-flex flex-column flex-sm-row justify-content-center align-items-center">
        <div className="metrics-landing-counter d-flex flex-column align-items-center">
          <h3><CountUp end={ 100 } suffix="k+" enableScrollSpy={ true } scrollSpyOnce={ true } scrollSpyDelay={ 250 } duration={ 3 }/></h3>
          <p>{t('landing.metricsCards1')}</p>
        </div>
        <div className="metrics-landing-counter-separator" ></div>
        <div className="metrics-landing-counter d-flex flex-column align-items-center">
          <h3><CountUp end={ 50 } suffix="+" enableScrollSpy={ true } scrollSpyOnce={ true } scrollSpyDelay={ 250 } duration={ 4 }/></h3>
          <p>{t('landing.metricsCards2')}</p>
        </div>
        <div className="metrics-landing-counter-separator" ></div>
        <div className="metrics-landing-counter d-flex flex-column align-items-center">
          <h3><CountUp end={ 3 } suffix="+" enableScrollSpy={ true } scrollSpyOnce={ true } scrollSpyDelay={ 250 } duration={ 5 }/></h3>
          <p>{t('landing.metricsCards3')}</p>
        </div>
      </div>
    </section>
  )

}
