import React from "react";

function GiftSvg2(props) {

  return (
    <svg width="130" height="113" viewBox="0 0 130 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17.1289" y="58.3587" width="69.1382" height="54.2698" rx="8.54935" stroke="#816CFF" strokeWidth="0.743422" strokeDasharray="1.49 1.49"/>
      <path d="M93.7126 85.6461C111.798 85.6461 126.46 70.9846 126.46 52.8987C126.46 34.8128 111.798 20.1513 93.7126 20.1513C75.6267 20.1513 60.9652 34.8128 60.9652 52.8987C60.9652 70.9846 75.6267 85.6461 93.7126 85.6461Z" fill="#E3DEFC"/>
      <path d="M38.4085 69.3749H63.3125V95.1749C63.3125 95.7698 62.8322 96.2499 62.2373 96.2499H48.0833C40.9167 96.2499 37.3333 92.6666 37.3333 85.4999V70.4498C37.3333 69.855 37.8137 69.3749 38.4085 69.3749ZM93.5915 69.3749H68.6875V95.1749C68.6875 95.7698 69.1678 96.2499 69.7627 96.2499H83.9167C91.0833 96.2499 94.6667 92.6666 94.6667 85.4999V70.4498C94.6667 69.855 94.1863 69.3749 93.5915 69.3749ZM98.25 51.4582V62.9249C98.25 63.5198 97.7697 63.9999 97.1748 63.9999H68.6875V51.4582H63.3125V63.9999H34.8252C34.2303 63.9999 33.75 63.5198 33.75 62.9249V51.4582C33.75 48.4912 36.158 46.0832 39.125 46.0832H42.941C41.798 43.8687 41.3718 41.2278 42.3895 38.3575C43.4035 35.498 45.7182 33.1295 48.6135 32.223C52.6197 30.9688 56.7407 32.2802 59.2633 35.3439C59.378 35.4837 63.4092 40.8479 66.0035 44.2951C68.5943 40.8479 72.622 35.4944 72.7188 35.3726C75.2593 32.2838 79.3443 30.983 83.3397 32.2013C86.1347 33.0577 88.4065 35.2723 89.4815 37.9921C90.6712 41.0021 90.2556 43.7721 89.066 46.0797H92.882C95.8418 46.0833 98.25 48.4912 98.25 51.4582ZM60.618 46.0832C58.1921 42.8582 55.2607 38.9595 55.0637 38.7051C54.2431 37.7053 53.0212 37.1249 51.6667 37.1249C49.1978 37.1249 47.1875 39.1351 47.1875 41.6041C47.1875 44.073 49.1978 46.0832 51.6667 46.0832H60.618ZM84.8125 41.6041C84.8125 39.1351 82.8023 37.1249 80.3333 37.1249C78.9788 37.1249 77.7567 37.7053 76.8895 38.7624C76.7211 38.9845 73.8008 42.8654 71.382 46.0832H80.3333C82.8023 46.0832 84.8125 44.073 84.8125 41.6041Z" fill="#442ECB"/>
      <path d="M103.97 11.4485C107.132 11.4485 109.695 8.88564 109.695 5.72423C109.695 2.56282 107.132 0 103.97 0C100.809 0 98.2463 2.56282 98.2463 5.72423C98.2463 8.88564 100.809 11.4485 103.97 11.4485Z" fill="#816CFF"/>
      <path d="M124.95 108.824C127.454 108.824 129.484 106.794 129.484 104.289C129.484 101.785 127.454 99.7552 124.95 99.7552C122.446 99.7552 120.416 101.785 120.416 104.289C120.416 106.794 122.446 108.824 124.95 108.824Z" fill="#442ECB"/>
      <path d="M21.2043 16.3543V26.0188" stroke="#FF8C5A" strokeWidth="1.09416" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M26.0472 21.1759H16.3827" stroke="#FF8C5A" strokeWidth="1.09416" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M9.0423 40.1482V55.2308" stroke="#F27327" strokeWidth="1.70757" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.5983 47.6729H1.51562" stroke="#F27327" strokeWidth="1.70757" strokeMiterlimit="10" strokeLinecap="round"/>
    </svg>
  );
}

export default GiftSvg2;