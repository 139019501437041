import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import RocketLaunchSmall from "../../components/icons/RocketLaunchSmall.jsx";

function InfoSecondPageChildren(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, isValid, values, submitForm } = useFormikContext();

  var navigate = useNavigate();

  const nextPage = (route) => {
    submitForm();
    if (!(props.ticketingCompany?.data[0]?.comercialName && props.ticketingCompany?.data[0]?.description 
      && props.ticketingCompany?.data[0]?.email && props.ticketingCompany?.data[0]?.phone 
      && props.ticketingCompany?.data[0]?.address && props.ticketingCompany?.data[0]?.companyName && props.ticketingCompany?.data[0]?.cif)) {
      navigate(route === "back" ? -1 : route);
    }
  }

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
          <div>
            <h5>{t('ticketerSection.infoField3') + "*"}</h5>
            <Field 
              className={ errors.email && touched.email ? "ticketer-field red-border" : touched.email ? "ticketer-field purple-border" : "ticketer-field" } 
              id="ticketer-email" type="email" name="email" placeholder=""
            />
            { errors.email && touched.email ? 
              <ErrorMessage name="email">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
              :
              null
            }
          </div>
          <div>
            <h5>{t('ticketerSection.infoField4')}</h5>
            <Field 
              className={ errors.altEmail && touched.altEmail ? "ticketer-field red-border" : touched.altEmail ? "ticketer-field purple-border" : "ticketer-field" } 
              id="ticketer-altEmail" type="email" name="altEmail" placeholder=""
            />
            { errors.altEmail && touched.altEmail ? 
              <ErrorMessage name="altEmail">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
              :
              null
            }
          </div>
        </div>
        <div className="w-100">
          <h5>{t('ticketerSection.infoField5') + "*"}</h5>
          <Field 
            className={ errors.phone && touched.phone ? "ticketer-field red-border" : touched.phone ? "ticketer-field purple-border" : "ticketer-field" } 
            id="ticketer-phone" type="phone" name="phone" placeholder=""
          />
          { errors.phone && touched.phone ? 
            <ErrorMessage name="phone">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
            :
            null
          }
        </div>
      </div>
      <ButtonGroupTibet button = { { text: t('ticketer.next'), disabled: !(isValid && values.email && values.phone), function: () => nextPage(routes.infoBilling) } }/>
    </>
  );
}

function InfoSecondPage(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, ticketerModal, setTicketerModal, role } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialSecondInfoData = {
    email: ticketingCompany?.data[0]?.email ? ticketingCompany?.data[0]?.email : "",
    altEmail: ticketingCompany?.data[0]?.email2 ? ticketingCompany?.data[0]?.email2 : "",
    phone: ticketingCompany?.data[0]?.phone ? ticketingCompany?.data[0]?.phone : ""
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const dataSecondInfoSchema = Yup.object().shape(
    {
      email: Yup.string().email(t('register.error1')).required(t('register.error2')),
      altEmail: Yup.string().email(t('register.error1')),
      phone: Yup.string().matches(phoneRegExp, t('profile.profilePhoneError')).required(t('register.error2'))
    }
  );

  return (
    <TicketingSectionComponent
      initialValues = { initialSecondInfoData }
      validationSchema = { dataSecondInfoSchema }
      ticketer = { true }
      title = { t('ticketer.info1') }
      pagination = { "2/3" }
      description = { t('ticketer.infoTitle5') }
      footer = { { text: t('ticketer.adviceInfo2'), icon: <RocketLaunchSmall/> } }
      nextPage={ ticketingCompany?.data[0]?.comercialName && ticketingCompany?.data[0]?.description 
        && ticketingCompany?.data[0]?.email && ticketingCompany?.data[0]?.phone 
        && ticketingCompany?.data[0]?.address && ticketingCompany?.data[0]?.companyName && ticketingCompany?.data[0]?.cif ? false : true }
    >
      <InfoSecondPageChildren ticketingCompany = { ticketingCompany } initialValues = { initialSecondInfoData }/>
    </TicketingSectionComponent>
  );
}

export default InfoSecondPage;