import React from "react";

function PapersText(props) {

  return (
    <svg width="113" height="95" viewBox="0 0 113 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M86.1935 120.551C83.9575 121.617 81.3449 122.41 78.3874 122.943L35.682 130.419C21.447 132.954 13.0613 127.104 10.5913 112.869L0.582629 55.9936C-1.95237 41.7586 3.89731 33.3736 18.1323 30.8386L29.8183 28.7843C31.1508 28.5503 32.3154 29.714 32.0749 31.0465L24.4308 74.3236C20.9858 94.0186 30.2803 107.278 49.9103 110.723C49.9103 110.723 83.834 116.684 85.5825 117.022C87.7405 117.412 88.085 119.713 86.1935 120.551ZM129.438 34.1925L119.401 91.0933C116.983 104.795 109.08 110.652 95.7879 108.832C95.281 108.76 94.8259 108.761 94.2994 108.67L51.6257 101.142C37.3972 98.6335 31.5416 90.268 34.0506 76.0395L42.7803 26.5353L44.0863 19.1383C46.5953 4.90978 54.9612 -0.946588 69.1897 1.56241L111.868 9.08954C126.09 11.5985 131.947 19.9705 129.438 34.1925ZM88.1041 78.3274C88.5721 75.6754 86.8047 73.1468 84.1527 72.6788L57.4767 67.9728C54.8377 67.4983 52.2967 69.2795 51.8352 71.925C51.3672 74.577 53.1346 77.1056 55.7866 77.5736L82.4626 82.2796C82.7486 82.3316 83.0337 82.351 83.3132 82.351C85.6337 82.3575 87.6881 80.6934 88.1041 78.3274ZM107.883 59.7307C108.351 57.0787 106.584 54.5505 103.932 54.0825L61.2599 46.5554C58.6339 46.0939 56.0783 47.8621 55.6168 50.5076C55.1488 53.1596 56.9178 55.6878 59.5698 56.1558L102.242 63.683C102.528 63.735 102.815 63.7544 103.094 63.7544C105.415 63.7544 107.467 62.0902 107.883 59.7307ZM112.435 38.528C112.903 35.876 111.134 33.3474 108.482 32.8794L65.8096 25.3522C63.1836 24.8842 60.6296 26.6589 60.1681 29.3044C59.7001 31.9564 61.4675 34.4851 64.1195 34.9531L106.792 42.4798C107.078 42.5318 107.364 42.5516 107.644 42.5516C109.964 42.5581 112.019 40.894 112.435 38.528Z" fill="#B2A6FF"/>
    </svg>
  );
}

export default PapersText;