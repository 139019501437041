import React from "react";

function EmptyLogo(props) {

  return (
    <svg width="87" height="87" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="86.619" height="86.619" rx="8" fill="#EFF0F2"/>
      <path d="M43.3096 20.8096C30.8828 20.8096 20.8096 30.8828 20.8096 43.3096C20.8096 55.7363 30.8828 65.8096 43.3096 65.8096C55.7363 65.8096 65.8096 55.7363 65.8096 43.3096C65.8096 30.8828 55.7363 20.8096 43.3096 20.8096ZM53.8284 43.2016C52.4312 50.4084 43.3096 54.5596 43.3096 54.5596C43.3096 54.5596 34.188 50.4084 32.7907 43.2016C31.9335 38.7804 33.8057 34.3321 38.5712 34.3096C42.1194 34.2916 43.3096 37.869 43.3096 37.869C43.3096 37.869 44.4997 34.2916 48.048 34.3096C52.8225 34.3321 54.6857 38.7804 53.8284 43.2016Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default EmptyLogo;