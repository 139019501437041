import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import routes from '../../routes/routes.js';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import {
  patchUserById,
  deletePictureUser
} from '../../services/axiosService.js';
import DashboardBar from '../shared/DashboardBar';
import SuccessFailPopup from '../ui/SuccessFailPopup';
import PictureProfileCircle from '../ui/PictureProfileCircle';
import FileTextEdit from '../icons/FileTextEdit';
import Download from '../icons/Download';
import ProfileStar from '../icons/ProfileStar';
import ProfileLines from '../icons/ProfileLines';
import ClipboardList from '../icons/ClipboardList';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';

function ProfileComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setImageModal,
    userData,
    setUserData
  } = React.useContext(AppContext);

  var navigate = useNavigate();

  var initialData = {
    name: userData?.name ? userData?.name : '',
    recoveryEmail: userData?.recoveryEmail ? userData?.recoveryEmail : '',
    phone: userData?.userAdditionalInformation?.phone
      ? userData?.userAdditionalInformation?.phone
      : ''
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const dataSchema = Yup.object().shape({
    name: Yup.string(),
    recoveryEmail: Yup.string()
      .email(t('register.error1'))
      .notOneOf([null, userData?.userId], t('register.error3')),
    phone: Yup.string().matches(phoneRegExp, t('profile.profilePhoneError'))
  });

  const [editUser, setEditUser] = useState(false);
  const [uploadMobilePicture, setUploadMobilePicture] = useState(false);
  const [popup, setPopup] = useState(null);

  const { isLoading, mutate } = useMutation(
    (variables) =>
      patchUserById(
        authState.token,
        userData.userId,
        variables.name,
        variables.recoveryEmail,
        variables.phone,
        variables.picture
      ),
    {
      onSuccess: (res) => {
        if (res.data) {
          setUserData({
            ...userData,
            name: res.data.name,
            recoveryEmail: res.data.recoveryEmail,
            userAdditionalInformation: res.data.userAdditionalInformation,
            picture: res.data.picture
          });
          setEditUser(false);
          setPopup({ state: 1, text: t('profile.success2') });
        }
      },
      onError: (err) => {
        console.error(err);
        setEditUser(false);
        setPopup({
          state: 0,
          text: t('profile.fail2') + '. (' + err.response.data.message + ')'
        });
      }
    }
  );

  const { mutate: deletePicture } = useMutation(
    () => deletePictureUser(authState.token),
    {
      onSuccess: (res) => {
        if (res.data) {
          setUserData({ ...userData, picture: res.data.picture });
          setEditUser(false);
          setPopup({ state: 1, text: t('profile.success2') });
        }
      },
      onError: (err) => {
        console.error(err);
        setEditUser(false);
        setPopup({
          state: 0,
          text: t('profile.fail2') + '. (' + err.response.data.message + ')'
        });
      }
    }
  );

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size < 1572864) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImageModal(
            {
              src: reader.result?.toString(),
              function: setUploadMobilePicture,
              functionForLoad: mutate
            } || ''
          )
        );
        reader.readAsDataURL(e.target.files[0]);
      } else {
        setImageModal({ sizeExceeded: true });
      }
    }
    e.target.value = '';
  }

  function onDelete() {
    deletePicture();
    setUploadMobilePicture(false);
  }

  const returnFromModal = (e) => {
    if (e.target !== e.currentTarget) return;
    else {
      setUploadMobilePicture(false);
    }
  };

  const logout = () => {
    dispatch({
      type: 'LOGOUT'
    });
    navigate(routes.auth);
  };

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <section
      id="profile-section"
      className="profile-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <Formik
        //innerRef={(FormikHelpers) => (fval.current = FormikHelpers)}
        // *** Initial values that the form will take
        enableReinitialize={true}
        initialValues={initialData}
        // *** Yup Validation Schema ***
        validationSchema={dataSchema}
        validateOnChange={true}
        validateOnBlur={true}
        // ** onSubmit Event
        onSubmit={(values, actions) => {
          mutate({
            name: values.name,
            recoveryEmail: values.recoveryEmail,
            phone: values.phone
          });
        }}
      >
        {/* We obtain props from Formik */}
        {(props) => {
          const {
            dirty,
            touched,
            errors,
            isValid,
            setFieldTouched,
            submitForm,
            isSubmitting
          } = props;
          i18n.on('languageChanged', () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });
          return (
            <>
              {popup ? (
                <SuccessFailPopup
                  text={popup.text}
                  state={popup.state}
                  function={() => setPopup(null)}
                />
              ) : null}
              <DashboardBar
                text={t('profile.title')}
                session={true}
                emoji="crazy"
                icon={
                  isLoading ? (
                    <SpinnerPurple />
                  ) : editUser ? (
                    <Download />
                  ) : (
                    <FileTextEdit />
                  )
                }
                buttonText={
                  isLoading
                    ? t('profile.sending')
                    : editUser
                    ? t('profile.save')
                    : t('profile.edit')
                }
                function={
                  isLoading
                    ? null
                    : editUser
                    ? () => submitForm()
                    : () => setEditUser(true)
                }
                disabled={
                  isLoading ? false : editUser ? !(dirty && isValid) : null
                }
                button={true}
                h6function={() => logout()}
              />
              <p className="d-none d-lg-block">{t('profile.message')}</p>
              <div
                className={
                  editUser
                    ? 'profile-section-box profile-section-box-padding d-flex flex-column justify-content-center align-items-center'
                    : 'profile-section-box d-flex flex-column justify-content-center align-items-center'
                }
              >
                <ProfileStar />
                <ProfileLines />
                <PictureProfileCircle
                  img={userData?.picture ? userData.picture : null}
                  mobile={false}
                  functionForLoad={mutate}
                  deleteFunction={onDelete}
                />
                <PictureProfileCircle
                  img={userData?.picture ? userData.picture : null}
                  mobile={true}
                  function={() => setUploadMobilePicture(!uploadMobilePicture)}
                />
                <div className="profile-section-box-background"></div>
                {editUser ? (
                  <Form className="change-user-data d-flex flex-column">
                    <h5
                      style={{ cursor: 'pointer', textAlign: 'end' }}
                      onClick={() => setEditUser(false)}
                    >
                      {t('gift.giftButton3')}
                    </h5>
                    <h5>{t('profile.name')}</h5>
                    <Field
                      className={
                        errors.name && touched.name
                          ? 'register-email-form-email red-border'
                          : touched.name
                          ? 'register-email-form-email purple-border'
                          : 'register-email-form-email'
                      }
                      id="register-email-form-name"
                      type="text"
                      name="name"
                      placeholder=""
                    />
                    {errors.name && touched.name ? (
                      <ErrorMessage name="name">
                        {(msg) => (
                          <div className="register-email-form-email-error mb-2">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    ) : (
                      <div className=""></div>
                    )}
                    <h5>{t('profile.recover')}</h5>
                    <Field
                      className={
                        errors.recoveryEmail && touched.recoveryEmail
                          ? 'register-email-form-email red-border'
                          : touched.recoveryEmail
                          ? 'register-email-form-email purple-border'
                          : 'register-email-form-email'
                      }
                      id="register-email-form-rrecoveryEmail"
                      type="email"
                      name="recoveryEmail"
                      placeholder=""
                    />
                    {errors.recoveryEmail && touched.recoveryEmail ? (
                      <ErrorMessage name="recoveryEmail">
                        {(msg) => (
                          <div className="register-email-form-email-error mb-2">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    ) : (
                      <div className=""></div>
                    )}
                    <h5>{t('profile.phone')}</h5>
                    <Field
                      className={
                        errors.phone && touched.phone
                          ? 'register-email-form-email red-border'
                          : touched.phone
                          ? 'register-email-form-email purple-border'
                          : 'register-email-form-email'
                      }
                      id="register-email-form-phone"
                      type="tel"
                      name="phone"
                      placeholder=""
                    />
                    {errors.phone && touched.phone ? (
                      <ErrorMessage name="phone">
                        {(msg) => (
                          <div className="register-email-form-email-error mb-2">
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    ) : (
                      <div className=""></div>
                    )}
                  </Form>
                ) : (
                  <div className="profile-section-box-data d-flex flex-column justify-content-center">
                    <h5>{t('profile.name')}</h5>
                    <h4>{userData?.name ? userData?.name : '-'}</h4>
                    <h5>{t('profile.email')}</h5>
                    <h4>{userData?.userId ? userData?.userId : '-'}</h4>
                    <h5>{t('profile.recover')}</h5>
                    <h4>
                      {userData?.recoveryEmail ? userData?.recoveryEmail : '-'}
                    </h4>
                    <h5>{t('profile.phone')}</h5>
                    <h4>
                      {userData?.userAdditionalInformation?.phone
                        ? userData?.userAdditionalInformation?.phone
                        : '-'}
                    </h4>
                  </div>
                )}
              </div>
              <div className="profile-section-bottom d-flex d-lg-none flex-row justify-content-between align-items-center">
                <p>{t('profile.closeSession1')}</p>
                <h6 onClick={() => logout()}>{t('profile.closeSession')}</h6>
              </div>
              {uploadMobilePicture ? (
                <div
                  className="profile-upload-image-mobile d-flex d-lg-none flex-row justify-content-center align-items-end"
                  onClick={(event) => returnFromModal(event)}
                >
                  <div className="profile-upload-image-mobile-box d-flex flex-column d-lg-none justify-content-between align-items-center">
                    <div
                      className="profile-upload-image-mobile-box-line"
                      onClick={() => setUploadMobilePicture(false)}
                    ></div>
                    <p>{t('profile.size')}</p>
                    <label className="d-flex d-lg-none flex-row justify-content-center align-items-center">
                      <ClipboardList />
                      {t('profile.choose')}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => onSelectFile(e)}
                      ></input>
                    </label>
                    {userData?.picture ? (
                      <button onClick={() => onDelete()}>
                        {t('profile.delete')}
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          );
        }}
      </Formik>
    </section>
  );
}

export default ProfileComponent;
