import React from "react";

function BadgeDiscountAlt(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5589 11.436L19.243 10.12C18.962 9.83899 18.8039 9.45698 18.8039 9.05898V7.20098C18.8039 6.36998 18.1299 5.696 17.2989 5.696H15.441C15.043 5.696 14.662 5.53798 14.38 5.25698L13.0639 3.941C12.4759 3.353 11.524 3.353 10.936 3.941L9.61996 5.25698C9.33895 5.53798 8.95692 5.696 8.55892 5.696H6.70101C5.87001 5.696 5.196 6.36998 5.196 7.20098V9.05898C5.196 9.45698 5.03792 9.83799 4.75692 10.12L3.441 11.436C2.853 12.024 2.853 12.976 3.441 13.564L4.75692 14.88C5.03792 15.161 5.196 15.543 5.196 15.941V17.799C5.196 18.63 5.87001 19.304 6.70101 19.304H8.55892C8.95692 19.304 9.33795 19.462 9.61996 19.743L10.936 21.059C11.524 21.647 12.4759 21.647 13.0639 21.059L14.38 19.743C14.661 19.462 15.043 19.304 15.441 19.304H17.2989C18.1299 19.304 18.8039 18.63 18.8039 17.799V15.941C18.8039 15.543 18.962 15.162 19.243 14.88L20.5589 13.564C21.1469 12.976 21.1469 12.024 20.5589 11.436ZM8.51497 9.99999C8.51497 9.44799 8.95797 8.99999 9.50997 8.99999H9.51998C10.073 8.99999 10.52 9.44799 10.52 9.99999C10.52 10.552 10.073 11 9.51998 11C8.96798 11 8.51497 10.552 8.51497 9.99999ZM10.03 15.53C9.88399 15.676 9.69196 15.75 9.49996 15.75C9.30796 15.75 9.11593 15.677 8.96993 15.53C8.67693 15.237 8.67693 14.762 8.96993 14.469L13.9699 9.46898C14.2629 9.17598 14.738 9.17598 15.031 9.46898C15.324 9.76198 15.324 10.237 15.031 10.53L10.03 15.53ZM14.52 16C13.968 16 13.515 15.552 13.515 15C13.515 14.448 13.958 14 14.51 14H14.52C15.073 14 15.52 14.448 15.52 15C15.52 15.552 15.072 16 14.52 16Z" fill="#F27327"/>
    </svg>
  );
}

export default BadgeDiscountAlt;