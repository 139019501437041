import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { getSubdomain } from '../../components/shared/functions.js';
import { getPublicElvisKey } from '../../services/axiosService';
import EnvelopeImage from '../../components/icons/EnvelopeImage';

function AuthSuccess(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const subdomain = useMemo(() => getSubdomain(), []);

  const { userData, ticketingCompany, setTicketingCompany, setPublicElvisId } =
    React.useContext(AppContext);

  const {
    isLoading: ticketingCompanyIsLoading,
    isError: ticketingCompanyIsError,
    error: ticketingCompanyError,
    data,
    isFetching: ticketingCompanyIsFetching
  } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getPublicElvisKey(subdomain),
    enabled: !!subdomain,
    onSuccess: (res) => {
      setTicketingCompany({ data: [res.data] });
      setPublicElvisId(res.data.apiKey);
    },
    onError: (err) => {
      if (err.response.status === 401) {
      }
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  return (
    <main className="sign-mail d-flex flex-row justify-content-center align-items-center">
      <div className="inner-container sign-mail-container d-flex flex-row justify-content-center align-items-center">
        <div className="sign-mail-box d-flex flex-column justify-content-center align-items-center">
          <EnvelopeImage />
          <h3>
            {t('register.mailConfirmation') +
              (subdomain
                ? ticketingCompany.data[0]
                  ? ticketingCompany.data[0]?.comercialName
                  : ''
                : 'Tibet')}
          </h3>
          <p>
            {t('register.mailConfirmation2') +
              (userData?.userId ? t('register.mailConfirmation3') : '')}
            <span>{userData?.userId ? userData?.userId : null}</span>
            {t('register.mailConfirmation4')}
          </p>
        </div>
      </div>
    </main>
  );
}

export default AuthSuccess;
