import React from "react";

function PreviewElvisImage(props) {

  return (
    <svg width="116" height="114" viewBox="0 0 116 114" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="31.1778" y="57.833" width="77.5131" height="47.349" rx="5.18682" stroke="#A5A9B8" strokeWidth="1.30279" strokeDasharray="2 2"/>
      <rect x="0.887695" y="21.2363" width="78.8159" height="48.6518" rx="5.83822" fill="#FCFCFC"/>
      <path d="M92.4023 52.7227V72.9311C92.4023 81.0144 88.3607 85.0561 80.2773 85.0561H31.7773C23.694 85.0561 19.6523 81.0144 19.6523 72.9311V52.7227C19.6523 44.6394 23.694 40.5977 31.7773 40.5977H52.9961V64.8477C52.9961 66.521 54.3541 67.879 56.0273 67.879C57.7006 67.879 59.0586 66.521 59.0586 64.8477V40.5977H80.2773C88.3607 40.5977 92.4023 44.6394 92.4023 52.7227ZM59.0586 19.6254L66.0101 26.5772C66.6002 27.1673 67.3763 27.4663 68.1523 27.4663C68.9283 27.4663 69.7045 27.1714 70.2945 26.5772C71.4788 25.393 71.4788 23.4731 70.2945 22.2889L58.1695 10.1639C57.8907 9.88501 57.5546 9.66277 57.1828 9.50918C56.4432 9.20202 55.6066 9.20202 54.8669 9.50918C54.4951 9.66277 54.1601 9.88501 53.8812 10.1639L41.7562 22.2889C40.572 23.4731 40.572 25.393 41.7562 26.5772C42.9404 27.7614 44.8603 27.7614 46.0445 26.5772L52.9961 19.6254V40.5977H59.0586V19.6254Z" fill="#C7C9CF"/>
      <path d="M109.57 92.1638C112.631 92.1638 115.112 89.6826 115.112 86.6219C115.112 83.5612 112.631 81.0801 109.57 81.0801C106.509 81.0801 104.028 83.5612 104.028 86.6219C104.028 89.6826 106.509 92.1638 109.57 92.1638Z" fill="#D7D9DF"/>
      <path d="M38.5819 102.566L36.1235 101.864C33.8624 101.218 32.1261 99.4821 31.4797 97.2202L30.7774 94.7617C30.6665 94.375 30.021 94.375 29.9101 94.7617L29.2079 97.2202C28.5615 99.4821 26.8251 101.218 24.5641 101.864L22.1056 102.566C21.9118 102.621 21.7783 102.799 21.7783 103C21.7783 103.201 21.9118 103.378 22.1056 103.434L24.5641 104.136C26.8251 104.782 28.5615 106.518 29.2079 108.78L29.9101 111.238C29.966 111.432 30.1427 111.565 30.3438 111.565C30.5448 111.565 30.7215 111.432 30.7774 111.238L31.4797 108.78C32.1261 106.518 33.8624 104.781 36.1235 104.136L38.5819 103.434C38.7758 103.379 38.9092 103.201 38.9092 103C38.9092 102.799 38.7758 102.622 38.5819 102.566Z" fill="#BBBEC6"/>
    </svg>
  );
}

export default PreviewElvisImage;