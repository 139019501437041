import React from "react";

function AngleWithCircle(props) {

  return (
    <svg onClick={ props.function } width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="14" fill="#EFECFC"/>
      <path d="M15.0852 11.1702L18.7351 15.4986C19.3485 16.2261 18.8285 17.3335 17.8735 17.3335L10.1269 17.3335C9.17193 17.3335 8.65198 16.2252 9.26531 15.4986L12.9152 11.1702C13.4811 10.4993 14.5194 10.4993 15.0852 11.1702Z" fill="#816CFF"/>
    </svg>
  );
}

export default AngleWithCircle;