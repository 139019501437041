import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import FilePlus from "../icons/FilePlus.jsx";
import AdviceFooter from "../ui/AdviceFooter.jsx";
import CloudUpload from "../icons/CloudUpload.jsx";

function ModalContent() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const {
    contentModal,
    setContentModal,
    createTicketInfo,
    setCreateTicketInfo,
    setImageModal,
  } = React.useContext(AppContext);

  const MAX_EXCLUSIVE_CONTENT = Number(
    process.env.REACT_APP_MAX_EXCLUSIVE_CONTENT
  );

  const [readerState, setReaderState] = useState(null);
  const [fileSizeState, setFileSizeState] = useState(false);

  var initialData = {
    title: "",
  };

  const dataSchema = Yup.object().shape({
    title: Yup.string().required(t("register.error2")),
  });

  const sendInfo = (values, actions) => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === contentModal
    ) {
      if (
        Array.isArray(createTicketInfo?.extraContent) &&
        Array.isArray(createTicketInfo?.extraContentTitle)
      ) {
        var contentVarible = createTicketInfo?.extraContent;
        var contentTitleVarible = createTicketInfo?.extraContentTitle;
        contentVarible.push({
          file: readerState.result,
          fileName: readerState.fileName,
          type: readerState.result?.split(";")[0]?.split(":")[1]?.split("/")[0],
        });
        contentTitleVarible.push(values.title);
      }
      if (
        contentModal?.extraContent?.length < MAX_EXCLUSIVE_CONTENT ||
        !contentModal?.extraContent
      ) {
        sessionStorage.setItem(
          "ticket_draft",
          JSON.stringify({
            ...createTicketInfo,
            eventId: contentModal,
            extraContent: createTicketInfo?.extraContent
              ? contentVarible
              : [
                  {
                    file: readerState.result,
                    fileName: readerState.fileName,
                    type: readerState.result
                      ?.split(";")[0]
                      ?.split(":")[1]
                      ?.split("/")[0],
                  },
                ],
            extraContentTitle: createTicketInfo?.extraContentTitle
              ? contentTitleVarible
              : [values.title],
          })
        );
        setCreateTicketInfo({
          ...createTicketInfo,
          eventId: contentModal,
          extraContent: createTicketInfo?.extraContent
            ? contentVarible
            : [
                {
                  file: readerState.result,
                  fileName: readerState.fileName,
                  type: readerState.result
                    ?.split(";")[0]
                    ?.split(":")[1]
                    ?.split("/")[0],
                },
              ],
          extraContentTitle: createTicketInfo?.extraContentTitle
            ? contentTitleVarible
            : [values.title],
        });
      }
      setReaderState(null);
      setContentModal(false);
    } else if (createTicketInfo.eventId !== contentModal) {
      setCreateTicketInfo({});
      sessionStorage.removeItem("ticket_draft");
    }
  };

  document.addEventListener("keyup", (e) => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setContentModal(false);
    }
  });

  document.addEventListener("click", (e) => {
    if (e.target === document.querySelector(".modal-container")) {
      setContentModal(false);
    }
  });

  function onSelectFile(e, id) {
    if (
      contentModal?.extraContent?.length < MAX_EXCLUSIVE_CONTENT ||
      !contentModal?.extraContent
    ) {
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size < 3145728) {
          if (
            Object.keys(createTicketInfo).length === 0 ||
            createTicketInfo.eventId === contentModal
          ) {
            const reader = new FileReader();
            reader.fileName = e.target.files[0].name;
            reader.addEventListener("load", () => {
              setReaderState(reader);
            });
            reader.readAsDataURL(e.target.files[0]);
          } else if (createTicketInfo.eventId !== contentModal) {
            setCreateTicketInfo({});
            sessionStorage.removeItem("ticket_draft");
          }
          setFileSizeState(false);
        } else if (e.target.files[0].size > 100000000) {
          setImageModal({
            sizeExceeded: true,
            message: t("profile.sizeTextUploadImageExtraContent"),
            messageTitle: t("profile.sizeTitleUploadImage"),
          });
        } else {
          setFileSizeState(true);
        }
      }
      e.target.value = "";
    }
  }

  return (
    <div
      id="modal-content-section"
      className={contentModal ? "modal-background" : "d-none"}
    >
      <div className="modal-container modal-relative justify-content-center align-items-center">
        <div
          className={`modal-content-container modal-discount sign-mail-box d-flex flex-column justify-content-center`}
        >
          <div className="modal-cancel-button-text d-flex flex-column justify-content-center">
            <h2>{t("ticket.contentTitle")}</h2>
          </div>
          <Formik
            // *** Initial values that the form will take
            enableReinitialize={true}
            initialValues={initialData}
            // *** Yup Validation Schema ***
            validationSchema={dataSchema}
            validateOnChange={true}
            validateOnBlur={true}
            // ** onSubmit Event
            onSubmit={(values, actions) => {
              sendInfo(values, actions);
            }}
          >
            {/* We obtain props from Formik */}
            {(props) => {
              const {
                dirty,
                touched,
                errors,
                values,
                isValid,
                setFieldTouched,
                isSubmitting,
              } = props;
              i18n.on("languageChanged", () => {
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                });
              });
              return (
                <Form className="w-100 discount-form d-flex flex-wrap">
                  <div className="w-100 discount-field-container d-flex flex-column">
                    <h5>{t("ticket.contentField1")}</h5>
                    <Field
                      className={
                        errors.title && touched.title
                          ? "ticketer-field red-border"
                          : touched.title
                          ? "ticketer-field purple-border"
                          : "ticketer-field"
                      }
                      id="content-title"
                      type="text"
                      name="title"
                      placeholder={t("ticket.contentPlaceholder")}
                    />
                    {errors.title && touched.title ? (
                      <ErrorMessage name="title">
                        {(msg) => (
                          <div className="ticketer-field-error">{msg}</div>
                        )}
                      </ErrorMessage>
                    ) : null}
                  </div>
                  <div className="w-100 discount-field-container d-flex flex-column">
                    <h5>{t("ticket.contentField2")}</h5>
                    <label className="upload-ticket d-flex flex-row justify-content-center align-items-center">
                      {readerState?.fileName ? null : <FilePlus />}
                      <p>
                        {readerState?.fileName
                          ? readerState?.fileName
                          : t("ticket.createTicketUploadButton2")}
                      </p>
                      <input
                        type="file"
                        accept="image/jpeg,image/jpg,image/png,application/pdf,video/mp4,audio/mpeg"
                        onChange={(e) => onSelectFile(e)}
                      ></input>
                    </label>
                  </div>
                  {fileSizeState ? (
                    <div className="ticketer-field-error">
                      {t("ticket.extraContentSizeError")}
                    </div>
                  ) : null}
                  <AdviceFooter
                    footer={{
                      text: t("ticket.contentText"),
                      icon: <CloudUpload />,
                    }}
                  />
                  <div className="modal-cancel-button-container d-flex flex-column flex-sm-row justify-content-center justify-content-sm-end align-items-center">
                    <button
                      className="register-email-form-button"
                      type="submit"
                      disabled={!(isValid && values.title && readerState)}
                    >
                      {t("ticket.contentButton")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ModalContent;
