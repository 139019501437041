import React from "react";

function Image(props) {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4563 2.43359H3.81922C2.28034 2.43359 1.43262 3.28131 1.43262 4.82019V11.1845C1.43262 12.7233 2.28034 13.5711 3.81922 13.5711H11.4563C12.9952 13.5711 13.8429 12.7233 13.8429 11.1845V4.82019C13.8429 3.28131 12.9952 2.43359 11.4563 2.43359ZM3.81922 3.38823H11.4563C12.46 3.38823 12.8883 3.81655 12.8883 4.82019V9.43429L9.99889 6.54488C9.75069 6.29668 9.34342 6.29668 9.09522 6.54488L5.95123 9.6888C5.83031 9.80972 5.62668 9.80972 5.50576 9.6888L4.90748 9.09059C4.65928 8.84238 4.25201 8.84238 4.00381 9.09059L2.38726 10.7071V4.82019C2.38726 3.81655 2.81557 3.38823 3.81922 3.38823ZM4.29654 6.09305C4.29654 5.65391 4.64914 5.29751 5.08764 5.29751H5.09401C5.53251 5.29751 5.88823 5.65391 5.88823 6.09305C5.88823 6.53218 5.53315 6.88858 5.09401 6.88858C4.65551 6.88858 4.29654 6.53218 4.29654 6.09305Z" fill="#FF5F00"/>
    </svg>
  );
}

export default Image;