import React from "react";

function LandingCardCircle(props) {

  return (
    <svg width="482" height="173" viewBox="0 0 482 173" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.1" d="M362.836 74.4863C228.006 -40.1963 32.4637 -18.4919 -74.0004 122.752L414.129 537.94C520.593 396.696 497.667 189.169 362.836 74.4863Z" fill="#816CFF"/>
    </svg>
  );
}

export default LandingCardCircle;