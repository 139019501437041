import React from "react";

function TrashTimes(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.75 6C20.75 6.414 20.414 6.75 20 6.75H4C3.586 6.75 3.25 6.414 3.25 6C3.25 5.586 3.586 5.25 4 5.25H8.21399C8.30699 5.068 8.37899 4.862 8.45599 4.632L8.65799 4.02499C8.86199 3.41299 9.43499 3 10.081 3H13.919C14.565 3 15.138 3.41299 15.342 4.02499L15.544 4.632C15.621 4.862 15.693 5.068 15.786 5.25H20C20.414 5.25 20.75 5.586 20.75 6ZM18.859 8.07001L18.19 18.2C18.08 19.78 17.25 21 15.19 21H8.81C6.75 21 5.92 19.78 5.81 18.2L5.14099 8.07001C5.12999 7.89701 5.267 7.75 5.44 7.75H18.559C18.733 7.75 18.87 7.89701 18.859 8.07001ZM13.061 14L14.531 12.53C14.824 12.237 14.824 11.762 14.531 11.469C14.238 11.176 13.763 11.176 13.47 11.469L12 12.939L10.53 11.469C10.237 11.176 9.76199 11.176 9.46899 11.469C9.17599 11.762 9.17599 12.237 9.46899 12.53L10.939 14L9.46899 15.47C9.17599 15.763 9.17599 16.238 9.46899 16.531C9.61499 16.677 9.80699 16.751 9.99899 16.751C10.191 16.751 10.383 16.678 10.529 16.531L11.999 15.061L13.469 16.531C13.615 16.677 13.807 16.751 13.999 16.751C14.191 16.751 14.383 16.678 14.529 16.531C14.822 16.238 14.822 15.763 14.529 15.47L13.061 14Z" fill="#EFECFC"/>
    </svg>
  );
}

export default TrashTimes;