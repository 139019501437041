import React from "react";

function CircleSubscriptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <g>
        <g>
          <g>
            <g>
              <path fill="#3623AA" d="M14.676 0h-1.354v28h1.354V0z"></path>
            </g>
            <g>
              <path fill="#3623AA" d="M28 13.322H0v1.354h28v-1.354z"></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M4.58 3.621l-.957.958 19.799 19.798.957-.957L4.58 3.621z"
              ></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M23.422 3.62L3.623 23.419l.957.957 19.8-19.799-.958-.957z"
              ></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M1.293 8.095L.783 9.35l25.93 10.565.51-1.254L1.294 8.095z"
              ></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M18.661.786L8.097 26.716l1.254.511 10.564-25.93-1.254-.511z"
              ></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M26.745 8.142L.77 18.598l.505 1.256L27.251 9.398l-.506-1.256z"
              ></path>
            </g>
            <g>
              <path
                fill="#3623AA"
                d="M9.393.768l-1.256.506L18.589 27.25l1.256-.506L9.393.768z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CircleSubscriptionIcon;
