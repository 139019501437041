import React from "react";

function Switch(props) {

  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M23.25 12.5C23.25 8.77208 20.2279 5.75 16.5 5.75H8.5C4.77208 5.75 1.75 8.77208 1.75 12.5C1.75 16.2279 4.77208 19.25 8.5 19.25L16.5 19.25C20.2279 19.25 23.25 16.2279 23.25 12.5ZM13.593 17.75C11.7484 16.7265 10.5 14.759 10.5 12.5C10.5 10.241 11.7484 8.27355 13.593 7.25L8.5 7.25C5.60051 7.25 3.25 9.6005 3.25 12.5C3.25 15.3995 5.60051 17.75 8.5 17.75L13.593 17.75Z" fill="#442ECB"/>
    </svg>
  );
}

export default Switch;