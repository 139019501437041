import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import ShieldHeartSmall from "../../components/icons/ShieldHeartSmall.jsx";

function InfoFirstPageChildren(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, isValid, values, submitForm } = useFormikContext();

  var navigate = useNavigate();

  const [count, setCount] = useState(0);
  const MAX_CHARACTERS = 500;

  const nextPage = async (route) => {
    await submitForm();
    if (!(props.ticketingCompany?.data[0]?.comercialName && props.ticketingCompany?.data[0]?.description 
      && props.ticketingCompany?.data[0]?.email && props.ticketingCompany?.data[0]?.phone 
      && props.ticketingCompany?.data[0]?.address && props.ticketingCompany?.data[0]?.companyName && props.ticketingCompany?.data[0]?.cif)) {
      navigate(route === "back" ? -1 : route);
    }
  }

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100">
          <h5>{t('ticketerSection.infoField1') + "*"}</h5>
          <Field 
            className={ errors.name && touched.name ? "ticketer-field red-border" : touched.name ? "ticketer-field purple-border" : "ticketer-field" } 
            id="ticketer-name" type="text" name="name" placeholder=""
          />
          { errors.name && touched.name ? 
            <ErrorMessage name="name">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
            :
            null
          }
        </div>
        <div className="w-100">
          <h5>{t('ticketerSection.infoField2') + "*"}</h5>
          <Field 
            className={ errors.description && touched.description ? "ticketer-field red-border" : touched.description ? "ticketer-field purple-border" : "ticketer-field" } 
            id="ticketer-description" type="text" name="description" placeholder={t('ticketerSection.ckeditorPlaceholder')} 
            as="textarea" maxLength={ MAX_CHARACTERS } onKeyUp={ (e) => setCount(e.target.value.length) }
          />
          { errors.description && touched.description ? 
            <ErrorMessage name="description">{msg => <div className="ticketer-field-error">{msg}</div>}</ErrorMessage>
            :
            null
          }
          <h5 className="description-counter">{ count } / { MAX_CHARACTERS }</h5>
        </div>
      </div>
      <ButtonGroupTibet button = { { text: t('ticketer.next'), disabled: !(isValid && values.name && values.description), function: () => nextPage(routes.infoContact) } }/>
    </>
  );
}

function InfoFirstPage(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, ticketerModal, setTicketerModal, role } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialFirstInfoData = {
    name: ticketingCompany?.data[0]?.comercialName ? ticketingCompany?.data[0]?.comercialName : "",
    description: ticketingCompany?.data[0]?.description ? ticketingCompany?.data[0]?.description : ""
  }

  const dataFirstInfoSchema = Yup.object().shape(
    {
      name: Yup.string().required(t('register.error2')),
      description: Yup.string().required(t('register.error2')).max(500, t('ticketerSection.characters'))
    }
  );

  return (
    <TicketingSectionComponent
      initialValues = { initialFirstInfoData }
      validationSchema = { dataFirstInfoSchema }
      ticketer = { true }
      title = { t('ticketer.info1') }
      pagination = { "1/3" }
      description = { t('ticketer.infoTitle4') }
      footer = { { text: t('ticketer.adviceInfo1'), icon: <ShieldHeartSmall/> } }
      nextPage={ ticketingCompany?.data[0]?.comercialName && ticketingCompany?.data[0]?.description 
        && ticketingCompany?.data[0]?.email && ticketingCompany?.data[0]?.phone 
        && ticketingCompany?.data[0]?.address && ticketingCompany?.data[0]?.companyName && ticketingCompany?.data[0]?.cif ? false : true }
    >
      <InfoFirstPageChildren ticketingCompany = { ticketingCompany } initialValues = { initialFirstInfoData }/>
    </TicketingSectionComponent>
  );
}

export default InfoFirstPage;