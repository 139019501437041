import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import InstagramSmall from "../../components/icons/InstagramSmall.jsx";
import FacebookSmall from "../../components/icons/FacebookSmall.jsx";
import TwitterSmall from "../../components/icons/TwitterSmall.jsx";
import TiktokSmall from "../../components/icons/TiktokSmall.jsx";

export default function LandingFooter(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setTermsLandingModal } = React.useContext(AppContext);

  return (
    <div
      className={`home-elvis-main-footer d-flex flex-column flex-lg-row ${
        props.landingPage ? "justify-content-between" : "justify-content-center"
      } align-items-center ${
        props.landingPage ? "align-items-lg-end" : "align-items-center"
      }`}
    >
      {props.landingPage ? (
        <div
          className={`home-elvis-main-footer-left d-flex flex-column justify-content-between align-items-center align-items-lg-start`}
        >
          {props.company?.instagram ||
          props.company?.facebook ||
          props.company?.twitter ||
          props.company?.tiktok ? (
            <>
              <h3>{t("landingElvis.contact")}</h3>
              <div className={`d-flex align-items-center`}>
                {props.company?.instagram ? (
                  <a
                    href={`https://www.instagram.com/${props.company?.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramSmall />
                  </a>
                ) : null}
                {props.company?.facebook ? (
                  <a
                    href={`https://www.facebook.com/${props.company?.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookSmall />
                  </a>
                ) : null}
                {props.company?.twitter ? (
                  <a
                    href={`https://www.twitter.com/${props.company?.twitter}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterSmall />
                  </a>
                ) : null}
                {props.company?.tiktok ? (
                  <a
                    href={`https://www.tiktok.com/${props.company?.tiktok}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TiktokSmall />
                  </a>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      ) : null}
      {props.company?.terms ? (
        <div className={`home-elvis-main-footer-right d-flex`}>
          <h4 onClick={() => setTermsLandingModal(props.company?.terms)}>
            {t("ticketer.terms1")}
          </h4>
        </div>
      ) : null}
    </div>
  );
}
