import React, { useEffect, useState, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import routes from './routes/routes';
import jwt_decode from 'jwt-decode';
import { initFacebookSdk } from './components/auth/authFb';
import { authPages, dashboardPages } from './components';
import { getSubdomain } from './components/shared/functions';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider
} from 'firebase/auth';
import AuthSuccess from './pages/auth/AuthSuccess';
import ModalContainer from './components/modals/ModalContainer';
import ModalTerms from './components/modals/ModalTerms';
import ModalTicketer from './components/modals/ModalTicketer';
import ModalTicketerSection from './components/modals/ModalTicketerSection';
import ModalDeleteEvent from './components/modals/ModalDeleteEvent';
import ModalLanding from './components/modals/ModalLanding';
import NotFoundPage from './pages/404/NotFoundPage';
import LandingPage from './pages/landing/LandingPage';
import LandingTicketer from './pages/landing/LandingTicketer';
import LandingEvent from './pages/landing/LandingEvent';
import '../src/styles/landing.scss';
import '../src/styles/ui.scss';
import '../src/styles/auth.scss';
import '../src/styles/dashboard.scss';
import '../src/styles/modal.scss';
import './App.scss';
import ModalDiscount from './components/modals/ModalDiscount';
import LandingTicketDetail from './pages/landing/LandingTicketDetail';
import LandingCheckout from './pages/landing/LandingCheckout';
import LandingPurchaseDone from './pages/landing/LandingPurchaseDone';
import ModalQr from './components/modals/ModalQr';
import LandingGift from './pages/landing/LandingGift';
import ModalImage from './components/modals/ModalImage';
import ModalTermsLanding from './components/modals/ModalTermsLanding';
import ModalContent from './components/modals/ModalContent';
import PaymentPage from './pages/ticketer/PaymentPage';
import SimulatePruchase from './pages/development/SimulatePruchase';
import StripeOk from './pages/development/StripeOk';
import StripeKo from './pages/development/StripeKo';
import SimulateSubscription from './pages/development/SimulateSubscription';
import SimulateCheckoutMercadopago from './pages/development/SimulateCheckoutMercadopago';
import LandingTerms from './pages/landing/LandingTerms';

export const AppContext = React.createContext();

initFacebookSdk();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MESAUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const initialState = {
  isAuthenticated: localStorage.getItem('token') ? true : false,
  token: localStorage.getItem('token')
    ? localStorage.getItem('token').replace(/['"]+/g, '')
    : null,
  userId: localStorage.getItem('token')
    ? jwt_decode(localStorage.getItem('token').replace(/['"]+/g, '')).username
    : null,
  exp: localStorage.getItem('token')
    ? jwt_decode(localStorage.getItem('token').replace(/['"]+/g, '')).exp
    : null,
  roles: localStorage.getItem('token')
    ? jwt_decode(localStorage.getItem('token').replace(/['"]+/g, '')).roles
    : null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
        userId: jwt_decode(action.payload)?.username
          ? jwt_decode(action.payload)?.username
          : null,
        exp: jwt_decode(action.payload)?.exp
          ? jwt_decode(action.payload)?.exp
          : null,
        roles: jwt_decode(action.payload)?.roles
          ? jwt_decode(action.payload)?.roles
          : null
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        userId: null,
        exp: null,
        roles: null
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const location = useLocation().pathname;
  const subdomain = useMemo(() => getSubdomain(), []);

  function isJsonString(str) {
    try {
      var json = JSON.parse(str);
      return typeof json === 'object';
    } catch (e) {
      return false;
    }
  }

  const [termsModal, setTermsModal] = useState(false);
  const [termsLandingModal, setTermsLandingModal] = useState(false);
  const [ticketerModal, setTicketerModal] = useState(false);
  const [ticketerSectionModal, setTicketerSectionModal] = useState(false);
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [landingModal, setLandingModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);
  const [contentModal, setContentModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [elvis, setElvis] = useState(
    subdomain
      ? state?.roles
        ? state?.roles[0] === 'ROLE_USER'
          ? false
          : true
        : false
      : true
  );
  const [publicElvisId, setPublicElvisId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [ticketingCompany, setTicketingCompany] = useState({ data: [] });
  const [createTicketInfo, setCreateTicketInfo] = useState(
    sessionStorage.getItem('ticket_draft')
      ? JSON.parse(sessionStorage.getItem('ticket_draft'))
      : {}
  );
  const [ticketSaleOpen, setTicketSaleOpen] = useState(
    sessionStorage.getItem('opened_cart') &&
      isJsonString(sessionStorage.getItem('opened_cart'))
      ? JSON.parse(sessionStorage.getItem('opened_cart'))
      : []
  );
  const [ticketSaleQuantity, setTicketSaleQuantity] = useState(
    sessionStorage.getItem('quantity_cart') &&
      isJsonString(sessionStorage.getItem('quantity_cart'))
      ? JSON.parse(sessionStorage.getItem('quantity_cart'))
      : []
  );
  const [firebaseLoading, setFirebaseLoading] = useState(
    sessionStorage.getItem('authOngoing') ? true : false
  );

  const registerWithGoogle = () => {
    const googleProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleProvider);
  };

  const registerWithFb = () => {
    const facebookProvider = new FacebookAuthProvider();
    signInWithRedirect(auth, facebookProvider);
  };
  /*const googleScript = () => {
    const urlGoogle = 'https://accounts.google.com/gsi/client';
    const scripts = Array.from(document.getElementsByTagName('script'));
    if (!scripts.find(o => o.src === urlGoogle)) {
      const scriptGoogle = document.createElement('script');
      scriptGoogle.src = urlGoogle;
      scriptGoogle.async = true;
      scriptGoogle.defer = true;
      document.body.appendChild(scriptGoogle);
    }
    return () => {
      document.body.removeChild(scripts.find(o => o.src === urlGoogle));
    }
  }

  const fbScript = () => {
    const urlFb = 'https://connect.facebook.net/es_ES/sdk.js#xfbml=1&version=v16.0';
    const scripts = Array.from(document.getElementsByTagName('script'));
    if (!scripts.find(o => o.src === urlFb)) {
      const scriptFb = document.createElement('script');
      scriptFb.src = urlFb;
      scriptFb.async = true;
      scriptFb.defer = true;
      scriptFb.crossorigin = "anonymous";
      scriptFb.nonce = "woLXNOnJ";
      document.getElementById('fb-root').after(scriptFb);
    }
    return () => {
      document.body.removeChild(scripts.find(o => o.src === urlFb));
    }
  }*/

  //const subdomain = window.location.hostname.split('.')[0];

  const defaultRoutes = !subdomain ? (
    <>
      <Route path={routes.landing} element={<LandingPage />} />
      {/*<Route path={ routes.faq } element={ <LandingFaq/> }/>*/}
      <Route path={routes.tibetTerms} element={<LandingTerms />} />
      <Route path={routes.ticketerPreview} element={<LandingTicketer />} />
      <Route
        path={routes.ticketerPreviewCustomize}
        element={<LandingTicketer />}
      />
      <Route path={routes.eventPreviewCustomize} element={<LandingEvent />} />
    </>
  ) : (
    <>
      <Route path={routes.landing} element={<LandingTicketer />} />
      <Route path={routes.ticketerEvent} element={<LandingEvent />} />
      <Route path={routes.ticketSaleId} element={<LandingTicketDetail />} />
      <Route path={routes.ticketResaleId} element={<LandingTicketDetail />} />
      <Route path={routes.payment} element={<PaymentPage />} />
      <Route path={routes.checkout} element={<LandingCheckout />} />
      <Route path={routes.checkoutResale} element={<LandingCheckout />} />
      <Route
        path={routes.landingPurchaseDone}
        element={<LandingPurchaseDone />}
      />
      <Route path={routes.myGift} element={<LandingGift />} />
    </>
  );

  useEffect(() => {
    if (
      location.includes(routes.createTicketsData) ||
      location.includes(routes.createTicketsSale) ||
      location.includes(routes.createTicketsAdvance) ||
      location.includes(routes.createTicketsResume)
    ) {
    } else if (Object.keys(createTicketInfo).length !== 0) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  }, [location]);

  useEffect(() => {
    setElvis(
      state?.roles ? (state?.roles[0] === 'ROLE_USER' ? false : true) : true
    );
  }, [state]);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
        registerWithGoogle,
        auth,
        firebaseLoading,
        setFirebaseLoading,
        termsModal,
        setTermsModal,
        termsLandingModal,
        setTermsLandingModal,
        ticketerModal,
        setTicketerModal,
        ticketerSectionModal,
        setTicketerSectionModal,
        deleteEventModal,
        setDeleteEventModal,
        landingModal,
        setLandingModal,
        discountModal,
        setDiscountModal,
        contentModal,
        setContentModal,
        qrModal,
        setQrModal,
        imageModal,
        setImageModal,
        elvis,
        setElvis,
        publicElvisId,
        setPublicElvisId,
        userData,
        setUserData,
        createTicketInfo,
        setCreateTicketInfo,
        ticketSaleOpen,
        setTicketSaleOpen,
        ticketSaleQuantity,
        setTicketSaleQuantity,
        ticketingCompany,
        setTicketingCompany
      }}
    >
      <Routes>
        <Route path="" element={<Navigate replace to={routes.landing} />} />
        {/* <Route path={routes.landing} element={<LandingPage />} /> */}
        {defaultRoutes}
        {authPages.map(
          ({ exact, path, component: Component, layout: Layout }, index) => (
            <Route
              key={index}
              exact={exact}
              path={path}
              element={
                true ? (
                  <Layout main={<Component />} />
                ) : (
                  <Navigate replace to={routes.landing} />
                )
              }
            />
          )
        )}
        <Route path={routes.authEmailOk} element={<AuthSuccess />} />
        {state.isAuthenticated && state.token
          ? dashboardPages.map(
              (
                {
                  exact,
                  path,
                  component: Component,
                  layout: Layout,
                  elvisComponent,
                  floraComponent
                },
                index
              ) => (
                <Route
                  key={index}
                  exact={exact}
                  path={path}
                  element={
                    elvisComponent === elvis || floraComponent === !elvis ? (
                      <Layout main={<Component />} />
                    ) : (
                      <Navigate replace to={routes.homepage} />
                    )
                  }
                />
              )
            )
          : null}
        <Route
          path={routes.simulateStripePurchase}
          element={<SimulatePruchase />}
        />
        <Route path={routes.simulateStripeOk} element={<StripeOk />} />
        <Route path={routes.simulateStripeKo} element={<StripeKo />} />
        <Route
          path={routes.simulateStripeSubscription}
          element={<SimulateSubscription />}
        />
        <Route
          path={routes.simulateCheckoutMercadopago}
          element={<SimulateCheckoutMercadopago />}
        />
        <Route path={'*'} element={<NotFoundPage />} />
      </Routes>
      <ModalContainer>
        {termsModal ? <ModalTerms /> : null}
        {termsLandingModal ? <ModalTermsLanding /> : null}
        {ticketerModal !== false &&
        elvis &&
        (location.includes(routes.ticketer) ||
          location.includes(routes.events)) ? (
          <ModalTicketer />
        ) : null}
        {contentModal !== false &&
        elvis &&
        location.includes(routes.createTicketsAdvance) ? (
          <ModalContent />
        ) : null}
        {imageModal !== false ? <ModalImage /> : null}
        {ticketerSectionModal !== false && elvis ? (
          <ModalTicketerSection />
        ) : null}
        {deleteEventModal !== false &&
        elvis &&
        location.includes(routes.events) ? (
          <ModalDeleteEvent />
        ) : null}
        {discountModal !== false &&
        elvis &&
        location.includes(routes.events) ? (
          <ModalDiscount />
        ) : null}
        {landingModal !== false ? <ModalLanding /> : null}
        {qrModal !== false &&
        !elvis &&
        location.includes(routes.ticketerMyTickets + '/') ? (
          <ModalQr />
        ) : null}
      </ModalContainer>
    </AppContext.Provider>
  );
}

export default App;
