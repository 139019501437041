import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes';
import { AppContext } from '../../App.js';
import { useMutation } from '@tanstack/react-query';
import { acceptTerms } from '../../services/axiosService';
import TermsImage from '../icons/TermsImage.jsx';
import ModalTermsEvents from '../icons/ModalTermsEvents';
import ModalTermsProfile from '../icons/ModalTermsProfile';
import Check from '../icons/Check.jsx';
import SpinnerPurple from '../icons/SpinnerPurple';

function ModalTerms() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    termsModal,
    setTermsModal,
    role
  } = React.useContext(AppContext);
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const [isRegisterOk, setIsRegisterOk] = useState(false);

  var navigate = useNavigate();

  const { mutate } = useMutation(() => acceptTerms(authState.token), {
    onSuccess: (res) => {
      if (res.data) {
        setIsRegisterOk(true);
      }
    },
    onError: (err) => {
      console.error(err);
    }
  });

  const toApp = async (route) => {
    setTermsModal(false);
    navigate(route);
  };

  document.addEventListener('keyup', (e) => {
    // if we press the ESC
    if (isRegisterOk) {
      if (e.key === 'Escape' && document.querySelector('.modal-background')) {
        setTermsModal(false);
      }
    }
  });

  document.addEventListener('click', (e) => {
    if (isRegisterOk) {
      if (e.target === document.querySelector('.modal-container')) {
        setTermsModal(false);
      }
    }
  });

  return (
    <div
      id="modal-terms-section"
      className={termsModal ? 'modal-background' : 'd-none'}
    >
      <div className="modal-container justify-content-center align-items-center">
        <div className="modal-terms-container sign-mail-box d-flex flex-column justify-content-center align-items-center">
          {isRegisterOk ? (
            <>
              <div className="modal-terms-container-welcome d-flex flex-column justify-content-center align-items-center">
                <h2>{t('terms.welcome')}😁</h2>
                <p>{t('terms.experience')}</p>
                <h4>{t('terms.start')}</h4>
              </div>
              <div className="modal-terms-container-boxes d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <div
                  className="modal-terms-container-boxes-left d-flex flex-column justify-content-start align-items-center"
                  onClick={() =>
                    toApp(role ? routes.events : routes.ticketerMyTickets)
                  }
                >
                  <ModalTermsEvents />
                  <h3>{t('terms.event1')}</h3>
                  <p className="d-none d-lg-block">
                    {t('terms.event2')}
                    <span>{t('terms.event3')}</span>
                  </p>
                </div>
                <div
                  className="modal-terms-container-boxes-right d-flex flex-column justify-content-start align-items-center"
                  onClick={() => toApp(routes.profile)}
                >
                  <ModalTermsProfile />
                  <h3>{t('terms.event4')}</h3>
                  <p className="d-none d-lg-block">
                    {t('terms.event5')}
                    <span>{t('terms.event6')}</span>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <TermsImage />
              <h3>{t('terms.sentence1')}</h3>
              <p>{t('terms.sentence2')}</p>
              <div className="checkbox-register-container d-flex flex-row justify-content-center align-items-center">
                <input
                  className="checkbox-register-mail"
                  type="checkbox"
                  id="checkbox-register-mail"
                  checked={termsCheckbox}
                  onChange={() => setTermsCheckbox(!termsCheckbox)}
                />
                <span className="checkmark d-flex flex-row justify-content-center align-items-center"></span>
                <label className="checkbox-label" htmlFor="checkbox">
                  {t('register.terms1')}
                  <span
                    onClick={() =>
                      window.open(
                        `${window.location.origin}${routes.tibetTerms}`,
                        '_blank',
                        'rel=noopener noreferrer popup=false'
                      )
                    }
                  >
                    {t('register.terms2')}
                  </span>
                  {/*t('register.terms3')*/}
                </label>
              </div>
              <button
                disabled={termsCheckbox ? false : true}
                id="register-email-form-button"
                className={
                  isRegisterOk
                    ? 'register-email-form-button register-ok-button'
                    : 'register-email-form-button'
                }
                onClick={async () => mutate()}
              >
                {false ? (
                  <SpinnerPurple />
                ) : isRegisterOk ? (
                  <Check />
                ) : (
                  t('register.send')
                )}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalTerms;
