import React from "react";

function CalendarCheckAlt(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 14C15.791 14 14 15.791 14 18C14 20.209 15.791 22 18 22C20.209 22 22 20.209 22 18C22 15.791 20.209 14 18 14ZM19.604 17.52L17.937 19.187C17.843 19.281 17.716 19.333 17.583 19.333C17.45 19.333 17.323 19.28 17.229 19.187L16.396 18.354C16.201 18.159 16.201 17.842 16.396 17.647C16.591 17.452 16.908 17.452 17.103 17.647L17.582 18.127L18.895 16.814C19.09 16.619 19.4071 16.619 19.6021 16.814C19.7971 17.009 19.799 17.325 19.604 17.52ZM19.7 8.75H2.30005C2.13405 8.75 2 8.88405 2 9.05005V17C2 19 3 20 5 20H12.46C12.67 20 12.8 19.8001 12.74 19.6101C12.56 19.0201 12.48 18.4 12.51 17.74C12.636 14.971 14.9729 12.635 17.7419 12.51C18.4009 12.48 19.0199 12.561 19.6089 12.74C19.7989 12.8 19.999 12.67 19.999 12.46V9.05005C20 8.88405 19.866 8.75 19.7 8.75ZM7.02002 17C6.46802 17 6.01489 16.552 6.01489 16C6.01489 15.448 6.45801 15 7.01001 15H7.02002C7.57302 15 8.02002 15.448 8.02002 16C8.02002 16.552 7.57202 17 7.02002 17ZM7.02002 13C6.46802 13 6.01489 12.552 6.01489 12C6.01489 11.448 6.45801 11 7.01001 11H7.02002C7.57302 11 8.02002 11.448 8.02002 12C8.02002 12.552 7.57202 13 7.02002 13ZM11.02 13C10.468 13 10.0149 12.552 10.0149 12C10.0149 11.448 10.458 11 11.01 11H11.02C11.573 11 12.02 11.448 12.02 12C12.02 12.552 11.572 13 11.02 13ZM20 6.5V6.94995C20 7.11595 19.866 7.25 19.7 7.25H2.30005C2.13405 7.25 2 7.11595 2 6.94995V6.5C2 4.5 3 3.5 5 3.5H6.25V2C6.25 1.59 6.59 1.25 7 1.25C7.41 1.25 7.75 1.59 7.75 2V3.5H14.25V2C14.25 1.59 14.59 1.25 15 1.25C15.41 1.25 15.75 1.59 15.75 2V3.5H17C19 3.5 20 4.5 20 6.5Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default CalendarCheckAlt;