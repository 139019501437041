import React from "react";

function Calendar(props) {

  return (
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.7749 15.4375H5.22508C4.96224 15.4375 4.75 15.6497 4.75 15.9126V28.5C4.75 31.6667 6.33333 33.25 9.5 33.25H28.5C31.6667 33.25 33.25 31.6667 33.25 28.5V15.9126C33.25 15.6497 33.0378 15.4375 32.7749 15.4375ZM12.6984 28.5C11.8244 28.5 11.1069 27.7907 11.1069 26.9167C11.1069 26.0427 11.8085 25.3333 12.6825 25.3333H12.6984C13.5739 25.3333 14.2817 26.0427 14.2817 26.9167C14.2817 27.7907 13.5724 28.5 12.6984 28.5ZM12.6984 22.1667C11.8244 22.1667 11.1069 21.4573 11.1069 20.5833C11.1069 19.7093 11.8085 19 12.6825 19H12.6984C13.5739 19 14.2817 19.7093 14.2817 20.5833C14.2817 21.4573 13.5724 22.1667 12.6984 22.1667ZM19.0317 28.5C18.1577 28.5 17.4402 27.7907 17.4402 26.9167C17.4402 26.0427 18.1418 25.3333 19.0158 25.3333H19.0317C19.9073 25.3333 20.615 26.0427 20.615 26.9167C20.615 27.7907 19.9057 28.5 19.0317 28.5ZM19.0317 22.1667C18.1577 22.1667 17.4402 21.4573 17.4402 20.5833C17.4402 19.7093 18.1418 19 19.0158 19H19.0317C19.9073 19 20.615 19.7093 20.615 20.5833C20.615 21.4573 19.9057 22.1667 19.0317 22.1667ZM25.365 28.5C24.491 28.5 23.7736 27.7907 23.7736 26.9167C23.7736 26.0427 24.4752 25.3333 25.3492 25.3333H25.365C26.2406 25.3333 26.9484 26.0427 26.9484 26.9167C26.9484 27.7907 26.239 28.5 25.365 28.5ZM25.365 22.1667C24.491 22.1667 23.7736 21.4573 23.7736 20.5833C23.7736 19.7093 24.4752 19 25.3492 19H25.365C26.2406 19 26.9484 19.7093 26.9484 20.5833C26.9484 21.4573 26.239 22.1667 25.365 22.1667ZM33.25 11.875V12.5874C33.25 12.8503 33.0378 13.0625 32.7749 13.0625H5.22508C4.96224 13.0625 4.75 12.8503 4.75 12.5874V11.875C4.75 8.70833 6.33333 7.125 9.5 7.125H11.4792V4.75C11.4792 4.10083 12.0175 3.5625 12.6667 3.5625C13.3158 3.5625 13.8542 4.10083 13.8542 4.75V7.125H24.1458V4.75C24.1458 4.10083 24.6842 3.5625 25.3333 3.5625C25.9825 3.5625 26.5208 4.10083 26.5208 4.75V7.125H28.5C31.6667 7.125 33.25 8.70833 33.25 11.875Z" fill="#816CFF"/>
    </svg>
  );
}

export default Calendar;