import React from "react";

function StarShootingSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2426 15.989C16.0586 16.168 15.9745 16.426 16.0175 16.678L16.5945 20.025C16.7115 20.704 15.9975 21.222 15.3865 20.902L12.3626 19.319C12.1356 19.2 11.8656 19.2 11.6386 19.319L8.61652 20.9C8.00352 21.221 7.28856 20.702 7.40656 20.022L7.9826 16.677C8.0266 16.424 7.94163 16.167 7.75763 15.988L5.23663 13.542C4.77363 13.093 5.02854 12.308 5.66754 12.216L9.1546 11.712C9.4086 11.675 9.62752 11.516 9.74152 11.287L11.2515 8.23999C11.5575 7.62199 12.4416 7.62199 12.7486 8.23999L14.2586 11.287C14.3726 11.516 14.5915 11.675 14.8455 11.712L18.3326 12.216C18.9716 12.309 19.2276 13.093 18.7636 13.542L16.2426 15.989ZM11.9996 5.75C12.4136 5.75 12.7496 5.414 12.7496 5V3C12.7496 2.586 12.4136 2.25 11.9996 2.25C11.5856 2.25 11.2496 2.586 11.2496 3V5C11.2496 5.414 11.5856 5.75 11.9996 5.75ZM17.9996 9.75C18.4136 9.75 18.7496 9.414 18.7496 9V3C18.7496 2.586 18.4136 2.25 17.9996 2.25C17.5856 2.25 17.2496 2.586 17.2496 3V9C17.2496 9.414 17.5856 9.75 17.9996 9.75ZM5.99957 9.75C6.41357 9.75 6.74957 9.414 6.74957 9V3C6.74957 2.586 6.41357 2.25 5.99957 2.25C5.58557 2.25 5.24957 2.586 5.24957 3V9C5.24957 9.414 5.58557 9.75 5.99957 9.75Z" fill="#816CFF"/>
    </svg>
  );
}

export default StarShootingSmall;