import React from "react";

function Music(props) {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.0077 3.25392V10.2329C13.0077 10.2349 13.0064 10.2362 13.0064 10.2381C13.0007 11.286 12.1474 12.1366 11.0989 12.1366C10.0465 12.1366 9.19016 11.2802 9.19016 10.2278C9.19016 9.17546 10.0465 8.31907 11.0989 8.31907C11.5895 8.31907 12.0329 8.5106 12.3714 8.81537V5.54433L6.00891 7.32586V12.136C6.00891 13.1883 5.15251 14.0447 4.10016 14.0447C3.0478 14.0447 2.19141 13.1883 2.19141 12.136C2.19141 11.0836 3.0478 10.2272 4.10016 10.2272C4.59071 10.2272 5.03417 10.4187 5.37266 10.7235V4.91958C5.37266 4.13253 5.77411 3.60454 6.53316 3.3914L11.3916 2.03036C11.87 1.89611 12.2772 1.94189 12.5699 2.16394C12.8607 2.38472 13.0077 2.75129 13.0077 3.25392Z" fill="#FF5F00"/>
    </svg>
  );
}

export default Music;