import routes from './routes/routes';
import AuthLayout from './pages/auth/AuthLayout';
import DashboardLayout from './pages/dashboard/DashboardLayout';
import RegisterButtonsContainer from './components/auth/RegisterButtonsContainer';
import RegisterEmail from './components/auth/RegisterEmail';
import ProfileComponent from './components/dashboard/ProfileComponent';
import HomeComponent from './components/dashboard/HomeComponent';
import EventsComponent from './components/dashboard/EventsComponent';
import TicketingComponent from './components/dashboard/TicketingComponent';
import DomainFirstPage from './pages/ticketer/DomainFirstPage';
import DomainSecondPageChildren from './pages/ticketer/DomainSecondPage';
import TermsPage from './pages/ticketer/TermsPage';
import PolicyPage from './pages/ticketer/PolicyPage';
import PaymentPage from './pages/ticketer/PaymentPage';
import CustomizeFirstPage from './pages/ticketer/CustomizeFirstPage';
import CustomizeSecondPage from './pages/ticketer/CustomizeSecondPage';
import InfoFirstPage from './pages/ticketer/InfoFirstPage';
import InfoSecondPage from './pages/ticketer/InfoSecondPage';
import InfoThirdPage from './pages/ticketer/InfoThirdPage';
import EventsCreateComponent from './components/dashboard/EventsCreateComponent';
import CustomizeEventFirstPage from './pages/createEvents/CustomizeEventFirstPage';
import CustomizeEventSecondPage from './pages/createEvents/CustomizeEventSecondPage';
import CustomizeEventThirdPage from './pages/createEvents/CustomizeEventThirdPage';
import InfoEventFirstPage from './pages/createEvents/InfoEventFirstPage';
import InfoEventSecondPage from './pages/createEvents/InfoEventSecondPage';
import InfoEventThirdPage from './pages/createEvents/InfoEventThirdPage';
import EventDetailComponent from './components/dashboard/EventDetailComponent';
import MyTicketsComponent from './components/dashboard/MyTicketsComponent';
import TicketsEvent from './pages/createEvents/TicketsEvent';
import TicketDetail from './pages/createEvents/TicketDetail';
import TicketsEventFirstPage from './pages/createEvents/TicketEventFirstPage';
import TicketsEventSecondPage from './pages/createEvents/TicketEventSecondPage';
import TicketsEventThirdPage from './pages/createEvents/TicketEventThirdPage';
import MyTicketsDetailComponent from './components/dashboard/MyTicketsDetailComponent';
import ManageSubscriptions from './components/dashboard/ManageSubscriptions';
import StripeAccountCancelLayout from './pages/development/StripeAccountCancelLayout';
import PaymentStripeVerify from './components/dashboard/PaymentStripeVerify';
import FaqsComponent from './components/dashboard/FaqsComponent';
import FaqsComponentId from './components/dashboard/FaqsComponentId';
import ReaderComponent from './components/dashboard/ReaderComponent';

export const authPages = [
  {
    exact: true,
    path: routes.auth,
    component: RegisterButtonsContainer,
    layout: AuthLayout
  },
  {
    exact: true,
    path: routes.authEmail,
    component: RegisterEmail,
    layout: AuthLayout
  }
];

export const dashboardPages = [
  {
    exact: true,
    path: routes.homepage,
    component: HomeComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: true
  },
  {
    exact: true,
    path: routes.profile,
    component: ProfileComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: true
  },
  {
    exact: true,
    path: routes.events,
    component: EventsComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.ticketer,
    component: TicketingComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.ticketerMyTickets,
    component: MyTicketsComponent,
    layout: DashboardLayout,
    elvisComponent: false,
    floraComponent: true
  },
  {
    exact: true,
    path: routes.ticketerMyTicketsId,
    component: MyTicketsDetailComponent,
    layout: DashboardLayout,
    elvisComponent: false,
    floraComponent: true
  },
  {
    exact: true,
    path: routes.terms,
    component: TermsPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.privacy,
    component: PolicyPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.domain,
    component: DomainFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.customize,
    component: CustomizeFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.payment,
    component: PaymentPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.info,
    component: InfoFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.social,
    component: CustomizeSecondPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.infoContact,
    component: InfoSecondPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.infoBilling,
    component: InfoThirdPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.domainChange,
    component: DomainSecondPageChildren,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreate,
    component: EventsCreateComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateNewTickets,
    component: TicketsEvent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsIdCreateTickets,
    component: TicketsEvent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsIdCheckTicketsId,
    component: TicketDetail,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreatePicture,
    component: CustomizeEventFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateMiniature,
    component: CustomizeEventSecondPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateSocial,
    component: CustomizeEventThirdPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateData,
    component: InfoEventFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateLocation,
    component: InfoEventSecondPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateDateTime,
    component: InfoEventThirdPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsId,
    component: EventDetailComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateTicketsData,
    component: TicketsEventFirstPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateTicketsSale,
    component: TicketsEventSecondPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateTicketsAdvance,
    component: TicketsEventThirdPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.eventsCreateTicketsResume,
    component: TicketDetail,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.manageSubscriptions,
    component: ManageSubscriptions,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.paymentStripeAccountVerify,
    component: PaymentStripeVerify,
    layout: StripeAccountCancelLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.paymentSuccess,
    component: PaymentPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.paymentFailed,
    component: PaymentPage,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.faqs,
    component: FaqsComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.faqsId,
    component: FaqsComponentId,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  },
  {
    exact: true,
    path: routes.reader,
    component: ReaderComponent,
    layout: DashboardLayout,
    elvisComponent: true,
    floraComponent: false
  }
];
