import React, {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { useQuery } from "@tanstack/react-query";
import {
    getPreferenceIdMercagopago,
    getSimulateStripeInfo,
    getTicketingCompanyById
} from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import GlobeSmall from "../../components/icons/GlobeSmall.jsx";
import TermsPolicyButton from "../../components/ui/TermsPolicyButton.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import SettingsSmall from "../../components/icons/SettingsSmall";
import TicketerButton from "../../components/ui/TicketerButton";
import axios from "axios";

function SimulateCheckoutMercadopago(props) {

    // eslint-disable-next-line
    const [t, i18n] = useTranslation("global");

    const { state: authState } = React.useContext(AppContext);
    const [preferenceId, setPreferenceId] = useState(null);
    const mercadopagoDivRef = React.createRef();

    var navigate = useNavigate();

    const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
        queryKey: ['preferenceIdMercadopago'],
        queryFn: () => getPreferenceIdMercagopago(authState.token),
        enabled: !!authState.isAuthenticated,
        onSuccess: (res) => {
            console.log(res);
            setPreferenceId(res.data.preference_id);
        },
        onError: (err) => {
            console.log(err);
            console.error(err.response.status);
        },
        refetchOnMount: false,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (preferenceId && mercadopagoDivRef.current) {
            const script = document.createElement('script');

            script.src = 'https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js';
            script.setAttribute('data-preference-id', preferenceId);

            mercadopagoDivRef.current.appendChild(script);

            return () => {
                mercadopagoDivRef.current.removeChild(script);
            }
        }
    }, [preferenceId]);

    return (
        <TicketingSectionComponent>
            <div className="ticketer-sections-domain-container d-flex flex-wrap justify-content-center align-items-center">
                <div ref={mercadopagoDivRef} />
            </div>
            <ButtonGroupTibet button = { null }/>
        </TicketingSectionComponent>
    );
}

export default SimulateCheckoutMercadopago;