import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import QRCode from "react-qr-code";
import Times from "../icons/Times.jsx";

function ModalQr() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { qrModal, setQrModal } = React.useContext(AppContext);

  document.addEventListener("keyup", e => {
    if (e.key === "Escape" && document.querySelector(".modal-background")) {
      setQrModal(false);
    }
  });

  document.addEventListener("click", e => {
    if (e.target === document.querySelector(".modal-container")) {
      setQrModal(false);
    }   
  });

  return (
    <div id="modal-qr-section" className={ qrModal ? "modal-background d-flex justify-content-center align-items-center" : "d-none" }>
      <Times function = { () => setQrModal(false) }/>
      <div>
        { qrModal ?
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={ qrModal }
            viewBox={`0 0 256 256`}
          />
          : null
        }
      </div>
    </div>
  );
}

export default ModalQr;