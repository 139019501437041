import React from "react";

function StarMagicSmall(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2757 13.3587L15.2497 14.6187C14.9477 14.7127 14.7117 14.9497 14.6167 15.2517L13.3578 19.2747C13.1028 20.0898 12.0247 20.2617 11.5327 19.5647L9.11374 16.1377C8.93074 15.8787 8.63167 15.7267 8.31467 15.7317L3.9747 15.7967C3.1767 15.8087 2.71472 14.9007 3.19272 14.2607L5.79868 10.7727C5.98868 10.5187 6.04177 10.1887 5.94077 9.8887L4.59775 5.90573C4.32575 5.09773 5.09773 4.32575 5.90573 4.59775L9.88767 5.9407C10.1877 6.0417 10.5177 5.98874 10.7717 5.79874L14.2588 3.19272C14.8988 2.71472 15.8068 3.1767 15.7948 3.9747L15.7297 8.3157C15.7247 8.6327 15.8767 8.93172 16.1357 9.11472L19.5647 11.5357C20.2607 12.0277 20.0897 13.1037 19.2757 13.3587ZM21.5298 20.4697L18.5298 17.4697C18.2368 17.1767 17.7617 17.1767 17.4687 17.4697C17.1757 17.7627 17.1757 18.2377 17.4687 18.5307L20.4687 21.5307C20.6147 21.6767 20.8067 21.7507 20.9987 21.7507C21.1907 21.7507 21.3828 21.6777 21.5288 21.5307C21.8228 21.2377 21.8228 20.7617 21.5298 20.4697Z" fill="#816CFF"/>
    </svg>
  );
}

export default StarMagicSmall;