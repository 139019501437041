import React from "react";

function Check(props) {

  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.08236 13.375C6.08125 13.375 6.08013 13.375 6.07903 13.375C5.8573 13.3739 5.64555 13.2852 5.48923 13.1267L1.05464 8.62335C0.732028 8.2963 0.736466 7.76973 1.06352 7.44711C1.39057 7.1256 1.91828 7.12892 2.23979 7.45597L6.08569 11.3628L16.5801 0.868381C16.905 0.543548 17.4316 0.543548 17.7564 0.868381C18.0812 1.19321 18.0812 1.71986 17.7564 2.04469L6.66994 13.1312C6.51473 13.2875 6.30298 13.375 6.08236 13.375Z" fill="#442ECB"/>
    </svg>
  );
}

export default Check;