import React from 'react';
import DashboardBar from '../shared/DashboardBar';
import ArrowRight from '../icons/ArrowRight';
import { useQuery } from '@tanstack/react-query';
import { faqs } from '../../services/axiosService';
import { AppContext } from '../../App';
import { Link } from 'react-router-dom';
import routes from '../../routes/routes';

const FaqsComponent = () => {
  const { state: authState } = React.useContext(AppContext);

  const { data } = useQuery({
    queryKey: ['faqs'],
    queryFn: () => faqs(authState.token),
    onError: (err) => {
      console.error(err.response.status);
    },
    refetchOnMount: true
  });
  return (
    <div className="ticketer-section d-flex flex-column justify-content-start align-items-center">
      <DashboardBar
        text={'Preguntas Frecuentes'}
        session={false}
        /* buttonText={t("home.create")} */
        /* function={() => createNewEvent()} */
        disabled={false}
        button={false}
      />
      {data?.data?.map((m) => (
        <div className="w-100" key={m.id}>
          <div className="faq-card">
            <div className="faq-card-header">
              <div className="faq-card-header-intro">
                <img src={m.image} alt={m.name} />
                {m.name}
              </div>
            </div>
            <ul className="faq-card-ul">
              {m?.faqs.map((f) => (
                <Link to={`${routes.faqs}/${f.id}`}>
                  <li className="faq-card-li">
                    {f?.question}
                    <ArrowRight />
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqsComponent;
