import React from "react";
import { useTranslation } from "react-i18next";
import ArrowRight from "../icons/ArrowRight.jsx";

function CancelDeleteEventComponent(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className="delete-event d-flex justify-content-between align-items-center">
      <div className="delete-event-left d-flex justify-content-between align-items-center">
        <ArrowRight/>
        <p>{ props.text1 }</p>
      </div>
      <h5 onClick={ props.function }>{ props.text2 }</h5>
    </div>
  );
}

export default CancelDeleteEventComponent;