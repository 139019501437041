import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getEventById,
  getPublicElvisKey,
  getTicketsTypeByEvent,
  getTicketsByEvent
} from '../../services/axiosService.js';
import { eventDetailsFields } from '../../components/dashboard/ticketerData';
import { getSubdomain } from '../../components/shared/functions.js';
import TicketerEventBanner from '../../components/icons/TicketerEventBanner.jsx';
import ProfileButton from '../../components/ui/ProfileButton.jsx';
import RocketLaunchSmall from '../../components/icons/RocketLaunchSmall.jsx';
import LandingFooter from '../../components/shared/LandingFooter.jsx';
import InstagramSmall from '../../components/icons/InstagramSmall.jsx';
import FacebookSmall from '../../components/icons/FacebookSmall.jsx';
import TwitterSmall from '../../components/icons/TwitterSmall.jsx';
import TiktokSmall from '../../components/icons/TiktokSmall.jsx';
import EventDetailDataLanding from '../../components/shared/EventDetailDataLanding.jsx';
import Checkout from '../../components/ui/Checkout.jsx';
import LandingEventTickets from '../../components/shared/LandingEventTickets.jsx';
import AngleLeft from '../../components/icons/AngleLeft.jsx';

export default function LandingEvent() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    ticketSaleOpen,
    setTicketSaleOpen,
    ticketSaleQuantity,
    setTicketSaleQuantity,
    publicElvisId,
    setPublicElvisId,
    ticketingCompany,
    setTicketingCompany
  } = React.useContext(AppContext);

  const CHARMAX = 150;

  let { eventElvisId } = useParams();
  var navigate = useNavigate();
  const location = useLocation().pathname;
  const subdomain = useMemo(() => getSubdomain(), []);

  const ticketArray = [
    { id: 0, text: t('landingElvis.ticket1') },
    { id: 1, text: t('landingElvis.ticket2') }
  ];

  const { data: events } = useQuery({
    queryKey: ['events', eventElvisId],
    queryFn: location.includes(routes.eventPreviewCustomizeNoId)
      ? () => getEventById(authState.token, eventElvisId)
      : () => getEventById(null, eventElvisId, publicElvisId),
    enabled:
      (!!subdomain && !!publicElvisId) ||
      (location.includes(routes.eventPreviewCustomizeNoId) &&
        !!authState.isAuthenticated),
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { data } = useQuery({
    queryKey: ['companyApiKey'],
    queryFn: () => getPublicElvisKey(subdomain),
    enabled: !!subdomain,
    onSuccess: async (res) => {
      if (res) {
        setTicketingCompany({ data: [res.data] });
        setPublicElvisId(res.data.apiKey);
      }
    },
    onError: (err) => {
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
      if (err.response?.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const { data: tickets } = useQuery({
    queryKey: ['tickets-model', eventElvisId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventElvisId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price',
        publicElvisId
      ),
    enabled: !!subdomain && !!publicElvisId,
    onSuccess: async (res) => {
      if (res) {
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response?.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const { data: secondary } = useQuery({
    queryKey: ['tickets-secondary', eventElvisId],
    queryFn: () =>
      getTicketsByEvent(
        authState.token,
        eventElvisId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price',
        true,
        publicElvisId
      ),
    enabled: !!subdomain && !!publicElvisId,
    onSuccess: async (res) => {
      if (res) {
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response?.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const [seeMore, setSeeMore] = useState(false);
  const [cartDataLoading, setCartDataLoading] = useState(true);

  const openSale = (id) => {
    setTicketSaleOpen(
      tickets?.data?.tickets?.map(function callback(
        currentValue,
        index,
        array
      ) {
        var prevStatus = ticketSaleOpen.filter(
          (ticket) => ticket.id === currentValue.id
        )[0].saleOpen;
        return {
          id: currentValue.id,
          eventId: currentValue.event.id,
          saleOpen:
            Number(currentValue.id) === Number(id) ? !prevStatus : prevStatus
        };
      })
    );
    sessionStorage.setItem(
      'opened_cart',
      JSON.stringify(
        tickets?.data?.tickets?.map(function callback(
          currentValue,
          index,
          array
        ) {
          var prevStatus = ticketSaleOpen.filter(
            (ticket) => ticket.id === currentValue.id
          )[0].saleOpen;
          return {
            id: currentValue.id,
            eventId: currentValue.event.id,
            saleOpen:
              Number(currentValue.id) === Number(id) ? !prevStatus : prevStatus
          };
        })
      )
    );
  };

  const changeQuantity = (id, direction) => {
    setTicketSaleQuantity(
      tickets?.data?.tickets?.map(function callback(
        currentValue,
        index,
        array
      ) {
        var quantity = ticketSaleQuantity.filter(
          (ticket) => ticket.id === currentValue.id
        )[0].quantity;
        return {
          id: currentValue.id,
          eventId: currentValue.event.id,
          quantity:
            Number(currentValue.id) === Number(id)
              ? direction
                ? quantity <
                  Number(
                    tickets?.data?.tickets?.filter(
                      (ticket) => Number(ticket.id) === Number(currentValue.id)
                    )[0]?.amount
                  )
                  ? quantity + 1
                  : quantity
                : quantity
                ? quantity - 1
                : quantity
              : quantity
        };
      })
    );
    sessionStorage.setItem(
      'quantity_cart',
      JSON.stringify(
        tickets?.data?.tickets?.map(function callback(
          currentValue,
          index,
          array
        ) {
          var quantity = ticketSaleQuantity.filter(
            (ticket) => ticket.id === currentValue.id
          )[0].quantity;
          return {
            id: currentValue.id,
            eventId: currentValue.event.id,
            quantity:
              Number(currentValue.id) === Number(id)
                ? direction
                  ? quantity <
                    Number(
                      tickets?.data?.tickets?.filter(
                        (ticket) =>
                          Number(ticket.id) === Number(currentValue.id)
                      )[0]?.amount
                    )
                    ? quantity + 1
                    : quantity
                  : quantity
                  ? quantity - 1
                  : quantity
                : quantity
          };
        })
      )
    );
  };

  useEffect(() => {
    setCartDataLoading(true);
    var otherEvent =
      eventElvisId !== ticketSaleOpen[0]?.eventId ||
      eventElvisId !== ticketSaleQuantity[0]?.eventId
        ? true
        : false;
    if (tickets?.data?.tickets?.length) {
      var oldEvent = false;
      var ticketThresholdCrossed = false;
      var badData = false;
      for (let i = 0; i < tickets?.data?.tickets?.length; i++) {
        var openFiltered = ticketSaleOpen.filter(
          (item) => item.id === tickets?.data?.tickets[i].id
        );
        var quantityFiltered = ticketSaleQuantity.filter(
          (item) => item.id === tickets?.data?.tickets[i].id
        );
        if (openFiltered?.length) oldEvent = true;
        if (quantityFiltered[0]?.quantity > tickets?.data?.tickets[i].amount)
          ticketThresholdCrossed = true;
        if (
          typeof openFiltered[0]?.id !== 'number' &&
          typeof openFiltered[0]?.saleOpen !== 'boolean'
        )
          badData = true;
        if (
          typeof quantityFiltered[0]?.id !== 'number' &&
          typeof quantityFiltered[0]?.quantity !== 'number'
        )
          badData = true;
      }
      if (
        ticketSaleOpen?.length === 0 ||
        !oldEvent ||
        ticketThresholdCrossed ||
        badData ||
        otherEvent
      ) {
        setTicketSaleOpen(
          tickets?.data?.tickets?.map(function callback(
            currentValue,
            index,
            array
          ) {
            return {
              id: currentValue.id,
              eventId: currentValue.event.id,
              saleOpen: false
            };
          })
        );
        sessionStorage.setItem(
          'opened_cart',
          JSON.stringify(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                saleOpen: false
              };
            })
          )
        );
      }
      if (
        ticketSaleQuantity?.length === 0 ||
        !oldEvent ||
        ticketThresholdCrossed ||
        badData ||
        otherEvent
      ) {
        setTicketSaleQuantity(
          tickets?.data?.tickets?.map(function callback(
            currentValue,
            index,
            array
          ) {
            return {
              id: currentValue.id,
              eventId: currentValue.event.id,
              quantity: 0
            };
          })
        );
        sessionStorage.setItem(
          'quantity_cart',
          JSON.stringify(
            tickets?.data?.tickets?.map(function callback(
              currentValue,
              index,
              array
            ) {
              return {
                id: currentValue.id,
                eventId: currentValue.event.id,
                quantity: 0
              };
            })
          )
        );
      }
      setCartDataLoading(false);
    }
  }, [tickets, ticketSaleQuantity, ticketSaleOpen]);

  useEffect(() => {
    if (
      subdomain &&
      authState.isAuthenticated &&
      authState.roles[0] === 'ROLE_ADMIN'
    ) {
      dispatch({
        type: 'LOGOUT'
      });
    }
  }, []);

  useEffect(() => {
    if (eventElvisId) {
    }
  }, []);

  return (
    <main
      id={`event-${eventElvisId}`}
      className={`landing-event-main d-flex flex-column ${
        location.includes(routes.eventPreviewCustomizeNoId) &&
        !!authState.isAuthenticated
          ? ''
          : 'justify-content-between'
      } align-items-center`}
    >
      {ticketSaleQuantity
        .map((item) => item.quantity)
        .reduce((prev, curr) => prev + curr, 0) && !cartDataLoading ? (
        <Checkout ticketSaleQuantity={ticketSaleQuantity} />
      ) : null}
      <div
        className={`landing-event-banner d-flex justify-content-center align-items-center`}
      >
        {!subdomain ||
        (location.includes(routes.eventPreviewCustomizeNoId) &&
          !!authState.isAuthenticated) ? null : (
          <ProfileButton
            icon={
              !!authState.isAuthenticated &&
              authState.roles[0] === 'ROLE_USER' ? null : (
                <RocketLaunchSmall />
              )
            }
            text={
              !!authState.isAuthenticated && authState.roles[0] === 'ROLE_USER'
                ? authState?.userId
                    ?.split(' ')
                    .map(function (item, index) {
                      if (index < 2) return item[0];
                      else return null;
                    })
                    .join('')
                    .toUpperCase()
                : t('landingElvis.login')
            }
            function={
              !!authState.isAuthenticated && authState.roles[0] === 'ROLE_USER'
                ? () => navigate(routes.homepage)
                : () => navigate(routes.auth)
            }
            disabled={false}
          />
        )}
        {events?.data?.images?.cover ? (
          <img
            src={events?.data?.images?.cover}
            alt={`landing-logo-${'item.id'}`}
            className=""
          />
        ) : (
          <TicketerEventBanner />
        )}
      </div>
      <div className={`w-100 landing-event-body d-flex flex-column`}>
        {location.includes(routes.eventPreviewCustomizeNoId) &&
        !!authState.isAuthenticated ? (
          <div className={`landing-event-description d-flex flex-column`}>
            <div
              className={`landing-event-bar d-flex flex-column flex-lg-row justify-content-between align-items-center`}
            >
              <div
                className={`landing-event-bar-left d-flex justify-content-center align-items-start`}
              >
                <h2>
                  {events?.data.name ? events?.data?.name : t('events.event')}
                </h2>
              </div>
              <div
                className={`landing-event-bar-right d-flex align-items-start`}
              >
                <InstagramSmall />
                <FacebookSmall />
                <TwitterSmall />
                <TiktokSmall />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={`landing-event-description d-flex flex-column`}>
              <div
                className={`landing-event-bar d-flex flex-column flex-lg-row justify-content-between align-items-center`}
              >
                <div
                  className={`landing-event-bar-left d-flex justify-content-center align-items-start`}
                >
                  <AngleLeft function={() => navigate(routes.landing)} />
                  <h2>
                    {events?.data?.name ? events?.data.name : t('events.event')}
                  </h2>
                </div>
                <div
                  className={`landing-event-bar-right d-flex align-items-start`}
                >
                  {events?.data.instragram ? <InstagramSmall /> : null}
                  {events?.data.facebook ? <FacebookSmall /> : null}
                  {events?.data.twitter ? <TwitterSmall /> : null}
                  {events?.data.tiktok ? <TiktokSmall /> : null}
                </div>
              </div>
              {events?.data.description ? (
                <>
                  <p className="d-inline d-lg-none">
                    {events?.data.description.substring(
                      0,
                      seeMore ? 500 : CHARMAX
                    ) +
                      (events?.data?.description?.length < CHARMAX
                        ? ''
                        : seeMore
                        ? ''
                        : '...')}
                    {events?.data?.description?.length < CHARMAX ? null : (
                      <span onClick={() => setSeeMore(!seeMore)}>
                        {seeMore
                          ? t('landingElvis.seeLess')
                          : t('landingElvis.seeMore')}
                      </span>
                    )}
                  </p>
                  <p className="d-none d-lg-inline">
                    {events?.data.description}
                  </p>
                </>
              ) : null}
            </div>
            <div className="card-stats-shadow">
              <div className={`landing-event-metrics-container d-flex`}>
                <div
                  className={`landing-event-metrics d-flex flex-column flex-sm-row justify-content-center justify-content-sm-between align-items-center`}
                >
                  {eventDetailsFields?.map((item) => {
                    return (
                      <EventDetailDataLanding
                        key={item.id}
                        item={item}
                        event={events?.data}
                        landing={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {ticketArray.map((field) => {
              return (
                <LandingEventTickets
                  key={field.id}
                  id={field.id}
                  text={field.text}
                  tickets={
                    field.id ? secondary?.data?.tickets : tickets?.data?.tickets
                  }
                  events={events?.data}
                  navigate={navigate}
                  ticketSaleOpen={ticketSaleOpen}
                  setTicketSaleOpen={setTicketSaleOpen}
                  changeQuantity={changeQuantity}
                  openSale={openSale}
                  ticketSaleQuantity={ticketSaleQuantity}
                  subdomain={subdomain}
                />
              );
            })}
          </>
        )}
      </div>
      {!(
        location.includes(routes.eventPreviewCustomizeNoId) &&
        !!authState.isAuthenticated
      ) ? (
        <LandingFooter landingPage={true} company={ticketingCompany?.data[0]} />
      ) : null}
    </main>
  );
}
