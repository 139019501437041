import React from "react";

function FileList(props) {

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3005 5.61468H10.8686C9.86301 5.61468 9.43661 5.18827 9.43661 4.18272V2.75076L12.3005 5.61468ZM12.7778 6.56932V11.8198C12.7778 13.0927 12.1414 13.7291 10.8686 13.7291H5.14073C3.86787 13.7291 3.23145 13.0927 3.23145 11.8198V4.18272C3.23145 2.90986 3.86787 2.27344 5.14073 2.27344H8.48197V4.18272C8.48197 5.72287 9.32841 6.56932 10.8686 6.56932H12.7778ZM6.25447 10.547C6.25447 10.2835 6.04063 10.0697 5.77715 10.0697C5.51367 10.0697 5.29983 10.2835 5.29983 10.547C5.29983 10.8105 5.51367 11.0243 5.77715 11.0243C6.04063 11.0243 6.25447 10.8105 6.25447 10.547ZM6.25447 8.00128C6.25447 7.7378 6.04063 7.52396 5.77715 7.52396C5.51367 7.52396 5.29983 7.7378 5.29983 8.00128C5.29983 8.26476 5.51367 8.4786 5.77715 8.4786C6.04063 8.4786 6.25447 8.26476 6.25447 8.00128ZM10.7095 10.547C10.7095 10.2835 10.4956 10.0697 10.2321 10.0697H7.36822C7.10474 10.0697 6.8909 10.2835 6.8909 10.547C6.8909 10.8105 7.10474 11.0243 7.36822 11.0243H10.2321C10.4956 11.0243 10.7095 10.8105 10.7095 10.547ZM10.7095 8.00128C10.7095 7.7378 10.4956 7.52396 10.2321 7.52396H7.36822C7.10474 7.52396 6.8909 7.7378 6.8909 8.00128C6.8909 8.26476 7.10474 8.4786 7.36822 8.4786H10.2321C10.4956 8.4786 10.7095 8.26476 10.7095 8.00128Z" fill="#FF5F00"/>
    </svg>
  );
}

export default FileList;