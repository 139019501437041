import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormikContext, Field, ErrorMessage } from "formik";
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import LikeSmall from "../../components/icons/LikeSmall.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";

function DomainSecondPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, isValid, submitForm } = useFormikContext();

  return (
    <>
      <div className="ticketer-field-domain-container">
        <h3>.tibetpass.com</h3>
        <Field
          className={
            errors.domain && touched.domain
              ? "ticketer-field ticketer-field-thick red-border"
              : dirty
              ? "ticketer-field ticketer-field-thick purple-border"
              : "ticketer-field ticketer-field-thick"
          }
          id="ticketer-domain"
          type="text"
          name="domain"
          placeholder={t("ticketerSection.domainExample")}
        />
        {errors.domain && touched.domain ? (
          <ErrorMessage name="domain">
            {(msg) => <div className="ticketer-field-error">{msg}</div>}
          </ErrorMessage>
        ) : null}
      </div>
      <ButtonGroupTibet
        button={{
          text: t("ticketer.save"),
          disabled: !(isValid && dirty && !(errors.domain && touched.domain)),
          function: submitForm,
        }}
      />
    </>
  );
}

function DomainSecondPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const {
    state: authState,
    dispatch,
    ticketerModal,
    setTicketerModal,
    role,
  } = React.useContext(AppContext);

  var navigate = useNavigate();

  const {
    isLoading,
    isError,
    error,
    data: ticketingCompany,
    isFetching,
  } = useQuery({
    queryKey: ["ticketingCompany"],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  var initialDomainData = {
    domain: ticketingCompany?.data[0]?.ourSubdomain
      ? ticketingCompany?.data[0]?.ourSubdomain
      : "",
  };

  const re = /^[a-z0-9]*$/gm;

  const dataDomainSchema = Yup.object().shape({
    domain: Yup.string()
      .required(t("register.error2"))
      .matches(re, t("ticketerSection.domainUrl")),
  });

  return (
    <TicketingSectionComponent
      initialValues={initialDomainData}
      validationSchema={dataDomainSchema}
      ticketer={true}
      title={t("ticketer.domain1")}
      pagination={null}
      description={t("ticketer.infoDomain4")}
      footer={{ text: t("ticketer.adviceDomainChange"), icon: <LikeSmall /> }}
    >
      <DomainSecondPageChildren />
    </TicketingSectionComponent>
  );
}

export default DomainSecondPage;
