import React from "react";

function FileTextEdit(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 5V2.75L17.25 7.25H15C13.42 7.25 12.75 6.58 12.75 5ZM17.9099 11.71L11.9399 17.72C11.6599 18 11.5 18.38 11.5 18.78V19.7C11.5 19.87 11.37 20 11.2 20H6C4 20 3 19 3 17V5C3 3 4 2 6 2H11.25V5C11.25 7.42 12.58 8.75 15 8.75H18V11.5C18 11.58 17.9699 11.66 17.9099 11.71ZM11.75 15C11.75 14.586 11.414 14.25 11 14.25H7C6.586 14.25 6.25 14.586 6.25 15C6.25 15.414 6.586 15.75 7 15.75H11C11.414 15.75 11.75 15.414 11.75 15ZM14.75 11C14.75 10.586 14.414 10.25 14 10.25H7C6.586 10.25 6.25 10.586 6.25 11C6.25 11.414 6.586 11.75 7 11.75H14C14.414 11.75 14.75 11.414 14.75 11ZM13 18.78V21H15.22L19.22 17.02L16.98 14.78L13 18.78ZM20.74 14.25L19.75 13.26C19.4 12.91 18.84 12.91 18.49 13.26L17.6799 14.08L19.9199 16.32L20.74 15.51C21.09 15.16 21.09 14.6 20.74 14.25Z" fill="#D4CDFF"/>
    </svg>
  );
}

export default FileTextEdit;