import React from 'react';
import { useTranslation } from 'react-i18next';
import AngleWithCircle from '../../components/icons/AngleWithCircle.jsx';

export default function TicketOpenedLocation({
  fieldStatus,
  setFieldStatus,
  ticket
}) {
  // eslint-disable-next-line
  const [t] = useTranslation('global');

  return (
    <div
      className={`ticket-sale-detail-info-location ${
        fieldStatus[0] ? 'active' : ''
      } d-flex flex-column justify-content-between align-items-center`}
    >
      <div
        className={`ticket-sale-detail-info-bar d-flex justify-content-between align-items-center`}
      >
        <h3>{t('landingElvis.info1')}</h3>
        <AngleWithCircle
          function={() =>
            setFieldStatus([!fieldStatus[0], fieldStatus[1], fieldStatus[2]])
          }
        />
      </div>
      <div
        className={`ticket-sale-detail-info-bottom d-flex flex-column justify-content-between align-items-center`}
      >
        <div
          className={`ticket-sale-detail-info-bottom-children d-flex flex-column justify-content-between`}
        >
          <h5>{t('ticket.ticketInfo18')}</h5>
          <h4>
            {ticket?.event?.address
              ? ticket?.event?.address + ' - '
              : ticket?.event?.address2 ?? null}
            <span style={{ fontSize: '14px' }}>
              {ticket?.event ? ticket?.event?.location : '-'}
            </span>
          </h4>
        </div>
        <div
          className={`ticket-sale-detail-info-bottom-children d-flex flex-column justify-content-between`}
        >
          <h5>{t('ticket.ticketInfo10')}</h5>
          <h4>
            {ticket?.sector
              ? ticket?.sector
              : ticket?.location?.sector
              ? ticket?.location?.sector
              : '-'}
          </h4>
        </div>
      </div>
    </div>
  );
}
