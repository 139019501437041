import React from "react";

function Expand(props) {

  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7683 4.2977V7.19181C18.7683 7.64826 18.3987 8.01871 17.9414 8.01871C17.4841 8.01871 17.1145 7.64826 17.1145 7.19181V4.2977C17.1145 3.92642 17.0715 3.88425 16.7011 3.88425H13.8069C13.3497 3.88425 12.9801 3.5138 12.9801 3.05736C12.9801 2.60092 13.3497 2.23047 13.8069 2.23047H16.7011C17.9761 2.23047 18.7683 3.02263 18.7683 4.2977ZM7.19181 2.23047H4.2977C3.02263 2.23047 2.23047 3.02263 2.23047 4.2977V7.19181C2.23047 7.64826 2.60009 8.01871 3.05736 8.01871C3.51463 8.01871 3.88425 7.64826 3.88425 7.19181V4.2977C3.88425 3.92642 3.92725 3.88425 4.2977 3.88425H7.19181C7.64909 3.88425 8.01871 3.5138 8.01871 3.05736C8.01871 2.60092 7.64909 2.23047 7.19181 2.23047ZM7.19181 17.1145H4.2977C3.92725 17.1145 3.88425 17.0723 3.88425 16.7011V13.8069C3.88425 13.3505 3.51463 12.9801 3.05736 12.9801C2.60009 12.9801 2.23047 13.3505 2.23047 13.8069V16.7011C2.23047 17.9761 3.02263 18.7683 4.2977 18.7683H7.19181C7.64909 18.7683 8.01871 18.3978 8.01871 17.9414C8.01871 17.485 7.64909 17.1145 7.19181 17.1145ZM17.9414 12.9801C17.4841 12.9801 17.1145 13.3505 17.1145 13.8069V16.7011C17.1145 17.0723 17.0715 17.1145 16.7011 17.1145H13.8069C13.3497 17.1145 12.9801 17.485 12.9801 17.9414C12.9801 18.3978 13.3497 18.7683 13.8069 18.7683H16.7011C17.9761 18.7683 18.7683 17.9761 18.7683 16.7011V13.8069C18.7683 13.3505 18.3987 12.9801 17.9414 12.9801Z" fill="#442ECB"/>
    </svg>
  );
}

export default Expand;