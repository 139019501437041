import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import { useNavigate } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { useQuery } from "@tanstack/react-query";
import {getStripeInfo, getTicketingCompanyById} from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import GlobeSmall from "../../components/icons/GlobeSmall.jsx";
import TermsPolicyButton from "../../components/ui/TermsPolicyButton.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import SettingsSmall from "../../components/icons/SettingsSmall";
import TicketerButton from "../../components/ui/TicketerButton";
import axios from "axios";

function StripeKo(props) {

    // eslint-disable-next-line
    const [t, i18n] = useTranslation("global");

    return (
        <TicketingSectionComponent>
            <div className="ticketer-sections-domain-container d-flex flex-wrap justify-content-center align-items-center">
                <h1>Pago fallido!</h1>
            </div>
            <ButtonGroupTibet button = { null }/>
        </TicketingSectionComponent>
    );
}

export default StripeKo;