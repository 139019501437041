import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import LandingSmile from '../icons/LandingSmile.jsx';

export default function TibetLandingComponent2() {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  var navigate = useNavigate();

  return (
    <section id="video-landing-section" className="video-landing-container d-flex flex-column justify-content-center align-items-center">
      <h2>{t('landing.videoTitle')}</h2>
      <div className="video-landing-item-container d-flex justify-content-center align-items-center">
        {/*<video className="video-landing-video" autoPlay loop muted type="video/mp4" width="320px" height="394px" src={ "https://youtu.be/hBBId-6Sigo?feature=shared" }/>*/}
        <iframe className="video-landing-video" width="auto" height="auto" src="https://www.youtube.com/embed/hBBId-6Sigo?rel=0" title="video-landing-video" 
            frameBorder="0" controls={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" type="video/mp4"
        ></iframe>
      </div>
      <LandingSmile/>
    </section>
  )

}
