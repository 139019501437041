import React from "react";

function PendingTicketerImage(props) {

  return (
    <svg width="95" height="83" viewBox="0 0 95 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2576 82.5154C21.6091 82.5154 23.5154 80.6092 23.5154 78.2577C23.5154 75.9062 21.6091 74 19.2576 74C16.9061 74 15 75.9062 15 78.2577C15 80.6092 16.9061 82.5154 19.2576 82.5154Z" fill="#CEC8F3"/>
      <path d="M67.5858 1V8.18846" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M71.1885 4.58838H64" stroke="#FF8C5A" strokeWidth="0.813837" strokeMiterlimit="10" strokeLinecap="round"/>
      <rect x="30.2765" y="25.2765" width="49.447" height="54.447" rx="6.35902" stroke="#816CFF" strokeWidth="0.552959" strokeDasharray="1.11 1.11"/>
      <rect x="6" y="5" width="48" height="57" rx="6.14998" fill="#E0DBFF"/>
      <path d="M31.3333 21.7292V18.6875C31.3333 15.6458 32.8542 14.125 35.8958 14.125H51.1042C54.1458 14.125 55.6667 15.6458 55.6667 18.6875V21.7292C55.6667 24.7708 54.1458 26.2917 51.1042 26.2917H35.8958C32.8542 26.2917 31.3333 24.7708 31.3333 21.7292ZM67.8333 29.3333V59.75C67.8333 65.8333 64.7917 68.875 58.7083 68.875H28.2917C22.2083 68.875 19.1667 65.8333 19.1667 59.75V29.3333C19.1667 24.0347 21.4537 21.0509 26.0557 20.3817C26.4237 20.327 26.7708 20.6311 26.7708 21.0022V21.7262C26.7708 27.262 30.36 30.8512 35.8958 30.8512H51.1042C56.64 30.8512 60.2292 27.262 60.2292 21.7262V21.0022C60.2292 20.6311 60.5793 20.327 60.9443 20.3817C65.5463 21.0509 67.8333 24.0347 67.8333 29.3333ZM48.2058 36.9375C44.6836 36.9193 43.5 40.4141 43.5 40.4141C43.5 40.4141 42.3164 36.9223 38.7942 36.9375C34.0644 36.9588 32.203 41.3023 33.0547 45.6184C34.4417 52.6568 43.5 56.7083 43.5 56.7083C43.5 56.7083 52.5583 52.6568 53.9453 45.6184C54.797 41.3023 52.9447 36.9588 48.2058 36.9375Z" fill="#442ECB"/>
      <path d="M83 56C77.48 56 73 60.48 73 66C73 71.52 77.48 76 83 76C88.52 76 93 71.52 93 66C93 60.48 88.52 56 83 56ZM86.5 66.75H83.75V69.5C83.75 69.91 83.41 70.25 83 70.25C82.59 70.25 82.25 69.91 82.25 69.5V66.75H79.5C79.09 66.75 78.75 66.41 78.75 66C78.75 65.59 79.09 65.25 79.5 65.25H82.25V62.5C82.25 62.09 82.59 61.75 83 61.75C83.41 61.75 83.75 62.09 83.75 62.5V65.25H86.5C86.91 65.25 87.25 65.59 87.25 66C87.25 66.41 86.91 66.75 86.5 66.75Z" fill="#FF8C5A"/>
    </svg>
  );
}

export default PendingTicketerImage;