import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../routes/routes.js';
import { getEventById } from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import Coupon from '../../components/icons/Coupon.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';

function TicketsEventFirstPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { errors, touched, isValid, values } = useFormikContext();
  const { createTicketInfo, setCreateTicketInfo } =
    React.useContext(AppContext);

  var navigate = useNavigate();

  const [count, setCount] = useState([
    values.name?.length,
    values.category?.name?.length,
    values.description?.length
  ]);
  const MAX_CHARACTERS_1 = 15;
  const MAX_CHARACTERS_2 = 10;
  const MAX_CHARACTERS_3 = 230;

  const nextPage = (route) => {
    if (
      Object.keys(createTicketInfo).length === 0 ||
      createTicketInfo.eventId === props.eventId
    ) {
      sessionStorage.setItem(
        'ticket_draft',
        JSON.stringify({
          ...createTicketInfo,
          eventId: props.eventId,
          name: values.name,
          category: { name: values.category },
          description: values.description
        })
      );
      setCreateTicketInfo({
        ...createTicketInfo,
        eventId: props.eventId,
        name: values.name,
        category: { name: values.category },
        description: values.description
      });
      navigate(route === 'back' ? -1 : route);
    } else if (createTicketInfo.eventId !== props.eventId) {
      setCreateTicketInfo({});
      sessionStorage.removeItem('ticket_draft');
    }
  };

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100">
          <h5>{t('ticket.createTicketField1')}*</h5>
          <Field
            className={
              errors.name && touched.name
                ? 'ticketer-field red-border'
                : touched.name
                ? 'ticketer-field purple-border'
                : 'ticketer-field'
            }
            id="event-ticket-name"
            type="text"
            name="name"
            placeholder=""
            maxLength={MAX_CHARACTERS_1}
            onKeyUp={(e) =>
              setCount([e.target.value.length, count[1], count[2]])
            }
          />
          {errors.name && touched.name ? (
            <ErrorMessage name="name">
              {(msg) => <div className="ticketer-field-error">{msg}</div>}
            </ErrorMessage>
          ) : null}
          <h5 className="description-counter">
            {count[0]} / {MAX_CHARACTERS_1}
          </h5>
        </div>
        <div className="w-100">
          <h5>{t('ticket.createTicketField2')}*</h5>
          <Field
            className={
              errors.category && touched.category
                ? 'ticketer-field red-border'
                : touched.category
                ? 'ticketer-field purple-border'
                : 'ticketer-field'
            }
            id="event-ticket-category"
            type="text"
            name="category"
            placeholder=""
            maxLength={MAX_CHARACTERS_2}
            onKeyUp={(e) =>
              setCount([count[0], e.target.value.length, count[2]])
            }
          />
          {errors.category && touched.category ? (
            <ErrorMessage name="category">
              {(msg) => <div className="ticketer-field-error">{msg}</div>}
            </ErrorMessage>
          ) : null}
          <h5 className="description-counter">
            {count[1]} / {MAX_CHARACTERS_2}
          </h5>
        </div>
        <div className="w-100">
          <h5>{t('ticket.createTicketField3')}*</h5>
          <Field
            className={
              errors.description && touched.description
                ? 'ticketer-field red-border'
                : touched.description
                ? 'ticketer-field purple-border'
                : 'ticketer-field'
            }
            id="event-ticket-description"
            type="text"
            name="description"
            placeholder={t('ticketerSection.ckeditorPlaceholder')}
            as="textarea"
            maxLength={MAX_CHARACTERS_3}
            onKeyUp={(e) =>
              setCount([count[0], count[1], e.target.value.length])
            }
          />
          {errors.description && touched.description ? (
            <ErrorMessage name="description">
              {(msg) => <div className="ticketer-field-error">{msg}</div>}
            </ErrorMessage>
          ) : null}
          <h5 className="description-counter">
            {count[2]} / {MAX_CHARACTERS_3}
          </h5>
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.next'),
          disabled: !(
            isValid &&
            values.name &&
            values.description &&
            values.category
          ),
          function: () =>
            nextPage(
              `${routes.events}/${props.eventId}${routes.createTicketsSale}`
            )
        }}
      />
    </>
  );
}

function TicketsEventFirstPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    createTicketInfo,
    setCreateTicketInfo
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const {
    isLoading,
    isError,
    error,
    data: events,
    isFetching
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialFirstTicketData = {
    name: createTicketInfo?.name ? createTicketInfo?.name : '',
    category: createTicketInfo?.category?.name
      ? createTicketInfo?.category?.name
      : '',
    description: createTicketInfo?.description
      ? createTicketInfo?.description
      : ''
  };

  const dataFirstTicketSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('register.error2'))
      .max(15, t('ticket.characters1')),
    category: {
      name: Yup.string()
        .required(t('register.error2'))
        .max(10, t('ticket.characters2'))
    },
    description: Yup.string()
      .required(t('register.error2'))
      .max(1000, t('ticket.characters3'))
  });

  return (
    <>
      {isLoading ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <SpinnerPurple />
        </div>
      ) : isError ? (
        <div className="spinner-container d-flex justify-content-center align-items-center">
          <h3 className="fetching-error">{t('home.errorRetrievingEvent')}</h3>
        </div>
      ) : (
        <TicketingSectionComponent
          initialValues={initialFirstTicketData}
          validationSchema={dataFirstTicketSchema}
          ticketer={false}
          eventTitle={events?.data?.name}
          title={t('ticket.createTicket')}
          pagination={'1/4'}
          description={t('ticket.createTicketTitle1')}
          footer={{ text: t('ticket.createTicketText1'), icon: <Coupon /> }}
        >
          <TicketsEventFirstPageChildren eventId={eventId} />
        </TicketingSectionComponent>
      )}
    </>
  );
}

export default TicketsEventFirstPage;
