import React from "react";

function InstagramSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path d="M10.7733 17.8161C12.6394 17.8161 14.1521 16.3033 14.1521 14.4373V10.6065C14.6491 10.8939 15.2261 11.0585 15.8415 11.0585H16.4046V9.36908H15.8415C14.9085 9.36908 14.1521 8.61271 14.1521 7.67969H12.4627V14.4373C12.4627 15.3703 11.7063 16.1267 10.7733 16.1267C9.84029 16.1267 9.08393 15.3703 9.08393 14.4373C9.08393 13.5042 9.84029 12.7479 10.7733 12.7479V11.0585C8.90727 11.0585 7.39453 12.5712 7.39453 14.4373C7.39453 16.3033 8.90727 17.8161 10.7733 17.8161Z" fill="#A5A9B8"/>
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path d="M13.7506 8.24414C12.5658 8.24414 11.6054 9.25263 11.6054 10.4967C11.6054 10.6847 11.6273 10.8673 11.6687 11.0418C10.5295 11.0418 8.81364 10.8115 7.38064 9.37581C7.19008 9.1849 6.83871 9.31254 6.85193 9.58196C7.0643 13.9107 8.91817 15.1734 9.87287 15.252C9.25638 15.8609 8.36086 16.3415 7.45973 16.5572C7.22191 16.6141 7.16316 16.8745 7.39571 16.9501C8.04051 17.1598 8.96345 17.2406 9.46023 17.2542C12.9746 17.2542 15.8311 14.2962 15.8947 10.6218C16.3561 10.3218 16.6516 9.67027 16.8177 9.20873C16.8581 9.09633 16.6739 8.96536 16.5636 9.01127C16.219 9.15477 15.7814 9.18879 15.4086 9.06733C15.0152 8.56471 14.4185 8.24414 13.7506 8.24414Z" fill="#A5A9B8"/>
      <circle cx="12" cy="12.8496" r="12" fill="#EFF0F2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.08616 7.11914C7.53112 7.11914 6.27051 8.37975 6.27051 9.9348V15.5661C6.27051 17.1212 7.53112 18.3818 9.08616 18.3818H14.7175C16.2725 18.3818 17.5331 17.1212 17.5331 15.5661V9.9348C17.5331 8.37975 16.2725 7.11914 14.7175 7.11914H9.08616ZM15.2806 9.93427C15.5916 9.93427 15.8437 9.68215 15.8437 9.37114C15.8437 9.06013 15.5916 8.80801 15.2806 8.80801C14.9695 8.80801 14.7174 9.06013 14.7174 9.37114C14.7174 9.68215 14.9695 9.93427 15.2806 9.93427ZM14.7173 12.7496C14.7173 14.3046 13.4567 15.5652 11.9016 15.5652C10.3466 15.5652 9.08599 14.3046 9.08599 12.7496C9.08599 11.1945 10.3466 9.93393 11.9016 9.93393C13.4567 9.93393 14.7173 11.1945 14.7173 12.7496ZM11.9014 14.4396C12.8344 14.4396 13.5908 13.6832 13.5908 12.7502C13.5908 11.8172 12.8344 11.0608 11.9014 11.0608C10.9683 11.0608 10.212 11.8172 10.212 12.7502C10.212 13.6832 10.9683 14.4396 11.9014 14.4396Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default InstagramSmall;