import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import TicketerButton from "./TicketerButton.jsx";
import InfoCircle from "../icons/InfoCircle";
import CheckCircle from "../icons/CheckCircle";

function TicketerItem(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setTicketerModal } = React.useContext(AppContext);

  return (
    <div
      className={`ticketer-section-item ${
        props.required
          ? props.success
            ? "ticketer-section-item-required-success"
            : "ticketer-section-item-required"
          : props.success
          ? "ticketer-section-item-success"
          : null
      } d-flex flex-column justify-content-between`}
    >
      {props.icon}
      <div>
        <div className="ticketer-section-item-title d-flex justify-content-between">
          <h2>{props.text}</h2>
          <div
            className={`ticketer-section-item-status ${
              props.success ? "ticketer-success" : null
            } d-flex justify-content-center align-items-center`}
          >
            {props.success ? <CheckCircle /> : <InfoCircle />}
          </div>
        </div>
        <h4>{props.altText}</h4>
      </div>
      <TicketerButton
        function={
          props.success
            ? () =>
                setTicketerModal({
                  id: props.id,
                  data: props.data,
                  function: props.function,
                })
            : () => props.function(props.id)
        }
        text={
          props.success
            ? props.tickets
              ? t("events.configureTickets")
              : t("ticketer.seeInfo")
            : props.button
        }
      />
    </div>
  );
}

export default TicketerItem;
