import React from "react";

function CalendarEditBig(props) {

  return (
    <svg width="107" height="93" viewBox="0 0 107 93" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M115.55 49.3711H2.45032C1.37132 49.3711 0.5 50.2424 0.5 51.3214V102.996C0.5 115.996 7 122.496 20 122.496H98C111 122.496 117.5 115.996 117.5 102.996V51.3214C117.5 50.2424 116.629 49.3711 115.55 49.3711ZM48.1519 107.871H34.6314V94.3506L58.7461 70.1057L72.3967 83.7564L48.1519 107.871ZM81.75 74.3904L76.9401 79.2003L63.2894 65.5497L68.0994 60.7397C70.1794 58.5947 73.6255 58.5947 75.7705 60.6747L81.8151 66.7192C83.9016 68.8707 83.9015 72.3104 81.75 74.3904ZM117.5 34.7461V37.6708C117.5 38.7498 116.629 39.6211 115.55 39.6211H2.45032C1.37132 39.6211 0.5 38.7498 0.5 37.6708V34.7461C0.5 21.7461 7 15.2461 20 15.2461H28.125V5.49609C28.125 2.83109 30.335 0.621094 33 0.621094C35.665 0.621094 37.875 2.83109 37.875 5.49609V15.2461H80.125V5.49609C80.125 2.83109 82.335 0.621094 85 0.621094C87.665 0.621094 89.875 2.83109 89.875 5.49609V15.2461H98C111 15.2461 117.5 21.7461 117.5 34.7461Z" fill="#E3DEFC"/>
    </svg>
  );
}

export default CalendarEditBig;