import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import routes from '../../routes/routes';
import RegisterButton from '../ui/RegisterButton';
import Google from '../icons/Google';
import Envelope from '../icons/Envelope';
import { getSubdomain } from '../shared/functions.js';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {
  getUserById,
  loginFirebaseAdmin,
  loginFirebaseUser
} from '../../services/axiosService.js';
import { queryClient } from '../../index.js';
import { useMutation } from '@tanstack/react-query';
import jwtDecode from 'jwt-decode';

function RegisterButtonsContainer(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    ticketingCompany,
    auth,
    setUserData,
    setTermsModal,
    setFirebaseLoading,
    publicElvisId
  } = React.useContext(AppContext);

  let [searchParams] = useSearchParams();
  var navigate = useNavigate();
  var location = useLocation().pathname;
  const subdomain = useMemo(() => getSubdomain(), []);

  const registerWithEmail = () => {
    navigate(subdomain ? location + '/mail' : routes.authEmail);
  };

  const { mutate } = useMutation(
    subdomain
      ? (values) => {
          loginFirebaseUser(
            values.firebaseToken,
            subdomain,
            values.displayName,
            publicElvisId
          ).then((res) => {
            dispatch({
              type: 'LOGIN',
              payload: res.data.token
            });
            getUserDataFunction(res.data.token);
          });
        }
      : (values) => {
          loginFirebaseAdmin(values.firebaseToken, values.displayName).then(
            (res) => {
              dispatch({
                type: 'LOGIN',
                payload: res.data.token
              });
              getUserDataFunction(res.data.token);
            }
          );
        },
    {
      onError: (err) => {
        //ERRO LOGIN
        console.error(err);
      }
    }
  );

  const getUserDataFunction = async (token) => {
    const user = await queryClient.fetchQuery({
      queryKey: ['user'],
      queryFn: () => getUserById(token),
      enabled: !!authState.isAuthenticated,
      onError: (err) => {
        if (err.response.status === 401) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      }
    });
    if (user.data) {
      setUserData(user.data);
      if (user.data.enabled === false) {
        setTermsModal(true);
      }
      navigate(subdomain ? routes.landing : routes.homepage);
    }
  };

  const myfn = () => {
    const googleProvider = new GoogleAuthProvider();
    setFirebaseLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        if (result?._tokenResponse && result?.operationType === 'signIn') {
          const token = result?._tokenResponse?.idToken;
          mutate({
            firebaseToken: token,
            displayName: result?.user.displayName
          });
        }
        const user = result?.user;
        //console.log(user);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setFirebaseLoading(false);
        sessionStorage.removeItem('authOngoing');
      });
  };

  useEffect(() => {
    var token = searchParams.get('token');
    var decodedToken;
    if (token) {
      try {
        decodedToken = jwtDecode(token);
      } catch (error) {
        console.error(error);
      }
    }
    if (authState?.isAuthenticated && authState?.exp * 1000 > Date.now()) {
      getUserDataFunction(authState.token);
    } else if (
      searchParams &&
      token &&
      decodedToken &&
      decodedToken?.exp * 1000 > Date.now()
    ) {
      dispatch({
        type: 'LOGIN',
        payload: token
      });
      getUserDataFunction(token);
    }
  }, []);

  return (
    <section
      id="register-buttons-section"
      className="register-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <h3>
        {t('register.title') +
          (subdomain
            ? ticketingCompany.data[0]
              ? ticketingCompany.data[0]?.comercialName
              : ''
            : 'Tibet')}
      </h3>
      <RegisterButton
        icon={<Google />}
        text={t('register.google')}
        function={myfn}
      />
      {/* <button onClick={logout}>salir</button> */}
      {/*<RegisterButton icon = { <Facebook/> } text = { t('register.facebook') } function = { registerWithFb }/>*/}
      <RegisterButton
        icon={<Envelope />}
        text={t('register.email')}
        function={registerWithEmail}
      />
    </section>
  );
}

export default RegisterButtonsContainer;
