import React from "react";

function GiftSvg3(props) {

  return (
    <svg width="132" height="117" viewBox="0 0 132 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.837 11.5649C26.0305 11.5649 28.6195 8.97597 28.6195 5.78242C28.6195 2.58888 26.0305 0 22.837 0C19.6434 0 17.0547 2.58888 17.0547 5.78242C17.0547 8.97597 19.6434 11.5649 22.837 11.5649Z" fill="#816CFF"/>
      <rect x="1.79517" y="38.116" width="88.7504" height="65.5663" rx="8.63627" stroke="#816CFF" strokeWidth="0.75098" strokeDasharray="1.5 1.5"/>
      <rect x="13.8208" y="33.97" width="81.4139" height="57.6906" rx="10.7833" fill="#8C79FF"/>
      <path d="M115.799 76.8396L114.329 76.4195C112.976 76.033 111.938 74.9951 111.551 73.6423L111.131 72.172C111.065 71.9407 110.679 71.9407 110.613 72.172L110.193 73.6423C109.806 74.9951 108.768 76.0335 107.416 76.4195L105.945 76.8396C105.829 76.8725 105.75 76.9787 105.75 77.0989C105.75 77.2191 105.829 77.3248 105.945 77.3582L107.416 77.7783C108.768 78.1648 109.806 79.2027 110.193 80.5555L110.613 82.0258C110.646 82.1417 110.752 82.2215 110.872 82.2215C110.992 82.2215 111.098 82.1417 111.131 82.0258L111.551 80.5555C111.938 79.2027 112.976 78.1643 114.329 77.7783L115.799 77.3582C115.915 77.3253 115.995 77.2191 115.995 77.0989C115.995 76.9787 115.915 76.873 115.799 76.8396Z" fill="#F27327"/>
      <path d="M128.753 49.6675L126.057 48.8974C123.578 48.1887 121.675 46.2859 120.966 43.8058L120.196 41.1103C120.074 40.6862 119.366 40.6862 119.245 41.1103L118.475 43.8058C117.766 46.2859 115.862 48.1897 113.383 48.8974L110.688 49.6675C110.475 49.7278 110.329 49.9225 110.329 50.1429C110.329 50.3633 110.475 50.5571 110.688 50.6183L113.383 51.3884C115.862 52.0971 117.766 53.9999 118.475 56.48L119.245 59.1755C119.306 59.3881 119.5 59.5344 119.72 59.5344C119.941 59.5344 120.134 59.3881 120.196 59.1755L120.966 56.48C121.675 53.9999 123.578 52.0961 126.057 51.3884L128.753 50.6183C128.965 50.5581 129.112 50.3633 129.112 50.1429C129.112 49.9225 128.965 49.7288 128.753 49.6675Z" fill="#FF8C5A"/>
      <path d="M79.0573 107.834C81.1417 107.834 82.8315 106.144 82.8315 104.06C82.8315 101.976 81.1417 100.286 79.0573 100.286C76.9729 100.286 75.2832 101.976 75.2832 104.06C75.2832 106.144 76.9729 107.834 79.0573 107.834Z" fill="#B2A6FF"/>
      <path d="M72.7492 37.8041V15.9165C72.7492 15.2734 73.2684 14.7544 73.9115 14.7544H88.2059C96.9609 14.7544 97.9294 18.2021 99.3627 23.1994L103.446 37.4864C103.659 38.2302 103.101 38.9662 102.33 38.9662H73.9115C73.2684 38.9662 72.7492 38.4471 72.7492 37.8041ZM37.3574 38.9662H65.776C66.419 38.9662 66.9383 38.4471 66.9383 37.8041V15.9165C66.9383 15.2734 66.419 14.7544 65.776 14.7544H51.4816C42.7266 14.7544 41.7581 18.2021 40.3247 23.1994L36.2418 37.4864C36.0288 38.2263 36.5865 38.9662 37.3574 38.9662ZM104.709 45.9391V72.8627C104.709 80.6105 100.835 84.4844 93.0871 84.4844H46.6004C38.8526 84.4844 34.9788 80.6105 34.9788 72.8627V45.9391C34.9788 45.2961 35.498 44.777 36.1411 44.777H103.546C104.189 44.777 104.709 45.2961 104.709 45.9391ZM75.8366 50.5878C71.3507 50.5685 69.8437 55.0157 69.8437 55.0157C69.8437 55.0157 68.3368 50.5685 63.8509 50.5878C57.8231 50.615 55.4563 56.1469 56.541 61.6439C58.3075 70.6042 69.8437 75.7681 69.8437 75.7681C69.8437 75.7681 81.38 70.6081 83.1465 61.6439C84.2312 56.1469 81.8722 50.615 75.8366 50.5878Z" fill="#3623AA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8985 99.7913L24.3611 99.2538C24.0186 98.9114 23.8259 98.4458 23.8259 97.9607V97.2039C23.8259 95.1856 22.1905 93.5501 20.1722 93.5501H19.4154C18.9304 93.5501 18.466 93.3575 18.1223 93.0151L17.5848 92.4776C16.1576 91.0504 13.8444 91.0504 12.4173 92.4776L11.8797 93.0151C11.5373 93.3575 11.0718 93.5501 10.5868 93.5501H9.82995C7.8117 93.5501 6.17608 95.1856 6.17608 97.2039V97.9607C6.17608 98.4458 5.98356 98.9102 5.64109 99.2538L5.10357 99.7913C3.67641 101.218 3.67641 103.532 5.10357 104.959L5.64109 105.496C5.98356 105.839 6.17608 106.304 6.17608 106.789V107.546C6.17608 109.564 7.8117 111.2 9.82995 111.2H10.5868C11.0718 111.2 11.5361 111.393 11.8797 111.735L12.4173 112.273C13.8444 113.7 16.1576 113.7 17.5848 112.273L18.1223 111.735C18.4647 111.393 18.9304 111.2 19.4154 111.2H20.1722C22.1905 111.2 23.8259 109.564 23.8259 107.546V106.789C23.8259 106.304 24.0186 105.84 24.3611 105.496L24.8985 104.959C26.3256 103.532 26.3256 101.218 24.8985 99.7913ZM19.302 100.584L14.427 105.459C14.2491 105.636 14.0151 105.727 13.7811 105.727C13.5471 105.727 13.313 105.638 13.1351 105.459L10.6976 103.021C10.3405 102.664 10.3405 102.085 10.6976 101.728C11.0547 101.371 11.6336 101.371 11.9907 101.728L13.7823 103.519L18.0113 99.2904C18.3684 98.9333 18.9473 98.9333 19.3044 99.2904C19.6591 99.6475 19.6591 100.228 19.302 100.584Z" fill="#FF5F00"/>
    </svg>
  );
}

export default GiftSvg3;