import React from "react";

function LikeSmall(props) {

  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.625 3.5H6.375C4.125 3.5 3 4.625 3 6.875V18.125C3 20.375 4.125 21.5 6.375 21.5H17.625C19.875 21.5 21 20.375 21 18.125V6.875C21 4.625 19.875 3.5 17.625 3.5ZM9 16.45C9 16.616 8.86595 16.75 8.69995 16.75H8.04004C7.60404 16.75 7.25 16.396 7.25 15.96V12.26C7.25 11.825 7.60504 11.47 8.04004 11.47H8.69995C8.86595 11.47 9 11.604 9 11.77V16.45ZM16.619 12.5L15.559 15.67C15.349 16.31 15.119 16.75 14.059 16.75H10.299C10.133 16.75 9.99902 16.616 9.99902 16.45V11.188C9.99902 11.156 10.004 11.125 10.014 11.094L11.099 7.78998C11.229 7.44998 11.549 7.25 11.889 7.25C12.049 7.25 12.209 7.29001 12.349 7.39001C12.789 7.68001 13.049 8.18002 13.049 8.71002V10.42H15.119C16.699 10.42 16.959 11.48 16.619 12.5Z" fill="#816CFF"/>
    </svg>
  );
}

export default LikeSmall;