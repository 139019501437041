import React from "react";

function EnvelopeImage(props) {

  return (
    <svg width="127" height="111" viewBox="0 0 127 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="23.7005" y="57.3128" width="67.4399" height="52.9367" rx="8.33934" stroke="#816CFF" strokeWidth="0.72516" strokeDasharray="1.45 1.45"/>
      <path d="M91.5201 83.9309C109.162 83.9309 123.463 69.6295 123.463 51.9879C123.463 34.3463 109.162 20.0449 91.5201 20.0449C73.8784 20.0449 59.5771 34.3463 59.5771 51.9879C59.5771 69.6295 73.8784 83.9309 91.5201 83.9309Z" fill="#E3DEFC"/>
      <path d="M101.525 11.5552C104.609 11.5552 107.109 9.0553 107.109 5.97155C107.109 2.88781 104.609 0.387939 101.525 0.387939C98.4412 0.387939 95.9414 2.88781 95.9414 5.97155C95.9414 9.0553 98.4412 11.5552 101.525 11.5552Z" fill="#816CFF"/>
      <path d="M121.989 106.538C124.432 106.538 126.412 104.558 126.412 102.115C126.412 99.6728 124.432 97.6926 121.989 97.6926C119.547 97.6926 117.566 99.6728 117.566 102.115C117.566 104.558 119.547 106.538 121.989 106.538Z" fill="#442ECB"/>
      <path d="M20.79 16.342V25.7691" stroke="#FF8C5A" strokeWidth="1.06728" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M25.515 21.0461H16.0879" stroke="#FF8C5A" strokeWidth="1.06728" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M8.92969 39.5481V54.2602" stroke="#F27327" strokeWidth="1.66562" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M16.3 46.8899H1.58789" stroke="#F27327" strokeWidth="1.66562" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M94.8796 62.0641V92.5205C94.8796 99.5834 91.3482 103.115 84.2853 103.115H41.9117C34.8488 103.115 31.3174 99.5834 31.3174 92.5205V62.0641C31.3174 55.0012 34.8488 51.4697 41.9117 51.4697H84.2853C91.3482 51.4697 94.8796 55.0012 94.8796 62.0641ZM85.902 64.5469C84.9367 63.2201 83.0772 62.9182 81.7385 63.8875L63.5252 77.1314C63.271 77.3181 62.93 77.3181 62.6718 77.1314L44.4575 63.8875C43.1188 62.9182 41.2603 63.2201 40.295 64.5469C39.3258 65.8776 39.6199 67.7407 40.9506 68.7099L59.1639 81.9539C60.3398 82.8119 61.7221 83.237 63.0966 83.237C64.471 83.237 65.8534 82.808 67.0292 81.9539L85.2425 68.7099C86.5772 67.7407 86.8712 65.8776 85.902 64.5469Z" fill="#442ECB"/>
      <path d="M107.308 50.1584V80.587C107.308 84.9804 105.933 88.0272 103.185 89.6837C102.628 90.0174 101.35 89.9221 101.35 88.5914V62.0716C101.35 51.7037 95.153 45.5067 84.7851 45.5067H46.4198C44.9183 45.5067 44.9413 44.1839 45.2948 43.6119C46.9593 40.9027 49.9942 39.5481 54.3559 39.5481H96.7023C103.773 39.5521 107.308 43.0875 107.308 50.1584Z" fill="#BAAFFF"/>
    </svg>
  );
}

export default EnvelopeImage;