import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import FacebookSmall from '../icons/FacebookSmall';
import TwitterSmall from '../icons/TwitterSmall';
import CopyAlt from '../icons/CopyAlt';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { DownloadCSV } from '../DownloadCSV.jsx';

function EventDetailCard(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const [shouldFetch, setShouldFetch] = useState(false);

  const { ticketingCompany, state: authState } = React.useContext(AppContext);

  const eventLocalUrl = `https://${ticketingCompany?.data[0]?.ourSubdomain}.tibetlocal.com:3001/landing/${props.event.id}`;

  function copyToClipBoard() {
    var text_to_copy = eventLocalUrl;
    if (!navigator.clipboard) {
      var falseInput = document.createElement('input');
      falseInput.setAttribute('value', eventLocalUrl);
      document.body.appendChild(falseInput);
      falseInput.select();
      document.execCommand('copy');
      document.body.removeChild(falseInput);
    } else {
      navigator.clipboard.writeText(text_to_copy).then(/*
        function(){
          alert("yeah!"); // success 
      })
      .catch(
        function() {
          alert("err"); // error
      }*/);
    }
  }

  /* const { data } = useQuery({
    queryKey: ['reportTickets'],
    queryFn: () => reportsTickets(authState.token, eventId),
    onSuccess: (res) => {
      if (res) {
        console.log('res', res);
      }
    },
    onError: (err) => {
      console.log('err', err);
    },
    refetchOnMount: true,
    refetchInterval: 2000
  }); */

  const handleClick = () => {
    setShouldFetch(true);
  };
  return (
    <div
      className={`detail-event-card ${
        props.item?.id ? 'detail-event-card-orange' : 'detail-event-card-purple'
      } d-flex flex-column justify-content-between`}
    >
      {props.item?.svg1}
      {props.item?.svg2}
      <div className="detail-event-card-top d-flex flex-column justify-content-center">
        <h3>{t(props.item?.title) + ' ' + props.item?.icon}</h3>
        <p>{t(props.item?.text)}</p>
      </div>
      <div className="detail-event-card-bottom d-flex">
        <div className="detail-event-card-bottom-icons d-flex">
          {props.item?.id ? (
            props.event?.status < 3 ? (
              <>
                <DownloadCSV />
              </>
            ) : null
          ) : (
            <>
              {props.event?.status === 1 ? (
                <>
                  {true ? (
                    <TwitterShareButton
                      url={eventLocalUrl}
                      title={t('events.cardsShare') + props.event.name}
                      via={''}
                      quote={''}
                      hashtags={[props.event.name]}
                    >
                      <TwitterSmall round size={40} />
                    </TwitterShareButton>
                  ) : null}
                  {true ? (
                    <FacebookShareButton
                      url={eventLocalUrl}
                      title={t('events.cardsShare') + props.event.name}
                      quote={t('events.cardsShare') + props.event.name}
                      hashtag={`#${props.event.name.replace(/\s+/g, '')}`}
                    >
                      <FacebookSmall round size={40} />
                    </FacebookShareButton>
                  ) : null}
                  <CopyAlt function={() => copyToClipBoard()} />
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventDetailCard;
