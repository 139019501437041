import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import EventButton from "../ui/EventButton.jsx";
import DateButton from "../ui/DateButton.jsx";
import AngleRightSmall from "../icons/AngleRightSmall.jsx";
import TicketBanner from "../icons/TicketBanner.jsx";
const { DateTime } = require("luxon");

function HomeEventVertical(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { ticketingCompany } = React.useContext(AppContext);

  const eventDate = DateTime.fromFormat(String(props.item?.dateEvent), 'yyyy-MM-dd HH:mm:ss');

  return (
    <div className="home-section-events-vertical d-flex justify-content-between align-items-center">
      <div className="home-section-events-vertical-left d-flex justify-content-start align-items-center">
        <DateButton day = { eventDate?.c?.day } month = { eventDate?.monthShort } black = { false }/>
        <div className="home-section-events-vertical-line"></div>
        <div className="home-section-events-vertical-info d-flex justify-content-start align-items-center">
          { props.item?.images?.thumb ?
            <img src={ props.item?.images?.thumb } alt={`ticket-banner-`} className=""/>
            : 
            <TicketBanner/> 
          }
          <div className="home-section-events-vertical-info-text d-flex flex-column justify-content-center">
            <h2>{ props.preview ? t('events.event') : props.item?.name }</h2>
            <h5>{ props.item?.location }</h5>
            <h5>{ props.preview ? null : props.item?.lowestPrice ? `${t('home.from')}${props.item?.lowestPrice} ${ticketingCompany?.data[0]?.currency?.abbreviation}` : t('home.noTicketsYet') }</h5>
          </div>
        </div>
        { props.elvis || !props.preview ?
          <>
            <div className="home-section-events-vertical-line d-none d-xl-block"></div>
            <div className="home-section-events-vertical-tickets d-none d-xl-flex flex-column">
              <h3>{t('home.sold')}</h3>
              <h4>{ props.item?.ticketsSold ? props.item?.ticketsSold : 0 }<span>{ props.item?.ticketsTotal ? `/${props.item?.ticketsTotal}` : `/0`}</span></h4>
            </div>
          </>
          :
          null
        }
      </div>
      { props.preview ?
        null :
        <div className="home-section-events-vertical-right d-flex justify-content-end align-items-center align-items-sm-start">
          <EventButton function = { props.function } text = { t('home.see') } icon = { <AngleRightSmall/> }/>
        </div>
      }
    </div>
  );
}

export default HomeEventVertical;