import React from "react";

function AngleRightSmall(props) {

  return (
    <svg onClick={ props.function } className={ `${props.rotate180 ? "chevron-rotated" : null} ${props.rotate0 ? "chevron-rotated-0" : null}` } width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.49972 10.0003C7.49972 9.74428 7.59769 9.48825 7.79269 9.29325C8.18369 8.90225 8.81575 8.90225 9.20675 9.29325L12.4997 12.5862L15.7927 9.29325C16.1837 8.90225 16.8158 8.90225 17.2067 9.29325C17.5977 9.68425 17.5977 10.3163 17.2067 10.7073L13.2067 14.7073C12.8157 15.0983 12.1837 15.0983 11.7927 14.7073L7.79269 10.7073C7.59769 10.5123 7.49972 10.2563 7.49972 10.0003Z" fill="#816CFF"/>
    </svg>
  );
}

export default AngleRightSmall;