import React from 'react';
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import Pen from "../icons/Pen";
import TrashTimes from '../icons/TrashTimes.jsx';

export default function PictureProfileCircle(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { setImageModal, userData, setUserData } = React.useContext(AppContext);

  function onSelectFile (e) {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size < 1572864) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImageModal({ src: reader.result?.toString(), function: null, functionForLoad: props.functionForLoad } || ''),
        );
        reader.readAsDataURL(e.target.files[0]);
      } else {
        setImageModal({ sizeExceeded: true });
      }
    }
    e.target.value = ''
  }

  function onUpload() {
    if (!props.mobile) {
      var element = document.getElementById("change-pic");
      element.click();
    }
  }

  return (
    <div className={ props.mobile ?
      "profile-section-picture d-flex d-lg-none flex-column justify-content-center align-items-center" 
      : 
      "profile-section-picture d-none d-lg-flex flex-column justify-content-center align-items-center" }
      onClick = { props.mobile ? props.function : null }
    >
      { props.img ?
        <>
          <img src={ props.img } alt="author-pic" className="" onClick={ () => onUpload() }/>
          { props.mobile ? null : <input className="d-none" id="change-pic" type="file" accept="image/*" onChange={ (e) => onSelectFile(e) }></input> }
          <div className="profile-section-picture-delete d-none d-lg-flex flex-column justify-content-center align-items-center"
            onClick={ () => props.deleteFunction() }
          >
            <TrashTimes/>
          </div>
        </>
        :
        <div className="profile-section-picture-inside d-flex flex-column justify-content-center align-items-center">
          <div className="profile-section-picture-inside-initials">{ userData?.name ? userData?.name.split(' ').map(function(item, index){if (index < 2) return item[0]; else return null}).join('') : userData?.userId ? userData?.userId.charAt(0) : null }</div>
          <div className="profile-section-picture-inside-hover flex-column justify-content-center align-items-center">{t('profile.size')}</div>
          { props.mobile ? null : <input type="file" accept="image/*" onChange={ (e) => onSelectFile(e) }></input> }
        </div>
      }
      <div className="profile-section-picture-edit d-flex flex-column justify-content-center align-items-center">
        <Pen/>
      </div>
    </div>
  )

}