import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import {
  getTicketById,
  sendTicketGift,
  putOnSaleTicket,
  withdrawOnSaleTicket,
  getTicketActivityById
} from '../../services/axiosService.js';
import routes from '../../routes/routes.js';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index.js';
import { saveAs } from 'file-saver';
import { getSubdomain } from '../shared/functions.js';
import AngleLeft from '../../components/icons/AngleLeft.jsx';
import TicketOpened from '../../components/ui/TicketOpened.jsx';
import Times from '../icons/Times.jsx';
import Download from '../icons/Download.jsx';
import TermsPolicyButton from '../ui/TermsPolicyButton.jsx';
import HomeEventHorizontal from '../shared/HomeEventHorizontal.jsx';
import TicketOpenedLocation from '../ui/TicketOpenedLocation.jsx';
import ContentField from '../ui/ContentField.jsx';
import AdviceFooter from '../ui/AdviceFooter.jsx';
import Gift from '../icons/Gift.jsx';
import GiftSvg3 from '../icons/GiftSvg3.jsx';
import SaleSvg1 from '../icons/SaleSvg1.jsx';
import SaleModal1 from '../icons/SaleModal1.jsx';
import SaleModal2 from '../icons/SaleModal2.jsx';
import CheckoutFinal from '../ui/CheckoutFinal.jsx';
import SuccessFailPopupLarge from '../ui/SuccessFailPopupLarge.jsx';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';

function MyTicketsDetailComponent(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    publicElvisId,
    setQrModal
  } = React.useContext(AppContext);

  let { ticketId } = useParams();
  var navigate = useNavigate();
  const subdomain = useMemo(() => getSubdomain(), []);

  const [fieldStatus, setFieldStatus] = useState([false, false, false]);
  const [contentStatus, setContentStatus] = useState(0);
  const [saleStatus, setSaleStatus] = useState(0);
  const [giftStatus, setGiftStatus] = useState(0);
  const [popup, setPopup] = useState(false);

  const { mutate: sendTicket } = useMutation(
    (values) =>
      sendTicketGift(
        authState.token,
        publicElvisId,
        ticketId,
        values.user,
        subdomain
      ),
    {
      onSuccess: (res) => {
        if (res.data) {
          setGiftStatus(giftStatus + 1);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const { mutate: sellTicket } = useMutation(
    (values) =>
      putOnSaleTicket(authState.token, publicElvisId, ticketId, values.price),
    {
      onSuccess: (res) => {
        if (res.data) {
          setSaleStatus(saleStatus + 1);
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const { mutate: withdrawTicket } = useMutation(
    () => withdrawOnSaleTicket(authState.token, publicElvisId, ticketId),
    {
      onSuccess: (res) => {
        if (res) {
          setSaleStatus(0);
          setPopup({ state: 1, text: t('ticket.withdrawSaleSuccess') });
          queryClient.setQueryData(['ticket', ticketId], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    onSaleUser: res.data.onSaleUser
                  }
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        setPopup({
          state: 0,
          text:
            t('ticket.withdrawSaleFail') +
            '. (' +
            err.response.data.message +
            ')'
        });
        console.error(err);
      }
    }
  );

  const { data: ticketActivity } = useQuery({
    queryKey: ['ticket-activity', ticketId],
    queryFn: () =>
      getTicketActivityById(authState.token, publicElvisId, ticketId),
    enabled: !!subdomain && !!publicElvisId && !!ticketId,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isError: ticketIsError,
    data: ticket,
    isFetching: ticketIsFetching
  } = useQuery({
    queryKey: ['ticket', ticketId],
    queryFn: () => getTicketById(authState.token, publicElvisId, ticketId),
    enabled:
      !!authState.isAuthenticated &&
      !!subdomain &&
      authState.roles[0] === 'ROLE_USER',
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  });

  var initialValuesSale = {
    price: '',
    user: ''
  };

  var initialValuesGift = {
    email: ''
  };

  const validationSchemaSale = Yup.object().shape({
    price: Yup.number()
      .required(t('register.error2'))
      .min(0, t('ticket.errorPrice2'))
      .round(),
    account: Yup.string()
  });

  const validationSchemaGift = Yup.object().shape({
    email: Yup.string()
      .email(t('register.error1'))
      .required(t('register.error2'))
  });

  const returnFromModal = (e) => {
    if (e.target !== e.currentTarget) return;
    else {
      setSaleStatus(0);
    }
  };

  /*async function downloadFile(url, fileName) {
    await getFile(url)
      .then((response) => {
        var binaryData = [];
        binaryData.push(response.data);    
        const url = window.URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.replaceAll(" ", "_");
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => null)
  };*/

  useEffect(() => {
    setQrModal(false);
    return () => {
      setQrModal(false);
    };
  }, []);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <main
      id={`my-ticket-detail-${ticketId}`}
      className={`ticket-sale-detail-main my-ticket-detail d-flex flex-column justify-content-between align-items-center`}
    >
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      {!contentStatus ? (
        saleStatus < 2 && !giftStatus ? (
          <>
            <div
              className={`ticket-sale-detail-title d-flex align-items-center`}
              onClick={() => navigate(routes.ticketerMyTickets)}
            >
              <AngleLeft />
              <h3>{t('landingElvis.yourTicket')}</h3>
            </div>
            {ticketIsFetching ? (
              <SpinnerPurple />
            ) : ticketIsError ? (
              <h3>{t('myTickets.notOwnTicketError')}</h3>
            ) : (
              <TicketOpened
                event={ticket?.data.event}
                fieldStatus={fieldStatus}
                setFieldStatus={setFieldStatus}
                ticket={ticket?.data}
                qrShowed={ticket?.data?.accessCode}
                onGift={false}
                setContentStatus={setContentStatus}
                setSaleStatus={setSaleStatus}
                setGiftStatus={setGiftStatus}
                historyShowed={ticketActivity}
                secondaryMarket={false}
                ownUser={authState.userId ? authState.userId : null}
              />
            )}
            {saleStatus === 1 ? (
              <div
                className="profile-upload-image-mobile d-flex flex-row justify-content-center"
                onClick={(event) => returnFromModal(event)}
              >
                <div className="profile-upload-image-mobile-box d-flex flex-column justify-content-between align-items-center">
                  <div
                    className="profile-upload-image-mobile-box-line"
                    onClick={() => null}
                  ></div>
                  {!ticket?.data?.onSaleUser ? <SaleModal1 /> : <SaleModal2 />}
                  <div
                    className={`ticket-sale-detail-modal-text d-flex flex-column align-items-center`}
                  >
                    <h3>
                      {!ticket?.data?.onSaleUser
                        ? t('secondary.sellTicketTitle1')
                        : t('secondary.sellTicketTitle2')}
                    </h3>
                    {!ticket?.data?.onSaleUser ? (
                      <div>
                        <div>1</div>
                        {t('secondary.sellTicketText1')}
                      </div>
                    ) : (
                      <div>{t('secondary.sellTicketText5')}</div>
                    )}
                    {!ticket?.data?.onSaleUser ? (
                      <div>
                        <div>2</div>
                        {t('secondary.sellTicketText2')}
                      </div>
                    ) : null}
                    {!ticket?.data?.onSaleUser ? (
                      <div>
                        <div>3</div>
                        {t('secondary.sellTicketText3')}
                      </div>
                    ) : null}
                    {!ticket?.data?.onSaleUser ? (
                      <div>{t('secondary.sellTicketText4')}</div>
                    ) : null}
                  </div>
                  {!ticket?.data?.accessCode ? (
                    <div
                      className={`ticket-sale-detail-modal-buttons d-flex flex-column align-items-center`}
                    >
                      {ticket?.data?.resale ? (
                        <TermsPolicyButton
                          text={
                            !ticket?.data?.onSaleUser
                              ? t('secondary.sellTicketButton1')
                              : t('secondary.sellTicketButton2')
                          }
                          disabled={false}
                          function={
                            !ticket?.data?.onSaleUser
                              ? () => setSaleStatus(2)
                              : () => withdrawTicket()
                          }
                        />
                      ) : null}
                      <TermsPolicyButton
                        text={t('secondary.sellTicketButton3')}
                        disabled={false}
                        function={() => setSaleStatus(0)}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        ) : giftStatus ? (
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesGift}
            validationSchema={validationSchemaGift}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, actions) => {
              //submitTicketerForm(values, actions);
              sendTicket({ user: values.email });
            }}
          >
            {(props) => {
              const {
                errors,
                touched,
                values,
                isValid,
                dirty,
                setFieldTouched,
                submitForm
              } = props;
              i18n.on('languageChanged', () => {
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                });
              });
              return (
                <>
                  {giftStatus !== 3 ? (
                    <>
                      <div
                        className={`ticket-sale-detail-title d-flex align-items-center`}
                        onClick={() => setGiftStatus(giftStatus - 1)}
                      >
                        <AngleLeft />
                        <h3>
                          {t('gift.giveTicket')}
                          <span>{ticket?.data.event.name}</span>
                        </h3>
                      </div>
                      <div
                        className={`ticket-sale-detail-form d-flex flex-column`}
                      >
                        <div className="ticketer-sections-counter d-flex justify-content-center align-items-center">
                          {giftStatus + ' / 2'}
                        </div>
                        {giftStatus === 1 ? (
                          <div
                            className={`ticket-sale-detail-field d-flex flex-column justify-content-between`}
                          >
                            <h3>{t('gift.giveTitle')}</h3>
                            <p>{t('gift.giveEmail')}</p>
                            <Field
                              className={
                                errors.email && touched.email
                                  ? 'ticketer-field red-border'
                                  : touched.email
                                  ? 'ticketer-field purple-border'
                                  : 'ticketer-field'
                              }
                              id="gift-form-email"
                              type="email"
                              name="email"
                              placeholder=""
                            />
                            {errors.email && touched.email ? (
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div className="ticketer-field-error">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            ) : null}
                            <h4>{t('gift.giveFooter')}</h4>
                          </div>
                        ) : giftStatus === 2 ? (
                          <div
                            className={`ticket-sale-detail-resume d-flex flex-column justify-content-between`}
                          >
                            <h3>{t('gift.giveDetails')}</h3>
                            <HomeEventHorizontal
                              key={null}
                              item={ticket?.data.event}
                              function={null}
                              elvis={true}
                              landing={true}
                            />
                            <TicketOpenedLocation
                              fieldStatus={fieldStatus}
                              setFieldStatus={setFieldStatus}
                            />
                          </div>
                        ) : null}
                      </div>
                      {giftStatus === 2 ? (
                        <>
                          <div
                            className={`ticket-sale-detail-mail d-flex flex-column justify-content-between align-items-center`}
                          >
                            <h3>{t('gift.giftReceiver')}</h3>
                            <ContentField
                              type={4}
                              functionClick={null}
                              text={values.email}
                            />
                          </div>
                          <AdviceFooter
                            footer={{
                              text: t('gift.giftFooter'),
                              icon: <Gift />
                            }}
                          />
                        </>
                      ) : null}
                      <TermsPolicyButton
                        text={
                          giftStatus === 1
                            ? t('gift.giftButton4')
                            : t('gift.giftButton5')
                        }
                        disabled={
                          !(
                            isValid &&
                            dirty &&
                            !(errors.email && touched.email)
                          )
                            ? true
                            : false
                        }
                        function={
                          giftStatus === 2
                            ? () => submitForm()
                            : () => setGiftStatus(giftStatus + 1)
                        }
                      />
                    </>
                  ) : (
                    <div
                      className={`purchase-done-main d-flex flex-column justify-content-center align-items-center`}
                    >
                      <GiftSvg3 />
                      <div
                        className={`purchase-done-main-top d-flex flex-column justify-content-between align-items-center`}
                      >
                        <h3>{t('gift.giftTitle3')}</h3>
                        <div
                          className={`purchase-done-main-top-text d-flex flex-column justify-content-between align-items-center`}
                        >
                          <p>
                            {t('gift.giftText5')}
                            <span>{values.email}</span>
                            {t('gift.giftText6')}
                          </p>
                        </div>
                      </div>
                      <TermsPolicyButton
                        text={t('gift.giftButton6')}
                        disabled={false}
                        function={() => navigate(`${routes.ticketerMyTickets}`)}
                      />
                    </div>
                  )}
                </>
              );
            }}
          </Formik>
        ) : saleStatus ? (
          <Formik
            enableReinitialize={true}
            initialValues={initialValuesSale}
            validationSchema={validationSchemaSale}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, actions) => {
              //submitTicketerForm(values, actions);
              sellTicket({ price: values.price });
            }}
          >
            {(props) => {
              const {
                errors,
                touched,
                isValid,
                dirty,
                values,
                setFieldTouched,
                submitForm
              } = props;
              i18n.on('languageChanged', () => {
                Object.keys(errors).forEach((fieldName) => {
                  setFieldTouched(fieldName);
                });
              });
              return (
                <>
                  {saleStatus === 4 ? (
                    <div
                      className={`purchase-done-main d-flex flex-column justify-content-center align-items-center`}
                    >
                      <SaleSvg1 />
                      <div
                        className={`purchase-done-main-top d-flex flex-column justify-content-between align-items-center`}
                      >
                        <h3>{t('secondary.sellFinal1')}</h3>
                        <div
                          className={`purchase-done-main-top-text d-flex flex-column justify-content-between align-items-center`}
                        >
                          <p>
                            {t('secondary.sellFinal2')}
                            <span>{'Elvis Producciones'}</span>
                            {t('secondary.sellFinal3')}
                            <span>{'contacto@tibetpass.com'}</span>.
                          </p>
                        </div>
                      </div>
                      <TermsPolicyButton
                        text={t('gift.giftButton6')}
                        disabled={false}
                        function={() => navigate(`${routes.ticketerMyTickets}`)}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className={`ticket-sale-detail-title d-flex align-items-center`}
                        onClick={() => setSaleStatus(saleStatus - 1)}
                      >
                        <AngleLeft />
                        <h3>
                          {t('secondary.sellTicket')}
                          <span>{ticket?.data.event.name}</span>
                        </h3>
                      </div>
                      <div
                        className={`ticket-sale-detail-form d-flex flex-column`}
                      >
                        <div className="ticketer-sections-counter d-flex justify-content-center align-items-center">
                          {saleStatus - 1 + ' / 2'}
                        </div>
                        {saleStatus === 2 ? (
                          <div
                            className={`ticket-sale-detail-field d-flex flex-column justify-content-between`}
                          >
                            <h3>{t('secondary.sellForm1')}</h3>
                            <p>{t('secondary.sellForm2')}</p>
                            <Field
                              className={
                                errors.price && touched.price
                                  ? 'ticketer-field red-border'
                                  : touched.price
                                  ? 'ticketer-field purple-border'
                                  : 'ticketer-field'
                              }
                              id="gift-form-price"
                              type="number"
                              name="price"
                              placeholder=""
                              min="0"
                              step="0.01"
                            />
                            {errors.price && touched.price ? (
                              <ErrorMessage name="price">
                                {(msg) => (
                                  <div className="ticketer-field-error">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            ) : null}
                            <h4>{t('secondary.sellForm3')}</h4>
                            <p>{t('secondary.sellForm4')}</p>
                            <Field
                              className={
                                errors.account && touched.account
                                  ? 'ticketer-field red-border'
                                  : touched.account
                                  ? 'ticketer-field purple-border'
                                  : 'ticketer-field'
                              }
                              id="gift-form-account"
                              type="text"
                              name="account"
                              placeholder=""
                            />
                            {errors.account && touched.account ? (
                              <ErrorMessage name="account">
                                {(msg) => (
                                  <div className="ticketer-field-error">
                                    {msg}
                                  </div>
                                )}
                              </ErrorMessage>
                            ) : null}
                          </div>
                        ) : saleStatus === 3 ? (
                          <div
                            className={`ticket-sale-detail-resume d-flex flex-column justify-content-between`}
                          >
                            <h3>{t('secondary.sellResume1')}</h3>
                            <HomeEventHorizontal
                              key={null}
                              item={ticket?.data.event}
                              function={null}
                              elvis={true}
                              landing={true}
                            />
                            <h4>{t('secondary.sellResume2')}</h4>
                            <CheckoutFinal
                              tickets={ticket?.data}
                              ticketSaleOpen={[
                                { id: 1, saleOpen: true },
                                { id: 2, saleOpen: false },
                                { id: 3, saleOpen: false },
                                { id: 4, saleOpen: false }
                              ]}
                              ticketSaleQuantity={[
                                { id: 1, quantity: 1 },
                                { id: 2, quantity: 0 },
                                { id: 3, quantity: 0 },
                                { id: 4, quantity: 0 }
                              ]}
                              code={null}
                              secondary={true}
                              totalPrice={values.price}
                            />
                          </div>
                        ) : null}
                      </div>
                      <TermsPolicyButton
                        text={
                          saleStatus === 2
                            ? t('secondary.sellTicketButton4')
                            : t('secondary.sellTicketButton5')
                        }
                        disabled={
                          !(
                            isValid &&
                            dirty &&
                            !(errors.price && touched.price)
                          )
                            ? true
                            : false
                        }
                        function={
                          saleStatus === 3
                            ? () => submitForm()
                            : () => setSaleStatus(saleStatus + 1)
                        }
                      />
                    </>
                  )}
                </>
              );
            }}
          </Formik>
        ) : null
      ) : (
        <div
          className={`ticket-content-max d-flex flex-column justify-content-between align-items-end`}
        >
          <div
            className={`ticket-content-top d-flex flex-column justify-content-between align-items-center`}
          >
            <div
              className={`ticket-content-top-bar d-flex justify-content-between align-items-center`}
            >
              {contentStatus.title}
              <Times function={() => setContentStatus(0)} />
            </div>
            <div
              className={`ticket-content-frame d-flex flex-column justify-content-between align-items-center`}
            >
              {contentStatus.type === 'jpg' ||
              contentStatus.type === 'jpeg' ||
              contentStatus.type === 'png' ? (
                <img
                  src={contentStatus.content}
                  alt={`content-${contentStatus.title}`}
                  className=""
                />
              ) : contentStatus.type === 'mp4' ? (
                <iframe
                  className="video-landing-video"
                  width="auto"
                  height="auto"
                  src={contentStatus.content}
                  title={`content-${contentStatus.title}`}
                  frameBorder="0"
                  controls={0}
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  type="video/mp4"
                ></iframe>
              ) : contentStatus.type === 'mp3' ? (
                <audio
                  src={contentStatus.content}
                  id={`content-${contentStatus.title}`}
                  controls
                />
              ) : contentStatus.type === 'pdf' ? (
                <embed src={contentStatus.content} width="auto" height="auto" />
              ) : null}
            </div>
            {contentStatus.description ? (
              <div
                className={`ticket-content-desc d-flex flex-column justify-content-between`}
              >
                <h3>{t('ticketer.infoField2')}</h3>
                <p>{contentStatus.description}</p>
              </div>
            ) : null}
          </div>
          <div
            className={`ticket-content-bottom d-flex`}
            onClick={() =>
              saveAs(
                contentStatus.content,
                `${contentStatus.title}.${contentStatus.type}`
              )
            }
          >
            <h3>{t('events.cardsDownload')}</h3>
            <Download />
          </div>
        </div>
      )}
    </main>
  );
}

export default MyTicketsDetailComponent;
