import React from "react";
import StarshipIcon from "../icons/CoheteIcon";
import Button from "../shared/Button";
import ModalSubscription from "../modals/ModalSubscription";
import ModalCancelSubscription from "../modals/ModalCancelSubscription";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { DateTime } from "luxon";
// import required modules

const SubscriptionCardMobile = ({ data, isMensual, subsStatistics }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalCancelPlan, setModalCancelPlan] = React.useState(false);
  const [dataToModal, setDataTomodal] = React.useState({});
  const [dataToCancelModal, setDataToCancelmodal] = React.useState({});
  const dt = DateTime.now();

  const currentPlan = (value) => {
    if (value === subsStatistics.subscriptionName) {
      return "Plan actual";
    } else {
      return "Elegir plan";
    }
  };
  const openModalToSubscription = (item) => {
    setOpenModal(true);
    setDataTomodal(item);
  };

  const openModalCancelSubscription = (item) => {
    setModalCancelPlan(true);
    setDataToCancelmodal(item);
  };

  return (
    <>
      <Swiper
        slidesPerView={1.3}
        centeredSlides={true}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          300: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          400: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          700: {
            slidesPerView: 1.7,
            spaceBetween: 20,
          },
        }}
        className="mySwiper d-xs-block d-sm-block d-md-block d-lg-none"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className={`home-section-dashboard-box ${
                item.name === "Pro"
                  ? "isPro"
                  : item.name === "Empresa" && "isEmpresa"
              }`}
            >
              {item.name === "Pro" && (
                <div className="sub-label-pro">Más elegido</div>
              )}
              {item.name === "Empresa" && (
                <div className="sub-label-empresa">
                  <StarshipIcon />
                </div>
              )}
              <h3 className="mb-2">{item.name}</h3>
              <p>{item.description}</p>

              <div className="sub-cards-types-price mt-4 text-center">
                ${item.price}{" "}
                <span className="fs-6 text fw-normal text-secondary">
                  {isMensual ? "/mensual" : "/anual"}
                </span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                <Button
                  onClick={() => openModalToSubscription(item)}
                  style={{ padding: "8px" }}
                  fullwidth={true}
                  disabled={item.name === subsStatistics?.subscriptionName}
                >
                  {currentPlan(item.name)}
                </Button>
                {item.name === subsStatistics?.subscriptionName &&
                  subsStatistics.dateEnd === null && (
                    <button
                      onClick={() => openModalCancelSubscription(item)}
                      className="subscription-cancel-button"
                      fullwidth
                    >
                      Cancelar plan
                    </button>
                  )}
                {item.name === subsStatistics?.subscriptionName &&
                  subsStatistics.dateEnd && (
                    <p className="text-danger fw-bold text-center">
                      La suscripción estará activa hasta el{" "}
                      <strong>
                        {dt.toLocaleString(subsStatistics.dateEnd)}
                      </strong>
                    </p>
                  )}
              </div>
              <div className="mt-4">
                <p className="mb-3">
                  Incluye <strong>{item.attributes.benefits_text}</strong>
                </p>
                <ul className="sub-list-attributes">
                  {item.attributes.benefits_list.map((benefit, bIndex) => (
                    <li key={bIndex}>{benefit}</li>
                  ))}
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {openModal && (
        <ModalSubscription
          data={dataToModal}
          isClosable={() => setOpenModal(false)}
        />
      )}
      {openModalCancelPlan && (
        <ModalCancelSubscription
          data={dataToCancelModal}
          isClosable={() => setModalCancelPlan(false)}
        />
      )}
    </>
  );
};

export default SubscriptionCardMobile;
