import React from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App.js";
import DateButton from "../ui/DateButton.jsx";
import TicketBanner from "../icons/TicketBanner.jsx";
const { DateTime } = require("luxon");

function HomeEventHorizontal(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { ticketingCompany } = React.useContext(AppContext);
  
  const eventDate = DateTime.fromFormat(String(props?.item?.dateEvent), 'yyyy-MM-dd HH:mm:ss');

  return (
    <div className={`home-section-events-horizontal d-flex flex-column justify-content-between ${props.function ? "ticket-clickable" : ""}`}
      onClick = { props.function }
    >
      { props.ticket?.image ?
        <img src={ props.ticket?.image } alt={`ticket-banner-`} className=""/>
        :
        props.item?.images?.cover ?
        <img src={ props.item?.images?.cover } alt={`ticket-banner-`} className=""/>
        : 
        <TicketBanner/> 
      }
      <DateButton day = { eventDate?.c?.day } month = { eventDate?.monthShort } black = { true }/>
      <div className="home-section-events-horizontal-bottom d-flex flex-column">
        <div className="home-section-events-horizontal-bottom-title d-flex flex-column flex-sm-row justify-content-between">
          <div className="home-section-events-horizontal-bottom-title-box">
            <h3>{t('home.event')}</h3>
            <h2>{ props.item?.name }</h2> 
          </div>
          { !props.elvis || props.landing ?
            null :
            <div>
              <h3>{t('home.sold')}</h3>
              <h2>{ props.item?.ticketsSold ? props.item?.ticketsSold : 0 }<span>{ props.item?.ticketsTotal ? `/${props.item?.ticketsTotal}` : `/0`}</span></h2> 
            </div>
          }
        </div>
        <h5>{ props.item?.location }</h5>
        <h5>{ props.secondaryMarketPrice ? props.secondaryMarketCurrency?.id === 2 ? 
                props.secondaryMarketCurrency?.symbol + Number(props.secondaryMarketPrice).toFixed(2)
                :
                Number(props.secondaryMarketPrice).toFixed(2) + props.secondaryMarketCurrency?.symbol
          : props.item?.lowestPrice ? `${t('home.from')}${props.item?.lowestPrice} ${ticketingCompany?.data[0]?.currency?.abbreviation}` : t('home.noTicketsYet') }
        </h5>
      </div>
    </div>
  );
}

export default HomeEventHorizontal;