import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../index.js';
import {
  getEventById,
  getTicketsTypeByEvent,
  getEventStats,
  getDiscountByEventId,
  patchDiscountById
} from '../../services/axiosService.js';
import routes from '../../routes/routes.js';
import DashboardBar from '../shared/DashboardBar';
import CalendarEdit from '../icons/CalendarEdit';
import CancelDeleteEventComponent from '../shared/CancelDeleteEventComponent.jsx';
import BadgeCheck from '../icons/BadgeCheck.jsx';
import SuccessFailPopupLarge from '../ui/SuccessFailPopupLarge.jsx';
import { eventDetailsFields, eventDetailsCards } from './ticketerData.js';
import { dashboardEventData } from './dashboardData';
import HomeBoxes from '../shared/HomeBoxes.jsx';
import EventDetailData from '../shared/EventDetailData.jsx';
import EventDetailCard from '../shared/EventDetailCard.jsx';
import ProfileButton from '../ui/ProfileButton.jsx';
import Coupon from '../icons/Coupon.jsx';
import AngleRightSmall from '../icons/AngleRightSmall.jsx';
import BadgeDiscountAlt from '../icons/BadgeDiscountAlt';
import Switch from '../icons/Switch.jsx';
import SpinnerPurple from '../icons/SpinnerPurple.jsx';
import SuccessFailPopup from '../ui/SuccessFailPopup.jsx';
import QRCode from 'react-qr-code';

function EventDetailComponentNotADraft(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setDeleteEventModal,
    setDiscountModal,
    elvis
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();

  const [select, setSelect] = useState([0, 0, 0]);
  const [selectOpen, setSelectOpen] = useState([false, false, false]);
  const [ticket, setTicket] = useState(0);
  const [ticketOpen, setTicketOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [metricsState, setMetricsState] = useState({});

  const updateSelectStatus = (index) => {
    let newArrOpen = selectOpen;
    newArrOpen[index] = !newArrOpen[index];
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
  };

  const updateSelect = (index, value) => {
    let newArr = select;
    newArr[index] = value;
    let newArrOpen = selectOpen;
    newArrOpen[index] = false;
    setSelectOpen([newArrOpen[0], newArrOpen[1], newArrOpen[2]]);
    setSelect([newArr[0], newArr[1], newArr[2]]);
  };

  const {
    isLoading: mutateIsLoading,
    isSuccess: mutateIsSuccess,
    isError: mutateIsError,
    error: mutateError,
    mutate
  } = useMutation(
    (values) => patchDiscountById(authState.token, values.id, values.enabled),
    {
      onSuccess: (res) => {
        if (res.data) {
          queryClient.setQueryData(['codes', eventId], (oldData) =>
            oldData
              ? {
                  ...oldData,
                  data: {
                    ...oldData.data,
                    promoCodes: [...oldData.data.promoCodes].map(
                      function callback(currentValue, index, array) {
                        if (currentValue.id === res.data.id) {
                          var value = currentValue;
                          value.enabled = res.data.enabled;
                          return value;
                        } else {
                          return currentValue;
                        }
                      }
                    )
                  }
                }
              : oldData
          );
        }
      },
      onError: (err) => {
        console.error(err);
      }
    }
  );

  const {
    isLoading,
    isError,
    error,
    data: event,
    isFetching
  } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: promoCodesIsLoading,
    isError: promoCodesIsError,
    error: promoCodesError,
    data: promoCodes,
    isFetching: promoCodesIsFetching
  } = useQuery({
    queryKey: ['codes', eventId],
    queryFn: () => getDiscountByEventId(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      if (res.data) {
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: ticketsIsLoading,
    isError: ticketsIsError,
    error: ticketsError,
    data: tickets,
    isFetching: ticketsIsFetching
  } = useQuery({
    queryKey: ['tickets-model', eventId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price'
      ),
    enabled: !!authState.isAuthenticated,
    onSuccess: async (res) => {
      if (res) {
        const metrics = await queryClient.fetchQuery({
          queryKey: ['metrics', eventId],
          queryFn: (values) =>
            getEventStats(authState.token, eventId, values.categoryId),
          enabled: !!authState.isAuthenticated,
          onError: (err) => {
            console.error(err.response.status);
            if (err.response.status === 401) {
              dispatch({
                type: 'LOGOUT'
              });
              navigate(routes.auth);
            }
          }
        });
        setMetricsState(metrics.data);
      }
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const handleMetricsByCategory = async (item) => {
    setTicket(item.id === ticket ? 0 : item.id);
    setTicketOpen(false);
    const metrics = await queryClient.fetchQuery({
      queryKey: ['metrics', eventId],
      queryFn: () => getEventStats(authState.token, eventId, item.category_id),
      enabled: !!authState.isAuthenticated,
      onError: (err) => {
        console.error(err.response.status);
        if (err.response.status === 401) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      }
    });
    setMetricsState(metrics.data);
  };

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  return (
    <>
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      <DashboardBar
        text={t('events.title')}
        session={false}
        emoji="create-event"
        icon={<CalendarEdit />}
        buttonText={t('events.seeEvent')}
        function={null}
        disabled={false}
        button={
          event?.data ? (event?.data?.status !== 1 ? false : elvis) : null
        }
        ticketer={false}
        icon2={null}
        buttonText2={null}
        function2={null}
        disabled2={false}
        breadcrum={true}
        breadcrumFunction={() =>
          props.navigateToTicketerSections(routes.events)
        }
        span={
          event?.data
            ? event?.data?.name
              ? `/ ${event?.data?.name}`
              : `/ ${t('events.nullTitle')}`
            : null
        }
      />
      <div className="detail-event-info d-flex flex-column">
        <div
          className={`detail-event-info-status ${
            event?.data
              ? event?.data?.status === 1 || event?.data?.status === 2
                ? 'detail-event-info-status-green'
                : 'detail-event-info-status-red'
              : null
          } d-flex flex-column justify-content-center`}
        >
          {event?.data
            ? event?.data?.status === 1
              ? t('events.eventStatus1')
              : event?.data?.status === 2
              ? t('events.eventStatus2')
              : event?.data?.status === 4
              ? t('events.eventStatus3')
              : null
            : null}
        </div>
        <div className="detail-event-info-name d-flex flex-column justify-content-center">
          <h2>
            {event?.data
              ? event?.data?.name
                ? event?.data?.name
                : t('events.nullTitle')
              : null}
          </h2>
        </div>
        <div className="detail-event-info-data d-flex">
          {eventDetailsFields.map((item) => {
            return (
              <EventDetailData key={item.id} item={item} event={event?.data} />
            );
          })}
        </div>
      </div>
      <div className="detail-event-cards d-flex justify-content-center align-items-center">
        {eventDetailsCards.map((item) => {
          return (
            <EventDetailCard key={item.id} item={item} event={event?.data} />
          );
        })}
        <div className="detail-event-qr">
          <QRCode size={'100%'} value={eventId} viewBox={`0 0 256 256`} />
        </div>
      </div>
      {ticketsIsLoading ? (
        <SpinnerPurple />
      ) : ticketsIsError ? (
        <h3 className="fetching-error">{t('events.errorRetrievingTickets')}</h3>
      ) : event?.data?.status < 3 && tickets?.data?.tickets ? (
        <>
          <div className="detail-event-metrics d-flex flex-column justify-content-center">
            <h3>{t('events.cardsMetric')}</h3>
            <div className="detail-event-metrics-bar d-flex justify-content-between align-items-center">
              <div
                className={`event-detail-select ${
                  ticketOpen ? 'event-detail-select-selected' : null
                } d-flex flex-row justify-content-between align-items-center`}
              >
                {ticket === 0
                  ? t('events.selectTicket')
                  : tickets?.data?.tickets?.filter(
                      (item) => item.id === ticket
                    )[0].category?.name}
                <AngleRightSmall
                  function={() => setTicketOpen(!ticketOpen)}
                  rotate180={ticketOpen}
                />
                {ticketOpen ? (
                  <ul>
                    <li
                      onClick={() =>
                        handleMetricsByCategory({ id: 0, category_id: null })
                      }
                    >
                      {t('events.selectTicket')}
                    </li>
                    {tickets?.data?.tickets
                      ?.filter((obj, pos, arr) => {
                        return (
                          arr
                            .map((mapObj) => mapObj['category_id'])
                            .indexOf(obj['category_id']) === pos
                        );
                      })
                      ?.map((item) => {
                        return (
                          <li
                            key={item.id}
                            onClick={() => handleMetricsByCategory(item)}
                          >
                            {item.category?.name}
                          </li>
                        );
                      })}
                  </ul>
                ) : null}
              </div>
              <ProfileButton
                icon={<Coupon />}
                text={t('events.seeTickets')}
                function={() =>
                  props.navigateToTicketerSections(
                    routes.events + '/' + eventId + routes.createTickets
                  )
                }
                disabled={false}
              />
            </div>
            <div className="detail-event-metrics-container d-flex justify-content-center align-items-center">
              {dashboardEventData.map((item) => {
                return (
                  <HomeBoxes
                    key={item.id}
                    item={item}
                    select={select}
                    selectOpen={selectOpen}
                    updateSelectStatus={updateSelectStatus}
                    updateSelect={updateSelect}
                    metrics={metricsState}
                    home={false}
                  />
                );
              })}
            </div>
          </div>
        </>
      ) : null}
      {event?.data?.status !== 1 ? null : ticketsIsLoading ||
        promoCodesIsLoading ? (
        <SpinnerPurple />
      ) : ticketsIsError || promoCodesIsError ? (
        <h3 className="fetching-error">{t('events.errorRetrievingTickets')}</h3>
      ) : event?.data?.status < 3 &&
        tickets?.data?.tickets &&
        promoCodes?.data?.promoCodes ? (
        <>
          <div className="detail-event-discounts d-flex flex-column justify-content-center">
            <div className="detail-event-discounts-title d-flex justify-content-between align-items-center">
              <h3>{t('events.cardsDiscounts')}</h3>
              {promoCodes?.data?.promoCodes?.length ? (
                <button
                  onClick={() =>
                    setDiscountModal({
                      data: tickets?.data?.tickets,
                      eventId: eventId,
                      popup: setPopup
                    })
                  }
                >
                  {t('events.discountButton')}
                </button>
              ) : null}
            </div>
            {promoCodes?.data?.promoCodes?.length ? (
              <div className={`discount-table-container d-flex flex-column`}>
                <div
                  className={`discount-table-title d-flex justify-content-between align-items-center`}
                >
                  <div
                    className={`discount-table-title-left d-flex justify-content-between align-items-center`}
                  >
                    <h6>{t('ticket.discountField3')}</h6>
                    <h6>{t('ticket.discountField4')}</h6>
                    <h6>{t('ticket.discountField1')}</h6>
                    <h6>{t('ticket.discountField5')}</h6>
                  </div>
                </div>
                {promoCodes?.data?.promoCodes?.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`discount-table-row d-flex justify-content-between align-items-center`}
                    >
                      <div
                        className={`discount-table-row-left d-flex justify-content-between align-items-center`}
                      >
                        <h6>{item.code}</h6>
                        <h6>{item.amount + ' %'}</h6>
                        <h6>{item.categories[0]}</h6>
                        <h6>{item.quantity}</h6>
                      </div>
                      <div
                        className={`discount-table-row-right ${
                          item.enabled ? '' : 'deactive'
                        } d-flex justify-content-center align-items-center`}
                        onClick={() =>
                          mutate({ id: item.id, enabled: !item.enabled })
                        }
                      >
                        <Switch />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="detail-event-discounts-bar d-flex justify-content-between align-items-center">
                <div className="detail-event-discounts-bar-left d-flex align-items-center">
                  <BadgeDiscountAlt />
                  {t('events.discountSentence')}
                </div>
                <button
                  onClick={() =>
                    setDiscountModal({
                      data: tickets?.data?.tickets,
                      eventId: eventId,
                      popup: setPopup
                    })
                  }
                >
                  {t('events.discountButton')}
                </button>
              </div>
            )}
          </div>
          <div className="detail-event-delete d-flex flex-column justify-content-center">
            <h3>{t('events.deleteEvent5')}</h3>
            <CancelDeleteEventComponent
              text1={t('events.deleteEvent3')}
              text2={t('events.deleteEvent4')}
              function={() =>
                setDeleteEventModal({
                  action: 2,
                  title: t('events.cancelCheck'),
                  text1: t('events.cancelText1'),
                  text2: t('events.cancelText2'),
                  list1: t('events.cancelList1'),
                  list2: t('events.cancelList2'),
                  textButton1: t('events.cancelButton1'),
                  textButton2: t('events.cancelButton2'),
                  event: event?.data,
                  svg: <BadgeCheck />
                })
              }
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default EventDetailComponentNotADraft;
