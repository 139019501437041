import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TicketBanner from '../../components/icons/TicketBanner.jsx';
import User from '../../components/icons/User.jsx';
import VideoPlay from '../../components/icons/VideoPlay.jsx';
import Image from '../../components/icons/Image.jsx';
import FileList from '../../components/icons/FileList.jsx';
import Music from '../../components/icons/Music.jsx';
import LocationPinSmall from '../icons/LocationPinSmall.jsx';
import TicketItemButtons from './TicketItemButtons.jsx';
import { AppContext } from '../../App.js';
import { NumericFormat } from 'react-number-format';

export default function TicketItem(props) {
  const { elvis, ticketingCompany } = useContext(AppContext);
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const [content, setContent] = useState([
    props.ticketContent?.video ? props.ticketContent?.video : [],
    props.ticketContent?.photo ? props.ticketContent?.photo : [],
    props.ticketContent?.audio ? props.ticketContent?.audio : [],
    props.ticketContent?.document ? props.ticketContent?.document : []
  ]);

  useEffect(() => {
    if (
      !content[0]?.length &&
      !content[1]?.length &&
      !content[2]?.length &&
      !content[3]?.length
    ) {
      var contentArray = content;
      content[0] = props.ticketContent?.video ? props.ticketContent?.video : [];
      content[1] = props.ticketContent?.photo ? props.ticketContent?.photo : [];
      content[2] = props.ticketContent?.audio ? props.ticketContent?.audio : [];
      content[3] = props.ticketContent?.document
        ? props.ticketContent?.document
        : [];
      setContent(contentArray);
    }
  }, [props.ticketContent]);

  return (
    <div
      className={`ticket-item d-flex flex-column ticket-hover ticket-active ${
        props.propsId === 0 || props.propsId === 1 ? 'ticket-clickable' : ''
      }`}
      onClick={props.availableTickets === 0 ? null : props.function}
    >
      <div
        className={`ticket-item-banner d-flex justify-content-center align-items-center`}
      >
        {props.ticketStatus ? (
          <div
            className={`ticket-item-banner-info d-flex justify-content-center align-items-center`}
          >
            {props.ticketStatus === 1 ||
            props.ticketStatus === 2 ||
            props.ticketStatus === 3 ||
            props.ticketStatus === 4 ? (
              <div
                className={`${
                  props.ticketStatus === 1 || props.ticketStatus === 3
                    ? 'green-dot'
                    : 'yellow-dot'
                }`}
              ></div>
            ) : null}
            {t(`ticket.ticketStatus${props.ticketStatus}`) +
              (props.ticketStatus === 4 || props.ticketStatus === 6
                ? '28-03-2023'
                : '')}
          </div>
        ) : null}
        {props.ticketImg ? (
          <img src={props.ticketImg} alt={`ticket-banner`} className="" />
        ) : props.eventImg ? (
          <img src={props.eventImg} alt={`ticket-banner-`} className="" />
        ) : (
          <TicketBanner />
        )}
      </div>
      <div className={`ticket-item-text d-flex flex-column`}>
        <div className={`ticket-item-type d-flex flex-column`}>
          <h3>
            {props.ticketCategory ? props.ticketCategory : '-'}
            <span> | {props.ticketName ? props.ticketName : '-'}</span>
          </h3>
          <h2>{props.eventName}</h2>
          <NumericFormat
            value={props.ticketPrice === null ? '0.00' : props.ticketPrice}
            thousandsGroupStyle="thousand"
            thousandSeparator=","
            displayType="text"
            renderText={(value) => (
              <h4>
                {ticketingCompany?.data[0].currency?.id !== 3
                  ? ticketingCompany?.data[0].currency?.symbol + value
                  : value + ticketingCompany?.data[0].currency?.symbol}
              </h4>
            )}
          />
          {/* <h4>
            {props.ticketPrice === null
              ? '0.00' + props.currency
              : props.ticketPrice}
          </h4> */}
        </div>
        {props.landing ? null : (
          <>
            <div className="ticket-item-separator"></div>
            <div className={`ticket-item-location d-flex flex-column`}>
              <div
                className={`ticket-item-location-top box-text-item d-flex align-items-center`}
              >
                <LocationPinSmall />
                {props.eventAddress ? props.eventAddress : '-'}
              </div>
              <div className={`ticket-item-location-bottom d-flex`}>
                <div
                  className={`ticket-item-location-bottom-left d-flex flex-column`}
                >
                  <p>{t('ticket.date')}</p>
                  <p>
                    {props.eventDate ? props.eventDate?.split(' ')[0] : '-'}
                  </p>
                </div>
                <div
                  className={`ticket-item-location-bottom-right d-flex flex-column`}
                >
                  <p>{t('ticket.time')}</p>
                  <p>
                    {props.eventDate
                      ? props.eventDate?.split(' ')[1].slice(0, 5)
                      : '-'}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="ticket-item-separator"></div>
        <div className={`ticket-item-extra d-flex flex-column`}>
          <div className={`ticket-item-extra-top d-flex flex-column`}>
            {t('ticket.content')}
          </div>
          {content[0]?.length ||
          content[1]?.length ||
          content[2]?.length ||
          content[3]?.length ||
          (Array.isArray(props.ticketContent)
            ? props.ticketContent?.length
            : null) ? (
            <div
              className={`ticket-item-extra-top-container d-flex align-items-center`}
            >
              {content[0].length ||
              (Array.isArray(props.ticketContent)
                ? props.ticketContent?.filter((item) => item.type === 'video')
                    ?.length
                : null) ? (
                <div
                  className={`ticket-item-extra-bottom box-text-item ticket-orange-color d-flex justify-content-center align-items-center`}
                >
                  <VideoPlay />
                  {t('ticket.content1')}
                </div>
              ) : null}
              {content[1].length ||
              (Array.isArray(props.ticketContent)
                ? props.ticketContent?.filter((item) => item.type === 'image')
                    ?.length
                : null) ? (
                <div
                  className={`ticket-item-extra-bottom box-text-item ticket-orange-color d-flex justify-content-center align-items-center`}
                >
                  <Image />
                  {t('ticket.content2')}
                </div>
              ) : null}
              {content[2].length ||
              (Array.isArray(props.ticketContent)
                ? props.ticketContent?.filter((item) => item.type === 'audio')
                    ?.length
                : null) ? (
                <div
                  className={`ticket-item-extra-bottom box-text-item ticket-orange-color d-flex justify-content-center align-items-center`}
                >
                  <Music />
                  {t('ticket.content4')}
                </div>
              ) : null}
              {content[3].length ||
              (Array.isArray(props.ticketContent)
                ? props.ticketContent?.filter(
                    (item) => item.type === 'application'
                  )?.length
                : null) ? (
                <div
                  className={`ticket-item-extra-bottom box-text-item ticket-orange-color d-flex justify-content-center align-items-center`}
                >
                  <FileList />
                  {t('ticket.content3')}
                </div>
              ) : null}
            </div>
          ) : (
            <div
              className={`ticket-item-extra-bottom box-text-item d-flex justify-content-center align-items-center`}
            >
              {t('ticket.emptyContent')}
            </div>
          )}
        </div>
        <div className="ticket-item-separator"></div>
      </div>
      {props.buttonActive ? (
        props.buttonMethod === 'recover' ? (
          <>
            <TicketItemButtons
              id={props.id}
              saleOpen={null}
              ticketSaleQuantity={null}
              changeQuantity={null}
              buttonFunction={props.buttonFunction}
              disabled={props.isFinished}
              text={t('ticket.recover')}
            />
          </>
        ) : props.availableTickets !== '0' ? (
          <>
            {props.ticketPrice !== null ? (
              <TicketItemButtons
                id={props.id}
                saleOpen={props.saleOpen}
                ticketSaleQuantity={props.ticketSaleQuantity}
                changeQuantity={
                  props.availableTickets === 0 ? null : props.changeQuantity
                }
                buttonFunction={
                  props.availableTickets === 0 ? null : props.buttonFunction
                }
                disabled={props.availableTickets === 0 || props.isFinished}
                text={
                  props.availableTickets === 0 ? 'SOLD OUT' : t('ticket.buy')
                }
                secondaryMarketUser={props.secondaryMarketUser}
              />
            ) : (
              <TicketItemButtons
                id={props.id}
                saleOpen={props.saleOpen}
                ticketSaleQuantity={props.ticketSaleQuantity}
                changeQuantity={
                  props.availableTickets === 0 ? null : props.changeQuantity
                }
                buttonFunction={
                  props.availableTickets === 0 ? null : props.buttonFunction
                }
                disabled={props.availableTickets === 0 || props.isFinished}
                text={
                  props.availableTickets === 0
                    ? 'SOLD OUT'
                    : t('ticket.buyForFree')
                }
                secondaryMarketUser={props.secondaryMarketUser}
              />
            )}
          </>
        ) : (
          <>
            <TicketItemButtons
              id={null}
              saleOpen={null}
              ticketSaleQuantity={null}
              changeQuantity={null}
              buttonFunction={null}
              disabled={props.isFinished}
              text={t('ticket.soldOut')}
            />
          </>
        )
      ) : null}
      {props.secondaryMarketUser && props.propsId === 1 ? (
        <div className={`ticket-item-info d-flex justify-content-center`}>
          <User />
          <p>{t('ticket.sellFor')}</p>
          <p>
            {props.secondaryMarketUser?.name}{' '}
            {props.secondaryMarketUser?.surname}
          </p>
        </div>
      ) : props.availableTickets && props.propsId === 0 ? (
        <div className={`ticket-item-info d-flex justify-content-center`}>
          <p>
            {t('ticket.available1') +
              props.availableTickets +
              t('ticket.available2')}
          </p>
        </div>
      ) : props.totalTickets ? (
        <div className={`ticket-item-info d-flex justify-content-center`}>
          <p>
            {elvis
              ? props.totalTickets + t('ticket.available3')
              : props.totalTickets + t('ticket.available2')}
          </p>
        </div>
      ) : props.propsId === 3 ? (
        <div className={`ticket-item-info d-flex justify-content-center`}>
          <p>{t('ticket.eventFinished')}</p>
        </div>
      ) : props.propsId === 0 ? (
        <div className={`ticket-item-info d-flex justify-content-center`}>
          <p>{t('ticket.eventOnGoing')}</p>
        </div>
      ) : (
        props.eventStatus === 1 &&
        props.availableTickets === 0 && (
          <div className={`ticket-item-info d-flex justify-content-center`}>
            <p>
              {props.availableTickets} {t('ticket.0ticketsAvailables')}
            </p>
          </div>
        )
      )}
    </div>
  );
}
