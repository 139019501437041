import React from "react";

function ReceiveStar(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 3H7C5 3 4 4 4 6V18.621C4 19.445 4.94098 19.915 5.59998 19.421L6.95898 18.402C7.55598 17.954 8.39204 18.014 8.92004 18.541L10.939 20.56C11.525 21.146 12.4751 21.146 13.0601 20.56L15.079 18.541C15.607 18.013 16.443 17.954 17.04 18.402L18.399 19.421C19.058 19.915 19.999 19.445 19.999 18.621V6C20 4 19 3 17 3ZM16.106 10.488L14.576 11.968C14.464 12.076 14.4129 12.232 14.4399 12.385L14.79 14.41C14.861 14.821 14.428 15.134 14.056 14.941L12.22 13.983C12.082 13.911 11.918 13.911 11.78 13.983L9.94495 14.94C9.57295 15.134 9.13896 14.82 9.20996 14.409L9.56006 12.385C9.58606 12.232 9.53495 12.076 9.42395 11.968L7.89404 10.488C7.61304 10.216 7.76801 9.741 8.15601 9.686L10.2729 9.38101C10.4269 9.35901 10.56 9.26299 10.629 9.12399L11.546 7.28101C11.732 6.90701 12.269 6.90701 12.455 7.28101L13.3719 9.12399C13.4409 9.26299 13.574 9.35901 13.728 9.38101L15.845 9.686C16.233 9.742 16.387 10.216 16.106 10.488Z" fill="#816CFF"/>
    </svg>
  );
}

export default ReceiveStar;