import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getEventById,
  getTicketsTypeByEvent,
  stripeAccountVerify
} from '../../services/axiosService.js';
import DashboardBar from '../../components/shared/DashboardBar.jsx';
import CouponHeart from '../../components/icons/CouponHeart.jsx';
import EmptyItemsBox from '../../components/shared/EmptyItemsBox.jsx';
import routes from '../../routes/routes.js';
import EmptyTickets from '../../components/icons/EmptyTickets.jsx';
import TicketItem from '../../components/ui/TicketItem.jsx';
import ArrowsRepeatCircleModal from '../../components/icons/ArrowsRepeatCircleModal.jsx';
import SuccessFailPopupLarge from '../../components/ui/SuccessFailPopupLarge.jsx';
import ProfileButton from '../../components/ui/ProfileButton.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';

function TicketsEvent(props) {
  // eslint-disable-next-line
  const [t] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    setTicketerModal,
    setTicketerSectionModal,
    elvis,
    setDeleteEventModal
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  let { eventId } = useParams();
  const [isPaymentActive, setIspaymentActive] = useState(undefined);
  const [popup, setPopup] = useState(false);
  const [enabledTickets, setEnabledTickets] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data: events } = useQuery({
    queryKey: ['events', eventId],
    queryFn: () => getEventById(authState.token, eventId),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const {
    isLoading: ticketsIsLoading,
    isError: ticketsIsError,
    data: tickets
  } = useQuery({
    queryKey: ['tickets-model', eventId],
    queryFn: () =>
      getTicketsTypeByEvent(
        authState.token,
        eventId,
        null,
        null,
        null,
        null,
        null,
        'desc',
        'price'
      ),
    enabled: !!authState.isAuthenticated && isPaymentActive,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    setTicketerModal(false);
    setTicketerSectionModal(false);
  }, []);

  useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup]);

  const { data } = useQuery({
    queryKey: ['stripeAccountVerify'],
    queryFn: () => stripeAccountVerify(authState.token),
    onSuccess: (res) => {
      if (res) {
        setIspaymentActive(res?.data?.verified);
        setEnabledTickets(true);
      }
    },
    onError: (err) => {
      setIspaymentActive(false);
      setIsLoading(false);
      /* navigate(routes.paymentFailed); */
    }
  });

  return (
    <section
      id="create-tickets-event-section"
      className="create-tickets-event-section d-flex flex-column justify-content-start justify-content-lg-center align-items-center"
    >
      <DashboardBar
        text={
          t('events.title') +
          (events?.data.name ? ' / ' + events?.data?.name : '')
        }
        session={false}
        emoji={null}
        icon={<CouponHeart />}
        buttonText={t('ticket.createTickets')}
        function={() => {
          if (isPaymentActive === false) {
            setPopup({
              state: 0,
              text: (
                <span className="popup-paymentMethod">
                  😓 Para poder publicar tickets, debes agregar tu cuenta de
                  Stripe en la ticketera.{' '}
                  <Link to={routes.payment}>Añadir cuenta</Link>
                </span>
              )
            });
          } else {
            navigate(`${routes.events}/${eventId}${routes.createTicketsData}`);
          }
        }}
        disabled={false}
        button={
          events?.data
            ? events?.data?.status === 1 || events?.data?.status === 3
              ? elvis
              : false
            : false
        }
        ticketer={
          events?.data ? (events?.data?.status === 1 ? elvis : false) : false
        }
        icon2={null}
        buttonText2={t('ticket.activateSale')}
        function2={() =>
          setDeleteEventModal({
            action: 5,
            title: t('ticket.activateTicketTitle'),
            text1: t('ticket.activateTicketParagraph'),
            textButton1: t('events.cancelButton1'),
            textButton2: t('ticket.activateTicket'),
            event: events?.data,
            svg: <ArrowsRepeatCircleModal />
          })
        }
        disabled2={
          events?.data ? (events?.data?.status === 1 ? false : elvis) : elvis
        }
        breadcrum={true}
        breadcrumFunction={() => navigate(-1)}
        span={t('ticket.tickets')}
      />
      {popup ? (
        <SuccessFailPopupLarge
          text={popup.text}
          state={popup.state}
          function={() => setPopup(null)}
        />
      ) : null}
      <div
        className={`create-tickets-bottom d-flex flex-column justify-content-center align-items-center`}
      >
        {/* SI NO HAY METODO DE COBRO */}
        {!data && isPaymentActive !== undefined && (
          <div className="home-section-events-box d-flex flex-column justify-content-start justify-content-lg-center align-items-center">
            <div
              className={`home-section-events-box-empty d-flex flex-column justify-content-center align-items-center`}
            >
              <div className="home-section-events-box-empty-icon d-flex flex-column justify-content-center align-items-center">
                <div
                  className={`home-section-events-box-empty-text d-flex flex-column justify-content-center align-items-center`}
                >
                  <h3>Configura un método de cobro</h3>
                  <h4>
                    Para poder crear tickets necesitas vincular un método de
                    cobro.
                  </h4>
                  {/* <p>{ props.text3 }<span>{ props.span }</span></p> */}
                </div>
              </div>
              <ProfileButton
                icon={null}
                text={'Ir a método de cobro '}
                function={() => navigate(routes.payment)}
                disabled={false}
              />
            </div>
          </div>
        )}

        {/* SI HAY METODO DE COBRO Y HAY TICKETS */}
        {isPaymentActive && tickets?.data?.tickets?.length > 0 && (
          <>
            <p>{t('ticket.createParagraph')}</p>
            <div
              className={`create-tickets-container d-flex flex-wrap justify-content-start align-items-center`}
            >
              {tickets?.data?.tickets?.map((item) => {
                return (
                  <TicketItem
                    key={item.id}
                    ticketStatus={item.ticketStatus}
                    eventStatus={events?.data?.status}
                    ticketImg={item.image}
                    eventImg={events?.data?.images.cover}
                    ticketCategory={item.category?.name}
                    currency={item?.currency?.symbol}
                    ticketName={item.name}
                    eventName={events?.data?.name}
                    eventDate={events?.data?.dateEvent}
                    eventAddress={events?.data?.location}
                    ticketPrice={
                      item.price
                        ? item.currency?.id === 2
                          ? item.currency?.symbol +
                            Number(item.price).toFixed(2)
                          : Number(item.price).toFixed(2) +
                            item.currency?.symbol
                        : null
                    }
                    ticketContent={item.extraContent}
                    totalTickets={elvis ? item?.total : item?.amount}
                    availableTickets={null}
                    secondaryMarketUser={null}
                    buttonActive={false}
                    function={() =>
                      navigate(
                        routes.events +
                          '/' +
                          eventId +
                          routes.tickets +
                          '/' +
                          item.id
                      )
                    }
                    ticketPaused={false}
                    ticketActivationDate={null}
                    saleOpen={null}
                    setTicketSaleOpen={null}
                  />
                );
              })}
            </div>
          </>
        )}

        {/* SI DA ERROR LOS TICKETS */}
        {ticketsIsError && (
          <h3 className="fetching-error">
            {t('events.errorRetrievingTickets')}
          </h3>
        )}

        {/* SI NO DA ERROR PERO NO HAY TICKETS CONDIGURADOS */}
        {tickets?.data?.tickets?.length <= 0 && isPaymentActive && (
          <EmptyItemsBox
            icon={<EmptyTickets />}
            text1={t('ticket.letsCreate')}
            text2={null}
            text3={t('ticket.wantCreate')}
            span={null}
            buttonIcon={<CouponHeart />}
            buttonText={t('ticket.createTickets')}
            buttonFunction={
              events?.data
                ? events?.data?.status === 1 || events?.data?.status === 3
                  ? () =>
                      navigate(
                        `${routes.events}/${eventId}${routes.createTicketsData}`
                      )
                  : null
                : null
            }
            buttonDisabled={
              events?.data
                ? events?.data?.status === 1 || events?.data?.status === 3
                  ? false
                  : elvis
                : elvis
            }
          />
        )}

        {isLoading && !data && (
          <div className="home-section-events-box d-flex flex-column justify-content-start justify-content-lg-center align-items-center">
            <div
              className={`home-section-events-box-empty d-flex flex-column justify-content-center align-items-center`}
            >
              <div className="home-section-events-box-empty-icon d-flex flex-column justify-content-center align-items-center">
                <SpinnerPurple />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default TicketsEvent;
