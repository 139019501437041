import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from "../../App.js";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from 'react-router-dom';
import routes from "../../routes/routes.js";
import { getTicketingCompanyById } from "../../services/axiosService.js";
import TicketingSectionComponent from "../../components/elvis/TicketingSectionComponent.jsx";
import ButtonGroupTibet from "../../components/shared/ButtonGroupTibet.jsx";
import FileHeart from "../../components/icons/FileHeart.jsx";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function PolicyPageChildren(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { errors, dirty, touched, isValid, setFieldValue, submitForm } = useFormikContext();

  return (
    <>
      <CKEditor
        editor={ ClassicEditor }
        data={props.initialValues.policy}
        config={{
          placeholder: t('ticketerSection.ckeditorPlaceholder'),
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'undo',
            'redo'
          ]
        }}
        onReady={ editor => {
            // You can store the "editor" and use when it is needed.
            //console.log( 'Editor is ready to use!', editor );
        } }
        onChange={ ( event, editor ) => {
            const data = editor.getData();
            setFieldValue("policy", data)
        } }
        onBlur={ ( event, editor ) => {
            //console.log( 'Blur.', editor );
        } }
        onFocus={ ( event, editor ) => {
            //console.log( 'Focus.', editor );
        } }
      />
      <ButtonGroupTibet button = { { text: t('ticketer.save'), disabled: !((isValid && dirty) && !(errors.policy && touched.policy)), function: submitForm } }/>
    </>
  );
}

function TermsPage(props) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const { state: authState, dispatch, ticketerModal, setTicketerModal, role } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { isLoading, isError, error, data: ticketingCompany, isFetching } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: "LOGOUT",
        })
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialPolicyData = {
    policy: ticketingCompany?.data[0]?.privacyPolicy ? ticketingCompany?.data[0]?.privacyPolicy : "",
  }

  const dataPolicySchema = Yup.object().shape(
    {
      policy: Yup.string().required(t('register.error2'))
    }
  );

  return (
    <TicketingSectionComponent
      initialValues = { initialPolicyData }
      validationSchema = { dataPolicySchema }
      ticketer = { true }
      title = { t('ticketer.policy1') }
      pagination = { null }
      description = { t('ticketer.infoPolicy2') }
      footer = { { text: t('ticketer.advicePolicy'), icon: <FileHeart/> } }
    >
      <PolicyPageChildren initialValues = { initialPolicyData }/>
    </TicketingSectionComponent>
  );
}

export default TermsPage;