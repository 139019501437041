import React from "react";

function ProfileLines(props) {

  return (
    <svg width="201" height="144" viewBox="0 0 201 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M122.429 57.3807C172.796 69.9603 220.197 53.8485 228.303 21.3938C236.409 -11.0609 202.15 -47.5684 151.783 -60.148C101.416 -72.7277 54.0148 -56.6159 45.9089 -24.1612C37.803 8.29345 72.0622 44.801 122.429 57.3807Z" stroke="#B2A6FF" strokeWidth="3" strokeMiterlimit="10"/>
      <path opacity="0.3" d="M149.84 57.8632C200.596 46.948 236.04 11.5866 229.006 -21.1186C221.973 -53.8238 175.126 -71.488 124.37 -60.5728C73.6143 -49.6576 38.1704 -14.2963 45.2038 18.4089C52.2372 51.1141 99.0845 68.7784 149.84 57.8632Z" stroke="#B2A6FF" strokeWidth="3" strokeMiterlimit="10"/>
      <path d="M92.6508 -3.05385C99.5238 48.4032 123.083 87.7147 145.272 84.751C167.46 81.7873 179.876 37.6706 173.003 -13.7864C166.13 -65.2434 142.571 -104.555 120.382 -101.591C98.1936 -98.6276 85.7778 -54.5109 92.6508 -3.05385Z" stroke="#B2A6FF" strokeWidth="2" strokeMiterlimit="10"/>
      <path d="M100.283 22.8794C121.635 67.0393 153.506 88.8304 171.47 71.5512C189.434 54.272 186.688 4.46575 165.336 -39.6942C143.985 -83.8541 112.113 -105.645 94.1491 -88.3661C76.1852 -71.0869 78.9314 -21.2806 100.283 22.8794Z" stroke="#B2A6FF" strokeWidth="2" strokeMiterlimit="10"/>
    </svg>
  );
}

export default ProfileLines;