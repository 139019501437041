import React from "react";

function Star4(props) {

  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9783 1.72364C13.2397 0.950234 14.3336 0.950231 14.5951 1.72364L15.8735 5.5055C16.2045 6.48467 17.3231 6.94718 18.2489 6.48772L21.7924 4.7293C22.5206 4.36795 23.2937 5.13292 22.9401 5.86488L21.1744 9.51976C20.7304 10.4388 21.1871 11.5396 22.1513 11.8744L25.9512 13.1939C26.7158 13.4594 26.7158 14.5406 25.9512 14.8061L22.1513 16.1256C21.1871 16.4604 20.7304 17.5612 21.1744 18.4802L22.9401 22.1351C23.2937 22.8671 22.5206 23.6321 21.7924 23.2707L18.2489 21.5123C17.3231 21.0528 16.2045 21.5153 15.8735 22.4945L14.5951 26.2764C14.3336 27.0498 13.2397 27.0498 12.9783 26.2764L11.6998 22.4945C11.3688 21.5153 10.2503 21.0528 9.32439 21.5123L5.78092 23.2707C5.05275 23.6321 4.27962 22.8671 4.63323 22.1351L6.39889 18.4802C6.84289 17.5612 6.38623 16.4604 5.42199 16.1256L1.62211 14.8061C0.857564 14.5406 0.857566 13.4594 1.62211 13.1939L5.42199 11.8744C6.38623 11.5396 6.84289 10.4388 6.39889 9.51976L4.63323 5.86488C4.27962 5.13292 5.05275 4.36795 5.78092 4.7293L9.32439 6.48772C10.2503 6.94718 11.3688 6.48467 11.6998 5.50549L12.9783 1.72364Z" fill="white" stroke="white" strokeWidth="0.853333"/>
    </svg>
  );
}

export default Star4;