import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App.js';
import { useNavigate, useLocation } from 'react-router-dom';
import TicketerBanner from '../../components/icons/TicketerBanner';
import HomeEventHorizontal from '../../components/shared/HomeEventHorizontal';
import EmptyItemsBox from '../../components/shared/EmptyItemsBox';
import ProfileButton from '../../components/ui/ProfileButton.jsx';
import { useQuery } from '@tanstack/react-query';
import {
  getTicketingCompanyById,
  getEvents,
  getPublicElvisKey
} from '../../services/axiosService.js';
import { getSubdomain } from '../../components/shared/functions.js';
import HeartElvis from '../../components/icons/HeartElvis';
import RocketLaunchSmall from '../../components/icons/RocketLaunchSmall.jsx';
import LandingFooter from '../../components/shared/LandingFooter.jsx';
import routes from '../../routes/routes.js';
import EmptyEvents from '../../components/icons/EmptyEvents.jsx';
import NotFoundPage from '../404/NotFoundPage.jsx';

export default function LandingTicketer() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const {
    state: authState,
    dispatch,
    publicElvisId,
    setPublicElvisId,
    ticketingCompany,
    setTicketingCompany
  } = React.useContext(AppContext);

  var navigate = useNavigate();
  const location = useLocation().pathname;
  const subdomain = useMemo(() => getSubdomain(), []);

  const charMax = 150;

  const [seeMore, setSeeMore] = useState(false);

  //const [events, setEvents] = useState({data: { events: [] }});

  const { data } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: subdomain
      ? () => getPublicElvisKey(subdomain)
      : () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated || !!subdomain,
    onSuccess: (res) => {
      setTicketingCompany(
        subdomain ? { data: [res.data] } : { data: [res.data[0]] }
      );
      setPublicElvisId(res.data.apiKey);
    },
    onError: (err) => {
      if (err.response.status === 401) {
        if (authState.isAuthenticated) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      } else {
        <NotFoundPage />;
        /* window.location.replace(`https://tibetlocal.com:3001/landing`); */
      }
      if (err.response.status === 404) {
        window.location.href = 'https://tibetpass.com/landing';
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false
  });

  const { data: events } = useQuery({
    queryKey: ['events', { ticketerId: ticketingCompany?.data[0]?.id }],
    queryFn: publicElvisId
      ? () => getEvents({ apiKey: publicElvisId, dateNext: true, status: 1 })
      : () =>
          getEvents({ userToken: authState.token, dateNext: true, status: 1 }),
    enabled:
      !!authState.isAuthenticated ||
      (!!publicElvisId && ticketingCompany?.data[0]?.id !== null),
    onSuccess: (res) => {
      //setEvents(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        if (authState.isAuthenticated) {
          dispatch({
            type: 'LOGOUT'
          });
          navigate(routes.auth);
        }
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (
      subdomain &&
      authState.isAuthenticated &&
      authState.roles[0] === 'ROLE_ADMIN'
    ) {
      dispatch({
        type: 'LOGOUT'
      });
    }
  }, []);

  return data ? (
    <main
      id={`home-elvis-${subdomain}`}
      className={`home-elvis-main d-flex flex-column justify-content-between align-items-center`}
    >
      <div className={`w-100 d-flex flex-column align-items-center`}>
        <div
          className={`home-elvis-main-banner d-flex justify-content-center align-items-center`}
        >
          {!(subdomain && ticketingCompany?.data[0]?.isPublished) ? (
            !ticketingCompany?.data[0]?.isPublished ? null : location ===
              routes.ticketerPreviewCustomize ? null : (
              <ProfileButton
                icon={
                  !!authState.isAuthenticated &&
                  authState.roles[0] === 'ROLE_ADMIN'
                    ? null
                    : null
                }
                text={
                  !!authState.isAuthenticated &&
                  authState.roles[0] === 'ROLE_ADMIN'
                    ? t('gift.giftButton3')
                    : null
                }
                function={
                  !!authState.isAuthenticated &&
                  authState.roles[0] === 'ROLE_ADMIN'
                    ? () => navigate(routes.ticketer)
                    : null
                }
                disabled={false}
              />
            )
          ) : (
            <ProfileButton
              icon={
                !!authState.isAuthenticated &&
                authState.roles[0] === 'ROLE_USER' ? null : (
                  <RocketLaunchSmall />
                )
              }
              text={
                !!authState.isAuthenticated &&
                authState.roles[0] === 'ROLE_USER'
                  ? authState?.userId
                      ?.split(' ')
                      .map(function (item, index) {
                        if (index < 2) return item[0];
                        else return null;
                      })
                      .join('')
                      .toUpperCase()
                  : t('landingElvis.login')
              }
              function={
                !!authState.isAuthenticated &&
                authState.roles[0] === 'ROLE_USER'
                  ? () => navigate(routes.homepage)
                  : () => navigate(routes.auth)
              }
              disabled={false}
            />
          )}
          {ticketingCompany?.data[0]?.background ? (
            <img
              src={ticketingCompany?.data[0]?.background}
              alt={`landing-logo-${'item.id'}`}
              className=""
            />
          ) : (
            <TicketerBanner />
          )}
        </div>
        <div
          className={`home-elvis-main-data d-flex flex-column flex-lg-row justify-content-between align-items-start`}
        >
          <div
            className={`home-elvis-main-data-left d-flex flex-column justify-content-start align-items-center`}
          >
            {ticketingCompany?.data[0]?.logo ? (
              <img
                src={ticketingCompany?.data[0]?.logo}
                alt={`landing-logo-${'item.id'}`}
                className=""
              />
            ) : (
              <HeartElvis />
            )}
            <h2>
              {ticketingCompany?.data[0]?.comercialName
                ? ticketingCompany?.data[0]?.comercialName
                : t('landingElvis.ticketerName')}
            </h2>
            <p className="d-inline d-lg-none">
              {ticketingCompany?.data[0]?.description
                ? ticketingCompany?.data[0]?.description?.length >= 150
                  ? ticketingCompany?.data[0]?.description?.substring(
                      0,
                      seeMore ? 500 : charMax
                    ) + (seeMore ? '' : '...')
                  : ticketingCompany?.data[0]?.description
                : t('landingElvis.ticketerDescription')}
              {ticketingCompany?.data[0]?.description ? (
                ticketingCompany?.data[0]?.description.length >= 150 ? (
                  <span onClick={() => setSeeMore(!seeMore)}>
                    {seeMore
                      ? t('landingElvis.seeLess')
                      : t('landingElvis.seeMore')}
                  </span>
                ) : null
              ) : null}
            </p>
            <p className="d-none d-lg-inline">
              {ticketingCompany?.data[0]?.description}
            </p>
          </div>
          <div
            className={`home-elvis-main-data-right d-flex flex-column justify-content-between align-items-start`}
          >
            <h3>{t('events.section2')}</h3>
            {events?.data?.events?.length !== 0 ? (
              <div className="home-section-events-horizontal-container d-flex flex-wrap justify-content-center align-items-center">
                {events?.data?.events?.map((item) => {
                  return (
                    <HomeEventHorizontal
                      key={item.id}
                      item={item}
                      function={
                        location === routes.ticketerPreview ||
                        location === routes.ticketerPreviewCustomize
                          ? null
                          : () => navigate(`${item.id}`)
                      }
                      elvis={
                        location === routes.ticketerPreview ||
                        location === routes.ticketerPreviewCustomize
                          ? null
                          : () => navigate(`${item.id}`)
                      }
                      landing={true}
                    />
                  );
                })}
              </div>
            ) : (
              <EmptyItemsBox
                icon={<EmptyEvents />}
                text1={t('landingElvis.nextEvents1')}
                text2={t('landingElvis.nextEvents2')}
                text3={null}
                span={null}
                buttonIcon={null}
                buttonText={null}
                buttonFunction={null}
                buttonDisabled={true}
                landing={true}
              />
            )}
          </div>
        </div>
      </div>
      {true ? (
        <LandingFooter landingPage={true} company={ticketingCompany?.data[0]} />
      ) : null}
    </main>
  ) : null;
}
