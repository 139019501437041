import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { AppContext } from '../../App.js';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes.js';
import {
  getTicketingCompanyById,
  getCurrencies
} from '../../services/axiosService.js';
import TicketingSectionComponent from '../../components/elvis/TicketingSectionComponent.jsx';
import ButtonGroupTibet from '../../components/shared/ButtonGroupTibet.jsx';
import ShieldExclamationSmall from '../../components/icons/ShieldExclamationSmall.jsx';
import SpinnerPurple from '../../components/icons/SpinnerPurple.jsx';

function InfoThirdPageChildren(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');
  const { ticketingCompany } = useContext(AppContext);

  const { errors, dirty, touched, values, isValid, submitForm } =
    useFormikContext();

  var navigate = useNavigate();

  const ticketCompany = ticketingCompany?.data?.[0];

  const { isLoading, isError, error, data, isFetching } = useQuery({
    queryKey: ['currencies'],
    queryFn: () => getCurrencies(props.authState.token),
    enabled: !!props.authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        props.dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: false,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  return (
    <>
      <div className="ticketer-field-info-container d-flex flex-column">
        <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
          <div>
            <h5>{t('ticketerSection.infoField6') + '*'}</h5>
            <Field
              className={
                errors.social && touched.social
                  ? 'ticketer-field red-border'
                  : touched.social
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="ticketer-social"
              type="text"
              name="social"
              placeholder=""
            />
            {errors.social && touched.social ? (
              <ErrorMessage name="social">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <h5>{t('ticketerSection.infoField7') + '*'}</h5>
            <Field
              className={
                errors.address && touched.address
                  ? 'ticketer-field red-border'
                  : touched.address
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="ticketer-address"
              type="text"
              name="address"
              placeholder=""
            />
            {errors.address && touched.address ? (
              <ErrorMessage name="address">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
        </div>
        <div className="w-100 ticketer-field-info-email d-flex flex-column flex-lg-row">
          <div>
            <h5>{t('ticketerSection.infoField8') + '*'}</h5>
            <Field
              className={
                errors.cif && touched.cif
                  ? 'ticketer-field red-border'
                  : touched.cif
                  ? 'ticketer-field purple-border'
                  : 'ticketer-field'
              }
              id="ticketer-cif"
              type="text"
              name="cif"
              placeholder=""
            />
            {errors.cif && touched.cif ? (
              <ErrorMessage name="cif">
                {(msg) => <div className="ticketer-field-error">{msg}</div>}
              </ErrorMessage>
            ) : null}
          </div>
          <div>
            <h5>{t('ticketerSection.infoField9') + '*'}</h5>
            {isLoading ? (
              <SpinnerPurple />
            ) : isError ? (
              <h3 className="fetching-error">
                {t('home.errorRetrievingEvents')}
              </h3>
            ) : (
              <>
                <Field
                  className={`ticketer-field ticketer-select ${
                    errors.currency && touched.currency ? 'red-border' : null
                  } ${touched.currency ? 'purple-border' : null} ${
                    values.currency !== 0 ? 'select-black' : null
                  }`}
                  id="ticketer-currency"
                  name="currency"
                  placeholder=""
                  as="select"
                  disabled={ticketCompany?.paymentMethod !== null}
                >
                  <option value="0" defaultValue disabled hidden>
                    {t('ticketerSection.currency')}
                  </option>
                  {data?.data.currencies.map((item) => {
                    return (
                      <option
                        disabled={ticketCompany?.paymentMethod !== null}
                        key={item.id}
                        value={item.id}
                      >
                        {item.abbreviation}
                      </option>
                    );
                  })}
                </Field>
                {errors.currency && touched.currency ? (
                  <ErrorMessage name="currency">
                    {(msg) => <div className="ticketer-field-error">{msg}</div>}
                  </ErrorMessage>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      <ButtonGroupTibet
        button={{
          text: t('ticketer.finish'),
          disabled: !(
            isValid &&
            values.address &&
            values.address &&
            values.cif &&
            values.currency
          ),
          function: submitForm
        }}
      />
    </>
  );
}

function InfoThirdPage(props) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('global');

  const { state: authState, dispatch } = React.useContext(AppContext);

  var navigate = useNavigate();

  const { data: ticketingCompany } = useQuery({
    queryKey: ['ticketingCompany'],
    queryFn: () => getTicketingCompanyById(authState.token),
    enabled: !!authState.isAuthenticated,
    onSuccess: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.error(err.response.status);
      if (err.response.status === 401) {
        dispatch({
          type: 'LOGOUT'
        });
        navigate(routes.auth);
      }
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  var initialThirdInfoData = {
    social: ticketingCompany?.data[0]?.companyName
      ? ticketingCompany?.data[0]?.companyName
      : '',
    address: ticketingCompany?.data[0]?.address
      ? ticketingCompany?.data[0]?.address
      : '',
    cif: ticketingCompany?.data[0]?.cif ? ticketingCompany?.data[0]?.cif : '',
    currency: ticketingCompany?.data[0]?.currency?.id
  };

  const dataThirdInfoSchema = Yup.object().shape({
    social: Yup.string().required(t('register.error2')),
    address: Yup.string().required(t('register.error2')),
    cif: Yup.string().required(t('register.error2')),
    currency: Yup.number()
      .required()
      .positive(t('ticketerSection.currencyError'))
      .integer()
  });

  return (
    <TicketingSectionComponent
      initialValues={initialThirdInfoData}
      validationSchema={dataThirdInfoSchema}
      ticketer={true}
      title={t('ticketer.info1')}
      pagination={'3/3'}
      description={t('ticketer.infoTitle6')}
      footer={{
        text: t('ticketer.adviceInfo3'),
        icon: <ShieldExclamationSmall />
      }}
    >
      <InfoThirdPageChildren authState={authState} dispatch={dispatch} />
    </TicketingSectionComponent>
  );
}

export default InfoThirdPage;
