import React from "react";

function FilePlus(props) {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.75 6V3.75L19.25 8.25H17C15.42 8.25 14.75 7.58 14.75 6ZM20 9.75V18C20 20 19 21 17 21H8C6 21 5 20 5 18V6C5 4 6 3 8 3H13.25V6C13.25 8.42 14.58 9.75 17 9.75H20ZM15.75 14C15.75 13.586 15.414 13.25 15 13.25H13.25V11.5C13.25 11.086 12.914 10.75 12.5 10.75C12.086 10.75 11.75 11.086 11.75 11.5V13.25H10C9.586 13.25 9.25 13.586 9.25 14C9.25 14.414 9.586 14.75 10 14.75H11.75V16.5C11.75 16.914 12.086 17.25 12.5 17.25C12.914 17.25 13.25 16.914 13.25 16.5V14.75H15C15.414 14.75 15.75 14.414 15.75 14Z" fill="#A5A9B8"/>
    </svg>
  );
}

export default FilePlus;