import React from "react";

function Eye(props) {

  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.0521 15.4999C19.0521 17.4594 17.4595 19.052 15.5001 19.052C13.5406 19.052 11.948 17.4594 11.948 15.4999C11.948 15.0491 12.0409 14.6215 12.1946 14.2237C12.4452 14.4226 12.7475 14.5595 13.0924 14.5595C13.9022 14.5595 14.5597 13.9021 14.5597 13.0922C14.5597 12.7474 14.4228 12.445 14.2238 12.1944C14.6217 12.0407 15.0493 11.9478 15.5001 11.9478C17.4595 11.9478 19.0521 13.5405 19.0521 15.4999ZM26.5981 17.4078C25.0132 20.0609 21.4533 24.5416 15.5001 24.5416C9.54676 24.5416 5.98688 20.0609 4.402 17.4078C3.69933 16.2324 3.69933 14.7675 4.402 13.5921C5.98688 10.939 9.54676 6.45825 15.5001 6.45825C21.4533 6.45825 25.0132 10.939 26.5981 13.5921C27.3008 14.7675 27.3008 16.2324 26.5981 17.4078ZM20.9896 15.4999C20.9896 12.4735 18.5277 10.0103 15.5001 10.0103C12.4724 10.0103 10.0105 12.4735 10.0105 15.4999C10.0105 18.5263 12.4724 20.9895 15.5001 20.9895C18.5277 20.9895 20.9896 18.5263 20.9896 15.4999Z" fill="#816CFF"/>
    </svg>
  );
}

export default Eye;