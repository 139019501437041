import React,  { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import { Formik, Form, useFormikContext, Field, ErrorMessage } from 'formik';
import { useMutation } from '@tanstack/react-query';
import SpinnerPurple from '../icons/SpinnerPurple';
import { sendLandingMail } from '../../services/axiosService';
import SuccessFailPopup from '../ui/SuccessFailPopup';

export default function ContactForm({ landing }) {

  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const [popup, setPopup] = useState(false);

  var initialValues = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    message: ""
  }
  
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape(
    {
      name: Yup.string().required(t('register.error2')),
      surname: Yup.string().required(t('register.error2')),
      phone: Yup.string().matches(phoneRegExp, t('profile.profilePhoneError')).required(t('register.error2')),
      email: Yup.string().email(t('register.error1')).required(t('register.error2')),
      message: Yup.string().required(t('register.error2'))
    }
  );

  const { isLoading, isSuccess, isError, error, mutate } =
  useMutation((values) => sendLandingMail(values), {
    onSuccess: (res) => {
      if (res.data) {
        setPopup({ state: 1, text: t('landing.contactSuccess') });
      }
    },
    onError: (err) => {
      setPopup({ state: 0, text: t('landing.contactError') });
      console.error(err);
    }
  });

  /*useEffect(() => {
    if (popup) {
      setTimeout(() => {
        setPopup(false);
      }, 5000);
    }
  }, [popup])*/

  return (
    <>
      <Formik
        enableReinitialize = {true}
        initialValues = { initialValues } 
        validationSchema = { validationSchema }
        validateOnChange={ true }
        validateOnBlur={ true }
        onSubmit={async (values, actions) => {
          await mutate(values);
          actions.resetForm({ values: initialValues});
        }}
      >
      {props => {
        const {
          errors,
          touched,
          dirty,
          isValid,
          isSubmitting,
          setFieldTouched,
        } = props;
        i18n.on("languageChanged", () => {
          Object.keys(errors).forEach(fieldName => {
            setFieldTouched(fieldName);
          });
        });
        return (
          <Form className={`contact-landing-form-wrap d-flex flex-column align-items-center ${landing ? "align-items-lg-start" : "w-100" }`}>
            <div className="contact-landing-form d-flex flex-wrap">
              <div className="contact-landing-form-item d-flex flex-column">
                <h4>{t('landing.contactField1')}</h4>
                <Field id="landing-name" type="text" name="name" placeholder=""/>
                { errors.name && touched.name ? 
                  <ErrorMessage name="name">{msg => <div className="contact-landing-form-item-error">{ msg }</div>}</ErrorMessage>
                  :
                  <div className="contact-landing-form-item-error"></div>
                }
              </div>
              <div className="contact-landing-form-item d-flex flex-column">
                <h4>{t('landing.contactField2')}</h4>
                <Field id="landing-surname" type="text" name="surname" placeholder=""/>
                { errors.surname && touched.surname ? 
                  <ErrorMessage name="surname">{msg => <div className="contact-landing-form-item-error">{ msg }</div>}</ErrorMessage>
                  :
                  <div className="contact-landing-form-item-error"></div>
                }
              </div>
              <div className="contact-landing-form-item d-flex flex-column">
                <h4>{t('landing.contactField4')}</h4>
                <Field id="landing-email" type="email" name="email" placeholder=""/>
                { errors.email && touched.email ? 
                  <ErrorMessage name="email">{msg => <div className="contact-landing-form-item-error">{ msg }</div>}</ErrorMessage>
                  :
                  <div className="contact-landing-form-item-error"></div>
                }
              </div>
              <div className="contact-landing-form-item d-flex flex-column">
                <h4>{t('landing.contactField3')}</h4>
                <Field id="landing-phone" type="phone" name="phone" placeholder=""/>
                { errors.phone && touched.phone ? 
                  <ErrorMessage name="phone">{msg => <div className="contact-landing-form-item-error">{ msg }</div>}</ErrorMessage>
                  :
                  <div className="contact-landing-form-item-error"></div>
                }
              </div>
              <div className="contact-landing-form-item d-flex flex-column">
                <h4>{t('landing.contactField5')}</h4>
                <Field id="landing-message" type="message" name="message" placeholder=""/>
                { errors.message && touched.message ? 
                  <ErrorMessage name="message">{msg => <div className="contact-landing-form-item-error">{ msg }</div>}</ErrorMessage>
                  :
                  <div className="contact-landing-form-item-error"></div>
                }
              </div>
            </div>
            <div className="contact-landing-form-item d-flex flex-column align-items-center">
              <button disabled={ ((isValid && dirty) && !(errors.name && touched.name) && !(errors.surname && touched.surname) && !(errors.email && touched.email) && !(errors.phone && touched.phone) && !(errors.message && touched.message)) ? false : true } id="landing-contact-button" type="submit" className="terms-policy-button">
                { isSubmitting ? 
                  <SpinnerPurple/> 
                  :
                  t('landing.contactButton')}
              </button>
              { popup ? <h4>{ popup.text }</h4> : null }
            </div>
          </Form> 
        )}}
      </Formik>
    </>
  )

}
